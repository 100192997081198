import { createI18n } from 'vue-i18n';

const loadedLocales = {};

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.js$/i);
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      loadedLocales[locale] = locales(key).default;
      console.log(locale,'getegeteegtegete...11')
    }
  });
  return loadedLocales;
}



const i18n = createI18n({
  locale: 'CA-en',
  fallbackLocale: 'CA-en',
  messages: loadLocaleMessages(),
  // messages: {},
  // messages: {} || loadLocaleMessages(),
  // messages: loadLocaleMessages(),
});

export default i18n;


export function updateLocaleMessages(locale, messages) {
  i18n.global.setLocaleMessage(locale, messages);
}









// const getConfigData = getConfigDataFromLocalStorage();
//   console.log(getConfigData,'getConfigData');

// if(getConfigData){
//   var getLanguage = getConfigData.payload.portal_configuration.languages;
//   var countyIsocode = getConfigData.payload.portal_configuration.country.iso_code;
// }

//   const storedLocale = localStorage.getItem("defaultlang");
//   const storedSetLang = localStorage.getItem("setlang");

//   let getlocalIso = "";
//   let getlocalLang = "";
//   let getsetIso = "";
//   let getsetlang = "";
//   let savedLanguage = "";
  

//   if(storedLocale){
//     getlocalIso = storedLocale.split('-')[0];
//     getlocalLang = storedLocale.split('-')[1];    
//   }
//   if(storedSetLang){
//     getsetIso = storedSetLang.split('-')[0];
//     getsetlang = storedSetLang.split('-')[1];
//   }


//   if(storedSetLang && storedLocale){
//     console.log('Check...1');
//     if(storedLocale == storedSetLang){
//       savedLanguage = storedSetLang;
//       console.log('Check...2');
//     }
//     else if(storedLocale !== storedSetLang){
//       console.log(getlocalIso,getsetIso,'Check...3',getlocalLang,getsetlang);
//       if(getlocalIso !== getsetIso && getlocalLang !== getsetlang){
//         savedLanguage = storedSetLang;

//         localStorage.removeItem("defaultlang");
//         console.log('Check...4');
//       }
//       else if(getlocalIso == getsetIso && getlocalLang !== getsetlang){
//         savedLanguage = storedLocale;
//         console.log('Check...5'); 
//       }
//     }
//   }
//   else if(storedSetLang){
//     savedLanguage = storedSetLang;
//     console.log('Check...6');
//   }
//   else if(storedLocale){
//     savedLanguage = storedLocale;
//     console.log('Check...7');
//   }
//   else{
//     savedLanguage = `${countyIsocode}-${getLanguage[0].code}`;
//     console.log('Check...8');
//   }
















// const messages = {
//     'en': {
//         welcomeMsg: 'The best tour experience',
//         guide: 'find and book best',
//         // welcomeMsg: 'Welcome to Your Vue.js App',
//         // guide: 'For a guide and recipes on how to configure / customize this project,',
//         checkout: 'check out the',
//         plugins: 'Installed CLI Plugins',
//         links: 'Essential Links',
//         ecosystem: 'Ecosystem'
//     },
//     'es': {
//         welcomeMsg: 'La mejor experiencia de viaje',
//         guide: 'encuentra y reserva lo mejor',
//         // welcomeMsg: 'Bienvenido a tu aplicación Vue.js',
//         // guide: 'Para una guía y recetas sobre cómo configurar / personalizar este proyecto,',
//         checkout: 'revisar la',
//         plugins: 'Plugins de CLI instalados',
//         links: 'Enlaces esenciales',
//         ecosystem: 'Ecosistema'
//     },
//     'fr': {
//       welcomeMsg: 'La meilleure expérience de voyage',
//       guide: 'trouver et réserver le meilleur',
//       // welcomeMsg: 'Bienvenue dans votre application Vue.js',
//       // guide: 'Pour un guide et des recettes sur la configuration / personnalisation de ce projet,',
//       checkout: 'consultez',
//       plugins: 'Plugins CLI installés',
//       links: 'Liens essentiels',
//       ecosystem: 'Écosystème'
//     },
//     'de': {
//       welcomeMsg: 'Das beste Reiseerlebnis',
//       guide: 'finde und buche das Beste',
//       // welcomeMsg: 'Willkommen in Ihrer Vue.js-Anwendung',
//       // guide: 'Für eine Anleitung und Rezepte zur Konfiguration / Anpassung dieses Projekts,',
//       checkout: 'siehe',
//       plugins: 'Installierte CLI-Plugins',
//       links: 'Wichtige Links',
//       ecosystem: 'Ökosystem'
//     },
//     'it': {
//       welcomeMsg: 'La migliore esperienza di viaggio',
//       guide: 'trova e prenota il migliore',
//       // welcomeMsg: 'Benvenuti nella tua app Vue.js',
//       // guide: 'Per una guida e ricette su come configurare / personalizzare questo progetto,',
//       checkout: 'controllare',
//       plugins: 'Plugin CLI installati',
//       links: 'Link essenziali',
//       ecosystem: 'Ecosistema'
//     },
//     'ja': {
//       welcomeMsg: '最高のツアー体験',
//       guide: '最高を見つけて予約する',
//       // welcomeMsg: 'Vue.jsアプリへようこそ',
//       // guide: 'このプロジェクトの設定方法やカスタマイズの手順についてのガイドやレシピについては、',
//       checkout: 'チェックアウトしてください',
//       plugins: 'インストール済みCLIプラグイン',
//       links: '必須リンク',
//       ecosystem: 'エコシステム'
//     },
//     'zh': {
//       welcomeMsg: '最佳旅游体验',
//       guide: '找到并预订最佳',
//       // welcomeMsg: '欢迎来到您的Vue.js应用程序',
//       // guide: '有关如何配置/自定义此项目的指南和配方，请',
//       checkout: '看看',
//       plugins: '已安装的CLI插件',
//       links: '基本链接',
//       ecosystem: '生态系统'
//     }
// };

// const i18n = createI18n({
//     locale: 'en',
//     // fallbackLocale: 'es',
//     messages
// });

