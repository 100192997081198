import CryptoJS from 'crypto-js';

export const getConfigDataFromLocalStorage = () => {
  const encryptedData = localStorage.getItem('portalData');
  if (encryptedData) {
    const secretKey = 'portal_config_datas';
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  }
  else{
    alert("Oops! Site is being updated. Sorry for the inconvenience. We will be right back with exciting new features. Please visit again after some time.");
    window.location.href = '/';
  }

  // return null;
};
