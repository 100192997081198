<template>
  <section style="padding: 20px 10px; background-color: #3c0ec2;color: white; height: 100%;margin-bottom:0;">
    <v-img :src="portalLogo" class="logo" style="filter: brightness(0) invert(1);margin:auto;width:270px;"/>
    <div class="d-flex flex-row justify-center datas" v-if="oneway || roundtrip">
      <div class="d-flex flex-column col-8 align-items-center">
        <p class="font-weight-bold fromdatas fs-6">{{ fromData }}</p>
        <p class="departureDate" v-if="oneway ? false : true">
          {{ dedate }}
        </p>
      </div>
      <div class="d-flex flex-column justify-content-between align-items-center">
        <div>
          <v-img :src="require('../assets/arrowright.png')" class="arrowright"></v-img>
        </div>

        <div v-if="oneway ? false : true">
          <v-img :src="require('../assets/arrowleft.png')" class="arrowleft"></v-img>
        </div>

        <div v-if="oneway ? true : false">
          <p class="departureDate m-0 text-center">{{ dedate }}</p>
          <p class="departureDate m-0 text-center">{{ tripType }}</p>
        </div>
      </div>

      <div class="d-flex flex-column col-8 align-items-center">
        <p class="font-weight-bold todatas">{{ toData }}</p>
        <p class="arrivalDate" v-if="roundtrip">{{ returnDate }}</p>
      </div>
    </div>
    <div v-if="multi" class="mt-0">
      {{ console.log(this.multiData,'datasloder...3')}}
      <div class="d-flex flex-row justify-center datas mb-3" v-for="(data,index) of multiData.itinery" :key="index">
        <div class="d-flex flex-column col-8 align-items-center">
          <p class="font-weight-bold fromdatas fs-6">{{ data.from }}</p>
          <!-- <p class="departureDate m-0">{{ getDate(data.depdate) }}</p> -->
        </div>
        <div class="d-flex flex-column justify-content-between align-items-center">
          <div>
            <v-img :src="require('../assets/arrowright.png')" class="arrowright mb-1" style="width:80px;"></v-img>
          </div>
          <!-- <div>
            <p class="departureDate m-0">{{ dedate }}</p>
          </div> -->
        </div>

        <div class="d-flex flex-column col-8 align-items-center">
          <p class="font-weight-bold todatas">{{ data.to }} - {{ getDate(data.depdate) }}</p>
        </div>
      </div>
    </div>

    <div class="loader" :style="{ top: oneway || roundtrip ? '36%' : '20%'}">
      <span style="--i: 1"></span>
      <span style="--i: 2"></span>
      <span style="--i: 3"></span>
      <span style="--i: 4"></span>
      <span style="--i: 5"></span>
      <span style="--i: 6"></span>
      <span style="--i: 7"></span>
      <span style="--i: 8"></span>
      <span style="--i: 9"></span>
      <span style="--i: 10"></span>
      <span style="--i: 11"></span>
      <span style="--i: 12"></span>
      <span style="--i: 13"></span>
      <span style="--i: 14"></span>
      <span style="--i: 15"></span>
      <span style="--i: 16"></span>
      <span style="--i: 17"></span>
      <span style="--i: 18"></span>
      <span style="--i: 19"></span>
      <span style="--i: 20"></span>
      <div class="plane">
        <ion-icon name="airplane-sharp" class="icon"></ion-icon>
      </div>
    </div>
    <v-progress-linear :style="{ top: oneway || roundtrip ? '52%' : '30% !important'}"
      color="white"
      indeterminate
      class="progress-line"
    ></v-progress-linear>
  </section>
</template>

<script>
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
import moment from "moment"
export default {
  props: {
    searchCase: String // Expect searchCase as a prop
  },
  // props: {
  //   searchCase: {
  //     type: Object,
  //     default: () => ({}),
  //   },
  // },
  data() {
    return {
      portalLogo: "",
      fromData: "",
      toData: "",
      dedate: "",
      returnDate: "",
      tripType: "",
      multi: false,
      roundtrip: false,
      oneway: false,
      interval: 1000,
      multiData:[],
      searchData:"",
      // multiData: this.searchCase || '',
      // searchData: this.searchCase || '',
      items: [
        {
          src: require("@/assets/caro1.jpg"),
        },
      ],
    };
  },
  
  watch: {
    searchCase(newValue) {
      // this.searchData = newValue;
      if (newValue) {
        this.loadSearchData(); 
      }
    },
  },

  mounted() {
    this.getConfigFunc();
    this.loadSearchData();
  },
  methods: {

    loadSearchData(){
      const tabId = sessionStorage.getItem("tabId");
      const tripType = this.$route.query.tripType;
      // console.log(this.searchCase,'storedDatasdasdasdas...1')


      let key = '';

      if (tripType === 'RT' || tripType === 'OW' ) {
        key = tripType === 'RT'? `searchedData-round-${tabId}` : `searchedData-oneway-${tabId}`;
        this.oneway = tripType === 'OW';
        this.roundtrip = tripType === 'RT';
        // console.log(key,'storedDatasdasdasdas...2');
      }
      else if (tripType === 'MC') {
        key = `searchedData-multicity-${tabId}`;
        this.multi = true;
        // console.log(key,'storedDatasdasdasdas...3');
      }

      const storedData = localStorage.getItem(key);
      // console.log(storedData,'storedDatasdasdasdas...4')


      if (storedData) {
        if (this.multi) {
          this.multiData = JSON.parse(storedData) || this.searchCase;
          // console.log(this.multiData,'storedDatasdasdasdas...5');
        } else {
          this.searchData = JSON.parse(storedData) || this.searchCase;
          // console.log(this.searchData,'storedDatasdasdasdas...6');

          this.fromData = this.searchData.from;
          this.toData = this.searchData.to;
          this.dedate = moment(this.searchData.dedate).format("DD MMM-YYYY");

          if(tripType === 'RT'){
            this.returnDate = moment(this.searchData.redate).format("DD MMM-YYYY");
          }

        }
      } else {
        console.log('No search data found for this tab.');
      }

      console.log(this.dedate, "mutiDatamutiData");
    },

    getDate(data){
      return moment(data).format("DD MMM-YYYY")
    },
    getConfigFunc() {
      const getConfigData = getConfigDataFromLocalStorage();

      console.log(getConfigData, "LOADERRR");

      if (getConfigData) {
        let Menus = "";
        Menus = getConfigData.payload.portal_configuration.menus.enabled.header;
        this.menuData =
          getConfigData.payload.portal_configuration.menus.enabled.header;
        this.headerMenus = Menus.split(", ");

        this.portalLogo = getConfigData.payload.portal_configuration.logo_path;
      }
    },
  },
  // created() {
  //   this.getConfigFunc();
  //   // this.loadSearchData();
  // },
};
</script>
<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
}

.main-section {
  width: 100%;
  /* height: 500px; */
}

/* loader main */
.con {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 30px;
  max-width: 735px;
  margin: auto;
}

.loader-main {
  border-bottom: 1px dashed rgba(17, 17, 26, 0.5);
}

.loader-main p {
  font-size: 15px;
  font-weight: 700;
}

.flight-img {
  width: 250px;
}

.info-img {
  width: 45px;
}

/* .detail-png img{
      margin-left: 50px;
  } */
.detail-png .para1 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 5px;
}

.text-center.para2 {
  font-size: 12px;
}

.border-right {
  border-right: 1px dashed rgba(17, 17, 26, 0.5);
}

.message {
  font-size: 12px;
  font-weight: 400;
}

.progress {
  height: 7px !important;
  background: none;
  border: 1px solid #002a5f !important;
  overflow: visible !important;
  z-index: -9999;
  position: relative;
  max-width: 700px !important;
  margin: 0% auto;
}

.progress-bar {
  background-color: #002a5f;
  z-index: 1;
  position: relative;
  overflow: visible !important;
  animation: progressLineTransmission 10s linear both;
}

.slideset1 {
  height: 10em;
  position: relative;
  color: white;
  margin: auto;
  display: flex;
  justify-content: center;
  top: 50px;
}

.slideset1 > * {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  animation: 6s autoplay1 infinite;
}

@keyframes autoplay1 {
  0% {
    visibility: visible;
  }
  33.33% {
    visibility: hidden;
  }
}
.slideset1 > *:nth-child(1) {
  animation-delay: 0s;
}
.slideset1 > *:nth-child(2) {
  animation-delay: 2s;
}
.slideset1 > *:nth-child(3) {
  animation-delay: 4s;
}
>>> .v-progress-circular--indeterminate > svg {
  animation: progress-circular-rotate 100s linear infinite;
  transform-origin: center center;
  transition: all 100s ease-in-out;
}
@keyframes progressLineTransmission {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.progress::before {
  content: "";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #002a5f;
  border: 2px solid #002a5f;
  box-shadow: rgba(255, 255, 51, 0.5) 0px 25px 50px -1px;
  position: absolute;
  bottom: -3px;
  left: -5px;
  z-index: 9999999;
  overflow: visible;
}

.progress::after {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #002a5f;
  border: 2px solid #002a5f;
  box-shadow: rgba(255, 255, 51, 0.5) 0px 25px 50px -1px;
  position: absolute;
  bottom: -3px;
  right: -5px;
  z-index: 9999999;
  overflow: visible;
}

.air-plane {
  position: relative;
  bottom: 12px;
  font-size: 20px;
  font-weight: bold;
}

>>> .v-img__img,
.v-img__picture,
.v-img__gradient,
.v-img__placeholder,
.v-img__error {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* border-radius: 50% !important; */
}
@media only screen and (max-width: 570px) {
  .content-rev {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 480px) {
  .air-plane {
    position: relative;
    bottom: 1px;
    font-size: 14px;
  }
  .air1 {
    display: flex;
    justify-content: flex-end;
  }
  .from {
    font-size: 12px;
  }
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@keyframes spinner-rotate {
  100% {
    transform: rotate(1turn);
  }
}

.spinner {
  position: relative;
  z-index: 0;
  height: 250px;
  width: 168px;
  border-radius: 81px;
  overflow: hidden;
  padding: 10px;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #162849;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#162849, #162849),
      linear-gradient(#162849, #162849), linear-gradient(#162849, #162849),
      linear-gradient(#fff, #fff);
    animation: spinner-rotate 1s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 5px;
    top: 5px;
    width: calc(100% - 9px);
    height: calc(100% - 9px);
    background: #162849;
    border-radius: 75px;
    /* color:#fff; */
  }
}

.loader {
  position: relative;
  width: 100px;
  height: 100px;
  top: 36%;
  margin:auto;
}
.loader span {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(calc(18deg * var(--i)));
}
.loader span::before {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(5px);
  transform: scale(0);
  animation: load 2s linear infinite;
  animation-delay: calc(0.1s * var(--i));
}
@keyframes load {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  10% {
    transform: scale(1.2);
    opacity: 1;
  }
  80%,
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
.plane {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  animation: rotate 2s linear infinite;
  animation-delay: -1.5s;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.plane .icon {
  font-size: 50px;
  color: #fff;
  position: absolute;
  top: -10px;
  left: 80px;
  transform: rotate(45deg);
}
.logo {
  height: 55px;
  cursor: pointer;
  width: 300px;
  /* position: absolute;
  z-index: 1; */
  /* left: 40%; */
  /* top: 8%; */
  filter: grayscale(100%) invert(100%) brightness(2);
}
.datas {
  position: relative;
  z-index: 1;
  width: 400px;
  margin: 0 auto;
  top: 80px;
  margin:auto;
}
.fromdatas,
.todatas {
  font-size: 15px;
}
.departureDate,
.arrivalDate {
  font-size: 14px;
}
.progress-line {
  width: 300px;
  position: relative;
  z-index: 1;
  top: 52% !important;
  /* margin:auto; */
}
@media screen and (max-width: 480px) {
  .datas {
    position: relative;
    z-index: 1;
    width: 200px !important;
    /* left:5%; */
    top: 80px;
  }
  .departureDate,
  .arrivalDate {
    text-align: left !important;
    font-size: 10px !important;
  }
  .fromdatas,
  .todatas {
    font-size: 12px !important;
  }
  .progress-line {
    width: 300px;
    position: relative;
    top: 32% !important;
    z-index: 1;
  }
  .logo {
    height: 50px;
    cursor: pointer;
    width: 250px;
    /* position: absolute; */
    z-index: 1;
    /* left: 20%; */
    /* top: 8%; */
    filter: grayscale(100%) invert(100%) brightness(2);
  }
  .loader {
    position: relative;
    width: 100px;
    height: 100px;
    top: 25%;
    margin:auto;
  }
  .arrowright {
    padding-right: 20px;
    width: 60px !important;
    height: 20px !important;
    color: white;
    margin-bottom: 15px;
  }
  .arrowleft {
    padding-right: 20px;
    width: 60px !important;
    height: 20px !important;
    position: relative;
    top: -20px;
  }
  .arrowright1 {
    padding-right: 20px;
    width: 60px !important;
    height: 20px !important;
    position: relative;
    top: -30px;
  }
  .returndate {
    left: 100px !important;
  }
}
.arrowright {
  padding-right: 20px;
  width: 100px;
  height: 30px;
  color: white;
  margin-bottom: 15px;
}
.arrowleft {
  padding-right: 20px;
  width: 100px;
  height: 30px;
  position: relative;
  top: -20px;
}
.arrowright1 {
  padding-right: 20px;
  width: 100px;
  height: 30px;
  position: relative;
  top: -30px;
}

</style>
