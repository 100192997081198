<template>
  <div v-if="searchresult" class="mb-4">
    <div class="bg-image">
      <div class="container">
        <div class="py-2">
          <searchform :searchCase="searchCase"/>
        </div>

        <div style="overflow: unset" class="d-none">
          <v-card rounded="3" style="padding: 10px 10px; overflow: unset" class="d-none">
            <v-card rounded="3" class="round-mode" v-if="roundModify">
              <div class="row d-flex">
                <div class="col-lg-5 col-12 d-flex justify-content-evenly">
                  <div>
                    <h5 class="text-center">{{ tripData.from }}</h5>
                    <p class="text-center">{{ tripData.dedate }}</p>
                  </div>
                  <v-icon size="30">mdi-swap-horizontal</v-icon>
                  <div>
                    <h5 class="text-center">{{ tripData.to }}</h5>
                    <p class="text-center">{{ tripData.redate }}</p>
                  </div>
                </div>
                <div class="col-lg-2 col-6 align-center">
                  <h6 class="text-center">{{ $t("formsContents.options.classType") }}</h6>
                  <p class="text-center" style="font-weight: 500; color: gray">
                    {{ tripData.class }}
                  </p>
                </div>
                <div class="col-lg-3 col-6 align-center">
                  <h6 class="text-center">{{ $t("formsContents.options.passenger") }}</h6>
                  <div class="d-flex justify-center">
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="tripData.adultValue > 0"
                      >{{ tripData.adultValue }} Adt</span
                    >
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="tripData.childValue > 0"
                      >, {{ tripData.childValue }} Ch</span
                    >
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="tripData.infantValue > 0"
                      >, {{ tripData.infantValue }} Inf</span
                    >
                  </div>
                </div>
                <div class="col-lg-2 col-12 align-center" align="center">
                  <span
                    class="not-collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse-Example"
                    aria-expanded="true"
                    aria-controls="collapse-Example"
                  >
                    <v-btn
                      color="#3c0ec2"
                      @click="modifySection = !modifySection"
                      style="color: white"
                      >Modify
                      <v-icon size="25" class="ps-2">
                        {{
                          modifySection
                            ? "mdi-arrow-up-drop-circle-outline"
                            : "mdi-pencil"
                        }}
                      </v-icon>
                    </v-btn>
                  </span>
                </div>
              </div>
            </v-card>

            <v-card rounded="3" class="round-mode" v-if="multiModify">
              <div class="row d-flex">
                <div class="col-lg-5 col-12 d-flex justify-content-evenly">
                  <div>
                    <h5 class="text-center">{{ getMultifrom(multi_city) }}</h5>
                    <p class="text-center">{{ getMultiDedate1(multi_city) }}</p>
                    <!-- <h5 class="text-center">{{ getMultifrom(multi_city) }}</h5>
                    <p class="text-center">{{ getMultiDedate1(multi_city) }}</p> -->
                  </div>
                  <div class="d-flex flex-column mt-3">
                    <v-icon size="18" style="transform: rotate(45deg)"
                      >mdi-arrow-top-right</v-icon
                    >
                    <v-icon size="18" style="transform: rotate(45deg)"
                      >mdi-arrow-top-right</v-icon
                    >
                    <!-- <v-icon size="18" style="transform: rotate(45deg);">mdi-arrow-top-right</v-icon> -->
                  </div>
                  <div>
                    <h5 class="text-center">{{ getMultiTo(multi_city) }}</h5>
                    <p class="text-center">{{ getMultiDedate2(multi_city) }}</p>
                  </div>
                </div>
                <div class="col-lg-2 col-6 align-center">
                  <h6 class="text-center">Class Typerr</h6>
                  <p class="text-center" style="font-weight: 500; color: gray">
                    {{ getMultiClass(multi_city) }}
                  </p>
                </div>
                <div class="col-lg-3 col-6 align-center">
                  <h6 class="text-center">{{ $t("fareDetContent.passengers") }}</h6>
                  <div class="d-flex justify-center">
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="getMultiAdult(multi_city) > 0"
                    >
                      {{ getMultiAdult(multi_city) }} Adt</span
                    >
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="getMultiChild(multi_city) > 0"
                      >, {{ getMultiChild(multi_city) }} Ch</span
                    >
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="getMultiInfrant(multi_city) > 0"
                      >, {{ getMultiInfrant(multi_city) }} Inf</span
                    >
                  </div>
                </div>
                <div class="col-lg-2 col-12 align-center" align="center">
                  <v-btn
                    color="#3c0ec2"
                    @click="modifySection = !modifySection"
                    style="color: white"
                    >Modify
                    <v-icon size="25" class="ps-2">
                      {{
                        modifySection
                          ? "mdi-arrow-up-drop-circle-outline"
                          : "mdi-pencil"
                      }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-card>

          <div class="custom-card">
            <div class="row d-none" align="center">
              <h6 style="color: #162849">FIND CHEAP TICKETS-SAVE BIG</h6>
            </div>

            <div class="py-2 px-3 mt-2 rounded-3 row" style="background-color: #5a33ca !important;" >
              <div class="col-lg-5 col-md-12 col-12 pt-3 px-sm-2 px-0">
                <v-btn
                  size="small"
                  value="Roundtrip"
                  class="normal-radio"
                  style="border-radius: 25px 0px 0px 25px;"
                  :class="{ radio: activetrip == 'Roundtrip' }"
                  @click="activeType('Roundtrip')">{{ $t("formsContents.options.roundTrip") }}</v-btn>
                <v-btn
                  size="small"
                  value="Oneway"
                  class="normal-radio"
                  style="border-radius: 0 !important"
                  :class="{ radio: activetrip == 'Oneway' }"
                  @click="activeType('Oneway')"
                  >{{ $t("formsContents.options.oneWay") }}</v-btn
                >
                <v-btn
                  rounded="xl"
                  size="small"
                  value="Multicity"
                  class="normal-radio"
                  style="border-radius: 0px 25px 25px 0px !important;"
                  :class="{ radio: activetrip == 'Multicity' }"
                  @click="activeType('Multicity')"
                  >{{ $t("formsContents.options.multiCity") }}
                </v-btn>
              </div>

              <!-- <div class="col-md-8 col-12">
                <div class="row collapse-row">
                  <div class="col-lg-12 col-md-12 w-100">
                    <div class="row justify-start"></div>
                  </div>
                </div>
              </div> -->
              <div class="col-lg-3 col-md-6 col-sm-6 col-5 pt-3 my-lg-0 my-3 align-center passengers-data">
                <div class="input-styles rounded-border modify-data">
                  <v-select
                    v-model="classType" :items="classTypeitems"
                    ref="mySelect"
                    class="select-2"
                    item-title="text"
                    variant="underlined"
                    style="color: black"
                  ></v-select>
                  </div>
                </div>
                
              <div class="col-lg-3 col-sm-6 col-7 g-2 pt-3 my-lg-0 my-3 passengers-data">
                        <div class="d-flex input-styles">
                          <!-- <span class="d-flex me-2" style="font-size: 18px; color: gray">
                        <v-icon> mdi-account-outline </v-icon>
                          </span> -->

                          <v-select
                            v-model="selectedCountry"
                            :items="countries"
                            ref="countrySelect"
                            variant="underlined"
                            class="select-1"
                          >
                            <!-- <template #item="{ item }"> -->
                            <template #item="{}">
                              <div
                                class="dropdown_section"
                                style="cursor: pointer"
                              >
                                <div
                                  class="row d-flex align-center my-2 mx-1"
                                  style="height: 37px"
                                  @click.stop
                                >
                                  <div class="col-3" style="width: 38%">
                                    <span
                                      style="
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: #4e4d4d;
                                      "
                                      >{{ $t("formsContents.options.adult") }}</span
                                    >
                                  </div>
                                  <div class="col-3 px-0" style="width: 9%">
                                    <v-icon
                                      style="
                                        width: auto;
                                        color: grey;
                                        font-size: 18px;
                                      "
                                    >
                                      mdi-human-male-female
                                    </v-icon>
                                  </div>
                                  <div
                                    class="col-6 adult"
                                    style="width: 50%; height: auto"
                                  >
                                    <div class="row" style="padding: 0px">
                                      <div class="col-4" @click="deg1()">
                                        <div class="adult-plus">-</div>
                                      </div>
                                      <div
                                        class="col-4"
                                        style="background-color: white"
                                        align="center"
                                      >
                                        <div>{{ adultValue }}</div>
                                      </div>
                                      <div class="col-4" @click="add1()">
                                        <div class="adult-plus">+</div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- <div>{{ item.name }}</div> -->
                                </div>

                                <div
                                  class="row d-flex align-center my-2 mx-1"
                                  @click.stop
                                >
                                  <div
                                    class="col-4 d-flex flex-column pe-0"
                                    style="width: 38%"
                                  >
                                    <span
                                      style="
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: #4e4d4d;
                                      "
                                      >{{ $t("formsContents.options.children") }}</span
                                    >
                                    <span
                                      style="font-size: 11px; color: #4e4d4d"
                                      >2-11({{ $t("formsContents.options.years") }})</span
                                    >
                                  </div>
                                  <div class="col-2 px-0" style="width: 9%">
                                    <v-icon
                                      style="
                                        width: auto;
                                        color: grey;
                                        font-size: 18px;
                                      "
                                    >
                                      mdi-human-child
                                    </v-icon>
                                  </div>
                                  <div class="col-6 adult" style="width: 50%">
                                    <div class="row" style="padding: 0px">
                                      <div class="col-4" @click="deg2()">
                                        <div class="adult-plus">-</div>
                                      </div>
                                      <div
                                        class="col-4"
                                        style="background-color: white"
                                        align="center"
                                      >
                                        <div>{{ childValue }}</div>
                                      </div>
                                      <div class="col-4" @click="add2()">
                                        <div class="adult-plus">+</div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- <div>{{ item.name }}</div> -->
                                </div>

                                <div
                                  class="row d-flex align-center my-2 mx-1"
                                  @click.stop
                                >
                                  <div
                                    class="col-5 d-flex flex-column pe-0"
                                    style="width: 38%"
                                  >
                                    <span
                                      style="
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: #4e4d4d;
                                      "
                                      >{{ $t("formsContents.options.infant") }}</span
                                    >
                                    <span
                                      style="font-size: 11px; color: #4e4d4d"
                                      >2({{ $t("formsContents.options.years") }})</span
                                    >
                                  </div>

                                  <div class="col-1 ps-0" style="width: 9%">
                                    <v-icon
                                      style="
                                        width: auto;
                                        color: grey;
                                        font-size: 18px;
                                      "
                                    >
                                      mdi-baby-buggy
                                    </v-icon>
                                  </div>
                                  <div class="col-6 adult" style="width: 50%">
                                    <div class="row" style="padding: 0px">
                                      <div class="col-4" @click="deg3()">
                                        <div class="adult-plus">-</div>
                                      </div>
                                      <div
                                        class="col-4"
                                        style="background-color: white"
                                        align="center"
                                      >
                                        <div>{{ infantValue }}</div>
                                      </div>
                                      <div class="col-4" @click="add3()">
                                        <div class="adult-plus">+</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  align="end"
                                  class="mt-4 pe-3 d-flex justify-end"
                                  @click="
                                    (travels = false),
                                      localdone(),
                                      closeDropdown()
                                  "
                                >
                                  <v-btn
                                    rounded="3"
                                    color="rgb(148, 194, 14)"
                                    width="100px"
                                    height="30px"
                                    style="color: white"
                                  >
                                  {{ $t("formsContents.options.done") }}
                                  </v-btn>
                                </div>
                              </div>
                            </template>

                            <template #selection="{}">
                              <div class="pax-fonts d-flex align-center">
                                <span
                                  class="d-flex me-2"
                                  style="font-size: 18px; color: gray"
                                >
                                  <v-icon> mdi-account-outline </v-icon>
                                </span>
                                <span>{{ resultDataPass }} {{ $t("formsContents.options.passengers") }}</span>
                                <!-- <span>{{ adultValue }} Adt</span>
                                  <span v-if="childValue > 0">, {{ childValue }} Ch</span>
                                  <span v-if="infantValue > 0">, {{ infantValue }} Inf</span> -->

                                <!-- <span>, {{ oneway.class }}</span> -->
                              </div>
                            </template>
                          </v-select>
                        </div>
              </div>
                      
                    
              <div v-if="rount || Oneway">
                <div class="row mt-sm-4 mt-2 gx-0">
                  <div class="col-lg-11 col-12">
                    <div class="row">
                      <div class="col-lg-3 col-sm-6 mb-3">
                        <div class="labelTop">
                          <p>{{$t("formsContents.labels.fromlabel")}}</p>
                        </div>
                        <div class="d-flex input-styles input-collapse align-center">
                          <span class="d-flex align-center">
                            <v-icon>mdi-airplane-takeoff</v-icon>
                          </span>
                          <span class="">
                            <!-- <AutoComplete
                              v-model="oneway.from"
                              optionLabel="name"
                              dropdown
                              :placeholder="this.$t('formsContents.labels.fromlabel')"
                              :suggestions="filteredCountries"
                              @change="onChangeFrom()"
                              @click="fromClick(oneway)"
                              @complete="search"
                              style="
                                width: 100%;
                                height: 100%;
                                position: relative;
                              "
                            >
                              <template #option="slotProps">
                                <div
                                  class="flex align-items-center dropdown-box"
                                  @click="focus1()"
                                >
                                  <div>{{ slotProps.option.originalName }}</div>
                                </div>
                              </template>
                            </AutoComplete>
                            <label>{{ $t("formsContents.labels.fromlabel") }}</label> -->
                            <div class="vas-autocomplete">
                              <div class="d-flex align-center">
                                <div>
                                  <input
                                    type="text"
                                    :placeholder="this.$t('formsContents.labels.fromlabel')"
                                    style="cursor: pointer" ref="firstAutoComplete"
                                    v-model="oneway.from"
                                    @keyup="typeCity(oneway.from, 1)"
                                    class="inter-input"
                                    @click="
                                      (oneway.from = ''),
                                        (formres = true),
                                        (formres1 = false),
                                        (this.city1 = []),
                                        this.selectOnewayFrom = false
                                    "
                                  />
                                </div>
                                <div v-if="selectOnewayFrom" class="col-2 ps-0">
                                  <v-icon @click="fromInputData()" size="16" color="grey">mdi-close</v-icon>
                                </div>
                                </div>
                                  <div v-if="typedrop" v-click-outside="clickoutDropdown">
                                    <div v-if="this.city.length > 0 ? true : false"
                                      class="autocompete-menual-dropdown">
                                      <div v-for="(data, index) in city" :key="index">
                                        <div @click="datachange(data)" :style="index == city.length - 1 ? '' : 'border-bottom:1px solid lightgray'"
                                          class="d-flex p-2 va-back" >
                                          <div style="display: flex;align-items: center;">
                                            <v-icon style="transform: rotate(90deg);" color="gray" size="20">
                                              {{ data.location === "airport" ? "mdi-airplane" : "mdi-radiobox-marked" }}
                                            </v-icon>
                                          </div>

                                          <div class="m-1 va-content">
                                            {{ data.iata }}, {{ data.city }},{{ data.name }}
                                          </div>
                                        </div>

                                        <div v-if="data.group_airports.length > 0">
                                          <div v-for="group in data.group_airports" :key="group">
                                            <div v-if="data.group_airport_city != group.iata">
                                              <div class="d-flex va-back" @click="datachange(group)">
                                                <div style="display: flex;align-items: center;" class="ms-3">
                                                  <v-icon style="transform: rotate(90deg);" color="gray" size="20"
                                                    >mdi-airplane</v-icon>
                                                </div>
                                                <div class="m-1 va-content">
                                                  {{ group.iata }}, {{ group.city }},{{ group.name }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                          </span>
                          <div v-if="fromloader">
                            <div class="spinner-border spinner-border-sm" 
                              style="display:flex;align-items:center;color:rgb(47,47,47);" role="status"></div>
                          </div>
                        </div>
                        {{ console.log(fromErrorMessage,'validatecheckworking.....0')}}

                        <div class="text-start">
                          <span class="p-error" v-if="fromErrorMessage"
                            >{{ $t("formsContents.errorMsg.departReq") }}</span
                          >
                          <!-- <br v-if="fromErrorMessage && maximumletter" />
                          <span class="p-error" v-if="maximumletter">{{ $t("formsContents.errorMsg.enterMinimum") }}</span> -->
                        </div>
                      </div>

                      <div class="col-lg-3 col-sm-6 mb-3">
                        <div class="labelTop">
                          <p>{{ $t("formsContents.labels.tolabel") }}</p>
                        </div>
                        <div class="d-flex input-styles align-center">
                          <span class="d-flex align-center">
                            <v-icon>mdi-airplane-landing</v-icon>
                          </span>

                          <span class="">
                            <!-- <AutoComplete
                              v-model="oneway.to"
                              optionLabel="name"
                              dropdown
                              :placeholder="this.$t('formsContents.labels.tolabel')"
                              :suggestions="filteredCountries"
                              @change="onChangeTo()"
                              @click="toClick(oneway)"
                              @complete="search"
                              ref="secondAutoComplete"
                              style="
                                width: 100%;
                                height: 100%;
                                position: relative;
                              "
                            >
                              <template #option="slotProps">
                                <div class="dropdown-container">
                                  <div
                                    class="flex align-items-center dropdown-box"
                                    @click="focus2()"
                                  >
                                    <div>
                                      {{ slotProps.option.originalName }}
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </AutoComplete>
                            <label>{{ $t("formsContents.labels.tolabel") }}</label> -->
                           <div class="vas-autocomplete">
                            <div class="d-flex align-center">
                              <div>
                                          <input type="text" style="cursor: pointer"
                                            :placeholder="this.$t('formsContents.labels.destination')"
                                            v-model="oneway.to" :ref="rount ? 'secondAutoComplete' : 'onewaysecondAuto'"
                                            @keyup="typeCity(oneway.to, 2)"
                                            class="inter-input"
                                            @click="
                                              (oneway.to = ''),
                                                (formres = false),
                                                (formres1 = true),
                                                (this.city = []),
                                                this.selectOnewayTo = false;
                                            "
                                          />
                                        </div>
                                          <div v-if="selectOnewayTo" class="col-2 ps-0">
                                            <v-icon @click="toInputData()" size="16" color="grey">mdi-close</v-icon>
                                          </div>
                                        </div>
                                          <div v-if="typedrop1" v-click-outside="clickoutDropdown">
                                            <div v-if="this.city1.length > 0 ? true : false" class="autocompete-menual-dropdown">
                                              <div v-for="(data, index) in city1" :key="index">
                                                <div @click="datachangeTo(data)" :style="index == city.length - 1 ? '' : 'border-bottom:1px solid lightgray'"
                                                  class="d-flex p-2 va-back">
                                                  <div style="display: flex;align-items: center;">
                                                    <v-icon style="transform: rotate(90deg);" color="gray" size="20">
                                                      {{data.location === "airport" ? "mdi-airplane" : "mdi-radiobox-marked"}}
                                                    </v-icon>
                                                  </div>

                                                  <div class="m-1 va-content">
                                                    {{ data.iata }}, {{ data.city }},{{ data.name }}
                                                  </div>
                                                </div>

                                                <div v-if="data.group_airports.length > 0">
                                                  <div v-for="group in data.group_airports" :key="group">
                                                    <div v-if="data.group_airport_city != group.iata">
                                                      <div class="d-flex va-back" @click="datachangeTo(group)">
                                                        <div style="display: flex;align-items: center;" class="ms-4">
                                                          <v-icon
                                                            style="transform: rotate(90deg );"
                                                            color="gray"
                                                            size="15"
                                                            >mdi-airplane</v-icon>
                                                        </div>

                                                        <div class="m-1 va-content">
                                                          {{ group.iata }},
                                                          {{ group.city }},
                                                          {{ group.name }}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                          
                          </span>
                           <div v-if="toloader">
                            <div class="spinner-border spinner-border-sm" 
                              style="display:flex;align-items:center;color:rgb(47,47,47);" role="status"></div>
                           </div>
                        </div>
                        <div class="text-start">
                          <span class="p-error" v-if="toErrorMessage"
                            >{{ $t("formsContents.errorMsg.arrivalReq") }}</span
                          >
                          <!-- <br v-if="toErrorMessage && maximumletter1" />
                          <span class="p-error" v-if="maximumletter1"
                            >{{ $t("formsContents.errorMsg.enterMinimum") }}</span
                          > -->
                        </div>
                      </div>

                      <div class="col-lg-3 col-sm-6 mb-3">
                        <div class="labelTop">
                            <p>{{ $t("formsContents.labels.departDate") }}</p>
                            </div>
                        <div class="d-flex input-styles align-center">
                          <span class="d-flex align-center">
                            <v-icon>mdi-calendar</v-icon>
                          </span>

                          <span class="">

                            <VueDatePicker
                              :ref="rount ? 'dpRef1' : 'onewaydpRef1'"
                              v-model="oneway.dedate"
                              :min-date="new Date()"
                              :placeholder="this.$t('formsContents.labels.departDate')"
                              :max-date="!oneway.dedate ? new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()) : ''"
                              :format="dateFormat"
                              :hide-navigation="['time']"
                              auto-apply
                              :multi-calendars="multiCalender"
                              @update:modelValue="fromDateChanged"
                              :on-click-outside="ClickOutDepat"
                              :month-change-on-scroll="false"
                              class="px-0 datepicker"
                            />
                          </span>
                        </div>
                        <div class="text-start">
                          <span class="p-error" v-if="fromDateError">{{ $t("formsContents.errorMsg.depdateReq") }}</span>
                        </div>
                      </div>

                      <div class="col-lg-3 col-sm-6 mb-3" :class="{'disable-return' : Oneway}">
                         <div class="labelTop">
                            <p>{{ $t("formsContents.labels.returnDate") }}</p>
                          </div>
                        <div class="d-flex input-styles align-center">
                          <span class="d-flex align-center">
                            <v-icon>mdi-calendar</v-icon>
                          </span>
                          <span class="">
                            <VueDatePicker
                              :ref="rount ? 'dpRef2' : ''"
                              v-model="oneway.redate"
                              :format="dateFormat"
                              :min-date="oneway.dedate ? oneway.dedate : new Date()"
                              :placeholder="this.$t('formsContents.labels.returnDate')"
                              :max-date="
                                new Date(
                                  new Date().getFullYear() + 1,
                                  new Date().getMonth(),
                                  new Date().getDate()
                                )
                              "
                              :hide-navigation="['time']"
                              auto-apply
                              no-today
                              :six-weeks="sixWeekMode"
                              @update:modelValue="toDateChanged"
                              :multi-calendars="multiCalender"
                              :on-click-outside="ClickOutReturn"
                              class="datepicker"
                            />
                          </span>
                        </div>
                        <div class="text-start">
                          <span class="p-error" v-if="rount && toDateError">{{ $t("formsContents.errorMsg.retdateReq") }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-1 mt-3 d-flex align-center justify-center justify-content-lg-start magnify-btn1">
                    <div v-if="rount" @click="sended()">
                      <v-btn
                        color="#94C20E"
                        height="40px"
                        width="80px"
                        class="ms-lg-3 ms-0 mt-1"
                        rounded="1"
                      >
                        <v-icon color="white">mdi-magnify</v-icon>
                      </v-btn>
                    </div>
                    <div v-if="Oneway" @click="sended()">
                      <v-btn
                        color="#94C20E"
                        height="40px"
                        width="80"
                        class="ms-lg-3 ms-0 mt-1"
                        rounded="1"
                      >
                        <v-icon color="white">mdi-magnify</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="multicity">
                <div class="row mt-sm-4 mt-2 gx-0">
                  <div class="col-lg-8 col-12">
                    <div
                      v-for="(datas, index) of multi_city.itinery"
                      :key="index"
                    >
                      <div class="row itin_Borders">
                        <div class="col-lg-4 col-sm-6 mb-3">
                          <div class="labelTop mb-1 mb-lg-0">
                            <p>{{$t("formsContents.labels.fromlabel")}} </p>
                          </div>
                          <div class="d-flex input-styles align-center">
                            <span class="d-flex align-center">
                              <v-icon>mdi-airplane-takeoff</v-icon>
                            </span>
                            <span class="">
                              <!-- <AutoComplete
                                v-model="datas.from"
                                optionLabel="name"
                                dropdown
                                :placeholder="this.$t('formsContents.labels.fromlabel')"
                                :suggestions="filteredCountries"
                                @change="onChangeMultiFrom(index)"
                                @click="multiFromClick(index)"
                                @complete="search"
                                style="
                                  width: 100%;
                                  height: 100%;
                                  position: relative;
                                "
                              >
                                <template #option="slotProps">
                                  <div
                                    class="flex align-items-center dropdown-box"
                                    @click="Multifocus1(index)"
                                  >
                                    <div>
                                      {{ slotProps.option.originalName }}
                                    </div>
                                  </div>
                                </template>
                              </AutoComplete>
                              <label>{{ $t("formsContents.labels.fromlabel") }}</label> -->
                            
                             <div class="vas-autocomplete">
                              <div class="d-flex align-center">
                                <div>
                                <input type="text" :placeholder="this.$t('formsContents.labels.fromlabel')" style="cursor: pointer"
                                  v-model="datas.from" ref="multiFromAutocomplete"
                                  @click="multi_city.itinery[index].from = '', datas.selectedFrom = false"
                                  @keyup="typeMultiCity(datas.from,index,1)"
                                  class="inter-input"/>
                                </div>
                                  <div v-if="datas.selectedFrom" class="col-2 ps-0">
                                            <v-icon @click="multiFrom(index)" size="16" color="grey">mdi-close</v-icon>
                                          </div>
                                        </div>
                                              <!-- {{console.log(data,'data.autocomfrom')}} -->
                                              <div v-if="multi_city.itinery[index].autocomfrom ? true : false" v-click-outside="clickoutmultiDropdown">
                                                <div class="autocompete-menual-dropdown"
                                                  v-if="this.multidrop.length > 0 ? true : false">
                                                  <div v-for="(data1, index1) in multidrop" :key="index1">
                                                    
                                                    <div @click="datachangeMuti(data1,index)"
                                                      :style="index1 == multidrop.length - 1 ? '' : 'border-bottom:1px solid lightgray'"
                                                      class="d-flex p-2 va-back">
                                                      <div style="display: flex;align-items: center;">
                                                        <v-icon style="transform: rotate( 90deg); " color="gray" size="20">mdi-airplane</v-icon>
                                                      </div>

                                                      <div class="ms-1 va-content">
                                                        {{ data1.iata }}, {{ data1.city }},{{ data1.name }}
                                                      </div>
                                                    </div>

                                                    <div v-if="data1.group_airports.length > 0">
                                                      <div v-for="( group, index1) in data1.group_airports" :key="index1">
                                                        <div v-if="data1.group_airport_city != group.iata">
                                                          <div
                                                            class="d-flex va-back"
                                                            @click="datachangeMuti(group,index1)">
                                                            <div style="display: flex;align-items: center;" class="ms-2">
                                                              <v-icon style="transform: rotate(90deg);"
                                                                color="gray"
                                                                size="20"
                                                                >mdi-airplane</v-icon>
                                                            </div>

                                                            <div
                                                              class="m-1 va-content"
                                                            >
                                                              {{ group.iata }},
                                                              {{ group.city }},
                                                              {{ group.name }}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                            </span>
                            <div v-if="datas.autocomfrom">
                              <div class="spinner-border spinner-border-sm" 
                                style="display:flex;align-items:center;color:rgb(47,47,47);" role="status"></div>
                            </div>
                          </div>
                          <div class="text-start">
                            <span class="p-error" v-if="fromErrorMessage"
                              >{{ $t("formsContents.errorMsg.departReq") }}</span
                            >
                            <!-- <br v-if="fromErrorMessage && maximumletter" />
                            <span class="p-error" v-if="maximumletter"
                              >{{ $t("formsContents.errorMsg.enterMinimum") }}</span
                            > -->
                          </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 mb-3">
                          <div class="labelTop mb-1 mb-lg-0">
                            <p>{{$t("formsContents.labels.tolabel")}} </p>
                          </div>
                          <div class="d-flex input-styles align-center">
                            <span class="d-flex align-center">
                              <v-icon>mdi-airplane-landing</v-icon>
                            </span>

                            <span class="">
                              <!-- <AutoComplete
                                v-model="datas.to"
                                ref="multiTo"
                                optionLabel="name"
                                dropdown
                                :placeholder="this.$t('formsContents.labels.tolabel')"
                                :suggestions="filteredCountries"
                                @change="onChangeMultiTo(index)"
                                @click="multiToClick(index)"
                                @complete="search"
                                style="
                                  width: 100%;
                                  height: 100%;
                                  position: relative;
                                "
                              >
                                <template #option="slotProps">
                                  <div class="dropdown-container">
                                    <div
                                      class="flex align-items-center dropdown-box"
                                      @click="Multifocus2(datas, index)"
                                    >
                                      <div>
                                        {{ slotProps.option.originalName }}
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </AutoComplete>
                              <label>{{ $t("formsContents.labels.tolabel") }}</label> -->

                              <div class="vas-autocomplete">
                                <div class="d-flex align-center">
                                  <div>
                                              <input
                                                style="cursor: pointer"
                                                type="text"
                                                :placeholder="this.$t('formsContents.labels.tolabel')"
                                                v-model="datas.to"
                                                @click="multi_city.itinery[index].to = '', datas.selectedTo = false"
                                                @keyup="typeMultiCity(datas.to,index,2)"
                                                class="inter-input" ref="multiToAutocomplete"
                                              />
                                            </div>
                                              <div v-if="datas.selectedTo" class="col-2 ps-0">
                                            <v-icon @click="multiTo(index)" size="16" color="grey">mdi-close</v-icon>
                                          </div>
                                        </div>

                                              <div v-if="datas.autocomTo" v-click-outside="clickoutmultiDropdown">
                                                <div
                                                  class="autocompete-menual-dropdown"
                                                  v-if=" multidrop1.length > 0 ? true : false">
                                                  <div v-for="(data, index1) in multidrop1" :key="index1">
                                                    <div @click="datachangeMutiTo(data,index)"
                                                      :style="index1 ==multidrop1.length - 1 ? '' : 'border-bottom:1px solid lightgray'" class="d-flex p-2 va-back">
                                                      <div style="display: flex;align-items: center;">
                                                        <v-icon
                                                          style="transform: rotate(90deg);"
                                                          color="gray" size="20">
                                                          {{ data.location === "airport" ? "mdi-airplane" : "mdi-radiobox-marked"}}
                                                        </v-icon>
                                                      </div>

                                                      <div class="ms-1 va-content">
                                                        {{ data.iata }}, {{ data.city }},{{ data.name }}
                                                      </div>
                                                    </div>

                                                    <div v-if="data.group_airports.length > 0">
                                                      <div v-for="(group, index1) in data.group_airports"
                                                        :key="index1">
                                                        <div v-if="data.group_airport_city != group.iata">
                                                          <div
                                                            class="d-flex va-back"
                                                            @click="datachangeMutiTo(group,index1)">
                                                            <div style="display: flex;align-items: center;" class="ms-2">
                                                              <v-icon style="transform: rotate(90deg);"
                                                                color="gray" size="20">mdi-airplane</v-icon>
                                                            </div>

                                                            <div class="m-1 va-content">
                                                              {{ group.iata }},
                                                              {{ group.city }},
                                                              {{ group.name }}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                            </span>
                            <div v-if="datas.autocomTo">
                              <div class="spinner-border spinner-border-sm" 
                                style="display:flex;align-items:center;color:rgb(47,47,47);" role="status"></div>
                            </div>
                          </div>
                          <div class="text-start">
                            <span class="p-error" v-if="toErrorMessage"
                              >{{ $t("formsContents.errorMsg.arrivalReq") }}</span
                            >
                            <!-- <br v-if="toErrorMessage && maximumletter1" />
                            <span class="p-error" v-if="maximumletter1"
                              >{{ $t("formsContents.errorMsg.enterMinimum") }}</span
                            > -->
                          </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 mb-3">
                              <div
                                class="labelTop">
                              <p>{{ $t("formsContents.labels.departure") }}</p>
                              </div>
                          <div class="d-flex input-styles align-center">
                            <span class="d-flex align-center">
                              <v-icon>mdi-calendar</v-icon>
                            </span>

                            <span class="">

                              <VueDatePicker
                                ref="dpRef4"
                                v-model="datas.depdate"
                                :placeholder="this.$t('formsContents.labels.departure')"
                                :start-date="
                                  getDatepicker(multi_city.itinery, index)
                                "
                                :min-date="
                                  getDatepicker(multi_city.itinery, index)
                                "
                                :max-date="
                                  new Date(
                                    new Date().getFullYear() + 1,
                                    new Date().getMonth(),
                                    new Date().getDate()
                                  )
                                "
                                :format="dateFormat"
                                :hide-navigation="['time']"
                                auto-apply
                                :multi-calendars="multiCalender"
                                @update:modelValue=" (newValue) => multiDateChanged(newValue, index)"
                                :on-click-outside="multiClickOutside()"
                                :month-change-on-scroll="false"
                                class="px-0 datepicker"
                              />
                            </span>
                          </div>
                          <div class="text-start">
                            <span class="p-error" v-if="fromDateError"
                              >{{ $t("formsContents.errorMsg.depdateReq") }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-12 d-grid">
                    <div
                      class="row justify-content-between"
                      style="margin-bottom: 16px"
                    >
                      <div
                        class="col-md-9 d-flex align-end justify-content-sm-start justify-content-end mb-1"
                      >
                        <span
                          class="d-flex align-center ms-1 mb-1"
                          style="cursor: pointer"
                          @click="deleteSegment(index)"
                          v-if="multi_city.itinery.length > 2"
                        >
                          <v-icon color="red" size="20"
                            >mdi-close-octagon</v-icon
                          >
                        </span>
                        <span
                          class="d-flex align-center justify-center add_style ms-2 order-sm-0 order-1"
                          style="width: 120px"
                          @click="multiSend(index)"
                          v-if="addmore"
                        >
                          <v-icon color="white" size="20"
                            >mdi-plus-circle</v-icon
                          >
                          <span
                            class="ms-1"
                            style="font-size: 12; font-weight: 600"
                            >{{ $t("formsContents.options.addMore") }}</span
                          >
                        </span>
                        <span
                          class="d-flex align-center justify-center clear_style ms-2 order-sm-1 order-0"
                          style="width: 110px"
                          @click="clearall1()"
                          v-if="multi_city.itinery.length > 2"
                        >
                          <v-icon color="white" size="20"
                            >mdi-close-box-multiple</v-icon
                          >
                          <span
                            class="ms-1"
                            style="font-size: 12; font-weight: 600;cursor:pointer;"
                            >{{ $t("formsContents.options.clearAll") }}</span
                          >
                        </span>

                        <!-- <span class="d-flex align-center add_style" @click="send(index)" v-if="addmore">
                        <v-icon color="green" size="20" style="width: auto;">mdi-plus-circle</v-icon>
                        <span class="ms-1" style="font-size:14; font-weight: 600;">Add Moreee</span>
                      </span> -->
                      </div>
                      <div
                        class="col-lg-3 mt-lg-0 mt-4 d-flex pe-2 align-end justify-center justify-content-lg-end magnify-btn"
                      >
                        <div @click="sendedMulti()">
                          <v-btn
                            color="#9aca0f"
                            height="40px"
                            width="85px"
                            class="ms-lg-1 ms-0"
                            rounded="1"
                          >
                            <v-icon color="white">mdi-magnify</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-1 col-sm-5 mt-3 mt-lg-0 d-flex align-center justify-center justify-content-lg-start magnify-btn2">
                <div @click="sended()">
                  <v-btn color="#94C20E" height="40px" width="120" rounded="1">
                    <v-icon color="white">mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row mt-4" v-if="!pageError">
        <div class="col-lg-3 mb-2 mb-lg-0">
          <v-card>
            <div style="background-color: #3c0ec2; padding: 3px">
              <div class="row p-2 align-center" style="color: white">
                <div class="col-6 d-flex align-center">
                  <div class="d-flex align-center">
                    <i class="fa fa-sliders" style="font-size: 18px"></i>
                     <span style="font-size: 16px" class="ms-1">  {{ $t("searchPageContent.filterContent.filterBy") }}</span>
                  </div>
                  <div class="filter-toggle ms-2">
                    <v-icon
                      size="18"
                      class="not-collapsed"
                      data-bs-toggle="collapse"
                      href="#collapse-Example1"
                      aria-expanded="true"
                      aria-controls="collapse-Example1"
                      v-model="isIcon"
                      @click="isIcon = !isIcon"
                      style="overflow-anchor: none"
                      >{{isIcon ? "mdi-chevron-up-circle-outline" : "mdi-chevron-down-circle-outline"}}</v-icon>
                  </div>
                </div>
                <div class="col-6" align="right">
                  <div style="">
                    <button @click ="resetAllFilters()">{{ $t("searchPageContent.filterContent.resetFilter") }}</button></div>
                </div>
              </div>
            </div>

            <div
              class="filter_icons"
              style="padding: 10px"
              :class="{ collapse: !isIcon, show: isIcon }"
              aria-expanded="false"
              :id="{ 'collapse-Example1': isIcon }"
            >
              <div>
                <!-- <br /> -->

                <div class="row gy-0" v-if="showNonStop">
                  <div class="col-8">
                    <v-checkbox
                      :label="this.$t('searchPageContent.itinContent.nonStop')"
                      v-model="NonStopFil"
                      @change="applyFilters"
                      :disabled="!hasNonStop"
                      color="primary"
                    ></v-checkbox>
                  </div>
                </div>

                <div class="row" v-if="showOneStop">
                  <div class="col-8">
                    <v-checkbox
                      :label="`1 ${this.$t('searchPageContent.itinContent.stops')}`"
                      v-model="OneStopFil"
                      @change="applyFilters"
                      :disabled="!hasOneStop"
                      color="primary"
                    ></v-checkbox>
                  </div>
                </div>

                <div class="row" v-if="showOneplusStop">
                  <div class="col-8">
                    <v-checkbox
                      :label="`1 + ${this.$t('searchPageContent.itinContent.stops')}`"
                      v-model="OnePlusFil"
                      @change="applyFilters"
                      :disabled ="!hasOnePlusStop"
                      color="primary"
                    ></v-checkbox>
                  </div>
                </div>

                <!-- <hr />
                <div class="stop mb-3">Baggage</div>
                <div class="row mt-1">
                  <div class="col-8">
                    <v-checkbox label="Baggage Inclusive" color="primary"></v-checkbox>
                  </div>
                  <div class="col-4" align="center">
                  <div>(100)</div>
                </div>
                </div> -->

                <hr />
                <div class="stop">{{ $t("searchPageContent.filterContent.fareType") }}</div>
                <div class="row mt-1">
                  <div class="col-8">
                    <v-checkbox
                      :label="this.$t('searchPageContent.itinContent.refund')"
                      color="primary"
                      v-model="RefundableData"
                      @change="applyFilters"
                      :disabled="!hasRefundable"
                    ></v-checkbox>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <v-checkbox
                      :label="this.$t('searchPageContent.itinContent.nonRefund')"
                      color="primary"
                      v-model="nonRefundableData"
                      @change="applyFilters"
                      :disabled="!hasNonRefundable"
                    ></v-checkbox>
                  </div>
                </div>

                <hr />
                <div class="stop">
                  {{ $t("searchPageContent.filterContent.priceRange") }}
                  <span style="font-size: 13px"> ({{ $t("searchPageContent.filterContent.avgperPax") }})</span>
                </div>
                <div class="row me-1">
                  <v-range-slider
                    v-model="changevalue"
                    @change="applyFilters"
                    :min="minimum"
                    :max="maximum"
                    :step="steps"
                    thumb-label
                    class="price_slider"
                    color="#0c4ca3"
                  ></v-range-slider>
                </div>
                <div class="row">
                  <div class="col-6" align="center">
                    <p class="f-size-13">
                      {{ currencyCode }} {{ changevalue[0] }}
                    </p>
                  </div>
                  <div class="col-6" align="center">
                    <p class="f-size-13">
                      {{ currencyCode }} {{ changevalue[1] }}
                    </p>
                  </div>
                </div>
                 <hr style="margin-bottom:12px !important;">

                 <div class="my-2" v-if="this.onedatafil || this.rounddatafil">
                  <h5>{{ $t("searchPageContent.filterContent.onwardjourney") }}</h5>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      {{ $t("searchPageContent.filterContent.depFrom") }} {{ FilterTimeDepname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeFilter4()">
                      <v-card
                        :color="timeFilterData4 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeFilterData4 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                          {{ $t("searchPageContent.filterContent.before") }} 6am
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilter1()">
                      <v-card
                        :color="timeFilterData1 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeFilterData1 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div
                      @click="TimeFilter2()">
                      <v-card
                       
                        :color="timeFilterData2 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeFilterData2 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunset-down</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div 
                      @click="TimeFilter3()">
                      <!-- :class="{ disabled: !hasEveningFlights }" -->
                      <v-card
                        
                        :color="timeFilterData3 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeFilterData3 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                          {{ $t("searchPageContent.filterContent.after") }} 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      {{ $t("searchPageContent.filterContent.arrivalAt") }} {{ FilterTimearrname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div
                    @click="TimeFilterArr4()">
                      <v-card
                        :color="timeFilterArrData4 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              timeFilterArrData4 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                          {{ $t("searchPageContent.filterContent.before") }} 6am
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div
                    @click="TimeFilterArr1()">
                      <v-card
                        :color="timeFilterArrData1 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              timeFilterArrData1 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div
                    @click="TimeFilterArr2()">
                      <!-- :class="{ disabled: !hasAfternoonFlights1 }" -->
                      <v-card
                        :color="timeFilterArrData2 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timeFilterArrData2 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunset-down</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div
                      @click="TimeFilterArr3()">
                      <!-- :class="{ disabled: !hasEveningFlights1 }" -->
                      <v-card
                        :color="timeFilterArrData3 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              timeFilterArrData3 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                          {{ $t("searchPageContent.filterContent.after") }} 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <hr />
                </div>

                <div class="my-2" v-if="this.rounddatafil">
                  <h5>{{ $t("searchPageContent.filterContent.returnjourney") }}</h5>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      {{ $t("searchPageContent.filterContent.depFrom") }} {{ FilterTimearrname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeretFilter4()">
                      <v-card
                        :color="timearrFilterData4 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              timearrFilterData4 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                          {{ $t("searchPageContent.filterContent.before") }} 6am
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilter1()">
                      <v-card
                        :color="timearrFilterData1 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              timearrFilterData1 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilter2()">
                      <v-card
                        :color="timearrFilterData2 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              timearrFilterData2 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunset-down</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilter3()">
                      <v-card
                        :color="timearrFilterData3 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              timearrFilterData3 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                          {{ $t("searchPageContent.filterContent.after") }} 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      {{ $t("searchPageContent.filterContent.arrivalAt") }} {{ FilterTimeDepname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                     <div @click="TimeretFilterarr4()">
                      <v-card
                        :color="
                          timearrFilterArrData4 ? '#bae9e45e' : 'white'
                        "
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              timearrFilterArrData4 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                          {{ $t("searchPageContent.filterContent.before") }} 6am
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilterarr1()">
                      <v-card
                        :color="
                          timearrFilterArrData1 ? '#bae9e45e' : 'white'
                        "
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              timearrFilterArrData1 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilterarr2()">
                      <v-card
                        :color="
                          timearrFilterArrData2 ? '#bae9e45e' : 'white'
                        "
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              timearrFilterArrData2 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunset-down</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilterarr3()">
                      <v-card
                        :color="
                          timearrFilterArrData3 ? '#bae9e45e' : 'white'
                        "
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              timearrFilterArrData3 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                          {{ $t("searchPageContent.filterContent.after") }} 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <hr />
                </div>

                <div class="d-none my-2" v-if="multifil">
                  <div>
                  <!-- <h5>Flight1 Journey</h5> -->

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                       <!-- Departure From {{ codeData[0].from }} -->
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <!-- <div @click="hasMorningFlights ? TimeFilter1() : null" -->
                    <div @click="TimeMulti4()">
                      <v-card
                        :color="timeMulti4 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeMulti4 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                          {{ $t("searchPageContent.filterContent.before") }} 6am
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeMulti1()">
                      <v-card
                        :color="timeMulti1 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeMulti1 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div
                      @click="TimeMulti2()">
                      <!-- :class="{ disabled: !hasAfternoonFlights }" -->
                      <v-card
                       
                        :color="timeMulti2 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeMulti2 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunset-down</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div 
                      @click="TimeMulti3()">
                      <!-- :class="{ disabled: !hasEveningFlights }" -->
                      <v-card
                        
                        :color="timeMulti3 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeMulti3 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                          {{ $t("searchPageContent.filterContent.after") }} 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      <!-- Arrival at {{ codeData[3].to }} -->
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <!-- <div @click="hasMorningFlights ? TimeFilter1() : null" -->
                    <div @click="TimeMultiArr4()">
                      <v-card
                        :color="timeMultiArr4 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeMultiArr4 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeMultiArr1()">
                      <v-card
                        :color="timeMultiArr1 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeMultiArr1 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div
                      @click="TimeMultiArr2()">
                      <!-- :class="{ disabled: !hasAfternoonFlights }" -->
                      <v-card
                       
                        :color="timeMultiArr2 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeMultiArr2 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunset-down</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div 
                      @click="TimeMultiArr3()">
                      <!-- :class="{ disabled: !hasEveningFlights }" -->
                      <v-card
                        
                        :color="timeMultiArr3 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeMultiArr3 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                          {{ $t("searchPageContent.filterContent.after") }} 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  </div>
                   <hr style="margin-bottom:12px !important;margin-top:12px !important;">

                  
                </div>
                <div v-if="multifil">
                  <h6>{{ $t("searchPageContent.filterContent.flightDuration") }}</h6>
                  <span class="d-inline-flex"><v-img src="../assets/airplane.png" width="20px" class="me-2"></v-img>{{ $t("searchPageContent.filterContent.outBound") }}</span>
                  <div class="outbound">
                    <v-select
                      :items="outBoundTimeMulti"
                      v-model="defaultOutBoundMulti"
                      @change="applyFilters"
                    ></v-select>
                  </div>
                </div>
                <div v-if="this.onedatafil || this.rounddatafil">
                 <div>
                  <h6>{{ $t("searchPageContent.filterContent.airports") }}</h6>
                  <span class="d-inline-flex"><v-img src="../assets/airplane.png" width="20px" class="me-2"></v-img>
                    {{ $t("searchPageContent.filterContent.departure") }}</span>
                  <div v-for="(flight,index) in fromAirportName" :key="index">
                    <v-checkbox 
                    :value="flight" 
                    :label="flight"
                    v-model="selectedFromAirports"
                    @change="applyFilters"
                    ></v-checkbox>
                  </div>
                 </div>
                 <hr v-if="isReturn" style="margin-bottom:12px !important;margin-top:12px !important;"/>
                 <div v-if="isReturn">
                  <span class="d-inline-flex"><v-img src="../assets/airplane.png" width="20px" class="me-2"
                  style="transform:rotate(180deg);"></v-img>
                  {{ $t("searchPageContent.filterContent.arrival") }}</span>
                  <div v-for="(val,index) of toAirportName" :key="index">
                    <v-checkbox 
                    :label="val" 
                    :value="val"
                    v-model="selectedToAirports"
                    @change="applyFilters"
                    ></v-checkbox>
                  </div>
                 </div>
                </div>

                <div v-if="multifil" class="d-none">
                  <div v-for="(flight, index) in multiAirport" :key="index">
                    <p class="mb-0 mt-2" style="font-size:16px;font-weight:500">{{ $t("searchPageContent.filterContent.flight") }}{{index + 1}}</p>
                    <div>
                      <v-checkbox 
                      :value="flight.from" 
                      :label="flight.from" 
                      v-model="multiFromAirports"
                      @change="applyFilters"
                      ></v-checkbox>
                    </div>
                    <div>
                      <v-checkbox 
                      :value="flight.to" 
                      :label="flight.to" 
                      v-model="multiToAirports"
                      @change="applyFilters"
                      ></v-checkbox>
                    </div>
                  </div>
                </div>

                <hr style="margin-bottom:12px !important;margin-top:12px !important;">

                <div v-if="this.onedatafil || this.rounddatafil">
                <div>
                  <h6>{{ $t("searchPageContent.filterContent.flightDuration") }}</h6>
                  <span class="d-inline-flex"><v-img src="../assets/airplane.png" width="20px" class="me-2"></v-img>{{ $t("searchPageContent.filterContent.outBound") }}</span>
                  <div class="outbound">
                    <v-select
                      :items="outBoundTime"
                      v-model="defaultOutBoundTime"
                      @change="applyFilters"
                    ></v-select>
                  </div>
                </div>
                <hr v-if="isReturn" style="margin-bottom:12px !important;margin-top:12px !important;"/>
                <div v-if="isReturn">
                  <span class="d-inline-flex"><v-img src="../assets/airplane.png" width="20px" class="me-2" style="transform:rotate(180deg);"></v-img>{{ $t("searchPageContent.filterContent.inBound") }}</span>
                  <div class="inbound">
                    <v-select
                      :items="inBoundTime"
                      v-model="defaultInBoundTime"
                      @change="applyFilters"
                    ></v-select>
                  </div>
                </div>
                <hr style="margin-bottom:12px !important;margin-top:12px !important;">
                </div>
                
                
                <div v-if="this.onedatafil || this.rounddatafil">
                  <div
                    class="stop"
                    v-if="this.multicityData.length > 0 ? false : true"
                  >
                  {{ $t("searchPageContent.filterContent.airlines") }}
                  </div>
                  <div
                    class="row mb-4"
                    style="font-size: 14px; cursor: pointer"
                    v-if="this.multicityData.length > 0 ? false : true"
                  >
                    <div class="col-md-6 col-6 ps-3" @click="airlineSelect()">
                      {{ $t("searchPageContent.filterContent.selectAll") }}
                    </div>
                    <div class="col-md-6 col-6 text-end pe-3" @click="airlineClear()">{{ $t("searchPageContent.filterContent.clearAll") }}</div>
                  </div>
                  
                  <div
                    class="row"
                    v-for="(data, index) of airlineList.slice(0, maxItemsToShow)"
                    :key="index"
                  >
                    <div class="col-8">
                      <v-checkbox
                        class="text-truncate"
                        :label="data.airline"
                        color="primary"
                        v-model="data.active"
                        @click="selectAirline(data, index)"
                        @change="applyFilters"
                      >
                      </v-checkbox>
                    </div>
                  </div>

                  <div v-if="airlineList.length > 5">
                    <p
                      class="m-0"
                      style="cursor: pointer; color: #3366cc; font-size: 14px"
                      @click="showMore"
                      v-if="!readMore"
                    >
                      ( +{{ airlineList.length - maxItemsToShow }} {{ $t("searchPageContent.filterContent.more") }} )
                    </p>

                    <p
                      class="m-0"
                      style="cursor: pointer; color: #3366cc; font-size: 14px"
                      @click="showMore"
                      v-if="readMore"
                    >
                      ( {{ $t("searchPageContent.filterContent.showless") }} )
                    </p>
                  </div>
                </div>

                <div v-if="multifil">
                  <div
                    class="stop"
                    v-if="this.multicityData.length > 0 ? true : false"
                  >
                  {{ $t("searchPageContent.filterContent.airlines") }}
                  </div>
                  <div
                    class="row mb-4"
                    style="font-size: 14px; cursor: pointer"
                    v-if="this.multicityData.length > 0 ? true : false"
                  >
                    <div class="col-md-6 ps-3" @click="airlineSelect()">
                      {{ $t("searchPageContent.filterContent.selectAll") }}
                    </div>
                    <div class="col-md-6 text-end pe-3" @click="airlineClear()">{{ $t("searchPageContent.filterContent.clearAll") }}</div>
                  </div>
                  
                  <div
                    class="row"
                    v-for="(data, index) of airlineList.slice(0, maxItemsToShow)"
                    :key="index"
                  >
                    <div class="col-8">
                      <v-checkbox
                        class="text-truncate"
                        :label="data.airline"
                        color="primary"
                        v-model="data.active"
                        @click="selectAirline(data, index)"
                        @change="applyFilters"
                      >
                      </v-checkbox>
                    </div>
                  </div>

                  <div v-if="airlineList.length > 5">
                    <p
                      class="m-0"
                      style="cursor: pointer; color: #3366cc; font-size: 14px"
                      @click="showMore"
                      v-if="!readMore"
                    >
                      ( +{{ airlineList.length - maxItemsToShow }} {{ $t("searchPageContent.filterContent.more") }} )
                    </p>

                    <p
                      class="m-0"
                      style="cursor: pointer; color: #3366cc; font-size: 14px"
                      @click="showMore"
                      v-if="readMore"
                    >
                      ( {{ $t("searchPageContent.filterContent.showless") }} )
                    </p>
                  </div>
                </div>

                <br />
                  
                </div>
            </div>
          </v-card>
        </div>
        <div class="col-lg-9">
          <div class="mb-3" v-for="(res, index) of rountresult" :key="index">
            <!-- {{ console.log(rountresult,'rountttttt') }} -->
            <v-card class="hoveredCard">
              <div class="row gx-0">

                <div class="col-lg-2 d-none flex-column align-center justify-center">
                  <div class="row g-0 px-3 align-center w-100">
                    <div class="d-flex align-center justify-content-between">
                      <div class="d-flex align-center">
                        <span class="me-1"
                          ><v-icon size="20px">mdi-airplane-takeoff</v-icon></span
                        >
                        <h6 class="m-0">{{ $t("searchPageContent.itinContent.depature") }}</h6>
                      </div>
                      <div class="d-flex align-center">
                        <div class="res-name text-center">
                          <span style="font-size: 12px">{{
                            res.Depature[0].MarketingCarrier.Name
                          }}</span>
                          <br />
                          <span style="font-size: 8px"
                            >{{ res.Depature[0].MarketingCarrier.AirlineID }} -
                            {{
                              res.Depature[0].MarketingCarrier.FlightNumber
                            }}</span
                          >
                        </div>
                        <div class="airline-logo ms-2">
                          <v-img
                            :src="getdepLogo(res.Depature[0].MarketingCarrier)"
                            width="22px"
                          ></v-img>
                          <!-- <v-img :src="res.Depature[0].MarketingCarrier.logo" width="22px"></v-img> -->
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-3">
                      <div class="airline-logo">
                        <v-img :src="res.ownerlogo" width="70px"></v-img>
                      </div>

                      <div>
                        <p
                          class="res-name text-center"
                          style="
                            padding-left: 0px !important;
                            text-align: left !important;
                          "
                        >
                          {{ res.ownername }}
                        </p>
                      </div>
                    </div> -->

                    <div class="col-6 d-none">
                      <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                        style="margin-bottom: 10px"
                      >
                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount">{{ res.perperson }}</span> -->
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>

                            <div class="d-flex price-Decimal">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: -3px;
                                "
                                >.</span
                              ><span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <span
                          class="content"
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            margin-top: -10px;
                            margin-bottom: 10px;
                          "
                          >Per Person</span
                        >
                      </div>
                    </div>

                    <div class="col-3 d-none d-flex justify-center align-center">
                      <v-btn
                        color="#94C20E"
                        class="selected_btn"
                        width="160px"
                        height="40px"
                        style="color: white"
                        @click.prevent="selected(res, index)"
                        >{{ $t("searchPageContent.itinContent.select") }}
                      </v-btn>
                    </div>
                  </div>
                </div>

                <div class="col-lg-9 Timing-section" style="padding: 5px">
                  <div class="d-flex align-center justify-content-between">
                    <div class="d-flex align-center">
                      <span class="me-1"><v-icon size="20px" color="black" class="m-0">mdi-airplane-takeoff</v-icon></span>
                      <h6 class="m-0">{{ $t("searchPageContent.itinContent.depature") }}</h6>
                    </div>
                    <div class="d-flex align-center">
                      <div class="res-name text-center">
                        <span style="font-size: 12px">{{
                          res.Depature[0].MarketingCarrier.Name
                        }}</span>
                        <br />
                        <span style="font-size: 8px"
                          >{{ res.Depature[0].MarketingCarrier.AirlineID }} -
                          {{
                            res.Depature[0].MarketingCarrier.FlightNumber
                          }}</span
                        >
                      </div>
                      <div class="airline-logo ms-2">
                        <v-img
                          :src="getdepLogo(res.Depature[0].MarketingCarrier)"
                          width="22px"
                        ></v-img>
                        <!-- <v-img :src="res.Depature[0].MarketingCarrier.logo" width="22px"></v-img> -->
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <v-card
                        class="card-in"
                        style="overflow: unset; padding: 1px"
                      >
                        <div class="row g-0">
                          <div
                            class="col-md-3 col-2 p-2 ps-0 aircode-hide"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-end mt-lg-0 mt-md-2 mt-sm-2 mt-0"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              <!-- {{ getAirportCode(res.Depature) }} -->
                              {{ getCountryName1(tripData.from) }}
                            </p>
                            <p
                              class="text-end airportName"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportNameFrom(res.Depature) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 pe-2 aircode-show"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportCode(res.Depature) }}
                            </p>
                          </div>

                          <div class="col-md-6 col-8">
                            <div class="row g-0 mt-lg-3 mt-1 justify-center">
                              <div class="col-md-4 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getDate(res.Depature) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getDateDeparture(res.Depature) }}
                                </p>
                                <p
                                  class="text-center m-0 f-size-24"
                                  style="font-weight: 400"
                                >
                                  {{ getTime(res.Depature) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Depaturejourney.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">{{ $t("searchPageContent.itinContent.direct") }}</p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                >
                                  <hr
                                    style="width: 87%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Depaturejourney.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Depaturejourney.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Depaturejourney.Stops }} {{ $t("searchPageContent.itinContent.stop") }}
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Depature.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Depature)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Depature)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Depaturejourney.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Depaturejourney.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Depaturejourney.Stops }} {{ $t("searchPageContent.itinContent.stops") }}
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Depature.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Depature)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Depature)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails2(res, index)"
                                    @mouseout="hideDetails2(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Depature.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName2 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Depature, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Depature)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Depaturejourney.Time }}</span>
                                </div>
                              </div>

                              <div class="col-md-3 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getArrivaldate(res.Depature) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getArrivaldateReturn(res.Depature) }}
                                </p>
                                <p
                                  class="text-center m-0 f-size-24"
                                  style="font-weight: 400"
                                >
                                  {{ getArrivalTime(res.Depature) }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-3 col-2 p-2 pe-0 aircode-hide"
                            style="border-left: 1px dotted gray"
                          >
                            <p class="mt-lg-0 mt-md-2 mt-sm-2 mt-0"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              <!-- {{ getArrivalCode(res.Depature) }} -->
                              {{ getCountryName2(tripData.to) }}
                            </p>
                            <p class="airportName"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportNameTo(res.Return) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-show"
                            style="border-left: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-start"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getArrivalCode(res.Depature) }}
                            </p>
                          </div>
                        </div>
                      </v-card>
                    </div>

                    <!-- <br /> -->

                    <div>
                      <div class="d-flex align-center justify-content-between">
                        <div class="d-flex align-end">
                          <span class="me-1"
                            ><v-icon
                              size="20px" color="black" class="m-0"
                              >mdi-airplane-landing</v-icon
                            ></span
                          >
                          <h6 class="m-0">{{ $t("searchPageContent.itinContent.return") }}</h6>
                        </div>
                        <div class="d-flex align-center">
                          <div class="res-name text-center">
                            <span style="font-size: 12px">{{
                              res.Return[0].MarketingCarrier.Name
                            }}</span>
                            <br />
                            <span style="font-size: 8px"
                              >{{ res.Return[0].MarketingCarrier.AirlineID }} -
                              {{
                                res.Return[0].MarketingCarrier.FlightNumber
                              }}</span
                            >
                          </div>
                          <div class="airline-logo ms-2">
                            <v-img
                              :src="
                                getreturnLogo(res.Return[0].MarketingCarrier)
                              "
                              width="22px"
                            ></v-img>
                          </div>
                        </div>
                      </div>

                      <v-card
                        class="card-in"
                        style="overflow: unset; padding: 1px"
                      >
                        <div class="row g-0">
                          <div
                            class="col-md-3 col-2 p-2 ps-0 aircode-hide"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-end mt-lg-0 mt-md-2 mt-0 mt-sm-2"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              <!-- {{ getreturnAirportCode(res.Return) }} -->
                              {{ getCountryName2(tripData.to) }}
                            </p>

                            <p
                              class="text-end airportName"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportNameTo(res.Return) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 pe-2 aircode-show"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-center"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getreturnAirportCode(res.Return) }}
                            </p>
                          </div>

                          <div class="col-md-6 col-8">
                            <div class="row g-0 mt-lg-3 mt-1 justify-center">
                              <div class="col-md-4 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getreturnDate(res.Return) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getreturnDateDeparture(res.Return) }}
                                </p>
                                <p
                                  class="text-center m-0 f-size-24"
                                  style="font-weight: 400"
                                >
                                  {{ getreturnTime(res.Return) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Returnjourney.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">{{ $t("searchPageContent.itinContent.direct") }}</p>
                                <div
                                  class="d-flex align-center justify-center my-2"
                                >
                                  <hr
                                    style="width: 87%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Returnjourney.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Returnjourney.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Returnjourney.Stops }} {{ $t("searchPageContent.itinContent.stop") }}
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails3(res, index)"
                                    @mouseout="hideDetails3(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Depature.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName3 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getreturndata(res.Return)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getreturntime(res.Return)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Returnjourney.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Returnjourney.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Returnjourney.Stops }} {{ $t("searchPageContent.itinContent.stops") }}
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails3(res, index)"
                                    @mouseout="hideDetails3(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Depature.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName3 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getreturndata(res.Return)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getreturntime(res.Return)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails4(res, index)"
                                    @mouseout="hideDetails4(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Depature.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName4 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        get1returntime(res.Return, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2returntime(res.Return)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Returnjourney.Time }}</span>
                                </div>
                              </div>

                              <div class="col-md-3 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getreturnArrivaldate(res.Return) }}
                                </p>
                                <p
                                  class="res-time airportName text-center m-0 date-section-show"
                                >
                                  {{ getreturnArrivaldateReturn(res.Return) }}
                                </p>
                                <p
                                  class="text-center m-0 f-size-24"
                                  style="font-weight: 400"
                                >
                                  {{ getreturnArrivalTime(res.Return) }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-3 col-2 p-2 pe-0 aircode-hide"
                            style="border-left: 1px dotted gray"
                          >
                            <p
                              class="res-time mt-md-2 mt-lg-0 mt-sm-2 mt-0"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              <!-- {{ getreturnArrivalcode(res.Return) }} -->
                              {{ getCountryName1(tripData.from) }}
                            </p>

                            <p
                              class="res-time airportName"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportNameFrom(res.Depature) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-show"
                            style="border-left: 1px dotted gray"
                          >
                            <p
                              class="res-time text-center"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getreturnArrivalcode(res.Return) }}
                            </p>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>

                  <!-- Seat................ -->

                  <div class="row d-flex justify-content-between">

                    <div class="col-12 col-lg-12 col-sm-6 col-md-6">
                    <div class="row d-flex mt-3 gx-0">
                      <div class="col-lg-4 col-md-6 col-sm-6 col-4 d-flex flex-lg-row flex-column align-center justify-content-center">
                        <span><v-icon size="20">mdi-cash</v-icon></span>
                        <div>
                          <span
                            style="
                              font-size: 12px;
                              color: gray;
                              padding-left: 2px;
                            "
                            >{{ translateRefund(res.Refund) }}</span
                          >
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-6 col-4 d-flex flex-lg-row flex-column justify-center align-center">
                        <span><v-icon size="18">mdi-seat-passenger</v-icon></span>
                        <span style="font-size: 12px; color: gray">{{
                          translateClass(oneway.class)
                        }}</span>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-6 col-4 d-flex flex-lg-row flex-column align-center justify-center">
                        <!-- <div class="trigger"></div> -->
                          <span><v-img :src="require('../assets/flightIcon.png')" width="20px" style="margin:auto;"></v-img></span>
                          <span style="color: #36c;font-size: 12px;margin-top: 2px; cursor: pointer;" @click="flighted1(res, index)">{{ $t("searchPageContent.itinContent.flightDetails") }}</span>
                      
                      </div>
                      <hr class="d-md-none d-sm-none d-block" style="margin:7px 0px !important"/>

                      <div class="col-md-6 col-sm-6 col-4 d-lg-none justify-center">
                        <v-menu location="bottom">
                          <template v-slot:activator="{ props }">
                            <div class="d-flex flex-column align-center mt-lg-0 mt-1">
                              <v-img :src="require('../assets/fare-icon.png')" width="20px" height="20px"></v-img>
                              <v-card-text
                                @click="fareOpen = !fareOpen"
                                style="color: #36c; font-weight: 500"
                                class="p-0 text-center"
                                v-bind="props"
                              >
                                {{ $t("fareDetContent.fareDetails") }}
                              </v-card-text>
                            </div>
                          </template>

                          <v-card width="290px" max-height="500px" class="pa-3 m-auto" style="border: 1px solid gray">
                        <div>
                          <div class="d-flex justify-content-between mb-1">
                            <div>
                              <p class="m-0 f-size-18 fw-500"> {{ $t("fareDetContent.totalPrice") }}</p>
                            </div>
                            <div>
                              <p class="m-0 f-size-18">{{ res.currency }} {{ res.TotalPrice }}</p>
                            </div>
                          </div>

   
                          <div class="d-flex justify-content-between my-1 ">
                            <div>
                              <span class="f-size-14 fw-500">{{ $t("fareDetContent.passengers")}}</span>
                            </div>
  
                            <div class="f-size-14 text-end">
                                <span v-if="res.priceDetails.adultPriceDetails.adultcount > 0">{{ res.priceDetails.adultPriceDetails.adultcount }} x Adt</span>
                                <span v-if="res.priceDetails.childPriceDetails.childcount > 0">, {{ res.priceDetails.childPriceDetails.childcount }} x Chd</span>
                                <span v-if="res.priceDetails.infPriceDetails.infcount > 0">, {{ res.priceDetails.infPriceDetails.infcount }} x Inf</span>
                            </div>
                          </div>

                          <hr data-v-19b8244e="" class="mt-1 mb-2">
                        </div>


                        <div class="comDetailSection my-1">
                          <div class="paxpricesection">
                            <table class="table table-borderless mb-0">
                              <thead>
                                <tr class="f-size-12 fw-500">
                                  <td>Pax</td>
                                  <td>Base</td>
                                  <td>Tax</td>
                                  <td>Per Person</td>
                                </tr>
                              </thead>
                              <tbody class="f-size-12 fw-400">
                                <tr v-if="res.priceDetails.adultPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.adultPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.adultPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.adultPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.adultPriceDetails) }}</td>
                                </tr>
                                <tr v-if="res.priceDetails.childPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.childPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.childPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.childPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.childPriceDetails) }}</td>
                                </tr>
                                <tr v-if="res.priceDetails.infPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.infPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.infPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.infPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.infPriceDetails) }}</td>
                                </tr>
                              </tbody>
                            </table>

                            <table class="table mb-0 d-none">
                              <thead>
                                <tr>
                                  <td colspan="3"  class="f-size-14 fw-500">Passenger(s)</td>
                                </tr>
                              </thead>
                              <tbody class="f-size-14 fw-400">
                                <tr v-if="res.priceDetails.adultPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.adultPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.adultPriceDetails.adultcount }}</td>
                                  <td>{{ getadultTotBase(res.priceDetails) }}</td>
                                  <!-- <td>{{(res.priceDetails.adultPriceDetails.baseprice) * (res.priceDetails.adultPriceDetails.adultcount) }}</td> -->
                                </tr>
                                <tr v-if="res.priceDetails.childPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.childPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.childPriceDetails.childcount }}</td>
                                  <td>{{getchildTotBase(res.priceDetails)}}</td>
                                </tr>
                                <tr v-if="res.priceDetails.infPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.infPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.infPriceDetails.infcount }}</td>
                                  <td>{{getInfTotBase(res.priceDetails)}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="pt-2 border-2 border-top">
                            <div class="d-flex justify-content-between mb-1 f-size-14">
                              <div>
                                <span class="fw-500"> {{ $t("fareDetContent.basePrice") }}</span>
                              </div>
                              <div>
                                <span>{{ res.currency }} {{ res.Baseprice }}</span>
                              </div>
                            </div>
                            <div class="d-flex justify-content-between f-size-14">
                              <div>
                                <span class="fw-500"> {{ $t("fareDetContent.taxesFees") }}</span>
                              </div>
                              <div>
                                <span>{{ res.currency }} {{ res.Taxprice }}</span>
                              </div>
                            </div>
                          </div>
                      </v-card>

                        </v-menu>
                      </div>

                       <div class="col-4 d-lg-none d-sm-none">
                        <div
                          class="d-flex flex-column align-center justify-center mbl-price-section"
                          style="margin-bottom: 10px"
                        >
                          <div class="price-head d-flex">
                            <span class="price-currency">{{ res.currency }}</span>
                            <!-- <span class="price-Amount">{{ res.perperson }}</span> -->
                            <div id="demo" class="d-flex">
                              <span id="f0" class="price-Amount" style="font-size:21px !important;margin-top:6px;">{{
                                res.find1
                              }}</span>

                              <div class="d-flex price-Decimal d-none">
                                <span
                                  style="
                                    font-size: 20px;
                                    position: relative;
                                    left: 2px;
                                    top: -3px;
                                  "
                                  >.</span
                                ><span
                                  id="f1"
                                  style="
                                    font-size: 18px;
                                    font-weight: 600;
                                    margin-bottom: 10px;
                                    margin-left: 2px;
                                  "
                                  >{{ res.find2 }}</span
                                >
                              </div>
                            </div>
                          </div>
                          <span
                            class="content"
                            style="
                              font-size: 13px;
                              font-weight: 500;
                              margin-top: -7px;
                            "
                            >{{ $t("fareDetContent.perPerson") }}</span
                          >
                        </div>
                      </div>

                      <div class="col-4 d-flex justify-center align-center airlineLogo-show">
                        <v-btn
                          color="#94C20E"
                          class="selected_btn"
                          width="100%"
                          style="color: white;height:45px !important;"
                          @click.prevent="selected(res, index)"
                          >{{ $t("searchPageContent.itinContent.select") }} </v-btn
                        >
                      </div>
                    </div>
                  </div>
                 <div class="col-4 d-md-flex d-sm-flex flex-column price-col d-none d-lg-none mb-lg-0 mb-2">
                     <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                      >
                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount">{{ res.perperson }}</span> -->
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount" style="font-size:21px !important;margin-top:12px;">{{
                              res.find1
                            }}</span>

                            <!-- <div class="d-flex price-Decimal d-none">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: -3px;
                                "
                                >.</span
                              ><span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div> -->
                          </div>
                        </div>
                        <span
                          class="content"
                          style="
                            font-size: 13px;
                            font-weight: 500;
                            margin-top: -7px;
                          "
                          >{{ $t("fareDetContent.perPerson") }}</span
                        >
                      </div>
                      <div class="d-md-flex d-sm-flex d-lg-none d-none justify-center align-center">
                      <v-btn
                        color="#94C20E"
                        class="selected_btn"
                        width="100%"
                        style="color: white;height:45px !important;"
                        @click.prevent="selected(res, index)"
                        >{{ $t("searchPageContent.itinContent.select") }} </v-btn
                      >
                    </div>
                  </div>
                  </div>
                  <!-- Seat End................ -->
                </div>

                <div class="col-lg-3 d-none d-lg-flex flex-column align-center justify-content-between"
                  style="border-left: 1px solid #f0f0f0">
                  <div>
                    <p class="tect-center f-size-14 fw-600 invisible"
                      style="color: #36c;margin-top: 2px;cursor: pointer;">
                      Price Details
                    </p>
                  </div>

                  <div class="d-lg-flex d-none flex-column align-center justify-center" style="margin-top: 35px; margin-bottom: 26px">
                    <div class="price-head d-flex">
                      <span class="price-currency">{{ res.currency }}</span>

                      <div id="demo" class="d-flex">
                        <span id="f0" class="price-Amount">{{
                          res.find1
                        }}</span>
                      </div>
                    </div>
                    <span
                      style="
                        font-size: 14px;
                        font-weight: 500;
                        margin: -10px 0px 0px 10px;
                      "
                      >{{ $t("fareDetContent.perPerson") }}</span
                    >
                    <v-btn
                      color="#94C20E"
                      class="selected_btn"
                      width="160px"
                      height="40px"
                      style="color: white"
                      @click.prevent="selected(res, index)"
                      >{{ $t("searchPageContent.itinContent.select") }} <v-icon size="24">mdi-menu-right</v-icon></v-btn
                    >
                  </div>

                  <div class="text-center d-lg-block d-none Fare-overlay">
                    <v-menu location="start">
                      <template v-slot:activator="{ props }">
                        <v-card-text
                          @click="fareOpen = !fareOpen"
                          style="color: #36c; font-weight: 500"
                          v-bind="props"
                        >
                          {{ $t("fareDetContent.fareDetails") }}
                        </v-card-text>
                      </template>

                      <v-card width="300px" max-height="500px" class="pa-3 m-auto" style="border: 1px solid gray">
                        <div>
                          <div class="d-flex justify-content-between mb-1">
                            <div>
                              <p class="m-0 f-size-18 fw-500"> {{ $t("fareDetContent.totalPrice") }}</p>
                            </div>
                            <div>
                              <p class="m-0 f-size-18">{{ res.currency }} {{ res.TotalPrice }}</p>
                            </div>
                          </div>

   
                          <div class="d-flex justify-content-between my-1 ">
                            <div>
                              <span class="f-size-14 fw-500">{{ $t("fareDetContent.passengers")}}</span>
                            </div>
  
                            <div class="f-size-14 text-end">
                                <span v-if="res.priceDetails.adultPriceDetails.adultcount > 0">{{ res.priceDetails.adultPriceDetails.adultcount }} x Adt</span>
                                <span v-if="res.priceDetails.childPriceDetails.childcount > 0">, {{ res.priceDetails.childPriceDetails.childcount }} x Chd</span>
                                <span v-if="res.priceDetails.infPriceDetails.infcount > 0">, {{ res.priceDetails.infPriceDetails.infcount }} x Inf</span>
                            </div>
                          </div>

                          <hr data-v-19b8244e="" class="mt-1 mb-2">
                        </div>


                        <div class="comDetailSection my-1">
                          <div class="paxpricesection">
                            <table class="table table-borderless mb-0">
                              <thead>
                                <tr class="f-size-12 fw-500">
                                  <td>Pax</td>
                                  <td>Base</td>
                                  <td>Tax</td>
                                  <td>Per Person</td>
                                </tr>
                              </thead>
                              <tbody class="f-size-12 fw-400">
                                <tr v-if="res.priceDetails.adultPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.adultPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.adultPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.adultPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.adultPriceDetails) }}</td>
                                </tr>
                                <tr v-if="res.priceDetails.childPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.childPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.childPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.childPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.childPriceDetails) }}</td>
                                </tr>
                                <tr v-if="res.priceDetails.infPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.infPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.infPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.infPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.infPriceDetails) }}</td>
                                </tr>
                              </tbody>
                            </table>

                            <table class="table mb-0 d-none">
                              <thead>
                                <tr>
                                  <td colspan="3"  class="f-size-14 fw-500">Passenger(s)</td>
                                </tr>
                              </thead>
                              <tbody class="f-size-14 fw-400">
                                <tr v-if="res.priceDetails.adultPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.adultPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.adultPriceDetails.adultcount }}</td>
                                  <td>{{ getadultTotBase(res.priceDetails) }}</td>
                                  <!-- <td>{{(res.priceDetails.adultPriceDetails.baseprice) * (res.priceDetails.adultPriceDetails.adultcount) }}</td> -->
                                </tr>
                                <tr v-if="res.priceDetails.childPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.childPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.childPriceDetails.childcount }}</td>
                                  <td>{{getchildTotBase(res.priceDetails)}}</td>
                                </tr>
                                <tr v-if="res.priceDetails.infPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.infPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.infPriceDetails.infcount }}</td>
                                  <td>{{getInfTotBase(res.priceDetails)}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="pt-2 border-2 border-top">
                            <div class="d-flex justify-content-between mb-1 f-size-14">
                              <div>
                                <span class="fw-500"> {{ $t("fareDetContent.basePrice") }}</span>
                              </div>
                              <div>
                                <span>{{ res.currency }} {{ res.Baseprice }}</span>
                              </div>
                            </div>
                            <div class="d-flex justify-content-between f-size-14">
                              <div>
                                <span class="fw-500"> {{ $t("fareDetContent.taxesFees") }}</span>
                              </div>
                              <div>
                                <span>{{ res.currency }} {{ res.Taxprice }}</span>
                              </div>
                            </div>
                          </div>
                      </v-card>
                    </v-menu>
                  </div>
                </div>

                  <div class="modal" v-if="showModal === index">
                    <div class="modal-content" style="top:10%;">
                      <div class="modal-header d-flex flex-row justify-content-between pt-0">
                      <h5>{{ $t("searchPageContent.itinContent.flightDetails") }}</h5>
                        <span class="close-button" @click="closeModal()">&times;</span>
                    </div>
                    <div v-if="res.Flightdepature" class="col-12 border">
                      <div>
                        <div
                          class="d-flex p-2 justify-content-between flex-wrap"
                          style="background-color: #3c0ec2;color:white"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              color="white">mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Depature) }} -
                              {{ getArrivalCode(res.Depature) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="f-size-15 fw-500"
                              >{{ $t("searchPageContent.flightDetails.totaltripDuration") }} :
                              {{ res.Depaturejourney.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of res.Depature"
                            :key="index"
                          >
                            <div class="row my-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 m-auto airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated(data.Departure.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated1(data.Arrival.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    {{ translateClass(oneway.class) }}
                                  </span>
                                </div>
                                
                                <!-- 
                                <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span class="f-size-12" style="padding-left: 5px">
                                    Meal provided
                                  </span>
                                </div> -->
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Depature.length - 1 !== index"
                                  style="
                                    width: 97%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Depature.length - 1 !== index"
                                  style="
                                    width: 180px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >{{ $t("searchPageContent.flightDetails.layover") }} {{ data.layoverTimes }} ({{data.Arrival.AirportCode}})</span>
                                  </div>
                                </div>
                              </div>

                              <!-- <div class="d-flex justify-content-between align-center">
                              <hr v-if="res.Depature.length - 1 !== index" style="width: 40%" class="mt-0 mb-0" />
                              <div class="d-flex justify-center mx-2 layover-size"
                                v-if="res.Depature.length - 1 !== index" style="width: 20%">
                                <div class="d-flex align-center">
                                  <span class="f-size-11 lay-fsize">
                                    {{ data.layoverTimes }} Layover
                                    Time</span>
                                </div>
                              </div>
                              <hr v-if="res.Depature.length - 1 !== index" style="width: 40%" class="mt-0 mb-0" />
                            </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-3 d-none">
                      <div class="d-flex ms-2 mt-2">
                        <div style="color: gray !important">
                          <v-icon color="gray"> mdi-bag-suitcase </v-icon>
                        </div>
                        <div
                          style="
                            font-size: 14px;
                            font-weight: 400;
                            margin-left: 5px;
                          "
                        >
                          <span> {{ $t("searchPageContent.flightDetails.checkedBag") }}:</span>
                          <!-- <span class="ms-1"> {{res.depBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                          <span class="ms-1">
                            {{
                              res.depBaggage.PieceAllowance.TotalQuantity || 0
                            }}

                            {{
                              res.depBaggage.PieceAllowance.TotalQuantity > 1
                                ? "Pieces"
                                : "Piece"
                            }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div v-if="res.Flightdepature">
                      <div class="mt-2 border">
                        <div
                          class="d-flex p-2 justify-content-between flex-wrap"
                          style="background-color: #3c0ec2;color:white;"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(-90deg) !important"
                              color="white">mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getreturnAirportCode(res.Return) }} -
                              {{ getreturnArrivalcode(res.Return) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="f-size-15 fw-500"
                              >{{ $t("searchPageContent.flightDetails.totaltripDuration") }} :
                              {{ res.Returnjourney.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div v-for="(data, index) of res.Return" :key="index">
                            <div class="row my-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 m-auto airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated2(data.Departure.Date)
                                    }}</span>

                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep1(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >,
                                    {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated3(data.Arrival.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarrival(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500">{{
                                      data.Arrival.AirportCode
                                    }}</span>
                                    , {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    {{ translateClass(oneway.class) }}
                                  </span>
                                </div>

                                <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span class="f-size-12" style="padding-left: 5px">
                                    Meal provided
                                  </span>
                                </div> -->
                              </div>
                            </div>

                            <div class="d-flex position-relative mt-3">
                              <hr
                                v-if="index !== res.Return.length - 1"
                                style="
                                  width: 100%;
                                  position: absolute;
                                  margin-top: 8px !important;
                                  margin-bottom: 0px !important;
                                "
                              />
                              <div
                                class="d-flex justify-center"
                                v-if="index !== res.Return.length - 1"
                                style="
                                  width: 180px;
                                  background: antiquewhite;
                                  border-radius: 18px;
                                  z-index: 1;
                                  margin: auto;
                                "
                              >
                                <div class="d-flex align-center">
                                  <span class="f-size-10"
                                    >{{ $t("searchPageContent.flightDetails.layover") }} {{ data.layoverTimes }} ({{
                                      data.Arrival.AirportCode
                                    }})</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
              </div>
            </v-card>
          </div>

          <div class="mb-3" v-for="(res, index) of onewayresulted" :key="index">
            <v-card class="hoveredCard">
              <div class="row">
                <div class="col-lg-2 d-none flex-column align-center justify-center" 
                  style="border-right: 1px solid #f0f0f0">
                  <div class="airline-logo">
                    <v-img :src="res.ownerlogo" width="60px"></v-img>
                  </div>
                  <div>
                    <p class="res-name text-center">
                      {{ res.ownername }}
                    </p>
                  </div>
                </div>

                <div class="col-lg-2 d-none flex-column align-center justify-center" >
                  <div class="row g-0 px-3 align-center">
                    <div class="col-3">
                      <div class="airline-logo">
                        <v-img :src="res.ownerlogo" width="70px"></v-img>
                      </div>

                      <div>
                        <p
                          class="res-name text-center"
                          style="
                            padding-left: 0px !important;
                            text-align: left !important;
                          "
                        >
                          {{ res.ownername }}
                        </p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                        style="margin-bottom: 10px"
                      >
                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount">{{ res.perperson }}</span> -->

                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>

                            <div class="d-flex price-Decimal">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: -3px;
                                "
                                >.</span
                              ><span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <span
                          class="content"
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            margin-top: -10px;
                            margin-bottom: 10px;
                          "
                          >{{ $t("fareDetContent.perPerson") }}</span
                        >
                      </div>
                    </div>

                    <div class="col-3 d-flex align-center justify-center">
                      <v-btn
                        color="#94C20E"
                        class="selected_btn"
                        width="160px"
                        height="40px"
                        style="color: white"
                        @click.prevent="onewayselect(res, index)"
                        >{{ $t("searchPageContent.itinContent.select") }}
                      </v-btn>
                    </div>
                  </div>
                </div>

                <div class="col-lg-9 Timing-section" style="padding: 2px 0px 7px 12px">
                  <div class="d-flex align-center justify-content-between px-2">
                    <div class="d-flex align-center">
                      <span class="me-1"
                        ><v-icon size="20px" color="black" class="m-0">mdi-airplane-takeoff</v-icon></span
                      >
                      <h6 class="m-0">{{ $t("searchPageContent.itinContent.depature") }}</h6>
                    </div>
                    <div class="d-flex align-center pt-1">
                      <div class="res-name text-center">
                        <span style="font-size: 12px">{{
                          res.Depature[0].MarketingCarrier.Name
                        }}</span>
                        <br />
                        <span style="font-size: 8px"
                          >{{ res.Depature[0].MarketingCarrier.AirlineID }} -
                          {{
                            res.Depature[0].MarketingCarrier.FlightNumber
                          }}</span
                        >
                      </div>
                      <div class="airline-logo ms-2">
                        <v-img
                          :src="getdepLogo(res.Depature[0].MarketingCarrier)"
                          width="22px"
                        ></v-img>
                        <!-- <v-img :src="res.Depature[0].MarketingCarrier.logo" width="22px"></v-img> -->
                      </div>
                    </div>
                  </div>
                  <div class="mt-1">
                    <div>
                      <v-card
                        class="card-in"
                        style="overflow: unset; padding: 1px"
                      >
                        <div class="row mt-2 g-0">
                          <div
                            class="col-md-3 col-2 pe-2 aircode-hide"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-end mt-md-3 mt-sm-3 mt-lg-0 mt-0"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              <!-- {{ getAirportCode(res.Depature) }} -->
                              {{ getCountryName1(tripData.from) }}
                            </p>
                            <p
                              class="text-end airportName"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportNameFrom(res.Depature) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 pe-2 aircode-show"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportCode(res.Depature) }}
                            </p>
                          </div>

                          <div class="col-md-6 col-8">
                            <div class="row g-0 mt-1 justify-center">
                              <div class="col-md-4 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getDate(res.Depature) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getDateDeparture(res.Depature) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getTime(res.Depature) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Depaturejourney.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">{{ $t("searchPageContent.itinContent.direct") }}</p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                >
                                  <hr
                                    style="width: 87%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Depaturejourney.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Depaturejourney.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Depaturejourney.Stops }} {{ $t("searchPageContent.itinContent.stop") }}
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Depature.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Depature)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Depature)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Depaturejourney.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Depaturejourney.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Depaturejourney.Stops }} {{ $t("searchPageContent.itinContent.stops") }}
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Depature.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Depature)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Depature)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails2(res, index)"
                                    @mouseout="hideDetails2(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Depature.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName2 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Depature, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Depature)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Depaturejourney.Time }}</span>
                                </div>
                              </div>

                              <div class="col-md-3 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getArrivaldate(res.Depature) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getArrivaldateReturn(res.Depature) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getArrivalTime(res.Depature) }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-hide"
                            style="border-left: 1px dotted gray"
                          >
                            <p class="mt-md-3 mt-sm-3 mt-lg-0 mt-0"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              <!-- {{ getArrivalCode(res.Depature) }} -->
                              {{ getCountryName2(tripData.to) }}
                            </p>
                            <p class="airportName"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getretairname(res.Depature) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-show"
                            style="border-left: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-start"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getArrivalCode(res.Depature) }}
                            </p>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>

                  <!-- Seat................ -->
                  <div class="row d-flex justify-content-between">

                    <div class="col-12 col-lg-12 col-sm-6 col-md-6">
                      <div class="row d-flex mt-2 gx-0">
                        <div class="col-lg-4 col-md-6 col-sm-6 col-4 d-flex flex-lg-row flex-column align-center justify-content-center">
                      <span><v-icon size="20">mdi-cash</v-icon></span>
                      <div>
                        <span
                          style="
                            font-size: 12px;
                            color: gray;
                            padding-left: 2px;
                          "
                          >{{ translateRefund(res.Refund) }}</span
                        >
                      </div>
                        </div>
                      
                        <div class="col-lg-4 col-md-6 col-sm-6 col-4 d-flex flex-lg-row flex-column justify-center align-center">
                      <span><v-icon size="18">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray">
                        {{translateClass(oneway.class)}}</span>
                        </div>
                      
                        <div class="col-lg-4 col-md-6 col-sm-6 col-4 d-flex flex-lg-row flex-column align-center justify-center" @click="flighted2(res, index)">
                      <!-- <div class="trigger"></div> -->
                        <span><v-img :src="require('../assets/flightIcon.png')" width="20px" style="margin:auto;"></v-img></span>
                        <span style="color: #36c;font-size: 12px;margin-top: 2px; cursor: pointer;">{{ $t("searchPageContent.itinContent.flightDetails") }}</span>

                        <!-- <v-icon style="color: #36c" size="18">{{res.Flightdepature ? "mdi-close-box" : "mdi-chevron-down-box"}}</v-icon> -->
                      
                 
                        </div>
                        <hr class="d-md-none d-sm-none d-block" style="margin:7px 0px !important"/>
                      
                        <div class="col-md-6 col-sm-6 col-4 d-lg-none justify-center">
                          <v-menu location="bottom">
                            <template v-slot:activator="{ props }">
                              <div class="d-flex flex-column align-center mt-lg-0 mt-1">
                                <v-img :src="require('../assets/fare-icon.png')" width="20px" height="20px"></v-img>
                                <v-card-text
                                  @click="fareOpen = !fareOpen"
                                  style="color: #36c; font-weight: 500"
                                  class="p-0 text-center"
                                  v-bind="props"
                                >
                                  {{ $t("fareDetContent.fareDetails") }}
                                </v-card-text>
                                <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                              </div>
                            </template>
                          
                            <v-card width="290px" max-height="500px" class="pa-3 m-auto" style="border: 1px solid gray">
                        <div>
                          <div class="d-flex justify-content-between mb-1">
                            <div>
                              <p class="m-0 f-size-18 fw-500"> {{ $t("fareDetContent.totalPrice") }}</p>
                            </div>
                            <div>
                              <p class="m-0 f-size-18">{{ res.currency }} {{ res.TotalPrice }}</p>
                            </div>
                          </div>

   
                          <div class="d-flex justify-content-between my-1 ">
                            <div>
                              <span class="f-size-14 fw-500">{{ $t("fareDetContent.passengers")}}</span>
                            </div>
  
                            <div class="f-size-14 text-end">
                                <span v-if="res.priceDetails.adultPriceDetails.adultcount > 0">{{ res.priceDetails.adultPriceDetails.adultcount }} x Adt</span>
                                <span v-if="res.priceDetails.childPriceDetails.childcount > 0">, {{ res.priceDetails.childPriceDetails.childcount }} x Chd</span>
                                <span v-if="res.priceDetails.infPriceDetails.infcount > 0">, {{ res.priceDetails.infPriceDetails.infcount }} x Inf</span>
                            </div>
                          </div>

                          <hr data-v-19b8244e="" class="mt-1 mb-2">
                        </div>


                        <div class="comDetailSection my-1">
                          <div class="paxpricesection">
                            <table class="table table-borderless mb-0">
                              <thead>
                                <tr class="f-size-12 fw-500">
                                  <td>Pax</td>
                                  <td>Base</td>
                                  <td>Tax</td>
                                  <td>Per Person</td>
                                </tr>
                              </thead>
                              <tbody class="f-size-12 fw-400">
                                <tr v-if="res.priceDetails.adultPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.adultPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.adultPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.adultPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.adultPriceDetails) }}</td>
                                </tr>
                                <tr v-if="res.priceDetails.childPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.childPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.childPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.childPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.childPriceDetails) }}</td>
                                </tr>
                                <tr v-if="res.priceDetails.infPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.infPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.infPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.infPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.infPriceDetails) }}</td>
                                </tr>
                              </tbody>
                            </table>

                            <table class="table mb-0 d-none">
                              <thead>
                                <tr>
                                  <td colspan="3"  class="f-size-14 fw-500">Passenger(s)</td>
                                </tr>
                              </thead>
                              <tbody class="f-size-14 fw-400">
                                <tr v-if="res.priceDetails.adultPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.adultPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.adultPriceDetails.adultcount }}</td>
                                  <td>{{ getadultTotBase(res.priceDetails) }}</td>
                                  <!-- <td>{{(res.priceDetails.adultPriceDetails.baseprice) * (res.priceDetails.adultPriceDetails.adultcount) }}</td> -->
                                </tr>
                                <tr v-if="res.priceDetails.childPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.childPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.childPriceDetails.childcount }}</td>
                                  <td>{{getchildTotBase(res.priceDetails)}}</td>
                                </tr>
                                <tr v-if="res.priceDetails.infPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.infPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.infPriceDetails.infcount }}</td>
                                  <td>{{getInfTotBase(res.priceDetails)}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="pt-2 border-2 border-top">
                            <div class="d-flex justify-content-between mb-1 f-size-14">
                              <div>
                                <span class="fw-500"> {{ $t("fareDetContent.basePrice") }}</span>
                              </div>
                              <div>
                                <span>{{ res.currency }} {{ res.Baseprice }}</span>
                              </div>
                            </div>
                            <div class="d-flex justify-content-between f-size-14">
                              <div>
                                <span class="fw-500"> {{ $t("fareDetContent.taxesFees") }}</span>
                              </div>
                              <div>
                                <span>{{ res.currency }} {{ res.Taxprice }}</span>
                              </div>
                            </div>
                          </div>
                      </v-card>
                          </v-menu>
                        </div>
                      
                        <div class="col-4 d-lg-none d-sm-none">
                          <div class="d-flex flex-column align-center justify-center mbl-price-section" style="margin-bottom: 10px">

                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount">{{ res.perperson }}</span> -->
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount" style="font-size:21px !important;margin-top:6px;">{{
                              res.find1
                            }}</span>

                            <div class="d-flex price-Decimal d-none">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: -3px;
                                "
                                >.</span
                              ><span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <span
                          class="content"
                          style="
                            font-size: 13px;
                            font-weight: 500;
                            margin-top: -7px;
                          "
                          >{{ $t("fareDetContent.perPerson") }}</span
                        >
                          </div>
                        </div>

                        <div class="col-4 d-flex justify-center align-center airlineLogo-show">
                      <v-btn
                        color="#94C20E"
                        class="selected_btn"
                        width="100%"
                        style="color: white;height:45px !important;"
                        @click.prevent="onewayselect(res, index)"
                        >{{ $t("searchPageContent.itinContent.select") }} </v-btn
                      >
                        </div>
                      </div>
                    </div>
                    <div class="col-4 d-md-flex d-sm-flex flex-column price-col d-none d-lg-none mb-lg-0 mb-2">
                      <div class="d-flex flex-column align-center justify-center mbl-price-section">
                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount">{{ res.perperson }}</span> -->
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount" style="font-size:21px !important;margin-top:12px;">{{
                              res.find1
                            }}</span>
                          </div>
                        </div>
                        <span class="content f-size-13 fw-500" style="margin-top: -7px;">{{ $t("fareDetContent.perPerson") }}</span>
                      </div>
                      
                      <div class="d-md-flex d-sm-flex d-lg-none d-none justify-center align-center">
                        <v-btn
                          color="#94C20E"
                          class="selected_btn"
                          width="100%"
                          style="color: white;height:45px !important;"
                          @click.prevent="onewayselect(res, index)"
                          >{{ $t("searchPageContent.itinContent.select") }} </v-btn>
                    </div>
                  </div>
                  </div>
                  <!-- Seat End................ -->
                </div>

                <div class="col-lg-3 d-none d-lg-flex flex-column align-center justify-center"
                  style="border-left: 1px solid #f0f0f0"
                >
                  <div class="d-flex flex-column align-center justify-center">
                    <div class="price-head d-flex">
                      <span class="price-currency">{{ res.currency }}</span>
                      <!-- <span class="price-Amount">{{ res.perperson }}</span> -->

                      <div id="demo" class="d-flex">
                        <span id="f0" class="price-Amount">{{
                          res.find1
                        }}</span>

                        <!-- <div class="d-flex price-Decimal">
                          <span
                            style="
                              font-size: 20px;
                              position: relative;
                              left: 2px;
                              top: 2px;
                            "
                            >.</span
                          ><span
                            id="f1"
                            style="
                              font-size: 18px;
                              font-weight: 600;
                              margin-bottom: 10px;
                              margin-left: 2px;
                              margin-top: 5px;
                            "
                            >{{ res.find2 }}</span
                          >
                        </div> -->
                      </div>
                    </div>
                    <span
                      style="
                        font-size: 14px;
                        font-weight: 500;
                        margin-top: -10px;
                        margin-bottom: 10px;
                      "
                      >{{ $t("fareDetContent.perPerson") }}</span
                    >
                    <v-btn
                      color="#94C20E"
                      class="selected_btn"
                      width="160px"
                      height="40px"
                      style="color: white"
                      @click.prevent="onewayselect(res, index)"
                      >{{ $t("searchPageContent.itinContent.select") }} <v-icon size="24">mdi-menu-right</v-icon></v-btn
                    >
                  </div>

                  <!-- <div> -->
                  <!-- <p class="tect-center"
                      style="color: #36c;font-size: 14px; font-weight:600; margin-top: 2px;cursor: pointer;">
                      Price Details</p> -->

                  <div class="text-center Fare-overlay">
                    <v-menu location="start">
                      <template v-slot:activator="{ props }">
                        <v-card-text
                          @click="fareOpen = !fareOpen"
                          style="color: #36c; font-weight: 500"
                          class="p-0 pt-1"
                          v-bind="props"
                        >
                          {{ $t("fareDetContent.fareDetails") }}
                        </v-card-text>
                      </template>

                      <v-card width="300px" max-height="500px" class="pa-3 m-auto" style="border: 1px solid gray">
                        <div>
                          <div class="d-flex justify-content-between mb-1">
                            <div>
                              <p class="m-0 f-size-18 fw-500"> {{ $t("fareDetContent.totalPrice") }}</p>
                            </div>
                            <div>
                              <p class="m-0 f-size-18">{{ res.currency }} {{ res.TotalPrice }}</p>
                            </div>
                          </div>

   
                          <div class="d-flex justify-content-between my-1 ">
                            <div>
                              <span class="f-size-14 fw-500">{{ $t("fareDetContent.passengers")}}</span>
                            </div>
  
                            <div class="f-size-14 text-end">
                                <span v-if="res.priceDetails.adultPriceDetails.adultcount > 0">{{ res.priceDetails.adultPriceDetails.adultcount }} x Adt</span>
                                <span v-if="res.priceDetails.childPriceDetails.childcount > 0">, {{ res.priceDetails.childPriceDetails.childcount }} x Chd</span>
                                <span v-if="res.priceDetails.infPriceDetails.infcount > 0">, {{ res.priceDetails.infPriceDetails.infcount }} x Inf</span>
                            </div>
                          </div>

                          <hr data-v-19b8244e="" class="mt-1 mb-2">
                        </div>


                        <div class="comDetailSection my-1">
                          <div class="paxpricesection">
                            <table class="table table-borderless mb-0">
                              <thead>
                                <tr class="f-size-12 fw-500">
                                  <td>Pax</td>
                                  <td>Base</td>
                                  <td>Tax</td>
                                  <td>Per Person</td>
                                </tr>
                              </thead>
                              <tbody class="f-size-12 fw-400">
                                <tr v-if="res.priceDetails.adultPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.adultPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.adultPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.adultPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.adultPriceDetails) }}</td>
                                </tr>
                                <tr v-if="res.priceDetails.childPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.childPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.childPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.childPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.childPriceDetails) }}</td>
                                </tr>
                                <tr v-if="res.priceDetails.infPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.infPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.infPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.infPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.infPriceDetails) }}</td>
                                </tr>
                              </tbody>
                            </table>

                            <table class="table mb-0 d-none">
                              <thead>
                                <tr>
                                  <td colspan="3"  class="f-size-14 fw-500">Passenger(s)</td>
                                </tr>
                              </thead>
                              <tbody class="f-size-14 fw-400">
                                <tr v-if="res.priceDetails.adultPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.adultPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.adultPriceDetails.adultcount }}</td>
                                  <td>{{ getadultTotBase(res.priceDetails) }}</td>
                                  <!-- <td>{{(res.priceDetails.adultPriceDetails.baseprice) * (res.priceDetails.adultPriceDetails.adultcount) }}</td> -->
                                </tr>
                                <tr v-if="res.priceDetails.childPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.childPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.childPriceDetails.childcount }}</td>
                                  <td>{{getchildTotBase(res.priceDetails)}}</td>
                                </tr>
                                <tr v-if="res.priceDetails.infPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.infPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.infPriceDetails.infcount }}</td>
                                  <td>{{getInfTotBase(res.priceDetails)}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="pt-2 border-2 border-top">
                            <div class="d-flex justify-content-between mb-1 f-size-14">
                              <div>
                                <span class="fw-500"> {{ $t("fareDetContent.basePrice") }}</span>
                              </div>
                              <div>
                                <span>{{ res.currency }} {{ res.Baseprice }}</span>
                              </div>
                            </div>
                            <div class="d-flex justify-content-between f-size-14">
                              <div>
                                <span class="fw-500"> {{ $t("fareDetContent.taxesFees") }}</span>
                              </div>
                              <div>
                                <span>{{ res.currency }} {{ res.Taxprice }}</span>
                              </div>
                            </div>
                          </div>
                      </v-card>
                
                    </v-menu>
                  </div>
                  <!-- </div> -->
                </div>
                <div>
                  <div class="modal" v-if="showModal === index">
                    <div class="modal-content">
                      <div
                        class="modal-header d-flex flex-row justify-content-between pt-0"
                      >
                        <h5>{{ $t("searchPageContent.itinContent.flightDetails") }}</h5>
                        <span class="close-button" @click="closeModalOneWay"
                          >&times;</span
                        >
                      </div>

                      <div v-if="res.Flightdepature" class="border">
                        <div>
                          <div
                            class="d-flex p-2 justify-content-between flex-wrap"
                            style="background-color: #3c0ec2; color: white"
                          >
                            <div class="d-flex align-center">
                              <v-icon
                                style="transform: rotate(90deg) !important"
                                >mdi-airplane</v-icon
                              >
                              <span class="f-size-15 fw-500 ps-2"
                                >{{ getAirportCode(res.Depature) }} -
                                {{ getArrivalCode(res.Depature) }}</span
                              >
                            </div>

                            <div class="d-flex align-center justify-end">
                              <div class="d-none">
                                <v-icon color="gray"> mdi-bag-suitcase </v-icon>
                                <v-tooltip activator="parent" location="bottom">
                                  <div
                                    class="d-flex flex-column"
                                    style="
                                      font-size: 14px;
                                      font-weight: 400;
                                      margin-left: 5px;
                                    "
                                  >
                                    <div>
                                      <span> {{ $t("searchPageContent.flightDetails.checkedBag") }}:</span>
                                      <span class="ms-1">
                                        <span class="ms-1">
                                          {{
                                            res.BaggageData.PieceAllowance
                                              .TotalQuantity || 0
                                          }}
                                          {{
                                            res.BaggageData.PieceAllowance
                                              .TotalQuantity > 1
                                              ? "Pieces"
                                              : "Piece"
                                          }}
                                        </span>
                                      </span>
                                    </div>
                                    <div>
                                      <span> {{ $t("searchPageContent.flightDetails.carryOnBag") }}:</span>
                                      <span class="ms-1">
                                        {{
                                          res.BaggageData.PieceAllowance
                                            .TotalQuantity > 0
                                            ? 1 + " Piece"
                                            : 0 + " Piece"
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </v-tooltip>
                              </div>
                              <span class="f-size-15 fw-500 ms-2"
                                >{{ $t("searchPageContent.flightDetails.totaltripDuration") }} :
                                {{ res.Depaturejourney.Time }}</span
                              >
                              <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                            </div>
                          </div>

                          <div class="px-2" style="max-width: 100%">
                            <div
                              v-for="(data, index) of res.Depature"
                              :key="index"
                            >
                              <div class="row my-3">
                                <div class="col-md-3 col-3 mb-3 mb-md-0">
                                  <div class="row ms-sm-1 ms-0 w-100">
                                    <div class="col-md-4 col-12 airline-logo">
                                      <v-img
                                        :src="data.MarketingCarrier.logo"
                                        width="30px"
                                      ></v-img>
                                    </div>
                                    <div
                                      class="col-md-8 col-12 airline-profile"
                                    >
                                      <p class="f-size-13 airline-name m-0">
                                        {{ data.MarketingCarrier.Name }}

                                        <br />
                                        <span class="f-size-12 airline-id">
                                          {{ data.MarketingCarrier.AirlineID }}
                                          -
                                          {{
                                            data.MarketingCarrier.FlightNumber
                                          }}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-7 col-9">
                                  <div class="row d-flex">
                                    <div class="col-sm-3 p-0">
                                      <span
                                        class="f-size-10 fw-500 airline-date"
                                        >{{
                                          getdated(data.Departure.Date)
                                        }}</span
                                      >
                                      <span
                                        class="f-size-13 airline-time"
                                        style="margin-left: 5px"
                                        >{{ getflightdep(data) }}</span
                                      >
                                    </div>
                                    <div
                                      class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                      style="margin-bottom: 4px"
                                    >
                                      <span class="fw-500"
                                        >{{ data.Departure.AirportCode }} </span
                                      >, {{ data.Departure.AirportName }}
                                    </div>
                                  </div>

                                  <div class="row d-flex">
                                    <div class="col-sm-3 p-0">
                                      <span
                                        class="f-size-10 fw-500 airline-date"
                                        >{{
                                          getdated1(data.Arrival.Date)
                                        }}</span
                                      >
                                      <span
                                        class="f-size-13 airline-time"
                                        style="margin-left: 5px"
                                        >{{ getflightarr(data) }}</span
                                      >
                                    </div>

                                    <div
                                      class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                      style="margin-bottom: 4px"
                                    >
                                      <span class="fw-500"
                                        >{{ data.Arrival.AirportCode }} </span
                                      >, {{ data.Arrival.AirportName }}
                                    </div>
                                  </div>
                                </div>

                                <div
                                  class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                                >
                                  <div class="d-flex class-type">
                                    <v-icon size="15px" color="#ccc">
                                      mdi-seat-passenger
                                    </v-icon>

                                    <span
                                      class="f-size-12"
                                      style="padding-left: 5px"
                                    >
                                      {{translateClass(oneway.class)}}
                                    </span>
                                  </div>

                                  <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span class="f-size-12" style="padding-left: 5px">
                                    Meal provided
                                  </span>
                                </div> -->
                                </div>

                                <div class="d-flex position-relative mt-3">
                                  <hr
                                    v-if="res.Depature.length - 1 !== index"
                                    style="
                                      width: 100%;
                                      position: absolute;
                                      margin-top: 8px !important;
                                      margin-bottom: 0px !important;
                                    "
                                  />
                                  <div
                                    class="d-flex justify-center"
                                    v-if="res.Depature.length - 1 !== index"
                                    style="
                                      width: 180px;
                                      background: antiquewhite;
                                      border-radius: 18px;
                                      z-index: 1;
                                      margin: auto;
                                    "
                                  >
                                    <div class="d-flex align-center">
                                      <span class="f-size-10"
                                        >{{ $t("searchPageContent.flightDetails.layover") }} {{ data.layoverTimes }} ({{
                                          data.Arrival.AirportCode
                                        }})</span
                                      >
                                    </div>
                                  </div>
                                </div>

                                <!-- <div class="d-flex justify-content-between align-center">
                              <hr v-if="res.Depature.length - 1 !== index" style="width: 40%" class="mt-0 mb-0" />
                              <div class="d-flex justify-center mx-2 layover-size"
                                v-if="res.Depature.length - 1 !== index" style="width: 20%">
                                <div class="d-flex align-center">
                                  <span class="f-size-11 lay-fsize">
                                    {{ data.layoverTimes }} Layover
                                    Time</span>
                                </div>
                              </div>
                              <hr v-if="res.Depature.length - 1 !== index" style="width: 40%" class="mt-0 mb-0" />
                            </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </div>

          <!-- {{ console.log(multicityData,'multicitysdsdsaddmulticity') }} -->


          <div class="mb-3" v-for="(res, index) of multicityData" :key="index">
            <v-card class="hoveredCard">
              <div class="row gx-0">
                <div class="col-lg-2 d-none flex-column align-center justify-center" 
                  style="border-right: 1px solid #f0f0f0">
                  <div class="airline-logo">
                    <v-img :src="res.ownerlogo" width="70px"></v-img>
                  </div>
                  <div>
                    <p class="res-name text-center">
                      {{ res.ownername }}

                      <br />
                      {{ getid(res.Flight1) }} -
                      {{ getNumber(res.Flight1) }}
                    </p>
                  </div>
                </div>

                <div class="col-lg-2 d-none flex-column align-center justify-center">
                  <div class="row g-0 px-3 align-center">
                    <div class="col-3">
                      <div class="airline-logo">
                        <v-img :src="res.ownerlogo" width="70px"></v-img>
                      </div>

                      <div>
                        <p
                          class="res-name text-center"
                          style="
                            padding-left: 0px !important;
                            text-align: left !important;
                          "
                        >
                          {{ res.ownername }}
                        </p>
                      </div>
                    </div>

                    <div class="col-6">
                      <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                        style="margin-bottom: 10px"
                      >
                        <!-- <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <span class="price-Amount">{{ res.perperson }}</span>
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{res.find1}}</span>

                            <div class="d-flex">
                              <span style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: -3px;
                                ">.</span>
                                <span id="f1" style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                ">{{ res.find2 }}</span>
                            </div>
                          </div>
                        </div> -->

                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount d-none">{{ getPriceperson(res.perperson) }}</span> -->
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>

                            <div class="d-flex price-Decimal">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: 2px;
                                "
                                >.</span
                              >
                              <span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                  margin-top: 5px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>

                        <span
                          class="content"
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            margin-top: -10px;
                            margin-bottom: 10px;
                          "
                          >Per Person</span
                        >
                      </div>
                    </div>

                    <div class="col-3 d-flex align-center justify-center">
                      <v-btn
                        color="#94C20E"
                        class="selected_btn"
                        width="160px"
                        height="40px"
                        style="color: white"
                        @click.prevent="selected(res, index)"
                        >{{ $t("searchPageContent.itinContent.select") }}
                      </v-btn>
                    </div>
                  </div>
                </div>

                <div class="col-lg-9 Timing-section" style="padding: 10px 5px">
                  <!-- ------flight1 start----- -->
                  <div>
                    <div class="d-flex align-center justify-content-between">
                    <div class="d-flex align-center">
                      <span class="me-1"
                        ><v-icon size="20px" color="black" class="m-0">mdi-airplane-takeoff</v-icon></span
                      >
                      <h6 class="m-0">{{ $t("searchPageContent.filterContent.flight") }} 1</h6>
                    </div>
                    <div class="d-flex align-center">
                      <div class="res-name text-center">
                        <span style="font-size: 12px">{{
                          res.Flight1[0].MarketingCarrier.Name
                        }}</span>
                        <br />
                        <span style="font-size: 8px"
                          >{{ res.Flight1[0].MarketingCarrier.AirlineID }} -
                          {{
                            res.Flight1[0].MarketingCarrier.FlightNumber
                          }}</span
                        >
                      </div>
                      <div class="airline-logo ms-2">
                        <v-img
                          :src="getdepLogo(res.Flight1[0].MarketingCarrier)"
                          width="22px"
                        ></v-img>
                        <!-- <v-img :src="res.Depature[0].MarketingCarrier.logo" width="22px"></v-img> -->
                      </div>
                    </div>
                  </div>
                    <div>
                      <v-card
                        class="card-in"
                        style="overflow: unset; padding: 1px"
                      >
                        <div class="row mt-2 g-0 mb-2">
                          <div
                            class="col-md-3 col-2 pe-2 aircode-hide"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-end  mt-md-3 mt-sm-3 mt-lg-0 mt-0"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ codeData[0].from }}
                            </p>
                            <p
                              class="text-end airportName"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportNameFrom(res.Flight1) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 pe-2 aircode-show"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportCode(res.Flight1) }}
                            </p>
                          </div>

                          <div class="col-md-6 col-8">
                            <div class="row g-0 mt-1 justify-center">
                              <div class="col-md-4 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getDate(res.Flight1) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getDateDeparture(res.Flight1) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getTime(res.Flight1) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight1JourneyTime.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">{{ $t("searchPageContent.itinContent.direct") }}</p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                >
                                  <hr
                                    style="width: 87%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span
                                    >{{ res.Flight1JourneyTime.Time }}
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight1JourneyTime.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight1JourneyTime.Stops }} {{ $t("searchPageContent.itinContent.stop") }}
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight1.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight1)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight1)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight1JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight1JourneyTime.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight1JourneyTime.Stops }} {{ $t("searchPageContent.itinContent.stops") }}
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight1.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight1)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight1)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails2(res, index)"
                                    @mouseout="hideDetails2(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Flight1.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName2 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Flight1, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Flight1)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight1JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div class="col-md-3 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getArrivaldate(res.Flight1) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getArrivaldateReturn(res.Flight1) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getArrivalTime(res.Flight1) }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-hide"
                            style="border-left: 1px dotted gray"
                          >
                            <p class="mt-md-3 mt-sm-3 mt-lg-0 mt-0"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              <!-- {{ getArrivalCode(res.Depature) }} -->
                              {{ codeData[0].to }}
                            </p>
                            <p class="airportName"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getretairname(res.Flight1) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-show"
                            style="border-left: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-start"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getArrivalCode(res.Flight1) }}
                            </p>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <!-- ------flight1 end----- -->

                  <!-- ------flight2 start----- -->
                  <div class="mt-2">
                    <div class="d-flex align-center justify-content-between">
                    <div class="d-flex align-center">
                      <span class="me-1"
                        ><v-icon size="20px" color="black" class="m-0">mdi-airplane-takeoff</v-icon></span
                      >
                      <h6 class="m-0">{{ $t("searchPageContent.filterContent.flight") }} 2</h6>
                    </div>
                    <div class="d-flex align-center">
                      <div class="res-name text-center">
                        <span style="font-size: 12px">{{
                          res.Flight2[0].MarketingCarrier.Name
                        }}</span>
                        <br />
                        <span style="font-size: 8px"
                          >{{ res.Flight2[0].MarketingCarrier.AirlineID }} -
                          {{
                            res.Flight2[0].MarketingCarrier.FlightNumber
                          }}</span
                        >
                      </div>
                      <div class="airline-logo ms-2">
                        <v-img
                          :src="getdepLogo(res.Flight2[0].MarketingCarrier)"
                          width="22px"
                        ></v-img>
                        <!-- <v-img :src="res.Depature[0].MarketingCarrier.logo" width="22px"></v-img> -->
                      </div>
                    </div>
                  </div>
                    <div>
                      <v-card
                        class="card-in"
                        style="overflow: unset; padding: 1px"
                      >
                        <div class="row mt-2 g-0 mb-2">
                          <div
                            class="col-md-3 col-2 pe-2 aircode-hide"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-end mt-md-3 mt-sm-3 mt-lg-0 mt-0"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ codeData[1].from }}
                            </p>
                            <p
                              class="text-end airportName"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportNameFrom(res.Flight2) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 pe-2 aircode-show"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportCode(res.Flight2) }}
                            </p>
                          </div>

                          <div class="col-md-6 col-8">
                            <div class="row g-0 mt-1 justify-center">
                              <div class="col-md-4 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getDate(res.Flight2) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getDateDeparture(res.Flight2) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getTime(res.Flight2) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight2JourneyTime.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">{{ $t("searchPageContent.itinContent.direct") }}</p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                >
                                  <hr
                                    style="width: 87%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span
                                    >{{ res.Flight2JourneyTime.Time }}
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight2JourneyTime.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight2JourneyTime.Stops }} {{ $t("searchPageContent.itinContent.stop") }}
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails2(res, index)"
                                    @mouseout="hideDetails2(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight2.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName2 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight2)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight2)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight2JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight2JourneyTime.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight2JourneyTime.Stops }} {{ $t("searchPageContent.itinContent.stops") }}
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails3(res, index)"
                                    @mouseout="hideDetails3(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight2.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName3 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight2)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight2)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails4(res, index)"
                                    @mouseout="hideDetails4(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Flight2.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName4 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Flight2, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Flight2)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight2JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div class="col-md-3 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getArrivaldate(res.Flight2) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getArrivaldateReturn(res.Flight2) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getArrivalTime(res.Flight2) }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-hide"
                            style="border-left: 1px dotted gray"
                          >
                            <p class="mt-md-3 mt-sm-3 mt-lg-0 mt-0"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ codeData[1].to }}
                            </p>
                            <p class="airportName"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getretairname(res.Flight2) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-show"
                            style="border-left: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-start"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getArrivalCode(res.Flight2) }}
                            </p>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <!-- ------flight2 end----- -->

                  <!-- ------flight3 start----- -->
                  <div class="mt-2" v-if="res.Flight3 ? true : false">
                    <div class="d-flex align-center justify-content-between">
                    <div class="d-flex align-center">
                      <span class="me-1"
                        ><v-icon size="20px" color="black" class="m-0">mdi-airplane-takeoff</v-icon></span
                      >
                      <h6 class="m-0">{{ $t("searchPageContent.filterContent.flight") }} 3</h6>
                    </div>
                    <div class="d-flex align-center">
                      <div class="res-name text-center">
                        <span style="font-size: 12px">{{
                          res.Flight3[0].MarketingCarrier.Name
                        }}</span>
                        <br />
                        <span style="font-size: 8px"
                          >{{ res.Flight3[0].MarketingCarrier.AirlineID }} -
                          {{
                            res.Flight3[0].MarketingCarrier.FlightNumber
                          }}</span
                        >
                      </div>
                      <div class="airline-logo ms-2">
                        <v-img
                          :src="getdepLogo(res.Flight3[0].MarketingCarrier)"
                          width="22px"
                        ></v-img>
                        <!-- <v-img :src="res.Depature[0].MarketingCarrier.logo" width="22px"></v-img> -->
                      </div>
                    </div>
                  </div>
                    <div>
                      <v-card
                        class="card-in"
                        style="overflow: unset; padding: 1px"
                      >
                        <div class="row mt-2 g-0 mb-2">
                          <div
                            class="col-md-3 col-2 pe-2 aircode-hide"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-end mt-md-3 mt-sm-3 mt-lg-0 mt-0"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ codeData[2].from }}
                            </p>
                            <p
                              class="text-end airportName"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportNameFrom(res.Flight3) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 pe-2 aircode-show"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportCode(res.Flight3) }}
                            </p>
                          </div>

                          <div class="col-md-6 col-8">
                            <div class="row g-0 mt-1 justify-center">
                              <div class="col-md-4 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getDate(res.Flight3) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getDateDeparture(res.Flight3) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getTime(res.Flight3) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight3JourneyTime.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">{{ $t("searchPageContent.itinContent.direct") }}</p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                >
                                  <hr
                                    style="width: 87%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span
                                    >{{ res.Flight3JourneyTime.Time }}
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight3JourneyTime.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight3JourneyTime.Stops }} {{ $t("searchPageContent.itinContent.stop") }}
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails3(res, index)"
                                    @mouseout="hideDetails3(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight3.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName3 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight3)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight3)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight3JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight3JourneyTime.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight3JourneyTime.Stops }} {{ $t("searchPageContent.itinContent.stops") }}
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight2.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight3)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight3)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails3(res, index)"
                                    @mouseout="hideDetails3(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Flight3.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName3 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Flight3, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Flight3)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight3JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div class="col-md-3 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getArrivaldate(res.Flight3) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getArrivaldateReturn(res.Flight3) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getArrivalTime(res.Flight3) }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-hide"
                            style="border-left: 1px dotted gray"
                          >
                            <p class=" mt-md-3 mt-sm-3 mt-lg-0 mt-0"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ codeData[2].to }}
                            </p>
                            <p class="airportName"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getretairname(res.Flight3) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-show"
                            style="border-left: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-start"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getArrivalCode(res.Flight3) }}
                            </p>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <!-- ------flight3 end----- -->

                  <!-- ------flight4 start----- -->
                  <div class="mt-2" v-if="res.Flight4 ? true : false">
                    <div class="d-flex align-center justify-content-between">
                    <div class="d-flex align-center">
                      <span class="me-1"
                        ><v-icon size="20px" color="black" class="m-0">mdi-airplane-takeoff</v-icon></span
                      >
                      <h6 class="m-0">{{ $t("searchPageContent.filterContent.flight") }} 4</h6>
                    </div>
                    <div class="d-flex align-center">
                      <div class="res-name text-center">
                        <span style="font-size: 12px">{{
                          res.Flight4[0].MarketingCarrier.Name
                        }}</span>
                        <br />
                        <span style="font-size: 8px"
                          >{{ res.Flight4[0].MarketingCarrier.AirlineID }} -
                          {{
                            res.Flight4[0].MarketingCarrier.FlightNumber
                          }}</span
                        >
                      </div>
                      <div class="airline-logo ms-2">
                        <v-img
                          :src="getdepLogo(res.Flight4[0].MarketingCarrier)"
                          width="22px"
                        ></v-img>
                        <!-- <v-img :src="res.Depature[0].MarketingCarrier.logo" width="22px"></v-img> -->
                      </div>
                    </div>
                  </div>
                    <div>
                      <v-card
                        class="card-in"
                        style="overflow: unset; padding: 1px"
                      >
                        <div class="row mt-2 g-0 mb-2">
                          <div
                            class="col-md-3 col-2 pe-2 aircode-hide"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-end mt-md-3 mt-sm-3 mt-lg-0 mt-0"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ codeData[3].from }}
                            </p>
                            <p
                              class="text-end airportName"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportNameFrom(res.Flight4) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 pe-2 aircode-show"
                            style="border-right: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportCode(res.Flight4) }}
                            </p>
                          </div>

                          <div class="col-md-6 col-8">
                            <div class="row g-0 mt-1 justify-center">
                              <div class="col-md-4 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getDate(res.Flight4) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getDateDeparture(res.Flight4) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getTime(res.Flight4) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight4JourneyTime.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">{{ $t("searchPageContent.itinContent.direct") }}</p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                >
                                  <hr
                                    style="width: 87%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span
                                    >{{ res.Flight4JourneyTime.Time }}
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight4JourneyTime.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight4JourneyTime.Stops }} {{ $t("searchPageContent.itinContent.stop") }}
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails4(res, index)"
                                    @mouseout="hideDetails4(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight4.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName4 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight4)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight4)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight4JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight4JourneyTime.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight4JourneyTime.Stops }} {{ $t("searchPageContent.itinContent.stops") }}
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight4.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight4)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight4)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails2(res, index)"
                                    @mouseout="hideDetails2(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Flight4.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName2 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Flight4, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Flight4)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight4JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div class="col-md-3 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getArrivaldate(res.Flight4) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getArrivaldateReturn(res.Flight4) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 400; font-size: 18px"
                                >
                                  {{ getArrivalTime(res.Flight4) }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-hide"
                            style="border-left: 1px dotted gray"
                          >
                            <p class=" mt-md-3 mt-sm-3 mt-lg-0 mt-0"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ codeData[3].to }}
                            </p>
                            <p class="airportName"
                              style="
                                margin-bottom: 4px;
                                font-size: 10px;
                                font-weight: 500;
                              "
                            >
                              {{ getretairname(res.Flight4) }}
                            </p>
                          </div>

                          <div
                            class="col-md-3 col-2 ps-2 aircode-show"
                            style="border-left: 1px dotted gray"
                          >
                            <p
                              class="text-center text-sm-start"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getArrivalCode(res.Flight4) }}
                            </p>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <!-- ------flight4 end----- -->

                  <!-- Seat................ -->
                   <div class="row d-flex justify-content-between">

                    <div class="col-12 col-lg-12 col-sm-6 col-md-6">
                  <div class="row d-flex mt-3 gx-0">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-4 d-flex flex-lg-row flex-column align-center justify-content-center">
                      <span><v-icon size="20">mdi-cash</v-icon></span>
                      <div>
                        <span
                          style="
                            font-size: 12px;
                            color: gray;
                            padding-left: 2px;
                          "
                          >{{ translateRefund(res.Refund) }}</span
                        >
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6 col-4 d-flex flex-lg-row flex-column justify-center align-center">
                      <span><v-icon size="18">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray">{{translateClass(res.cabinClass)}}</span>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6 col-4 d-flex flex-lg-row flex-column align-center justify-center" @click="flighted3(res, index)">
                      <!-- <div class="trigger"></div> -->
                        <span><v-img :src="require('../assets/flightIcon.png')" width="20px" style="margin:auto;"></v-img></span>
                        <span style="color: #36c;font-size: 12px;margin-top: 2px; cursor: pointer;">{{ $t("searchPageContent.itinContent.flightDetails") }} </span>

                        <!-- <v-icon style="color: #36c" size="18">{{res.Flightdepature ? "mdi-close-box" : "mdi-chevron-down-box"}}</v-icon> -->
                      
                 
                    </div>
                    <hr class="d-md-none d-sm-none d-block" style="margin:7px 0px !important"/>
                    <div class="col-md-6 col-sm-6 col-4 d-lg-none justify-center">
                      <v-menu location="bottom">
                        <template v-slot:activator="{ props }">
                          <div class="d-flex flex-column align-center mt-lg-0 mt-1">
                            <v-img :src="require('../assets/fare-icon.png')" width="20px" height="20px"></v-img>
                            <v-card-text
                              @click="fareOpen = !fareOpen"
                              style="color: #36c; font-weight: 500"
                              class="p-0 text-center"
                              v-bind="props"
                            >
                              {{ $t("fareDetContent.fareDetails") }}
                            </v-card-text>
                            <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                          </div>
                        </template>

                        <v-card width="290px" max-height="500px" class="pa-3 m-auto" style="border: 1px solid gray">
                        <div>
                          <div class="d-flex justify-content-between mb-1">
                            <div>
                              <p class="m-0 f-size-18 fw-500"> {{ $t("fareDetContent.totalPrice") }}</p>
                            </div>
                            <div>
                              <p class="m-0 f-size-18">{{ res.currency }} {{ res.TotalPrice }}</p>
                            </div>
                          </div>

   
                          <div class="d-flex justify-content-between my-1 ">
                            <div>
                              <span class="f-size-14 fw-500">{{ $t("fareDetContent.passengers")}}</span>
                            </div>
  
                            <div class="f-size-14 text-end">
                                <span v-if="res.priceDetails.adultPriceDetails.adultcount > 0">{{ res.priceDetails.adultPriceDetails.adultcount }} x Adt</span>
                                <span v-if="res.priceDetails.childPriceDetails.childcount > 0">, {{ res.priceDetails.childPriceDetails.childcount }} x Chd</span>
                                <span v-if="res.priceDetails.infPriceDetails.infcount > 0">, {{ res.priceDetails.infPriceDetails.infcount }} x Inf</span>
                            </div>
                          </div>

                          <hr data-v-19b8244e="" class="mt-1 mb-2">
                        </div>


                        <div class="comDetailSection my-1">
                          <div class="paxpricesection">
                            <table class="table table-borderless mb-0">
                              <thead>
                                <tr class="f-size-12 fw-500">
                                  <td>Pax</td>
                                  <td>Base</td>
                                  <td>Tax</td>
                                  <td>Per Person</td>
                                </tr>
                              </thead>
                              <tbody class="f-size-12 fw-400">
                                <tr v-if="res.priceDetails.adultPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.adultPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.adultPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.adultPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.adultPriceDetails) }}</td>
                                </tr>
                                <tr v-if="res.priceDetails.childPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.childPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.childPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.childPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.childPriceDetails) }}</td>
                                </tr>
                                <tr v-if="res.priceDetails.infPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.infPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.infPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.infPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.infPriceDetails) }}</td>
                                </tr>
                              </tbody>
                            </table>

                            <table class="table mb-0 d-none">
                              <thead>
                                <tr>
                                  <td colspan="3"  class="f-size-14 fw-500">Passenger(s)</td>
                                </tr>
                              </thead>
                              <tbody class="f-size-14 fw-400">
                                <tr v-if="res.priceDetails.adultPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.adultPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.adultPriceDetails.adultcount }}</td>
                                  <td>{{ getadultTotBase(res.priceDetails) }}</td>
                                  <!-- <td>{{(res.priceDetails.adultPriceDetails.baseprice) * (res.priceDetails.adultPriceDetails.adultcount) }}</td> -->
                                </tr>
                                <tr v-if="res.priceDetails.childPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.childPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.childPriceDetails.childcount }}</td>
                                  <td>{{getchildTotBase(res.priceDetails)}}</td>
                                </tr>
                                <tr v-if="res.priceDetails.infPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.infPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.infPriceDetails.infcount }}</td>
                                  <td>{{getInfTotBase(res.priceDetails)}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="pt-2 border-2 border-top">
                            <div class="d-flex justify-content-between mb-1 f-size-14">
                              <div>
                                <span class="fw-500"> {{ $t("fareDetContent.basePrice") }}</span>
                              </div>
                              <div>
                                <span>{{ res.currency }} {{ res.Baseprice }}</span>
                              </div>
                            </div>
                            <div class="d-flex justify-content-between f-size-14">
                              <div>
                                <span class="fw-500"> {{ $t("fareDetContent.taxesFees") }}</span>
                              </div>
                              <div>
                                <span>{{ res.currency }} {{ res.Taxprice }}</span>
                              </div>
                            </div>
                          </div>
                      </v-card>
                      </v-menu>
                    </div>
                     <div class="col-4 d-lg-none d-sm-none">
                      <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                        style="margin-bottom: 10px"
                      >
                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount">{{ res.perperson }}</span> -->
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount" style="font-size:21px !important;margin-top:6px;">{{
                              res.find1
                            }}</span>

                            <div class="d-flex price-Decimal d-none">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: -3px;
                                "
                                >.</span
                              ><span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <span
                          class="content"
                          style="
                            font-size: 13px;
                            font-weight: 500;
                            margin-top: -7px;
                          "
                          >{{ $t("fareDetContent.perPerson") }}</span
                        >
                      </div>
                    </div>
                    <div class="col-4 d-flex justify-center align-center airlineLogo-show">
                      <v-btn
                        color="#94C20E"
                        class="selected_btn"
                        width="100%"
                        style="color: white;height:45px !important;"
                        @click.prevent="selected(res, index)"
                        >{{ $t("searchPageContent.itinContent.select") }} </v-btn
                      >
                    </div>
                  </div>
                  </div>
                 <div class="col-4 d-md-flex d-sm-flex flex-column price-col d-none d-lg-none mb-lg-0 mb-2">
                     <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                      >
                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount">{{ res.perperson }}</span> -->
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount" style="font-size:21px !important;margin-top:12px;">{{
                              res.find1
                            }}</span>

                            <!-- <div class="d-flex price-Decimal d-none">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: -3px;
                                "
                                >.</span
                              ><span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div> -->
                          </div>
                        </div>
                        <span
                          class="content"
                          style="
                            font-size: 13px;
                            font-weight: 500;
                            margin-top: -7px;
                          "
                          >{{ $t("fareDetContent.perPerson") }}</span
                        >
                      </div>
                      <div class="d-md-flex d-sm-flex d-lg-none d-none justify-center align-center">
                      <v-btn
                        color="#94C20E"
                        class="selected_btn"
                        width="100%"
                        style="color: white;height:45px !important;"
                        @click.prevent="selected(res, index)"
                        >{{ $t("searchPageContent.itinContent.select") }} </v-btn
                      >
                    </div>
                  </div>
                  </div>

                  <!-- Seat End................ -->
                </div>

                <div  class="col-lg-3 d-none d-lg-flex flex-column align-center justify-center"
                  style="border-left: 1px solid #f0f0f0">
                  <div
                    class="d-flex flex-column align-center justify-center"
                    style="margin-top: 35px; margin-bottom: 26px"
                  >
                    <div class="price-head d-flex">
                      <span class="price-currency">{{ res.currency }}</span>
                      <!-- <span class="price-Amount d-none">{{ getPriceperson(res.perperson) }}</span> -->
                      <div id="demo" class="d-flex">
                        <span id="f0" class="price-Amount">{{
                          res.find1
                        }}</span>

                        <!-- <div class="d-flex price-Decimal">
                          <span
                            style="
                              font-size: 20px;
                              position: relative;
                              left: 2px;
                              top: 2px;
                            "
                            >.</span
                          >
                          <span
                            id="f1"
                            style="
                              font-size: 18px;
                              font-weight: 600;
                              margin-bottom: 10px;
                              margin-left: 2px;
                              margin-top: 5px;
                            "
                            >{{ res.find2 }}</span
                          >
                        </div> -->
                      </div>
                    </div>
                    <span
                      style="
                        font-size: 14px;
                        font-weight: 500;
                        margin-top: -10px;
                        margin-bottom: 10px;
                      "
                      >{{ $t("fareDetContent.perPerson") }}</span
                    >
                    <v-btn
                      color="#94C20E"
                      class="selected_btn"
                      width="160px"
                      height="40px"
                      style="color: white"
                      @click.prevent="selected(res, index)"
                      >{{ $t("searchPageContent.itinContent.select") }} <v-icon size="24">mdi-menu-right</v-icon></v-btn
                    >
                  </div>

                  <!-- <div> -->
                  <!-- <p class="tect-center"
                      style="color: #36c;font-size: 14px; font-weight:600; margin-top: 2px;cursor: pointer;">
                      Price Details</p> -->

                  <div class="text-center Fare-overlay">
                    <v-menu location="start">
                      <template v-slot:activator="{ props }">
                        <v-card-text
                          @click="fareOpen = !fareOpen"
                          style="color: #36c; font-weight: 500"
                          v-bind="props"
                        >
                          {{ $t("fareDetContent.fareDetails") }}
                        </v-card-text>
                      </template>

                      <v-card width="300px" max-height="500px" class="pa-3 m-auto" style="border: 1px solid gray">
                        <div>
                          <div class="d-flex justify-content-between mb-1">
                            <div>
                              <p class="m-0 f-size-18 fw-500"> {{ $t("fareDetContent.totalPrice") }}</p>
                            </div>
                            <div>
                              <p class="m-0 f-size-18">{{ res.currency }} {{ res.TotalPrice }}</p>
                            </div>
                          </div>

   
                          <div class="d-flex justify-content-between my-1 ">
                            <div>
                              <span class="f-size-14 fw-500">{{ $t("fareDetContent.passengers")}}</span>
                            </div>
  
                            <div class="f-size-14 text-end">
                                <span v-if="res.priceDetails.adultPriceDetails.adultcount > 0">{{ res.priceDetails.adultPriceDetails.adultcount }} x Adt</span>
                                <span v-if="res.priceDetails.childPriceDetails.childcount > 0">, {{ res.priceDetails.childPriceDetails.childcount }} x Chd</span>
                                <span v-if="res.priceDetails.infPriceDetails.infcount > 0">, {{ res.priceDetails.infPriceDetails.infcount }} x Inf</span>
                            </div>
                          </div>

                          <hr data-v-19b8244e="" class="mt-1 mb-2">
                        </div>


                        <div class="comDetailSection my-1">
                          <div class="paxpricesection">
                            <table class="table table-borderless mb-0">
                              <thead>
                                <tr class="f-size-12 fw-500">
                                  <td>Pax</td>
                                  <td>Base</td>
                                  <td>Tax</td>
                                  <td>Per Person</td>
                                </tr>
                              </thead>
                              <tbody class="f-size-12 fw-400">
                                <tr v-if="res.priceDetails.adultPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.adultPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.adultPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.adultPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.adultPriceDetails) }}</td>
                                </tr>
                                <tr v-if="res.priceDetails.childPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.childPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.childPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.childPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.childPriceDetails) }}</td>
                                </tr>
                                <tr v-if="res.priceDetails.infPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.infPriceDetails.pax_type }}</td>
                                  <td>{{ res.priceDetails.infPriceDetails.baseprice }}</td>
                                  <td>{{ res.priceDetails.infPriceDetails.taxprice }}</td>
                                  <td>{{ getperpersonPrice(res.priceDetails.infPriceDetails) }}</td>
                                </tr>
                              </tbody>
                            </table>

                            <table class="table mb-0 d-none">
                              <thead>
                                <tr>
                                  <td colspan="3"  class="f-size-14 fw-500">Passenger(s)</td>
                                </tr>
                              </thead>
                              <tbody class="f-size-14 fw-400">
                                <tr v-if="res.priceDetails.adultPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.adultPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.adultPriceDetails.adultcount }}</td>
                                  <td>{{ getadultTotBase(res.priceDetails) }}</td>
                                  <!-- <td>{{(res.priceDetails.adultPriceDetails.baseprice) * (res.priceDetails.adultPriceDetails.adultcount) }}</td> -->
                                </tr>
                                <tr v-if="res.priceDetails.childPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.childPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.childPriceDetails.childcount }}</td>
                                  <td>{{getchildTotBase(res.priceDetails)}}</td>
                                </tr>
                                <tr v-if="res.priceDetails.infPriceDetails.baseprice">
                                  <td>{{ res.priceDetails.infPriceDetails.pax_type }}</td>
                                  <td>x {{ res.priceDetails.infPriceDetails.infcount }}</td>
                                  <td>{{getInfTotBase(res.priceDetails)}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="pt-2 border-2 border-top">
                            <div class="d-flex justify-content-between mb-1 f-size-14">
                              <div>
                                <span class="fw-500"> {{ $t("fareDetContent.basePrice") }}</span>
                              </div>
                              <div>
                                <span>{{ res.currency }} {{ res.Baseprice }}</span>
                              </div>
                            </div>
                            <div class="d-flex justify-content-between f-size-14">
                              <div>
                                <span class="fw-500"> {{ $t("fareDetContent.taxesFees") }}</span>
                              </div>
                              <div>
                                <span>{{ res.currency }} {{ res.Taxprice }}</span>
                              </div>
                            </div>
                          </div>
                      </v-card>

                    </v-menu>
                  </div>
                  <!-- </div> -->
                </div>
                
                 <div class="modal" v-if="showModal === index">
                    <div class="modal-content" style="top:10%;">
                      <div
                        class="modal-header d-flex flex-row justify-content-between pt-0"
                      >
                      <h5>{{ $t("searchPageContent.itinContent.flightDetails") }}</h5>
                        <span class="close-button" @click="closeModalMulti"
                          >&times;</span
                        >
                    </div>
                    <!-- FLight1 Details   -->
                    <div v-if="res.Flightdepature">
                      <div>
                        <div
                          class="d-flex p-2 justify-content-between flex-wrap"
                          style="background-color: #3c0ec2; color: white"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Flight1) }} -
                              {{ getArrivalCode(res.Flight1) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="f-size-15 fw-500"
                              >{{ $t("searchPageContent.flightDetails.totaltripDuration") }} :
                              {{ res.Flight1JourneyTime.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of res.Flight1"
                            :key="index"
                          >
                            <div class="row my-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span
                                      class="f-size-10 fw-500 airline-date"
                                      >{{ getdated(data.Departure.Date) }}</span
                                    >
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span
                                      class="f-size-10 fw-500 airline-date"
                                      >{{ getdated1(data.Arrival.Date) }}</span
                                    >
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    {{translateClass(res.cabinClass)}}
                                  </span>
                                </div>

                                <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span class="f-size-12" style="padding-left: 5px">
                                    Meal provided
                                  </span>
                                </div> -->
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Flight1.length - 1 !== index"
                                  style="
                                    width: 100%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Flight1.length - 1 !== index"
                                  style="
                                    width: 180px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >{{ $t("searchPageContent.flightDetails.layover") }} {{ data.layoverTimes }} ({{
                                        data.Arrival.AirportCode
                                      }})
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- FLight1 Details End  -->

                    <!-- FLight2 Details   -->
                    <div v-if="res.Flightdepature">
                      <div>
                        <div
                          class="d-flex p-2 justify-content-between flex-wrap"
                          style="background-color: #3c0ec2; color: white"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Flight2) }} -
                              {{ getArrivalCode(res.Flight2) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="f-size-15 fw-500"
                              >{{ $t("searchPageContent.flightDetails.totaltripDuration") }} :
                              {{ res.Flight2JourneyTime.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of res.Flight2"
                            :key="index"
                          >
                            <div class="row my-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span
                                      class="f-size-10 fw-500 airline-date"
                                      >{{ getdated(data.Departure.Date) }}</span
                                    >
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span
                                      class="f-size-10 fw-500 airline-date"
                                      >{{ getdated1(data.Arrival.Date) }}</span
                                    >
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                  {{translateClass(res.cabinClass)}}
                                  </span>
                                </div>
                                <!-- 
                                <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span class="f-size-12" style="padding-left: 5px">
                                    Meal provided
                                  </span>
                                </div> -->
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Flight2.length - 1 !== index"
                                  style="
                                    width: 100%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Flight2.length - 1 !== index"
                                  style="
                                    width: 180px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >{{ $t("searchPageContent.flightDetails.layover") }} {{ data.layoverTimes }} ({{
                                        data.Arrival.AirportCode
                                      }})
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- FLight2 Details End  -->

                    <!-- FLight3 Details   -->
                    <div v-if="res.Flightdepature">
                      <div v-if="res.Flight3 ? true : false">
                        <div
                          class="d-flex p-2 justify-content-between flex-wrap"
                          style="background-color: #3c0ec2; color: white"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Flight3) }} -
                              {{ getArrivalCode(res.Flight3) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="f-size-15 fw-500"
                              >{{ $t("searchPageContent.flightDetails.totaltripDuration") }} :
                              {{ res.Flight3JourneyTime.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of res.Flight3"
                            :key="index"
                          >
                            <div class="row my-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span
                                      class="f-size-10 fw-500 airline-date"
                                      >{{ getdated(data.Departure.Date) }}</span
                                    >
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span
                                      class="f-size-10 fw-500 airline-date"
                                      >{{ getdated1(data.Arrival.Date) }}</span
                                    >
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    {{translateClass(res.cabinClass)}}
                                  </span>
                                </div>

                                <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span class="f-size-12" style="padding-left: 5px">
                                    Meal provided
                                  </span>
                                </div> -->
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Flight3.length - 1 !== index"
                                  style="
                                    width: 100%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Flight3.length - 1 !== index"
                                  style="
                                    width: 180px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >{{ $t("searchPageContent.flightDetails.layover") }} {{ data.layoverTimes }} ({{
                                        data.Arrival.AirportCode
                                      }})
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- FLight3 Details End  -->

                    <!-- FLight4 Details   -->
                    <div v-if="res.Flightdepature">
                      <div v-if="res.Flight4 ? true : false">
                        <div
                          class="d-flex p-2 justify-content-between flex-wrap"
                          style="background-color: #3c0ec2; color: white"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Flight4) }} -
                              {{ getArrivalCode(res.Flight4) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="f-size-15 fw-500"
                              >{{ $t("searchPageContent.flightDetails.totaltripDuration") }} :
                              {{ res.Flight4JourneyTime.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of res.Flight4"
                            :key="index"
                          >
                            <div class="row my-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span
                                      class="f-size-10 fw-500 airline-date"
                                      >{{ getdated(data.Departure.Date) }}</span
                                    >
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span
                                      class="f-size-10 fw-500 airline-date"
                                      >{{ getdated1(data.Arrival.Date) }}</span
                                    >
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    {{translateClass(res.cabinClass)}}
                                  </span>
                                </div>

                                <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span class="f-size-12" style="padding-left: 5px">
                                    Meal provided
                                  </span>
                                </div> -->
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Flight4.length - 1 !== index"
                                  style="
                                    width: 100%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Flight4.length - 1 !== index"
                                  style="
                                    width: 180px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >{{ $t("searchPageContent.flightDetails.layover") }} {{ data.layoverTimes }} ({{
                                        data.Arrival.AirportCode
                                      }})
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- FLight4 Details End  -->
                  </div>
                </div>

              </div>
            </v-card>
          </div>

          
          <div v-if="disableData">
            <v-card class="p-3">
              <div class="d-flex flex-row">
                <span><v-img src="../assets/no-flight.png" width="45px" height="30px"></v-img></span>
                <p class="m-0">{{$t("searchPageContent.filterContent.filterText")}}</p>
              </div>
            </v-card>
          </div>
        </div>
      </div>

      <div v-if="pageError">
        <pageError :dateError ="dateError"></pageError>
      </div>


    </div>
  </div>

  <div v-if="searchloader">
    <loader :searchCase="searchCase"></loader>
  </div>
  <!-- <div v-if="pageError">
    <pageError></pageError>
  </div> -->
  <v-dialog v-model="datePop" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h6 class="text-center">{{ dateError }}</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <!-- <v-btn @click="dialogActive" color="#94c20e">{{$t('errorContent.buttonText.tryAgain')}}</v-btn> -->

          <v-btn to="/" color="#94c20e">Change Your Search</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import loader from "@/components/loaderComponent.vue";
// import onewayService from '@/service/onewayService'
import multicityService from "@/service/multicityService";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
// import AutoComplete from "primevue/autocomplete";
import axios from "axios";
const totalPricefilter = require("lodash");
import pageError from "@/components/pageError.vue";
// import multiResponse from '@/multiResponse.json';
import searchform from "@/components/searchForm.vue";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  components: {
    searchform,
    VueDatePicker,
    // AutoComplete,
    loader,
    pageError,
  },
  emits :{
    localeChanged: null,
    },

  data() {
    return {
      // loderstatus: false,
      showModal: null,
      dateError:"",
      datePop:false,
      openmodal: false,
      editing: false,
      cityNameInput: "",
      scrollTop: 0,
      headerStyles: ["headerCom", "headerCom1", "headerCom2", "headerCom3"],
      footerStyles: ["footerCom", "footerCom1", "footerCom2", "footerCom3"],
      strResult: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      selectedHeader: "headerCom1",
      selectedFooter: "footerCom",
      resultDataPass: 1,
      drawerVisible: false,
      pageError: false,
      dialog: false,
      fareOpen: false,
      multiCalender: true,
      sixWeekMode: true,
      totaladult: 0,
      classSelect: false,
      triggerRadio: false,
      vj: "",
      modifySection: false,
      FlightDetail: false,
      FlightDetail1: false,
      dateFormat: " dd MMM, yyyy",
      detail: {
        from: "",
        to: "",
        dedate: "",
        redate: "",
        class: "Economy",
        child: "",
        adult: "",
        infant: "",
        tripType: "",
      },
      fromloader:false,
      toloader:false,
      airlineList: [],
      items: [],
      airlogodata: [],
      outBoundTime:[],
      inBoundTime:[],
      outBoundTimeMulti:[],
      defaultOutBoundMulti:null,
      defaultOutBoundTime:null,
      defaultInBoundTime:null,
      outBoundMulti:null,
      outBound:null,
      inBound:null,
      fromAirportName:[],
      toAirportName:[],
      multiAirport:[],
      isReturn:false,
      oneway1: false,
      roundtrip: false,
      multitrip: false,
      multicityTotalData: [],
      searchresult: false,
      searchloader: true,
      isIcon1: true,
      fareIcon: true,
      isIcon: true,
      date1: "",
      date2: "",
      value: "1",
      class: "Business",
      travels: false,
      activetrip: 'Roundtrip',
      
      searchCase:null,

      typedrop: false,
      typedrop1: false,
      city: [],
      city1: [],

      multidrop: [],
      multidrop1: [],
      // paxhide: true,

      flights: [], // Your flight data array
      selectedFlight: null,
      roundModify: true,
      onewayModify: false,
      multiModify: false,

      find1: [],
      find2: [],

      offerData1: [],
      offerData2: [],
      offerData3: [],
      offerData4: [],
      offerData5: [],

      returnData: [],
      rount:false,
      Oneway: false,
      roundTrip: true,
      multicity: false,
      airData: [],
      flight: [],
      flight1: [],
      flightTime: [],
      flightseg: [],
      segkey: [],
      SegmentKey: [],
      arriv: [],
      dep: [],
      splitValues: [],
      splitValues1: [],
      seats: [],
      seg: [],
      uniqueId: [],
      Journey: [],
      Journey1: [],
      flightsegmet: [],
      flightsegmetkey: [],
      datas: [],
      journeyData: [],
      changevalue: [],
      initialChangeValue:[],
      steps: null,
      value1: [0, 100],
      value2: [0, 24],
      datasource: [],
      air: [],
      codeData: [],
      airnam: [],
      airnumber: [],
      airflight: [],
      flightprice: [],
      price: [],
      flightdate: [],
      time: [],
      arrivaltime: [],
      Renuvable: [],
      vasan: [],
      vasan1: [],
      totalprice: [],
      segmentref: [],
      stop: [],
      time1: [],
      multicityData: [],
      multicityData1: [],
      aircode: [],
      depcode: [],
      airname: [],
      depname: [],
      airtime: [],
      deptime: [],
      airid: [],
      airnum: [],
      airstop: [],
      offerpay: [],
      airstime: [],
      airfname: [],
      airdate: [],
      depdate: [],
      airref: [],
      airprice: [],
      sortingPrice: [],
      depnamedate: [],
      localData: [],
      rountresult: [],
      filterData: [],
      onewayresulted: [],
      onewayfilterData: [],
      multiFilterData: [],
      routtime: [],
      airportname: "",
      traveltime: "",
      Adult: [],
      Child: [],
      Infrant: [],
      maximum: null,
      minimum: null,
      countries: [{ name: "Australia", code: "AU" }],
      selectedCountry: "",
      loading: false,
      fromAirports: [],
      airitems: [],

      adultValue: 1,
      childValue: 0,
      infantValue: 0,
      addition: 0,
      classType: "Economy",

      classTypeitems: this.getClassType(),

      oneway: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "Economy",
        adult: "",
        child: "",
        infant: "",
        tripType: "",
      },

      tripData: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "",
        child: "",
        adult: "",
        infant: "",
        tripType: "",
      },

      data_id: "",
      roundOfferId: [],

      oneway_id: "",
      onewayOfferId: [],

      responseData: [],
      clicked1: false,
      clicked2: false,
      clicked3: false,
      datePickerIsOpen: false,

      fromErrorMessage: false,
      toErrorMessage: false,
      fromDateError: false,
      toDateError: false,

      disButton: false,
      loadSearch: false,

      hoveredName: false,
      hoverDetail: "Hello....",

      storefilterData: [],
      filteredData:[],

      showNonStop: true,
      showOneStop: true,
      showOneplusStop: true,

      NonStopFil: false,
      OneStopFil: false,
      OnePlusFil: false,
      hasNonStop: false,
      hasOneStop: false,
      hasOnePlusStop: false,
      hasRefundable: false,
      hasNonRefundable: false,
      RefundableData: false,
      nonRefundableData: false,
      selectedFromAirports:false,
      selectedToAirports:false,
      multiFromAirports:false,
      multiToAirports:false,
      onedatafil:false,
      rounddatafil:false,
      multifil:false,
      timeFilterData1: false,
      timeFilterData2: false,
      timeFilterData3: false,
      timeFilterData4: false,
      timeFilterArrData1: false,
      timeFilterArrData2: false,
      timeFilterArrData3: false,
      timeFilterArrData4: false,
      timearrFilterData1:false,
      timearrFilterData2:false,
      timearrFilterData3:false,
      timearrFilterData4:false,
      timearrFilterArrData1:false,
      timearrFilterArrData2:false,
      timearrFilterArrData3:false,
      timearrFilterArrData4:false,
      morningRange: [6, 12], 
      afternoonRange: [12, 18],
      eveningRange: [18, 24],
      beforeMorningRange: [0,6],
      hasMorningFlights: false,
      hasAfternoonFlights: false,
      hasEveningFlights: false,
      hasMorningFlights1: false,
      hasAfternoonFlights1: false,
      hasEveningFlights1: false,
       hasMorningReturn: false,
      hasAfternoonReturn: false,
      hasEveningReturn: false,
      hasMorningReturn1: false,
      hasAfternoonReturn1: false,
      hasEveningReturn1: false,
      nonStop: [],
      oneStop: [],
      onePlusStop: [],
      disableData:false,
      timeMulti1:false,
      timeMulti2:false,
      timeMulti3:false,
      timeMulti4:false,
      timeMultiArr1:false,
      timeMultiArr2:false,
      timeMultiArr3:false,
      timeMultiArr4:false,

      filNonstop: [],
      filOneStop: [],
      filOnePlusStop: [],
      concatStops: [],
      totalpassenger: [],

      changedLang:"",

      // layoverTimes: [],
      // layoverTimes1: [],
      // input: [
      //   {
      //     from: null,
      //     to: null,
      //     depdate: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //     clicked3:false,
      //   },
      //   {
      //     from: null,
      //     to: null,
      //     depdate: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //     clicked3:false,
      //   },
      // ],

      input: [
        {
          from: null,
          to: null,
          depdate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter: false,
          maximumletter1: false,
          clicked3: false,
        },
        {
          from: null,
          to: null,
          depdate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter1: false,
          clicked3: false,
        },
      ],
      Flight2: [],
      Flight3: [],
      Flight4: [],
      Flight5: [],
      resulted: [],

      preferCurrency: "",
      metaCurrency: "",
      pointofSale:"",

      onewaydata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "",
                      Date: "",
                    },
                    Arrival: {
                      AirportCode: "",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "",
                    PTC: "",
                  },
                ],
              },
            },
            Preference: {
              TripType: "",
              CabinType: "",
              Currency: "",
              FareType: [],
            },
            MetaData: {
              Currency: "",
            },
          },
        },
        country: "",
        token: "Tc7RQaX79Hrx5CFK",
      },

      Rountdata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "",
                      Date: "",
                    },
                    Arrival: {
                      AirportCode: "",
                    },
                  },
                  {
                    Departure: {
                      AirportCode: "",
                      Date: "",
                    },
                    Arrival: {
                      AirportCode: "",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "",
                    PTC: "",
                  },
                ],
              },
            },
            Preference: {
              TripType: "",
              CabinType: "",
              Currency: "",
              FareType: [],
              CalendarSearch: "",
              CalendarSearchDays: "",
              IncludedAirlines: [],
              ExcludedAirlines: [],
              IncludedViaStops: [],
              ExcludedViaStops: [],
              Stops: [],
              AvoidCountry: [],
              LayoverHours: [],
              Penalities: [],
            },
            MetaData: {
              Currency: "",
            },
          },
        },
        country: "",
        token: "Tc7RQaX79Hrx5CFK",
      },

      MulticityReq: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [],
              },
            },
            Preference: {
              TripType: "",
              CabinType: "",
              Currency: "",
              FareType: [],
              CalendarSearch: "",
              CalendarSearchDays: "",
              IncludedAirlines: [],
              ExcludedAirlines: [],
              IncludedViaStops: [],
              ExcludedViaStops: [],
              Stops: [],
              AvoidCountry: [],
              LayoverHours: [],
              Penalities: [],
            },
            MetaData: {
              Currency: "",
            },
          },
        },
        country: "",
        token: "Tc7RQaX79Hrx5CFK",
      },

      formMultiData: [],

      multi_city: {
        itinery: [
          {
            from: null,
            to: null,
            depdate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocomfrom:false,
            autocomTo:false,
            selectedFrom:false,
            selectedTo:false,
          },
          {
            from: null,
            to: null,
            depdate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocomfrom:false,
            autocomTo:false,
            selectedFrom:false,
            selectedTo:false,
          },
        ],
        class: "",
        child: "",
        adult: "",
        infant: "",
        tripType: "",
      },

      showMulti_data: {},
      addmore: true,
      clearall: false,
      deleteSeg: true,

      makeSearchUrl: "",
      airLogoApi: [],
      readMore: false,
      airline_logoData: [],
      maxItemsToShow: 5,
      airlineIcaoUrl: "",
      airlineIcaoType: "",
       FilterTimeDepname: "",
      FilterTimearrname: "",
      autocompleteApi:"",
      selectOnewayFrom:false,
      selectOnewayTo:false,
    };
  },

  methods: {

    dialogActive(){
      setTimeout(() => {
        location.reload();
      }, 500);
    },

    clickoutDropdown(){
      this.typedrop = false;
      this.typedrop1 = false;
      this.oneway.from = null;
      this.oneway.to = null;
    },

    clickoutmultiDropdown(){
      this.multi_city.itinery.map((v) => {
          if (v.autocomfrom) {
            v.from = null;
            v.autocomfrom = false;
          }

         if(v.autocomTo){
            v.to = null;
            v.autocomTo = false;
          }
      });
    },

    multiFrom(index){
      // const regex = /[-()]/;
       this.multi_city.itinery.map((v, i) => {
        if(i==index){
          v.from = null
          v.selectedFrom = false
        }
        // else{
        //   if(regex.test(v.from)){
        //     v.selectedFrom = true
        //   }
        // }

        // if(i==index){
        //     v.from = ""
        //     v.selectedFrom = false
        //   }
        //   else if(regex.test(v.from)){
        //     v.selectedFrom = true
        // }
       })
    },
    multiTo(index){
      // const regex = /[-()]/;
       this.multi_city.itinery.map((v, i) => {
        if(i==index){
          v.to = null
          v.selectedTo = false
        }
        // else{
        //   if(regex.test(v.from)){
        //     v.selectedTo = true
        //   }
        // }

       })
    },

      translateRefund(key) {
      // console.log(key,'keykeykeykeykeykeykey')
      if(key == "Refundable"){
        return this.$t("searchPageContent.itinContent.refund");
      }
      else{
        return this.$t("searchPageContent.itinContent.nonRefund");
      }
    },
    translateClass(classKey) {
      // console.log(classKey,'classKeyclassKeyclassKey')
      if(classKey == "Economy"){
        return this.$t("formsContents.options.economy");
      }
      else if(classKey == "Business"){
        return this.$t("formsContents.options.business");
      }
      else if(classKey == "First Class"){
        return this.$t("formsContents.options.firstClass");
      }
      else if(classKey == "Premium Economy"){
        return this.$t("formsContents.options.premiumEconomy");
      }
      else if(classKey == "Premium Business"){
        return "Premium Business";
      }
      else if(classKey == "Premium First"){
        return "Premium First";
      }
    },

    selectClasstype(item) {
      this.classType = item.value;
    },

    getClassType(){
      return [
          {
            text: this.$t("formsContents.options.economy"),
            value: "Economy",
            department: "Economy Department",
          },
          {
            text: this.$t("formsContents.options.business"),
            value: "Business",
            department: "Business Department",
          },
          {
            text: this.$t("formsContents.options.firstClass"),
            value: "First Class",
            department: "First Class Department",
          },
          { 
            text: this.$t('formsContents.options.premiumEconomy'), 
            value: 'Premium Economy', 
            department: 'Premium Economy Department' 
          },
          { 
            text: "Premium Business", 
            value: 'Premium Business', 
            department: 'Premium Business Department' 
          },
          { 
            text: "Premium First Class", 
            value: 'Premium First Class', 
            department: 'Premium First Department' 
          },
        ]
    },
    // openModal(flight) {
    //     this.selectedFlight = flight;
    //     $('#flightDetailsModal').modal('show'); // Show the modal
    // },

    getRandomChars(length) {
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * this.strResult.length);
        result += this.strResult[randomIndex];
      }
      // console.log(result, "result");
      return result;
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    generateBookingRefId() {
      const randomChars1 = this.getRandomChars(8);
      const randomNum = this.getRandomInt(10, 99);
      const randomChars2 = this.getRandomChars(8);

      this.uniqueId = randomChars1 + randomNum + randomChars2;
      // console.log(
      //   this.uniqueId,
      //   "bookingRefIdbookingRefIdbookingRefId"
      // );
    },

    
     showMore() {
      this.readMore = !this.readMore;

      // let $data = []
      console.log(this.filterAirline, "this.filterAirline ");

      if (this.readMore) {
        this.maxItemsToShow = this.airlineList.length;
      } else {
        this.maxItemsToShow = 5;
      }
    },
    airlineFilter() {
      let storeAirline = new Set();
      let $event = [];

      if (this.filterData.length > 0) {
        $event = this.filterData;
      }

      if (this.onewayfilterData.length > 0) {
        $event = this.onewayfilterData;
      }

      if (this.multiFilterData.length > 0) {
        $event = this.multiFilterData;
      }

      if (this.rountresult) {
        this.rountresult.forEach((data) => {
          data.Depature.forEach((flight) => {
            if (flight.MarketingCarrier.Name) {
              storeAirline.add(flight.MarketingCarrier.Name);
            }
          });
          data.Return.forEach((flight) => {
            if (flight.MarketingCarrier.Name) {
              storeAirline.add(flight.MarketingCarrier.Name);
            }
          });
        });
      }
      if(this.onewayresulted){
        this.onewayresulted.forEach((data) => {
          data.Depature.forEach((flight) => {
            if (flight.MarketingCarrier.Name) {
              storeAirline.add(flight.MarketingCarrier.Name);
            }
          });
        })
      }
      if(this.multicityData){
        this.multicityData.forEach((data) => {
          data.Flight1.forEach((flight) => {
            storeAirline.add(flight.MarketingCarrier.Name)
          })
          data.Flight2.forEach((flight) => {
            storeAirline.add(flight.MarketingCarrier.Name)
          })
          if(data.Flight3){
            data.Flight3.forEach((flight) => {
            storeAirline.add(flight.MarketingCarrier.Name)
          })
          }
          else if(data.Flight4){
            data.Flight4.forEach((flight) => {
            storeAirline.add(flight.MarketingCarrier.Name)
          })
          }
        })
      }

      $event.forEach((v) => {
        if (v.ownername && !storeAirline.has(v.ownername)) {
          storeAirline.add(v.ownername);
        }
      });

     this.airlineList = Array.from(storeAirline)
    .sort()  
    .map(s => ({ airline: s, active: true }));

    //console.log(this.airlineList,'airlineList')

      this.filterAirline = this.airlineList;
  },
    selectAirline(data, index) {
      let $data = []
      let $result = [];
       if (this.filterData.length > 0) {
        $data = this.filterData;
      }

      if (this.onewayfilterData.length > 0) {
        $data = this.onewayfilterData;
      }
      if(this.multiFilterData.length > 0){
        $data = this.multiFilterData;
      }
      this.airlineList.map((a, i) => {
        if (i == index) {
          a.active = !a.active;
        }
        if (a.active) {
          $data.forEach((c) => {
            if (c.ownername == a.airline) {
              $result.push(c);
            }
          });
        }

        $result.sort((a, b) => a.perperson - b.perperson);
        if (this.filterData.length > 0) {
          this.rountresult = $result;
        }

        else if (this.onewayfilterData.length > 0) {
          this.onewayresulted = $result;
        }
        else if(this.multiFilterData.length > 0){
        this.multicityData = $result;
      }
      });
      this.applyFilters()

      //  this.Cheapest()
      //   this.Shortest()
      // this.bestlayTime()
      //  this.Recommend()
      // $result.sort((a, b) => a.perperson - b.perperson);
      // this.rountresult = $result;
    },
     airlineClear() {
      let $result = [];
      let $data = [];

      if (this.filterData.length > 0) {
        $data = this.filterData;
      }

      if (this.onewayfilterData.length > 0) {
        $data = this.onewayfilterData;
      }
      if(this.multicityData.length > 0){
        $data = this.multiFilterData;
      }

      this.airlineList.forEach((v, i) => {
        if (i !== 0) {
          v.active = false;
        } else {
          v.active = true;
          $data.forEach((c) => {
            if (c.ownername == v.airline) {
              $result.push(c);
            }
          });
        }
      });

      $result.sort((a, b) => a.perperson - b.perperson);
      if (this.filterData.length > 0) {
          this.rountresult = $result;
        }

        else if (this.onewayfilterData.length > 0) {
          this.onewayresulted = $result;
        }
        else if(this.multiFilterData.length > 0){
        this.multicityData = $result;
      }
      this.applyFilters();
    },

     airlineSelect() {
      this.airlineList.forEach((v) => {
        v.active = true;
      });
      if(this.rountresult.length > 0){
        this.rountresult = this.filterData;
      }
      else if(this.onewayresulted.length > 0){
        this.onewayresulted = this.onewayfilterData
      }
      else if(this.multicityData.length > 0){
        this.multicityData = this.multiFilterData
      }
      this.applyFilters();
    },
    showDetails1(res, index) {
      if (this.rountresult) {
        this.rountresult.map((v, i) => {
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(i, "oooooooooooppppppppppppppppppp");
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }

      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
    },

    hideDetails1(res) {
      res.hoveredName1 = false;

      // console.log(res, "oooooooooooooooo");
    },

    showDetails2(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.hoveredName2 = true;
        } else {
          v.hoveredName2 = false;
        }
      });
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(i, "oooooooooooppppppppppppppppppp");
          if (i == index) {
            v.hoveredName2 = true;
          } else {
            v.hoveredName2 = false;
          }
        });
      }
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName2 = true;
          } else {
            v.hoveredName2 = false;
          }
        });
      }
    },
    hideDetails2(res) {
      res.hoveredName2 = false;
    },

    showDetails3(res, index) {
      this.rountresult.map((v, i) => {
        // console.log(v, "ppppp");
        if (i == index) {
          v.hoveredName3 = true;
        } else {
          v.hoveredName3 = false;
        }
      });
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName3 = true;
          } else {
            v.hoveredName3 = false;
          }
        });
      }
    },
    hideDetails3(res) {
      res.hoveredName3 = false;

      // console.log(res, "oooooooooooooooo");
    },

    showDetails4(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.hoveredName4 = true;
        } else {
          v.hoveredName4 = false;
        }
      });
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName4 = true;
          } else {
            v.hoveredName4 = false;
          }
        });
      }
    },
    hideDetails4(res) {
      res.hoveredName4 = false;
    },

    activeType(data) {
      this.activetrip = data;
      // this.oneway.tripType = this.activetrip;
      console.log(this.activetrip, "pppppppppppppppppppppppppp");

      if (this.activetrip == 'Roundtrip') {
        this.Oneway = false;
        this.roundTrip = true;
        this.multicity = false;
      }
      if (this.activetrip == 'Oneway') {
        this.Oneway = true;
        (this.roundTrip = false), (this.multicity = false);
        this.oneway.redate = null
      }
      if (this.activetrip == 'Multicity') {
        this.Oneway = false;
        this.roundTrip = false;
        this.multicity = true;
        // this.paxhide = false
      }
    },

    passengerData() {
      this.resultDataPass = this.adultValue + this.childValue + this.infantValue;
    },
    deg1() {
      // this.adulted1();
      if (this.adultValue == this.infantValue && this.infantValue > 1) {
        this.deg3();
      }
      if (this.adultValue > 1) {
        this.adultValue = this.adultValue - 1;

        this.added();
      }
    },
    add1() {
      console.log(this.adultValue, "1111");
      if (this.adultValue < 9 && this.addition < 9) {
        this.adultValue = this.adultValue + 1;
        console.log(this.adultValue, "2222");
        // this.adulted();
        this.added();
      }
    },

    deg2() {
      if (this.childValue > 0) {
        this.childValue = this.childValue - 1;

        // this.Childed1();
        this.added();
      }
      // console.log( this.Child,'concatenatedArray')
    },
    add2() {
      if (this.childValue < 8 && this.addition < 9) {
        this.childValue = this.childValue + 1;

        // console.log(this.childValue, "2222");

        // this.Childed();
        this.added();
      }
      // console.log( this.Child,'concatenatedArray')
    },
    deg3() {
      if (this.infantValue > 0) {
        this.infantValue = this.infantValue - 1;

        this.Infranted1();
        this.added();
      }
    },
    add3() {
      if (this.addition < 9 && this.adultValue > this.infantValue) {
        this.infantValue = this.infantValue + 1;

        // console.log(this.infantValue, "3333");

        this.Infranted();
        this.added();
      }
    },
    Infranted() {
      this.Infrant.push({
        PassengerID: "T",
        PTC: "INF",
      });
      this.passengerData();
    },
    Infranted1() {
      this.Infrant.pop();
    },
    added() {
      this.addition = this.adultValue + this.childValue + this.infantValue;
      this.concatenatedArray = this.Adult.concat(this.Child).concat(
        this.Infrant
      );
      this.passengerData();
      // console.log(this.concatenatedArray, "concatenatedArray");
    },

    localdone() {
      localStorage.setItem("value1", "this.adultValue");
    },

    selectFunction() {
      this.classSelect = !this.classSelect;
      this.$refs.mySelect.focus();
      // console.log(this.classSelect, "ffffaaaallllssseeee");
      if (!this.classSelect) {
        this.$refs.countrySelect.focus();
      }
    },

    closeDropdown() {
      setTimeout(() => {
        this.$refs.mySelect.blur();
        if (this.classType) {
          this.$refs.countrySelect.blur();
          console.log("working:");
        }
      }, 50);
    },

    multiSend() {
      if (this.multi_city.itinery.length < 4) {
        this.multi_city.itinery.push({
          from: null,
          to: null,
          depdate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        });

        if (this.multi_city.itinery.length == 4) {
          console.log(
            this.multi_city,
            this.multi_city.itinery.length,
            "lengthlen.....gthlength"
          );

          this.addmore = false;
          this.clearall = true;
        }
        // else{
        //   this.clearall = false;
        // }
      }

      this.multi_city.itinery.map((v, i) => {
        if (this.multi_city.itinery.length - 2 == i) {
          this.multi_city.itinery[i + 1].from = this.multi_city.itinery[i].to;
        }
      });

      if (this.multi_city.itinery.length > 2) {
        this.deleteSeg = true;
        this.multi_city.itinery.map((v) => {
          if(v.from){
            v.selectedFrom = true;
          }
          else{
            v.selectedFrom = false;
          }
        });
      }

      // for (let i = 1; i < this.multi_city.itinery.length; i++) {
      //   this.multi_city.itinery[i].from = this.multi_city.itinery[i - 1].to;
      // }
    },

    deleteSegment() {
      if (this.multi_city.itinery.length == 3) {
        this.multi_city.itinery.splice(-1);
        this.deleteSeg = false;
        this.addmore = true;
      } else if (this.multi_city.itinery.length > 2) {
        console.log(this.multi_city.itinery.length, "lengthlengthl,..111..");
        this.multi_city.itinery.splice(-1);
        this.deleteSeg = true;
        this.addmore = true;
      } else {
        this.deleteSeg = false;
      }
    },

    clearall1() {
      this.multi_city.itinery.splice(2, 5);

      (this.multi_city.itinery = [
        {
          from: null,
          to: null,
          depdate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
        {
          from: null,
          to: null,
          depdate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
      ]),
      this.clearall = false;
      this.addmore = true;
    },


    getadultTotBase(data){
     if (data.adultPriceDetails) {
       let getAdtbase = parseFloat(data.adultPriceDetails.adultcount) * parseFloat(data.adultPriceDetails.baseprice);
       console.log(getAdtbase, 'getadultTotBasegetadultTotBase...2');
       return getAdtbase.toFixed(2);
     }
   },

     getchildTotBase(data){
     if (data.childPriceDetails) {
       let getChdbase = parseFloat(data.childPriceDetails.childcount) * parseFloat(data.childPriceDetails.baseprice);
       console.log(getChdbase, 'getadultTotBasegetadultTotBase...3');
       return getChdbase.toFixed(2);
     }
     },

     getInfTotBase(data){
     if (data.infPriceDetails) {
       let getInfbase = parseFloat(data.infPriceDetails.infcount) * parseFloat(data.infPriceDetails.baseprice);
       console.log(getInfbase, 'getadultTotBasegetadultTotBase...4');
       return getInfbase.toFixed(2);
     }
   },

   getperpersonPrice(getdata){
    if (getdata.adultcount) {
      let getAdtperfare = parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
      console.log(getAdtperfare, 'getadultTotBasegetadultTotBase...2');
      return getAdtperfare.toFixed(2);
    }

    if (getdata.childcount) {
       let getChdperfare = parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
       console.log(getChdperfare, 'getadultTotBasegetadultTotBase...3');
       return getChdperfare.toFixed(2);
     }
   
    if (getdata.infcount) {
        let getInfperfare = parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getInfperfare, 'getadultTotBasegetadultTotBase...4');
        return getInfperfare.toFixed(2);
    }
  },


    // getprice(res) {
    //   return (((res.TotalAmount) / this.totaladult).toFixed(2));
    // },

    getdepseat(data) {
      // console.log(data,'ppppppppppppppppppppppp')
      return data[0].Seat;
    },

    getretseat(data) {
      return data[0].Seat;
    },
    // getlogo($event) {
    //   return $event[0].MarketingCarrier.logo;
    // },
    // getlogo1($event) {
    //   return $event[0].MarketingCarrier.logo;
    // },
    getname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },
    getNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },

    // All Date Section
    getDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getArrivaldate($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    getreturnDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getreturnArrivaldate($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    // All Date Section End

    // Mobile Date Format Section

    getDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getArrivaldateReturn($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    getreturnDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getreturnArrivaldateReturn($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    // Mobile Date Show End

    getdepLogo($event) {
      for (let i = 0; i < this.airline_logoData.length - 1; i++) {
        if ($event.AirlineID == this.airline_logoData[i].id) {
          return this.airline_logoData[i].logo;
        }
      }
    },

    getreturnLogo($event) {
      // console.log($event,'getreturnLogogetreturnLogo')
      for (let i = 0; i < this.airline_logoData.length - 1; i++) {
        if ($event.AirlineID == this.airline_logoData[i].id) {
          // console.log(this.airline_logoData[i].logo,"logoDatalogoDatalogoData");
          return this.airline_logoData[i].logo;
        }
      }
    },

    getAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getshowAirCode1($event) {
      const parts = $event.split("(");
      const depCode = parts.pop().replace(")", "");
      return depCode;
    },

    getshowAirCode2($event) {
      const parts = $event.split("(");
      const retCode = parts.pop().replace(")", "");
      return retCode;
    },

    getCountryName1($event) {
      this.FilterTimeDepname = $event.split("(")[0];
      return $event.split("(")[0];
    },
    getCountryName2($event) {
      this.FilterTimearrname = $event.split("(")[0];
      return $event.split("(")[0];
    },

    getAirportNameFrom($event) {
      return $event[0].Departure.AirportName;
    },

    getAirportNameTo($event) {
      return $event[0].Departure.AirportName;
    },

    getarrivaldata($event) {
      return $event[0].Arrival.AirportName;
    },
    getreturndata($event) {
      return $event[0].Arrival.AirportName;
    },
    getarrtime($event) {
      return $event[0].Arrival.Time;
    },
    getreturntime($event) {
      return $event[0].Arrival.Time;
    },
    get1arrtime($event) {
      // console.log($event, '$event')

      return $event[$event.length - 1].Departure.AirportName;
    },
    get1returntime($event) {
      return $event[$event.length - 1].Departure.AirportName;
    },

    get2arrtime($event) {
      return $event[$event.length - 1].Departure.Time;
    },

    get2returntime($event) {
      return $event[$event.length - 1].Departure.Time;
    },
    getTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getArrivalCode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getretairname($event) {
      return $event[$event.length - 1].Arrival.AirportName;
    },
    getreturnname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getreturnNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },
    getreturnid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },

    getdeparDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format(
        "ddd MMM DD"
      );
    },

    getdated($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated1($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated2($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated3($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getreturnAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },
    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getreturnTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getreturnArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getreturnArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    //     getlayover(data) {
    //       this.layoverTimes1 = [];

    // let $event=data
    // let layoverTime=[]
    // for (let i = 0; i <= $event.length-1; i++) {
    //   console.log($event[i],'data.length - 1')

    //   const currentIndex = i;
    //   const nextIndex = (i + 1) % data.length;

    //   const time1 = new Date(
    //     `${data[currentIndex].Arrival.Date}T${data[currentIndex].Arrival.Time}`
    //   );
    //   const time2 = new Date(
    //     `${data[nextIndex].Departure.Date}T${data[nextIndex].Departure.Time}`
    //   );

    //   const diffInMilliseconds = Math.abs(time2 - time1);
    //   const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));F

    //   const hours = Math.floor(diffInMinutes / 60);
    //   const minutes = diffInMinutes % 60;

    //     layoverTime .push(`${hours.toString().padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`) ;

    // }

    // this.layoverTimes1=layoverTime
    // console.log(this.layoverTimes1,'0000000000000000000')
    //     },

    //     getlayoverdep(data) {
    //       console.log(data,'indexxxxx')
    //       this.layoverTimes= [];
    // let $event=data
    // let layoverTime=[]
    // for (let i = 0; i <= $event.length-1; i++) {
    //   console.log($event[i],'data.length - 1')

    //   const currentIndex = i;
    //   const nextIndex = (i + 1) % data.length;

    //   const time1 = new Date(
    //     `${data[currentIndex].Arrival.Date}T${data[currentIndex].Arrival.Time}`
    //   );
    //   const time2 = new Date(
    //     `${data[nextIndex].Departure.Date}T${data[nextIndex].Departure.Time}`
    //   );

    //   const diffInMilliseconds = Math.abs(time2 - time1);
    //   const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

    //   const hours = Math.floor(diffInMinutes / 60);
    //   const minutes = diffInMinutes % 60;

    //     layoverTime .push(`${hours.toString().padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`) ;

    // }

    // this.layoverTimes=layoverTime
    // console.log(this.layoverTimes,'0000000000000000000')
    // },

    closeRet(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.Flightreturn = false;
        }
      });
    },

    // openModal(index) {
    //   this.showModal =  index;
    //   this.openmodal = true;
    //   document.documentElement.style.overflow = 'hidden';
    //   document.body.scroll = "no";
    //   // console.log(this.rountresult,'this.rountresultthis.rountresult.....1')
    // },
    closeModal() {
      this.rountresult[this.showModal].Flightdepature = false;
      // console.log(this.showModal,'lavsssssss')
      this.showModal = null;
      this.openmodal = false;
      document.documentElement.style.overflow = "scroll";
      // document.body.scroll = "yes";
    },
    closeModalOneWay() {
      this.onewayresulted[this.showModal].Flightdepature = false;
      // console.log(this.showModal,'lavsssssss')
      this.showModal = null;
      this.openmodal = false;
      document.documentElement.style.overflow = "scroll";
      // document.body.scroll = "yes";
    },
    closeModalMulti() {
      this.multicityData[this.showModal].Flightdepature = false;

      this.showModal = null;
      this.openmodal = false;
      document.documentElement.style.overflow = "scroll";
      // document.body.scroll = "yes";
    },
    flighted1(res, index) {
      this.rountresult.find((v, i) => {
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false);
      });

      this.showModal = index;
      this.openmodal = true;
      document.documentElement.style.overflow = "hidden";
      document.body.scroll = "no";
    },
    flighted2(res, index) {
      this.onewayresulted.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
      this.showModal = index;
      this.openmodal = true;
      document.documentElement.style.overflow = "hidden";
      document.body.scroll = "no";
    },

    flighted3(res, index) {
      console.log(index, res);
      this.multicityData.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
      this.showModal = index;
      this.openmodal = true;
      document.documentElement.style.overflow = "hidden";
      document.body.scroll = "no";
    },

    closeDep(res, index) {
      if (this.rountresult) {
        this.rountresult.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
    },

    getflightdep(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarr(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightdep1(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarrival(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },

    onewayselect(result, index) {
      localStorage.removeItem("itineryData");
      localStorage.removeItem("uniId");
      console.log(result, index);
      localStorage.setItem(`${result.currency}-${result.selectId}`,JSON.stringify(result));
      this.$router.push({
        path: "/flightinfo",
        query: { pass_id1: `${result.currency}-${result.selectId}` },
      });
    },

    multidata() {
      multicityService.getAllUser().then((v) => {
        this.offerpay = v.data.OffersGroup.AirlineOffers[0].Offer;

        this.Journey = v.data.DataLists.FlightList.Flight;

        this.Journey1 = v.data.DataLists.FlightSegmentList.FlightSegment;
        this.Journey.forEach((a) => {
          this.flightsegmet.push(a.SegmentReferences.split(" "));
        });
        this.Journey1.forEach((t) => {
          this.flightsegmetkey.push(t);
        });

        this.Journey.forEach((q) => {
          this.airstime.push(q.Journey.Time);
          this.airstop.push(q.Journey.Stops);
        });
        for (let i = 0; i <= this.offerpay.length - 1; i++) {
          let item = [];
          item = this.offerpay[i].OfferItem;
          item.forEach((t) => {
            this.airprice.push(
              t.TotalPriceDetail.TotalAmount.BookingCurrencyPrice
            );
            if (t.PassengerType === "ADT" || t.PassengerType == "ChD" || t.PassengerType == "INF") {
              if (t.Refundable === "true") {
                this.airref.push("Refundable");
              } else {
                this.airref.push("NON Refundable");
              }
            }
          });
        }

        this.airprice.forEach((c) => {
          this.multicityData1.push({ airprice: c });
        });
      });
    },

    mydata() {
      try {
        this.resulted = JSON.parse(localStorage.getItem("myData"));
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },

    // RoundTrip datePicker

    getDatepicker($data, index) {
      if (index > 0) {
        // console.log($data, index, '$data,index....1')

        return $data[index - 1].depdate;
      } else {
        // console.log($data, index, '$data,index....2')

        return `${new Date()}`;
      }
    },

    fromDateChanged(newValue) {
      this.oneway.dedate = newValue;

      console.log(newValue,'newValuenewValue')

      if (this.oneway.dedate) {
        this.clicked1 = true;
        this.fromDateError = false;
        if(!this.Oneway){
          this.$refs.dpRef2.openMenu();
        }
        // console.log(this.oneway.dedate, "ddddddddd...1....eeeeeeeeeee");
      } else {
        this.clicked1 = false;

        if(this.rount){
          this.$refs.dpRef1.openMenu();
        }
        else{
          this.$refs.onewaydpRef1.openMenu();
        }

        // if(this.rount){
        //   setTimeout(()=>{
        //     this.$refs.dpRef1.focus();
        //   },100)
        // }
        // else{
        //   setTimeout(()=>{
        //     this.$refs.onewaydpRef1.focus();
        //   },100)
        // }

        // this.fromDateError = true;
        // this.$refs.dpRef1.openMenu();
        // console.log(this.oneway.dedate, "ddddddddd...2....eeeeeeeeeee");
      }
    },

    ClickOutDepat() {

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }

      if(this.rount){
          this.$refs.dpRef1.closeMenu();
        }
        else{
          this.$refs.onewaydpRef1.closeMenu();
        }
    },

    toDateChanged(newValue) {
      this.oneway.redate = newValue;
      // console.log(this.oneway.redate, "ttttttttttt");

      if (this.oneway.redate) {
        this.clicked2 = true;
        // this.toDateError = false;
      } else {
        this.clicked2 = false;
        this.$refs.dpRef2.openMenu();
        // this.toDateError = true;
      }
    },

    triggerFunction() {
      alert("Hiii...");
      // this.triggerRadio = !this.triggerRadio

      // if (this.triggerRadio) {
      //   console.log(this.triggerRadio)
      //   this.$refs.radioSelect2.focus();
      // }
    },

    ClickOutReturn() {
      this.$refs.dpRef2.closeMenu();
      // console.log(this.oneway.redate, "kkkkkkkkk");

      if (this.oneway.redate) {
        this.clicked2 = true;
      } else {
        this.clicked2 = false;
      }
    },
    // RoundTrip datePicker End

    // Oneway datePicker
    onewayDateChanged(newValue) {
      this.oneway.dedate = newValue;

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    onewayClickOutside() {
      this.$refs.dpRef3.closeMenu();
      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    multiDateChanged(newValue, index) {
      // console.log(index, newValue, "didididid...");

      this.multi_city.itinery.map((v, i) => {
        if (index == i) {
          // if(!v.departDate){
          //   this.$refs.dpRef4[index].closeMenu()
          // }
          if (v.depdate) {
            console.log(v.depdate,'openMenuopenMenuopenMenu...1')
              this.$refs.dpRef4[index].closeMenu();

              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = false;

              const inputElement = this.$refs.multiToAutocomplete[index+1];
              // console.log(this.$refs.fromAutoComplete);
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.focus();
              }
          } 
          else {
            console.log(v.depdate,'openMenuopenMenuopenMenu...2')
            this.$refs.dpRef4[index].openMenu()
              // const inputElement = this.$refs.dpRef4[index];
              // if (inputElement) {
              //   inputElement.openMenu();
              // }
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = true;
          }

          if (newValue) {
            v.clicked3 = true;
            v.multiDateError = false;
          } else {
            v.clicked3 = false;
            v.multiDateError = true;
          }
        }
      });

      this.multi_city.itinery.forEach((v, i) => {
        if (index < i) {
          if (newValue > new Date(v.depdate)) {
            v.depdate = null;
          }
        }
      });
    },

    multiClickOutside() {
      this.multi_city.itinery.clicked3 = false;
      this.datePickerIsOpen = false;
    },
    // Multicity datepicker End


    async fetchCity(event) {

      if (event.length >= 3) {
      try {
        const response = await axios.get(`${this.autocompleteApi}?airportString=${event}`, {
          headers: {
            "Content-Type": this.airlineIcaoType,
          },
        });
        return response.data;
      } catch (error) {
        console.log(error, "erroroor.....");
      }
      } else {
        return [];
      }
    },

     async roundData() {
      // this.oneway.from = this.$route.query.itinerary.from;
      // this.oneway.to = this.$route.query.itinerary.to;
      
      if(this.$route.query.itinerary){

        let getItinerary = this.$route.query.itinerary;
        let getpaxtype = this.$route.query.paxType;

        let splitItin = getItinerary.split('_');

        let getdepDetails = splitItin[0].split('-');
        let [from, to, depdate]= getdepDetails;

        console.log(depdate,'sdasdasdadepdate');

        let getfromlist = []
        let getTolist = []

        if(from){
          getfromlist = await this.fetchCity(from);
          if(getfromlist.length > 0){
            getfromlist.forEach((data,i)=>{
              if(i == 0){
                this.oneway.from = `${data.city} (${from})`;
                this.tripData.from = this.oneway.from;
                console.log(data.city,'asdasdadadsas',i)
              }
            })
          }
        }

        if(to){
          getTolist = await this.fetchCity(to);
          if(getTolist.length > 0){
            getTolist.forEach((data,i)=>{
              if(i == 0){
                this.oneway.to = `${data.city} (${to})`
                this.tripData.to = this.oneway.to;
                console.log(data.city,'asdasdadadsas',i)
              }
            })
          }
        }

        // this.oneway.dedate = moment(new Date(depdate)).format("DD MMM-YYYY");
        this.oneway.dedate = new Date(depdate); 

        if(splitItin.length == 2){  
          let getredate = splitItin[1].split('-')[2]; 
          this.oneway.redate = new Date(getredate);
          // this.oneway.redate = moment(getredate).format("DD MMM-YYYY");
        }

        let getPaxDetails = getpaxtype.split('_');
        let [Adt,Chd,Inf] = getPaxDetails;

        let [getAdult, getChild, getInf] = [Adt.split('-')[1],Chd.split('-')[1],Inf.split('-')[1]];

        this.oneway.adult = getAdult;
        this.oneway.child = getChild;
        this.oneway.infant = getInf;
        this.oneway.tripType = this.$route.query.tripType;

        let getrouteCabin = this.$route.query.cabin;
        if(getrouteCabin == "Economy"){
            this.oneway.class = "Economy";
        }
        if(getrouteCabin == "Business"){
            this.oneway.class = "Business";
        }
        if(getrouteCabin == "FirstClass"){
            this.oneway.class = "First Class";
        }
        if(getrouteCabin == "PremiumEconomy"){
            this.oneway.class = "Premium Economy";
        }
        if(getrouteCabin == "PremiumBusiness"){
            this.oneway.class = "Premium Business";
        }
        if(getrouteCabin == "PremiumFirstClass"){
            this.oneway.class = "Premium First";
        }
        // this.oneway.class = this.$route.query.cabin;
        
        console.log(this.oneway,'splitItinsplit...6');

      if (getAdult) {
        for (let i = 0; i < getAdult; i++) {
          this.Adult.push({
            PassengerID: "T",
            PTC: "ADT",
          });
        }
      }

      if (getChild) {
        for (let i = 0; i < getChild; i++) {
          this.Child.push({
            PassengerID: "T",
            PTC: "CHD",
          });
        }
      }
      if (getInf) {
        for (let i = 0; i < getInf; i++) {
          this.Infrant.push({
            PassengerID: "T",
            PTC: "INF",
          });
        }
      }

      let totalpass = [];
      totalpass = [...this.Adult, ...this.Child, ...this.Infrant];

      totalpass.forEach((v, index) => {
        let obj = {
          PassengerID: `${v.PassengerID}${index + 1}`,
          PTC: v.PTC,
        };
        this.totalpassenger.push(obj);
      });

  
      // console.log(this.totalpassenger, "aadjj");
      this.Rountdata.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
        this.totalpassenger;
      this.onewaydata.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
        this.totalpassenger;

      this.adultValue = parseFloat(getAdult);
      this.tripData.adultValue = parseFloat(getAdult);

      this.childValue = parseFloat(getChild);
      this.tripData.childValue = parseFloat(getChild);

      this.infantValue = parseFloat(getInf);
      this.tripData.infantValue = parseFloat(getInf);


      this.totaladult = this.adultValue + this.childValue + this.infantValue;
      // this.classType = this.$route.query.cabin;

      // let langLocale = `${this.pointofSale}-${this.$route.query.language}`
      // this.$i18n.locale = langLocale;

      // this.changedLang = `${this.pointofSale}-${this.$route.query.language}`
      // this.$emit('localeChanged', this.changedLang);

      this.tripData.select = this.$route.query.class;

      
      const tabId = sessionStorage.getItem("tabId") || Date.now().toString();
      sessionStorage.setItem("tabId", tabId);
      
      const getLocalData =  JSON.parse(localStorage.getItem(`searchedData-round-${tabId}`)) || JSON.parse(localStorage.getItem(`searchedData-oneway-${tabId}`));
      console.log(getLocalData,'getLocalDataasdasdgetLocalData');
      if(getLocalData) getLocalData.advanceOptions ? this.oneway.advanceOptions = getLocalData.advanceOptions : delete this.oneway.advanceOptions;
      
      if(this.oneway.from && this.oneway.to && this.oneway.dedate && this.oneway.redate){

        console.log(this.oneway,'sdasdaresult112332')
        const result1 = JSON.stringify(this.oneway);
        console.log(result1,'sdasdaresult11233')

        localStorage.setItem(`searchedData-round-${tabId}`, result1);
  
        this.searchCase = JSON.stringify(this.oneway);
        console.log(this.searchCase,'ssdasdseasaaSrchCase...1')

        // if(this.searchCase){
          this.searchloader = true;
        // }
        console.log(this.fromErrorMessage,'validatecheckworking.....8');
     
        this.sendedRoundData();
      }

      if(this.oneway.from && this.oneway.to && this.oneway.dedate && !this.oneway.redate){
        
      // let result1 = [];
      //   result1 = JSON.stringify(this.oneway);
      //   localStorage.setItem("searchedData", result1);
      const result1 = JSON.stringify(this.oneway);
        console.log(result1,'sdasdaresult12333')

        localStorage.setItem(`searchedData-oneway-${tabId}`, result1);

        this.searchCase = JSON.stringify(this.oneway);
        console.log(this.searchCase,'ssdasdseasaaSrchCase...2')
        if(this.searchCase){
          this.searchloader = true;
          }
        this.sendedone1();
      }

  }
    },


  async multiDataConvert() {
    if (this.$route.query.itinerary) {
      let getItinerary = this.$route.query.itinerary;
      let getpaxtype = this.$route.query.paxType;

      let splitItin = getItinerary.split('_');
      let segments = splitItin.map(segment => segment.split('-'));

      this.multi_city.itinery = [];
      for (let i = 0; i < segments.length; i++) {
        let [from, to, depdate] = segments[i];

        let $result = {
          Departure: {
            AirportCode: from,
            Date: moment(new Date(depdate)).format("YYYY-MM-DD"),
          },
          Arrival: {
            AirportCode: to,
          },
        };
        this.MulticityReq.request.AirShoppingRQ.CoreQuery.OriginDestinations.OriginDestination.push($result);

        if (from && to) {
          const [fromCityList, toCityList] = await Promise.all([
            this.fetchCity(from),
            this.fetchCity(to)
          ]);

          console.log(fromCityList,'sdfdfdasdada',toCityList)

          if (fromCityList.length > 0 && toCityList.length > 0) {
            this.multi_city.itinery.push({
              from: `${fromCityList[0].city} (${from})`,
              to: `${toCityList[0].city} (${to})`,
              depdate: moment(new Date(depdate)).format("YYYY-MM-DD"),
            });

            this.codeData.push({ from: `${fromCityList[0].city} (${from})`, to: `${toCityList[0].city} (${to})` });
          }
        }

        if(from){
          this.multi_city.itinery[i].selectedFrom = true;
        }

        if(to){
          this.multi_city.itinery[i].selectedTo = true;
        }

        console.log(this.multi_city.itinery[i], `Segment ${i + 1}`);
      }


      let getPaxDetails = getpaxtype.split('_');
      let [Adt, Chd, Inf] = getPaxDetails;
      let [getAdult, getChild, getInf] = [Adt.split('-')[1], Chd.split('-')[1], Inf.split('-')[1]];

      this.multi_city.adult = getAdult;
      this.multi_city.child = getChild;
      this.multi_city.infant = getInf;
      // this.classType = this.$route.query.cabin;

      let getrouteCabin = this.$route.query.cabin;
        if(getrouteCabin == "Economy"){
            this.classType = "Economy";
        }
        if(getrouteCabin == "Business"){
            this.classType = "Business";
        }
        if(getrouteCabin == "FirstClass"){
            this.classType = "First Class";
        }
        if(getrouteCabin == "PremiumEconomy"){
            this.classType = "Premium Economy";
        }
        if(getrouteCabin == "PremiumBusiness"){
            this.classType = "Premium Business";
        }
        if(getrouteCabin == "PremiumFirstClass"){
            this.classType = "Premium First";
        }

      console.log(this.classType,'asdasdasdasdaswqwqwqwdaszxsdfsfsd');
      
      this.multi_city.tripType = this.$route.query.tripType;


      if (getAdult) {
        for (let i = 0; i < getAdult; i++) {
          this.Adult.push({
            PassengerID: "T",
            PTC: "ADT",
          });
        }
      }

      if (getChild) {
        for (let i = 0; i < getChild; i++) {
          this.Child.push({
            PassengerID: "T",
            PTC: "CHD",
          });
        }
      }

      if (getInf) {
        for (let i = 0; i < getInf; i++) {
          this.Infrant.push({
            PassengerID: "T",
            PTC: "INF",
          });
        }
      }

      let totalpass = [...this.Adult, ...this.Child, ...this.Infrant];
      totalpass.forEach((v, index) => {
        let obj = {
          PassengerID: `${v.PassengerID}${index + 1}`,
          PTC: v.PTC,
        };
        this.totalpassenger.push(obj);
      });

      this.MulticityReq.request.AirShoppingRQ.DataLists.PassengerList.Passenger = this.totalpassenger;


      this.adultValue = parseFloat(getAdult);
      this.tripData.adultValue = parseFloat(getAdult);

      this.childValue = parseFloat(getChild);
      this.tripData.childValue = parseFloat(getChild);

      this.infantValue = parseFloat(getInf);
      this.tripData.infantValue = parseFloat(getInf);

      this.totaladult = this.adultValue + this.childValue + this.infantValue;
      this.multi_city.class = this.classType;
      this.tripData.select = this.$route.query.cabin;

      // let langLocale = `${this.pointofSale}-${this.$route.query.language}`;
      // this.$i18n.locale = langLocale;
      
      // this.changedLang = `${this.pointofSale}-${this.$route.query.language}`
      // this.$emit('localeChanged', this.changedLang);

      const tabId = sessionStorage.getItem("tabId") || Date.now().toString();
      sessionStorage.setItem("tabId", tabId);

      const getLocalData =  JSON.parse(localStorage.getItem(`searchedData-multicity-${tabId}`));
      console.log(getLocalData,'getLocalDataasdasdgetLocalData');
      if(getLocalData) getLocalData.advanceOptions ? this.multi_city.advanceOptions = getLocalData.advanceOptions : delete this.multi_city.advanceOptions;

      if(this.multi_city.itinery.length > 0){
 
      const result1 = JSON.stringify(this.multi_city);
      localStorage.setItem(`searchedData-multicity-${tabId}`, result1);

        this.searchCase = JSON.stringify(this.multi_city);
        console.log(this.searchCase,'ssdasdseasaaSrchCase...3')
        
        if(this.searchCase){
          this.searchloader = true;
          }
        console.log(this.multi_city,'MulticityRequestData...1');
        console.log(this.MulticityReq, 'MulticityRequestData....2');
        await this.multicityresult();
      }
  
    }
  },


  async multicityresult() {

      this.multi_city.class = this.classType;
      console.log(this.multi_city,'sdasdasdmulticity');
      let multiCabin_type = this.multi_city.class;

      console.log(
        multiCabin_type,
        "multiCabin_typemultiCabin_typemultiCabin_type"
      );

      if (multiCabin_type == "Economy") {
        multiCabin_type = "Y";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType = multiCabin_type;
      } else if (multiCabin_type == "Business") {
        multiCabin_type = "C";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType = multiCabin_type;
      } else if (multiCabin_type == "First Class") {
        multiCabin_type = "F";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType = multiCabin_type;
      } else if (multiCabin_type == "Premium Economy") {
        multiCabin_type = "S";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType = multiCabin_type;
      } else if (multiCabin_type == "Premium Business") {
        multiCabin_type = "J";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType = multiCabin_type;
      } else if (multiCabin_type == "Premium First") {
        multiCabin_type = "P";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType = multiCabin_type;
      }

      this.MulticityReq.country = this.pointofSale;
      this.MulticityReq.request.AirShoppingRQ.Preference.TripType = "MULTICITY";
      this.MulticityReq.request.AirShoppingRQ.Preference.Currency = this.preferCurrency;
      this.MulticityReq.request.AirShoppingRQ.MetaData.Currency = this.metaCurrency;


      console.log(this.multi_city,'onewayoconsoleneway...3');
        if(this.multi_city.advanceOptions){
          let getadvanceOpt = this.multi_city.advanceOptions;
          this.MulticityReq.request.AirShoppingRQ.Preference.DirectFlight = getadvanceOpt.directFlight;
          this.MulticityReq.request.AirShoppingRQ.Preference.BaggageInclusive = getadvanceOpt.baggageInclusive;
          this.MulticityReq.request.AirShoppingRQ.Preference.NearbyAirport = getadvanceOpt.nearbyAirport;
          this.MulticityReq.request.AirShoppingRQ.Preference.Stops = getadvanceOpt.stopsSelect ? [getadvanceOpt.stopsSelect] : [];
          this.MulticityReq.request.AirShoppingRQ.Preference.LayoverHours = getadvanceOpt.adlayoverTime;
          this.MulticityReq.request.AirShoppingRQ.Preference.AvoidCountry = getadvanceOpt.avoidCountry;
          // this.MulticityReq.request.AirShoppingRQ.Preference.IncludedAirlines = getadvanceOpt.includeAirline;
          // this.MulticityReq.request.AirShoppingRQ.Preference.ExcludedAirlines = getadvanceOpt.excludeAirline;
          // this.MulticityReq.request.AirShoppingRQ.Preference.IncludedViaStops = getadvanceOpt.includeAirport;
          // this.MulticityReq.request.AirShoppingRQ.Preference.ExcludedViaStops = getadvanceOpt.excludeAirport;
          this.MulticityReq.request.AirShoppingRQ.Preference.IncludedAirlines = (getadvanceOpt.includeAirline ?? []).map(airline => {
            const match = airline.match(/\(([^)]+)\)/);
            return match ? match[1] : airline;
          });
          this.MulticityReq.request.AirShoppingRQ.Preference.ExcludedAirlines = (getadvanceOpt.excludeAirline ?? []).map(airline => {
            const match = airline.match(/\(([^)]+)\)/);
            return match ? match[1] : airline; 
          });
          this.MulticityReq.request.AirShoppingRQ.Preference.IncludedViaStops = (getadvanceOpt.includeAirport ?? []).map(airport => {
              const match = airport.match(/\(([^)]+)\)/);
              return match ? match[1] : airport;
          });
          this.MulticityReq.request.AirShoppingRQ.Preference.ExcludedViaStops = (getadvanceOpt.excludeAirport ?? []).map(airport => {
              const match = airport.match(/\(([^)]+)\)/);
              return match ? match[1] : airport;
          });
        }

      console.log(
        this.MulticityReq,
        "MulticityReqMulticityReqMulticityReqMulticityReq"
      );

      
      try {
        const response = await axios.post(this.makeSearchUrl, this.MulticityReq, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(response, "multi_citymulti_citymulti_city");
          if (response.data) {
            this.multicityTotalData.push(response.data);
            this.multicityconvert();
          }
      } catch (error) {
        
          setTimeout(() => {
                  this.searchloader = false;
                  this.searchresult = true;
                  if(error.response && error.response.data.success == false){
                    this.dateError = error.response.data.message;
                    // console.log(this.dateError,'dateerror')
                  }
                  this.pageError = true;
                }, 7000);
        console.error('Error during Axios request:', error);
      }

      // await axios.post(this.makeSearchUrl, this.MulticityReq, {
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   })

      //   .then((response) => {
      //     console.log(response, "multi_citymulti_citymulti_city");
      //     if (response.data) {
      //       this.multicityTotalData.push(response.data);
      //       this.multicityconvert();
      //     }
      //   })
      //   .catch((error) => {
      //     this.searchloader = true;
      //       setTimeout(() => {
      //           this.searchloader = false;
      //           this.searchresult = true;
      //           this.pageError = true;
      //         }, 7000);

      //     // this.multicityTotalData.push(multiResponse);
      //     // this.searchloader = false;
      //     // this.searchresult = true;
          

      //       console.log(error,'eroororororoowooe...multi');
      //       // this.$router.push("/pageError");
      //     });
    },

    multicityconvert() {
      let baggageList = [];
      for (let i = 0; i < this.multicityTotalData.length; i++) {
        console.log(this.multicityTotalData[i], "power999999999");
        let shoppingId = this.multicityTotalData[i].ShoppingResponseId;
        let Journey = [];
        let flightsegment = [];
        Journey = this.multicityTotalData[i].DataLists.FlightList.Flight;
        baggageList =
          this.multicityTotalData[i].DataLists.BaggageAllowanceList
            .BaggageAllowance;
        console.log(Journey, "Journey6666666666666666");
        flightsegment =
          this.multicityTotalData[i].DataLists.FlightSegmentList.FlightSegment;
        // console.log(flightsegment, "flightsegment");
        let $data = [];
        $data = this.multicityTotalData[i].OffersGroup.AirlineOffers.Offer;

        let source1 = [];
        let source2 = [];
        let source3 = [];
        let source4 = [];
        let source5 = [];
        let Baggage1 = [];
        let Baggage2 = [];
        let Baggage3 = [];
        let Baggage4 = [];
        let Baggage5 = [];

        $data.forEach((v) => {
          v.OfferItem[0][0].FareComponent.forEach((s, index) => {
            if (index == 0) {
              if(v.OfferItem[0][0].BaggageAllowance.length > 0){
                Baggage1.push(
                  v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                );
              }
              this.offerData1.push(s.SegmentRefs.split(" "));

              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source1.push(Journey[i].Journey);
                }
              }
            }
            if (index == 1) {
              if(v.OfferItem[0][0].BaggageAllowance.length > 0){
                Baggage2.push(
                  v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                );
              }
              this.offerData2.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source2.push(Journey[i].Journey);
                }
              }
            }
            if (index == 2) {
              if(v.OfferItem[0][0].BaggageAllowance.length > 0){
                Baggage3.push(
                  v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                );
              }
              this.offerData3.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source3.push(Journey[i].Journey);
                }
              }
            }
            if (index == 3) {
              if(v.OfferItem[0][0].BaggageAllowance.length > 0){
                Baggage4.push(
                  v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                );
              }
              this.offerData4.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source4.push(Journey[i].Journey);
                }
              }
            }
            if (index == 4) {
              if(v.OfferItem[0][0].BaggageAllowance.length > 0){
                Baggage5.push(
                  v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                );
              }
              this.offerData5.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source5.push(Journey[i].Journey);
                }
              }
            }
          });
        });
        console.log(flightsegment, "this.flightsegment");
        let BaggageData1 = [];
        if (this.offerData1.length > 0) {
          Baggage1.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData1.push(c);
              }
            });
          });
          for (let i = 0; i < this.offerData1.length; i++) {
            let seg = [];
            this.offerData1[i].forEach((f, index) => {
              let items = [];
              items = flightsegment.filter((v) => v.SegmentKey == f);
              if (items.length > 0) {
                seg.push(items[0]);
              }
              if (index == this.offerData1[i].length - 1) {
                this.multicityData.push({ Flight1: seg });
              }
            });
          }
        }
        let BaggageData2 = [];
        if (this.offerData2.length > 0) {
          Baggage2.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData2.push(c);
              }
            });
          });
          for (let i = 0; i < this.offerData2.length; i++) {
            let seg = [];
            this.offerData2[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData2[i].length - 1) {
                this.Flight2.push(seg);
              }
            });
          }
        }
        let BaggageData3 = [];
        if (this.offerData3.length > 0) {
          Baggage3.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData3.push(c);
              }
            });
          });
          for (let i = 0; i < this.offerData3.length; i++) {
            let seg = [];
            this.offerData3[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData3[i].length - 1) {
                this.Flight3.push(seg);
              }
            });
          }
        }
        let BaggageData4 = [];
        if (this.offerData4.length > 0) {
          Baggage4.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData4.push(c);
              }
            });
          });

          for (let i = 0; i < this.offerData4.length; i++) {
            let seg = [];
            this.offerData4[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData4[i].length - 1) {
                this.Flight4.push(seg);
              }
            });
          }
        }
        let BaggageData5 = [];
        if (this.offerData5.length > 0) {
          Baggage5.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData5.push(c);
              }
            });
          });
          for (let i = 0; i < this.offerData5.length; i++) {
            let seg = [];
            this.offerData5[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData5[i].length - 1) {
                this.Flight5.push(seg);
              }
            });
          }
        }
        for (let i = 0; i < this.airlogodata.length - 1; i++) {
          for (let j = 0; j < flightsegment.length - 1; j++) {
            if (
              flightsegment[j].MarketingCarrier.AirlineID ==
              this.airlogodata[i].id
            ) {
              flightsegment[j].MarketingCarrier.logo = this.airlogodata[i].logo;
            }
          }
        }

        let currency = [];
        let Baseprice = [];
        let Tax = [];
        let perperson = [];
        let ownername = [];
        let ownerlogo = [];
        let offerId = [];
        let TotalPrice = [];
        let refund = [];
        let adultcount = [];
        let childcount = [];
        let infantcount = [];
        let combinedPricedetail = [];

        $data.forEach((v) => {
          // console.log(v, "offeriddatataa");
          offerId.push(v.OfferID);
        });

        $data.forEach((s) => {
          if (s.ReqCurrency == "USD") {
            currency.push("US$");
          } else if (s.ReqCurrency == "CAD") {
            currency.push("CA$");
          } else {
            currency.push("US$");
          }

          ownername.push(s.OwnerName);
          for (let i = 0; i < this.airline_logoData.length - 1; i++) {
            if (s.Owner == this.airline_logoData[i].id) {
              ownerlogo.push(this.airline_logoData[i].logo);
            }
          }

          Baseprice.push(
            parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
          );
          Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
          perperson.push(
            parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
          );
          TotalPrice.push(
            parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
          );


          
          let adultPriceDetails = {};
          let childPriceDetails = {};
          let infPriceDetails = {};

          s.OfferItem[0].forEach((e) => {
            if (e.PassengerType === 'ADT' || e.PassengerType === 'JCB') {
              let getAdultbase = e.FareDetail[e.FareDetail.length -1].Price.BaseAmount.BookingCurrencyPrice;
              let getAdulttax = e.FareDetail[e.FareDetail.length -1].Price.TaxAmount.BookingCurrencyPrice;
              let getAdultcount = e.PassengerQuantity;

              adultPriceDetails = { 
                baseprice: parseFloat(getAdultbase).toFixed(2), 
                taxprice: parseFloat(getAdulttax).toFixed(2),
                adultcount: getAdultcount,
                pax_type: "Adult"
              }    

              // console.log(adultPriceDetails,'adultPriceDetailsadultPriceDetails...a');
            }

            if (e.PassengerType === 'CNN' || e.PassengerType === 'JNN') {
              let getChildbase = e.FareDetail[e.FareDetail.length -1].Price.BaseAmount.BookingCurrencyPrice;
              let getChildtax = e.FareDetail[e.FareDetail.length -1].Price.TaxAmount.BookingCurrencyPrice;
              let getChildcount = e.PassengerQuantity;

              childPriceDetails = { 
                baseprice: parseFloat(getChildbase).toFixed(2), 
                taxprice: parseFloat(getChildtax).toFixed(2),
                childcount: getChildcount,
                pax_type: "Child"
              }   

              // console.log(childPriceDetails,'childPriceDetailschildPriceDetails...c');
            }

            if (e.PassengerType === 'INF' || e.PassengerType === 'JNF') {
              let getInfbase = e.FareDetail[e.FareDetail.length -1].Price.BaseAmount.BookingCurrencyPrice;
              let getInftax = e.FareDetail[e.FareDetail.length -1].Price.TaxAmount.BookingCurrencyPrice;
              let getInfcount = e.PassengerQuantity;

              infPriceDetails = { 
                baseprice: parseFloat(getInfbase).toFixed(2), 
                taxprice: parseFloat(getInftax).toFixed(2),
                infcount: getInfcount,
                pax_type: "Inf(lap)"
              }      

              // console.log(infPriceDetails,'infPriceDetailsinfPriceDetails...i');
            }
          });

          combinedPricedetail.push({adultPriceDetails,childPriceDetails,infPriceDetails});




          s.OfferItem.forEach((v) => {
            console.log(
              v[0].FareDetail[0].Price.NonRefundable,
              "Price.RefundablePrice.Refundable"
            );

            if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
              refund.push("Refundable");
            } else {
              refund.push("Non Refundable");
            }
          });


          s.OfferItem.forEach((t) => {
            t.forEach((e) => {
              if (e.PassengerType === "ADT") {
                adultcount.push(e.PassengerQuantity);
              }
            
              if(e.PassengerType == "CNN"){
                childcount.push(e.PassengerQuantity);
              }
            
              if(e.PassengerType == "INF"){
                infantcount.push(e.PassengerQuantity);
              }
            });
          });

        });

        for (let i = 0; i < perperson.length; i++) {
          let nume1 = perperson[i];
          // console.log(nume1, "llldlldldldldld")
          this.find1.push(
            (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[0]
          );
          this.find2.push(
            (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[1]
          );
        }

        for (let i = 0; i < this.multicityData.length; i++) {
          this.multicityData[i].selectId = offerId[i];
          this.multicityData[i].currency = currency[i];
          this.multicityData[i].Baseprice = Baseprice[i];
          this.multicityData[i].TotalPrice = TotalPrice[i];
          this.multicityData[i].BaggageData1 = BaggageData1[i];
          this.multicityData[i].BaggageData2 = BaggageData2[i];
          this.multicityData[i].Adult = adultcount[i];
          this.multicityData[i].Child = childcount[i];
          this.multicityData[i].Inf = infantcount[i];
          this.multicityData[i].Taxprice = Tax[i];
          this.multicityData[i].perperson = perperson[i];
          this.multicityData[i].Flight2 = this.Flight2[i];
          this.multicityData[i].find1 = this.find1[i];
          this.multicityData[i].find2 = this.find2[i];
          this.multicityData[i].Flightdepature = false;
          this.multicityData[i].hoveredName1 = false;
          this.multicityData[i].ShoppingResponseId = shoppingId;
          this.multicityData[i].priceDetails = combinedPricedetail[i];
          // this.multicityData[i].Flight2 = this.Flight2[i];
          this.multicityData[i].ownerlogo = ownerlogo[i];
          this.multicityData[i].muticityData = this.codeData;
          this.multicityData[i].ownername = ownername[i];
          this.multicityData[i].Flight1JourneyTime = source1[i];
          this.multicityData[i].Flight2JourneyTime = source2[i];
          this.multicityData[i].Refund = refund[i];
          this.multicityData[i].cabinClass = this.multi_city.class;
          if (this.Flight3.length > 0) {
            this.multicityData[i].Flight3 = this.Flight3[i];
            this.multicityData[i].Flight3JourneyTime = source3[i];
            this.multicityData[i].BaggageData3 = BaggageData3[i];
          }

          if (this.Flight4.length > 0) {
            this.multicityData[i].BaggageData4 = BaggageData4[i];
            this.multicityData[i].Flight4 = this.Flight4[i];
            this.multicityData[i].Flight4JourneyTime = source4[i];
          }
          if (this.Flight5.length > 0) {
            this.multicityData[i].BaggageData5 = BaggageData5[i];
            this.multicityData[i].Flight5 = this.Flight5[i];
            this.multicityData[i].Flight5JourneyTime = source5[i];
          }
        }
        this.getmultitime1();

        console.log(this.multicityData,"multicityDatamulticityDatamulticityData55555555555");

        this.pageError = !this.multicityData || this.multicityData == '' ? true : false;

        this.multiFilterData = this.multicityData;
        this.priceRange();
            this.filterOutBoundMulti();
            this.getAiportFilterMulti();
            this.airlineFilter();

        this.multicityData = totalPricefilter.sortBy(this.multiFilterData, [
          "TotalAmount",
        ]);

        if (this.multicityData) {
          this.searchresult = true;
          this.searchloader = false;

          document.getElementById("navbar").style.display = "block";
          document.getElementById("food").style.display = "block";
        }
        // console.log(this.multicityData, "this.multicityDatathis.multicityData");

      }
         this.passengerData();
    },

    async airlineLogo_func() {
      let airlogoUrl = "";
      let airlogoType = "";

      airlogoUrl = this.airLogoApi.url;
      airlogoType = this.airLogoApi.type;

      await axios
        .get(airlogoUrl, {
          headers: {
            "Content-Type": airlogoType,
          },
        })
        .then((response) => {
          if (response.data) {
            this.airline_logoData = response.data;

            // console.log( this.airlogodata,'this.airlogodatathis.airlogodata')
          }
        })
        .catch((error) => {
          console.log(error, "ressloerrrororooool");
        });


 this.airlogodata=this.airline_logoData
//  console.log(this.airlogodata,'this.airlogodata')

    },

    roundedtrip() {
      for (let i = 0; i < this.localData.length; i++) {
        // console.log(this.localData[i],"this.localData[i]this.localData[i]this.localData[i]");

        if (this.localData[i]) {
          this.localData[i].forEach((v) => {
            // console.log(v.DataLists, "good");
            // let seats = [];
            let baggageList = [];
            this.Journey = v.DataLists.FlightList.Flight;
            let shoppingId = v.ShoppingResponseId;
            // console.log(shoppingId, "shoppingIdshoppingId");
            let Baseprice = [];
            let Tax = [];
            let pricedetail = [];
            let $data = v.OffersGroup.AirlineOffers.Offer;
            baggageList = v.DataLists.BaggageAllowanceList.BaggageAllowance;
            let offerId = [];
            // $data.forEach((v=>{
            //   offerId.push(v.OffersGroup.AirlineOffers.Offer.offerId)
            // }))
            // offerId.push(v.OffersGroup.AirlineOffers.Offer)

            let arrsplit = [];
            let depsplit = [];
            let resultdata = [];
            let resultdata1 = [];
            let refund = [];
            let currency = [];
            let ownerlogo = [];
            let ownername = [];
            // let depAirLogo = [];
            // let returnAirLogo = [];
            let perperson = [];
            let TotalPrice = [];
            let adultcount = [];
            let childcount = [];
            let infantcount = [];
            let combinedPricedetail = [];

            $data.forEach((v) => {
              // console.log(v, "offeriddatataa");
              offerId.push(v.OfferID);
            });

            $data.forEach((s) => {
              if (s.ReqCurrency == "USD") {
                currency.push("US$");
              } else if (s.ReqCurrency == "CAD") {
                currency.push("CA$");
              } else {
                currency.push("US$");
              }

              ownername.push(s.OwnerName);
              for (let i = 0; i < this.airline_logoData.length - 1; i++) {
                if (s.Owner == this.airline_logoData[i].id) {
                  ownerlogo.push(this.airline_logoData[i].logo);
                }
              }

              // console.log(s.ReqCurrency, "currency");
              Baseprice.push(
                parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
              );
              Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
              perperson.push(
                parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
              );
              TotalPrice.push(
                parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
              );
              pricedetail.push(s.OfferItem);


              let adultPriceDetails = {};
              let childPriceDetails = {};
              let infPriceDetails = {};

              s.OfferItem[0].forEach((e) => {
                if (e.PassengerType === 'ADT' || e.PassengerType === 'JCB') {
                  let getAdultbase = e.FareDetail[e.FareDetail.length -1].Price.BaseAmount.BookingCurrencyPrice;
                  let getAdulttax = e.FareDetail[e.FareDetail.length -1].Price.TaxAmount.BookingCurrencyPrice;
                  let getAdultcount = e.PassengerQuantity;

                  adultPriceDetails = { 
                      baseprice: parseFloat(getAdultbase).toFixed(2), 
                      taxprice:  parseFloat(getAdulttax).toFixed(2),
                      adultcount: getAdultcount,
                      pax_type: "Adult"
                    }                  
                  // console.log(adultPriceDetails,'adultPriceDetailsadultPriceDetails...a');
                }

                if (e.PassengerType === 'CNN' || e.PassengerType === 'JNN') {
                  let getChildbase = e.FareDetail[e.FareDetail.length -1].Price.BaseAmount.BookingCurrencyPrice;
                  let getChildtax = e.FareDetail[e.FareDetail.length -1].Price.TaxAmount.BookingCurrencyPrice;
                  let getChildcount = e.PassengerQuantity;

                  childPriceDetails = { 
                      baseprice: parseFloat(getChildbase).toFixed(2), 
                      taxprice: parseFloat(getChildtax).toFixed(2),
                      childcount: getChildcount,
                      pax_type: "Child"
                    }                  
                  // console.log(childPriceDetails,'childPriceDetailschildPriceDetails...c');
                }

                if (e.PassengerType === 'INF' || e.PassengerType === 'JNF') {
                  let getInfbase = e.FareDetail[e.FareDetail.length -1].Price.BaseAmount.BookingCurrencyPrice;
                  let getInftax = e.FareDetail[e.FareDetail.length -1].Price.TaxAmount.BookingCurrencyPrice;
                  let getInfcount = e.PassengerQuantity;

                  infPriceDetails = { 
                      baseprice: parseFloat(getInfbase).toFixed(2), 
                      taxprice: parseFloat(getInftax).toFixed(2),
                      infcount: getInfcount,
                      pax_type: "Inf(lap)"
                    }                  
                  // console.log(infPriceDetails,'infPriceDetailsinfPriceDetails...i');
                }
              });


              combinedPricedetail.push({adultPriceDetails,childPriceDetails,infPriceDetails});



              s.OfferItem.forEach((v) => {
                // console.log(v, "kannannaaannnanannananannanan");
                resultdata1 = v[0].FareComponent[0].SegmentRefs;
                resultdata = v[0].FareComponent[1].SegmentRefs;

                // console.log(v[0].FareDetail[0].Price.NonRefundable, "kannannaaannnanannananannanan");

                if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
                  refund.push("Refundable");
                } else {
                  refund.push("Non Refundable");
                }
              });
              arrsplit.push(resultdata.split(" "));
              depsplit.push(resultdata1.split(" "));


              s.OfferItem.forEach((t) => {
                t.forEach((e) => {
                  if (e.PassengerType === "ADT") {
                    adultcount.push(e.PassengerQuantity);
                  }
                
                  if(e.PassengerType == "CNN"){
                    childcount.push(e.PassengerQuantity);
                  }
                
                  if(e.PassengerType == "INF"){
                    infantcount.push(e.PassengerQuantity);
                  }
                });
              });

            });

            console.log($data,"datadatadatadatadatadata...2");

            // console.log(refund, 'ppppppppppppppppppppppppppppppppp')
            this.Journey1 = v.DataLists.FlightSegmentList.FlightSegment;
            // console.log( this.Journey1,'flight1')
            this.Journey.forEach((a) => {
              this.routtime.push(a.Journey);
              // console.log(a,'thesplitvalueaaaaaaaaaaaaaa')
              this.flightsegmet.push(a.SegmentReferences.toString().split(" "));
              // console.log(this.flightsegmet ,'thesplitvalue')
            });
            this.Journey1.forEach((t) => {
              this.flightsegmetkey.push(t);
              // console.log(this.flightsegmetkey, "vasanthjourney");
            });

            // seats = v.DataLists.PriceClassList.PriceClass;
            // console.log(seats, "seatsseatsseats");
            // seats.forEach((c) => {
            //   c.ClassOfService.forEach((v) => {
            //     for (let i = 0; i < this.flightsegmetkey.length - 1; i++) {
            //       if (v.SegementRef == this.flightsegmetkey[i].SegmentKey) {
            //         this.flightsegmetkey[i].Seat = v.Code.SeatsLeft;
            //       }
            //     }
            //   });
            // });

            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              for (let j = 0; j < this.flightsegmetkey.length - 1; j++) {
                if (
                  this.flightsegmetkey[j].MarketingCarrier.AirlineID ==
                  this.airlogodata[i].id
                ) {
                  this.flightsegmetkey[j].MarketingCarrier.logo =
                    this.airlogodata[i].logo;
                }
              }
            }

            for (let i = 0; i <= depsplit.length - 1; i++) {
              // console.log(depsplit,'depsplit')
              let seg = [];
              depsplit[i].forEach((f, index) => {
                let items = [];
                items = this.flightsegmetkey.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == depsplit[i].length - 1) {
                  this.rountresult.push({ Depature: seg });
                }
              });
            }

            for (let i = 0; i <= arrsplit.length - 1; i++) {
              // console.log(arrsplit,'arrsplit')
              let seg = [];
              arrsplit[i].forEach((f, index) => {
                this.items = this.flightsegmetkey.filter(
                  (v) => v.SegmentKey == f
                );
                if (this.items.length > 0) {
                  seg.push(this.items[0]);
                }
                if (index == arrsplit[i].length - 1) {
                  this.returnData.push(seg);
                }
              });
            }
            let Arrjourney = [];
            let depjourney = [];
            let depBaggage = [];
            let arrBaggage = [];
            for (let j = 0; j <= $data.length - 1; j++) {
              let result = $data[j].OfferItem[0][0].FareComponent[0];
              if($data[j].OfferItem[0][0].BaggageAllowance.length > 0){
                depBaggage.push(
                  $data[j].OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                );
                arrBaggage.push(
                  $data[j].OfferItem[0][0].BaggageAllowance[1].BaggageAllowanceRef
                );
              }
              let result1 = $data[j].OfferItem[0][0].FareComponent[1];
              let source = [];
              let source1 = [];
              for (let i = 0; i <= this.Journey.length - 1; i++) {
                if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
                  source = this.Journey[i].Journey;
                } else if (
                  result1.SegmentRefs == this.Journey[i].SegmentReferences
                ) {
                  source1 = this.Journey[i].Journey;
                }
              }
              Arrjourney.push(source);
              depjourney.push(source1);
            }
            let BaggageData = [];
            let BaggageData1 = [];
            depBaggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData.push(c);
                }
              });
            });
            arrBaggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData1.push(c);
                }
              });
            });
            // for (let j = 0; j <= $data.length - 1; j++) {
            //   let result = $data[j].OfferItem[0][0].FareComponent[0];
            //   let result1 = $data[j].OfferItem[0][0].FareComponent[1];
            //   let source = [];
            //   let source1 = [];
            //   for (let i = 0; i <= this.Journey.length - 1; i++) {
            //     if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
            //       source = this.Journey[i].Journey;
            //     } else if (
            //       result1.SegmentRefs == this.Journey[i].SegmentReferences
            //     ) {
            //       source1 = this.Journey[i].Journey;
            //     }
            //   }
            //   Arrjourney.push(source);
            //   depjourney.push(source1);
            // }
            for (let i = 0; i < perperson.length; i++) {
              let nume1 = perperson[i];
              // console.log(nume1, "llldlldldldldld")
              this.find1.push((Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[0]);
              this.find2.push((Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[1]);
            }
            // console.log(this.find1, "this.find1this.find1");
            for (let i = 0; i <= this.rountresult.length - 1; i++) {
              this.rountresult[i].Baseprice = Baseprice[i];
              this.rountresult[i].TotalPrice = TotalPrice[i];
              this.rountresult[i].Taxprice = Tax[i];
              // this.rountresult[i].pricedetail = pricedetail[i];
              this.rountresult[i].Return = this.returnData[i];
              this.rountresult[i].depBaggage = BaggageData[i];
              this.rountresult[i].arrBaggage = BaggageData1[i];
              this.rountresult[i].priceDetails = combinedPricedetail[i];
              this.rountresult[i].Flightreturn = false;
              this.rountresult[i].Flightdepature = false;
              this.rountresult[i].hoveredName1 = false;
              this.rountresult[i].hoveredName2 = false;
              this.rountresult[i].hoveredName3 = false;
              this.rountresult[i].hoveredName4 = false;
              this.rountresult[i].Refund = refund[i];
              this.rountresult[i].currency = currency[i];
              this.rountresult[i].ownerlogo = ownerlogo[i];
              this.rountresult[i].ownername = ownername[i];
              this.rountresult[i].Returnjourney = depjourney[i];
              this.rountresult[i].Depaturejourney = Arrjourney[i];
              this.rountresult[i].perperson = perperson[i];
              this.rountresult[i].cabinClass = this.oneway.class;
              this.rountresult[i].find1 = this.find1[i];
              this.rountresult[i].find2 = this.find2[i];
              this.rountresult[i].ShoppingResponseId = shoppingId;
              this.rountresult[i].selectId = offerId[i];
              this.rountresult[i].Adult = adultcount[i];
              this.rountresult[i].Child = childcount[i];
              this.rountresult[i].Inf = infantcount[i];
              this.rountresult[i].fromCityName = this.tripData.from;
              this.rountresult[i].toCityName = this.tripData.to;
            }

            console.log(this.rountresult, "resulppppppppppppppppppppppppt");

            this.pageError = !this.rountresult || this.rountresult == '' ? true : false;

            this.gettimedep();
            this.gettimeret();

            this.filterData = this.rountresult;
            this.rounddatafil = true;

            this.priceRange();
            this.filterOutBound();
            this.getAiportFilter();
            this.airlineFilter();

            if (this.rountresult) {
              this.searchresult = true;
              this.searchloader = false;

              document.getElementById("navbar").style.display = "block";
              document.getElementById("food").style.display = "block";
            }
            // console.log(this.rountresult, "resulppppppppppppppppppppppppt");

            // this.filterShowing();

            // console.log(this.rountresult, 'result222222222222222222222222')

            this.rountresult = totalPricefilter.sortBy(this.filterData, [
              "TotalAmount",
            ]);
          });
        }
      }
      this.passengerData();
    },
    //     getper(res) {
    //         console.log(res.perperson, 'priceperson');
    //     let date = res.perperson.toString();
    //     let convertedDate = date.replace(".", "<sub>") + "</sub>";

    //     getPriceperson($event) {
    //   let nume1 = $event;
    //   console.log(nume1,"llldlldldldldld")
    //   this.find1 = (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[0];
    //   this.find2 = (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[1];
    // },

    gettimedep() {
      for (let i = 0; i < this.rountresult.length; i++) {
        // console.log(this.rountresult, "result222222222222222222222222");
        for (let j = 0; j < this.rountresult[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.rountresult[i].Depature.length;

          const time1 = new Date(
            `${this.rountresult[i].Depature[currentIndex].Arrival.Date}T${this.rountresult[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.rountresult[i].Depature[nextIndex].Departure.Date}T${this.rountresult[i].Depature[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.rountresult[i].Depature[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },

    gettimeret() {
      for (let i = 0; i < this.rountresult.length; i++) {
        // console.log(this.rountresult, "result222222222222222222222222");
        for (let j = 0; j < this.rountresult[i].Return.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.rountresult[i].Return.length;

          const time1 = new Date(
            `${this.rountresult[i].Return[currentIndex].Arrival.Date}T${this.rountresult[i].Return[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.rountresult[i].Return[nextIndex].Departure.Date}T${this.rountresult[i].Return[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.rountresult[i].Return[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },

    gettimedep1() {
      for (let i = 0; i < this.onewayresulted.length; i++) {
        for (let j = 0; j < this.onewayresulted[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.onewayresulted[i].Depature.length;

          const time1 = new Date(
            `${this.onewayresulted[i].Depature[currentIndex].Arrival.Date}T${this.onewayresulted[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.onewayresulted[i].Depature[nextIndex].Departure.Date}T${this.onewayresulted[i].Depature[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.onewayresulted[i].Depature[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },

    getmultitime1() {
      for (let i = 0; i < this.multicityData.length; i++) {
        for (let j = 0; j < this.multicityData[i].Flight1.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.multicityData[i].Flight1.length;

          const time1 = new Date(
            `${this.multicityData[i].Flight1[currentIndex].Arrival.Date}T${this.multicityData[i].Flight1[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.multicityData[i].Flight1[nextIndex].Departure.Date}T${this.multicityData[i].Flight1[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.multicityData[i].Flight1[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }

        for (let j = 0; j < this.multicityData[i].Flight2.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.multicityData[i].Flight2.length;

          const time1 = new Date(
            `${this.multicityData[i].Flight2[currentIndex].Arrival.Date}T${this.multicityData[i].Flight2[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.multicityData[i].Flight2[nextIndex].Departure.Date}T${this.multicityData[i].Flight2[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.multicityData[i].Flight2[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }

        if (this.multicityData[i].Flight3) {
          for (let j = 0; j < this.multicityData[i].Flight3.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight3.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight3[currentIndex].Arrival.Date}T${this.multicityData[i].Flight3[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight3[nextIndex].Departure.Date}T${this.multicityData[i].Flight3[nextIndex].Departure.Time}`
            );

            const diffInMilliseconds = Math.abs(time2 - time1);
            const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

            const hours = Math.floor(diffInMinutes / 60);
            const minutes = diffInMinutes % 60;

            this.multicityData[i].Flight3[currentIndex].layoverTimes = `${hours
              .toString()
              .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
          }
        }

        if (this.multicityData[i].Flight4) {
          for (let j = 0; j < this.multicityData[i].Flight4.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight4.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight4[currentIndex].Arrival.Date}T${this.multicityData[i].Flight4[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight4[nextIndex].Departure.Date}T${this.multicityData[i].Flight4[nextIndex].Departure.Time}`
            );

            const diffInMilliseconds = Math.abs(time2 - time1);
            const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

            const hours = Math.floor(diffInMinutes / 60);
            const minutes = diffInMinutes % 60;

            this.multicityData[i].Flight4[currentIndex].layoverTimes = `${hours
              .toString()
              .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
          }
        }
      }
    },

    priceRange() {
      let min = Infinity;
      let max = -Infinity;

      for (let i = 0; i < this.filterData.length; i++) {
        const totalAmount = parseFloat(this.filterData[i].perperson);
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      for (let i = 0; i < this.onewayfilterData.length; i++) {
        const totalAmount = parseFloat(this.onewayfilterData[i].perperson);
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      for (let i = 0; i < this.multiFilterData.length; i++) {
        const totalAmount = parseFloat(this.multiFilterData[i].perperson);

        console.log(totalAmount, "tatatatat,,,.....");
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      this.minimum = min.toFixed(2);
      this.maximum = max.toFixed(2);
      this.steps = ((max - min) / 50).toFixed(2);
      this.changevalue[0] = min.toFixed(2);
      this.changevalue[1] = max.toFixed(2);
      console.log(this.changevalue,'totalAmount')

      this.initialChangeValue = this.changevalue

      this.currencyCode = this.preferCurrency;
    },
    filterOutBound(){
      // console.log(data,'outBoundTime')
      let outBoundTime1 = []
      let inBoundTime1 = []
      if(this.rountresult){
      this.rountresult.forEach((v) => {
        if(v.Depature){
           let result = v.Depaturejourney.Time.split(" ");
            let hours = parseInt(result[0]); 
            let minutes = parseInt(result[2]); 

            let roundedHours = Math.round(hours + minutes / 60);
                      outBoundTime1.push(roundedHours + "Hour")
          }
        if(v.Return){
          this.isReturn = true;
            let result1 = v.Returnjourney.Time.split(" ");
            let hours = parseInt(result1[0]); 
            let minutes = parseInt(result1[2]); 

            let roundedHours = Math.round(hours + minutes / 60);
                      inBoundTime1.push(roundedHours + "Hour")
        }
        });
      }
      if(this.onewayresulted) {
        this.onewayresulted.forEach((v) => {
          // console.log(this.onewayresulted,'onewayyyy')
        if(v.Depature){

            let result = v.Depaturejourney.Time.split(" ");
            let hours = parseInt(result[0]); 
            let minutes = parseInt(result[2]); 

            let roundedHours = Math.round(hours + minutes / 60);
                      outBoundTime1.push(roundedHours + "Hour")
        }
        });
      }

        let hours = [...new Set(outBoundTime1)];
        // console.log(hours,'hours')
          let numbers = hours.map(str => parseInt(str));

          let minHour = Math.min(...numbers);
          let maxHour = Math.max(...numbers);
          this.defaultOutBoundTime = `${maxHour} hour`
          this.outBound = `${maxHour} hour`
          for (let i = minHour; i <= maxHour; i++) {
              this.outBoundTime.push(`${i} hour`);
          }
          // console.log(this.outBoundTime,'this.outBoundTimethis.outBoundTime')

          let hours1 = [...new Set(inBoundTime1)];
          let numbers1 = hours1.map(str => parseInt(str));

          let minHour1 = Math.min(...numbers1);
          let maxHour1 = Math.max(...numbers1);
          this.defaultInBoundTime = `${maxHour1} hour`
          this.inBound = `${maxHour1} hour`
          for (let i = minHour1; i <= maxHour1; i++) {
              this.inBoundTime.push(`${i} hour`);
          }
          // console.log(this.inBoundTime,'this.outBoundTimethis.outBoundTime')
    },
    filterOutBoundMulti(){
      let outBoundTime1 = []
      this.multicityData.forEach((v) => {
       for (let i = 1; i <= 4; i++) {
        if (v[`Flight${i}`]) {
          let result = v[`Flight${i}JourneyTime`].Time.split("H");
          let hours = parseInt(result[0]); 
          let minutes = parseInt(result[1]); 
          let roundedHours = Math.round(hours + minutes / 60);
          outBoundTime1.push(roundedHours + "Hour");
        }
       }
       })
        let hours = [...new Set(outBoundTime1)];
        // console.log(hours,'hours')
          let numbers = hours.map(str => parseInt(str));

          let minHour = Math.min(...numbers);
          let maxHour = Math.max(...numbers);
          this.defaultOutBoundMulti = `${maxHour} hour`
          this.outBoundMulti = `${maxHour} hour`
          for (let i = minHour; i <= maxHour; i++) {
              this.outBoundTimeMulti.push(`${i} hour`);
          }
          // console.log(this.outBoundTimeMulti,'outBoundoutBound')
    },

    getAiportFilter() {
  if (this.rountresult.length > 0) {
    console.log('yessss1')
    let fromAirportsSet = new Set();
    let toAirportsSet = new Set();

    this.filterData.forEach(data => {
      fromAirportsSet.add(data.Depature[0].Departure.AirportName);
      toAirportsSet.add(data.Depature[data.Depature.length - 1].Arrival.AirportName);
    });

    this.fromAirportName = Array.from(fromAirportsSet);
    this.toAirportName = Array.from(toAirportsSet);
  } 
  console.log(this.onewayresulted,'onewayyyyyyyy')
  if (this.onewayresulted.length > 0) {
    console.log('yessss')
    let fromAirportsSet = new Set();

    this.onewayfilterData.forEach(data => {
      fromAirportsSet.add(data.Depature[0].Departure.AirportName);
    });

    this.fromAirportName = Array.from(fromAirportsSet);
  }
},

getAiportFilterMulti() {
    let flight1From = new Set();
    let flight1To = new Set();
    let flight2From = new Set();
    let flight2To = new Set();
    let flight3From = new Set();
    let flight3To = new Set();
    let flight4From = new Set();
    let flight4To = new Set();

    this.multiFilterData.forEach(data => {
      flight1From.add(data.Flight1[0].Departure.AirportName);
      flight1To.add(data.Flight1[data.Flight1.length - 1].Arrival.AirportName);
      flight2From.add(data.Flight2[0].Departure.AirportName);
      flight2To.add(data.Flight2[data.Flight2.length - 1].Arrival.AirportName);
      
      if (data.Flight3) {
        flight3From.add(data.Flight3[0].Departure.AirportName);
        flight3To.add(data.Flight3[data.Flight3.length - 1].Arrival.AirportName);
      }
      
      if (data.Flight4) {
        flight4From.add(data.Flight4[0].Departure.AirportName);
        flight4To.add(data.Flight4[data.Flight4.length - 1].Arrival.AirportName);
      }
    });

    this.multiAirport = [
      {
        from: Array.from(flight1From) || [],
        to: Array.from(flight1To) || []
      },
      {
        from: Array.from(flight2From) || [],
        to: Array.from(flight2To) || []
      },
      {
        from: Array.from(flight3From) || [],
        to: Array.from(flight3To) || []
      },
      {
        from: Array.from(flight4From) || [],
        to: Array.from(flight4To) || []
      }
    ];
    console.log(this.multiAirport,'multiAirportmultiAirport')
},


    

    // Filter initailly Working ------->

    filterShowing() {
      let filterStore = [];
      // filterStore = this.multiFilterData

      filterStore = this.filterData;

      console.log(filterStore, "fsfsfsfsfs....1...1...1");

      filterStore.forEach((v) => {
        if (v.Depaturejourney.Stops !== 0 && v.Returnjourney.Stops !== 0) {
          this.showNonStop = false;
        } else {
          this.showNonStop = true;
        }
      });

      filterStore.forEach((v) => {
        if (v.Depaturejourney.Stops == 1 && v.Returnjourney.Stops == 1) {
          this.showOneStop = true;
        } else {
          this.showOneStop = false;
        }
      });

      filterStore.forEach((v) => {
        if (v.Depaturejourney.Stops > 1 && v.Returnjourney.Stops > 1) {
          this.showOneplusStop = true;
        } else {
          this.showOneplusStop = false;
        }
      });

      if(this.onewayfilterData){
        let filterStoreData = []
        filterStoreData = this.onewayfilterData;
         filterStoreData.forEach((v) => {
        if (v.Depaturejourney.Stops !== 0) {
          this.showNonStop = false;
        } else {
          this.showNonStop = true;
        }
      });

      filterStoreData.forEach((v) => {
        if (v.Depaturejourney.Stops == 1) {
          this.showOneStop = true;
        } else {
          this.showOneStop = false;
        }
      });

      filterStoreData.forEach((v) => {
        if (v.Depaturejourney.Stops > 1) {
          this.showOneplusStop = true;
        } else {
          this.showOneplusStop = false;
        }
      });
      }
      if(this.multiFilterData){
        let filterStoreData1 = []
        filterStoreData1 = this.multiFilterData;
        filterStoreData1.forEach((v) => {
        if (v.Flight1JourneyTime.Stops !== 0 && v.Flight2JourneyTime.Stops !== 0 && v.Flight3JourneyTime.Stops !== 0 && v.Flight4JourneyTime.Stops !== 0) {
          this.showNonStop = false;
        } else {
          this.showNonStop = true;
        }
      });

      filterStoreData1.forEach((v) => {
        if (v.Flight1JourneyTime.Stops == 1 && v.Flight2JourneyTime.Stops == 1 && v.Flight3JourneyTime.Stops == 1 && v.Flight4JourneyTime.Stops == 1) {
          this.showOneStop = true;
        } else {
          this.showOneStop = false;
        }
      });

      filterStoreData1.forEach((v) => {
        if (v.Flight1JourneyTime.Stops > 1 && v.Flight2JourneyTime.Stops > 1 && v.Flight3JourneyTime.Stops > 1 && v.Flight4JourneyTime.Stops > 1) {
          this.showOneplusStop = true;
        } else {
          this.showOneplusStop = false;
        }
      });
      }
    },

    onewaytrip() {
      let onewayresult = [];
      onewayresult = JSON.parse(localStorage.getItem("onewaytrip"));
      console.log(onewayresult,'onewayResultedoneway')
      let baggageList = [];
      if (onewayresult) {
        for (let i = 0; i < onewayresult.length; i++) {
          if (onewayresult[i]) {
            this.Journey = onewayresult[i].DataLists.FlightList.Flight;
            baggageList =
              onewayresult[i].DataLists.BaggageAllowanceList.BaggageAllowance;
            let shoppingId = onewayresult[i].ShoppingResponseId;
            let refund = [];
            let Baseprice = [];
            let Tax = [];
            let pricedetail = [];
            let $data = onewayresult[i].OffersGroup.AirlineOffers.Offer;

            let $data_onewayId =
              onewayresult[i].OffersGroup.AirlineOffers.Offer;

            // console.log($data_onewayId,'onewaaayyyyyyy....1...')

            let offerId = [];
            let depsplit = [];
            let resultdata1 = [];
            let currency = [];
            let ownerlogo = [];
            let ownername = [];
            let perperson = [];
            let TotalPrice = [];
            let adultcount = [];
            let childcount = [];
            let infantcount = [];
            let combinedPricedetail = [];

            $data_onewayId.forEach((v) => {
              offerId.push(v.OfferID);
            });

            $data.forEach((s) => {
              if (s.ReqCurrency == "USD") {
                currency.push("US$");
              } else if (s.ReqCurrency == "CAD") {
                currency.push("CA$");
              } else {
                currency.push("US$");
              }
              ownername.push(s.OwnerName);
              for (let i = 0; i < this.airline_logoData.length - 1; i++) {
                if (s.Owner == this.airline_logoData[i].id) {
                  ownerlogo.push(this.airline_logoData[i].logo);
                }
              }

              Baseprice.push(
                parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
              );
              Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
              perperson.push(
                parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
              );
              TotalPrice.push(
                parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
              );

              pricedetail.push(s.OfferItem);

              s.OfferItem.forEach((v) => {
                if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
                  refund.push("Refundable");
                } else {
                  refund.push("Non Refundable");
                }

                if (v[0].FareComponent[0].SegmentRefs) {
                  resultdata1 = v[0].FareComponent[0].SegmentRefs;
                }
              });

              depsplit.push(resultdata1.split(" "));


              s.OfferItem.forEach((t) => {
                t.forEach((e) => {
                  if (e.PassengerType === "ADT") {
                    adultcount.push(e.PassengerQuantity);
                  }
                
                  if(e.PassengerType == "CNN"){
                    childcount.push(e.PassengerQuantity);
                  }
                
                  if(e.PassengerType == "INF"){
                    infantcount.push(e.PassengerQuantity);
                  }
                });

            // console.log(t, 'onewayzxczcddasdasdasd')

            let adultPriceDetails = {};
              let childPriceDetails = {};
              let infPriceDetails = {};

              s.OfferItem[0].forEach((e) => {
                if (e.PassengerType === 'ADT' || e.PassengerType === 'JCB') {
                  let getAdultbase = e.FareDetail[e.FareDetail.length -1].Price.BaseAmount.BookingCurrencyPrice;
                  let getAdulttax = e.FareDetail[e.FareDetail.length -1].Price.TaxAmount.BookingCurrencyPrice;
                  let getAdultcount = e.PassengerQuantity;

                  adultPriceDetails = { 
                      baseprice: parseFloat(getAdultbase).toFixed(2), 
                      taxprice:  parseFloat(getAdulttax).toFixed(2),
                      adultcount: getAdultcount,
                      pax_type: "Adult"
                    }                  
                  // console.log(adultPriceDetails,'adultPriceDetailsadultPriceDetails...a');
                }

                if (e.PassengerType === 'CNN' || e.PassengerType === 'JNN') {
                  let getChildbase = e.FareDetail[e.FareDetail.length -1].Price.BaseAmount.BookingCurrencyPrice;
                  let getChildtax = e.FareDetail[e.FareDetail.length -1].Price.TaxAmount.BookingCurrencyPrice;
                  let getChildcount = e.PassengerQuantity;

                  childPriceDetails = { 
                      baseprice: parseFloat(getChildbase).toFixed(2), 
                      taxprice: parseFloat(getChildtax).toFixed(2),
                      childcount: getChildcount,
                      pax_type: "Child"
                    }                  
                  // console.log(childPriceDetails,'childPriceDetailschildPriceDetails...c');
                }

                if (e.PassengerType === 'INF' || e.PassengerType === 'JNF') {
                  let getInfbase = e.FareDetail[e.FareDetail.length -1].Price.BaseAmount.BookingCurrencyPrice;
                  let getInftax = e.FareDetail[e.FareDetail.length -1].Price.TaxAmount.BookingCurrencyPrice;
                  let getInfcount = e.PassengerQuantity;

                  infPriceDetails = { 
                      baseprice: parseFloat(getInfbase).toFixed(2), 
                      taxprice: parseFloat(getInftax).toFixed(2),
                      infcount: getInfcount,
                      pax_type: "Inf(lap)"
                    }                  
                  // console.log(infPriceDetails,'infPriceDetailsinfPriceDetails...i');
                }
              });


              combinedPricedetail.push({adultPriceDetails,childPriceDetails,infPriceDetails});

              });

              
            });

            this.Journey1 =
              onewayresult[i].DataLists.FlightSegmentList.FlightSegment;
            // console.log( this.Journey1,'flight1')Seatsseats
            this.Journey.forEach((a) => {
              this.routtime.push(a.Journey);
              this.flightsegmet.push(a.SegmentReferences.split(" "));
            });
            this.Journey1.forEach((t) => {
              this.flightsegmetkey.push(t);
            });
            // let seats = [];
            // seats = onewayresult[i].DataLists.PriceClassList.PriceClass;
            // seats.forEach((c) => {
            //   c.ClassOfService.forEach((v) => {
            //     for (let i = 0; i < this.flightsegmetkey.length - 1; i++) {
            //       if (v.SegementRef == this.flightsegmetkey[i].SegmentKey) {
            //         this.flightsegmetkey[i].Seat = v.Code.SeatsLeft;
            //       }
            //     }
            //   });
            // });

            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              for (let j = 0; j < this.flightsegmetkey.length - 1; j++) {
                if (
                  this.flightsegmetkey[j].MarketingCarrier.AirlineID ==
                  this.airlogodata[i].id
                ) {
                  this.flightsegmetkey[j].MarketingCarrier.logo =
                    this.airlogodata[i].logo;
                }
              }
            }

            for (let i = 0; i <= depsplit.length - 1; i++) {
              let seg = [];
              depsplit[i].forEach((f, index) => {
                let items = [];
                items = this.flightsegmetkey.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == depsplit[i].length - 1) {
                  this.onewayresulted.push({ Depature: seg });
                }
              });
            }

            let depjourney = [];
            let Baggage = [];
            for (let j = 0; j <= $data.length - 1; j++) {
              let result = $data[j].OfferItem[0][0].FareComponent[0];
              if($data[j].OfferItem[0][0].BaggageAllowance.length > 0){
                Baggage.push(
                  $data[j].OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                );
              }
              let source = [];

              for (let i = 0; i <= this.Journey.length - 1; i++) {
                if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
                  source = this.Journey[i].Journey;
                }
              }

              depjourney.push(source);
            }
            let BaggageData = [];

            Baggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData.push(c);
                }
              });
            });

            for (let i = 0; i < perperson.length; i++) {
              let nume1 = perperson[i];
              // console.log(nume1, "llldlldldldldld")
              this.find1.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[0]
              );
              this.find2.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[1]
              );
            }

            for (let i = 0; i < this.onewayresulted.length; i++) {
              this.onewayresulted[i].Baseprice = Baseprice[i];
              this.onewayresulted[i].TotalPrice = TotalPrice[i];
              this.onewayresulted[i].Taxprice = Tax[i];
              // this.onewayresulted[i].pricedetail = pricedetail[i];
              this.onewayresulted[i].priceDetails = combinedPricedetail[i];
              this.onewayresulted[i].Flightreturn = false;
              this.onewayresulted[i].Flightdepature = false;
              this.onewayresulted[i].hoveredName1 = false;
              this.onewayresulted[i].hoveredName2 = false;
              this.onewayresulted[i].hoveredName3 = false;
              this.onewayresulted[i].hoveredName4 = false;
              this.onewayresulted[i].BaggageData = BaggageData[i];
              this.onewayresulted[i].Refund = refund[i];
              this.onewayresulted[i].currency = currency[i];
              this.onewayresulted[i].ownerlogo = ownerlogo[i];
              this.onewayresulted[i].ownername = ownername[i];
              this.onewayresulted[i].Depaturejourney = depjourney[i];
              this.onewayresulted[i].perperson = perperson[i];
              this.onewayresulted[i].cabinClass = this.oneway.class;
              this.onewayresulted[i].find1 = this.find1[i];
              this.onewayresulted[i].find2 = this.find2[i];
              this.onewayresulted[i].ShoppingResponseId = shoppingId;
              this.onewayresulted[i].selectId = offerId[i];
              this.onewayresulted[i].Adult = adultcount[i];
              this.onewayresulted[i].Child = childcount[i];
              this.onewayresulted[i].Inf = infantcount[i];
              this.onewayresulted[i].fromCity = this.oneway.from;
              this.onewayresulted[i].toCity = this.oneway.to;
            }

            console.log(this.onewayresulted, "oneway.....w...w..w..w");

            this.pageError = !this.onewayresulted || this.onewayresulted == '' ? true : false;

            //  this.onewayfilterData=this.onewayresulted
            this.gettimedep1();
            this.gettimeret();
            this.onewayfilterData = this.onewayresulted;
            this.onedatafil = true;
            this.priceRange();
            this.filterOutBound();
            this.getAiportFilter();
            this.airlineFilter();
            if (this.onewayfilterData) {
              this.searchresult = true;
              this.searchloader = false;

              document.getElementById("navbar").style.display = "block";
              document.getElementById("food").style.display = "block";
            }

            this.onewayresulted = totalPricefilter.sortBy(
              this.onewayfilterData,
              ["TotalAmount"]
            );

            // console.log(this.onewayresulted, "onewayresulted");
          }
        }
         this.passengerData();
      }
    },

     recentFromData() {
      let result1 = JSON.parse(localStorage.getItem("recentData")) || [];
      this.RecentSearchData = result1.slice(0,15)
    },
    handleMultiSearch(newMultiData) {
      let storedRecentFrom = JSON.parse(localStorage.getItem("recentData")) || [];

      storedRecentFrom = storedRecentFrom.filter(existingItem => {
          const existingItinery = existingItem.itinery || [];

          const isDuplicate = newMultiData.itinery.every(newItin => 
              existingItinery.some(existingItin =>
                  newItin.from === existingItin.from &&
                  newItin.to === existingItin.to &&
                  newItin.depdate === existingItin.depdate
              )
          );

          const isAdditionalPropsMatch = (
              existingItem.adult === newMultiData.adult &&
              existingItem.child === newMultiData.child &&
              existingItem.infant === newMultiData.infant &&
              existingItem.class === newMultiData.class &&
              existingItem.tripType === newMultiData.tripType
          );

          return !(isDuplicate && isAdditionalPropsMatch);
      });

      storedRecentFrom.unshift(newMultiData);

      // localStorage.setItem("recentFrom", JSON.stringify(storedRecentFrom));
      return storedRecentFrom;
    },


     handleRecentSearch(data) {
        let recentSearchObj;

        if (data) {
          recentSearchObj = {
            from: data.from.name ? data.from.name : data.from,
            to: data.to.name ? data.to.name : data.to,
            fromDate: data.fromDate || '',
            toDate: data.toDate ? data.toDate : null,
            adult: data.adult || 0,
            child: data.child || 0,
            inf: data.inf || 0,
            tripType: data.tripType || '',
            class: data.class || ''
          };
          console.log('Using provided data:', recentSearchObj);
        } else {
          recentSearchObj = {
            from: this.oneway.from.name ? this.oneway.from.name : this.oneway.from,
            to: this.oneway.to.name ? this.oneway.to.name : this.oneway.to,
            fromDate: this.oneway.dedate || '',
            toDate: this.oneway.redate ? this.oneway.redate : null,
            adult: this.adultValue || 0,
            child: this.childValue || 0,
            inf: this.infantValue || 0,
            tripType: this.activetrip || '',
            class: this.classType || ''
          };
          console.log('Using current state values:', recentSearchObj);
        }

        let storedRecentFrom = JSON.parse(localStorage.getItem("recentData")) || [];
        console.log('Stored recent searches:', storedRecentFrom);

        storedRecentFrom = storedRecentFrom.filter((search) => {
          const searchFromDate = new Date(search.fromDate).toDateString();
          const searchToDate = new Date(search.toDate).toDateString();
          const recentFromDate = new Date(recentSearchObj.fromDate).toDateString();
          const recentToDate = new Date(recentSearchObj.toDate).toDateString();

          return !(
            search.from === recentSearchObj.from &&
            search.to === recentSearchObj.to &&
            searchFromDate === recentFromDate &&
            searchToDate === recentToDate &&
            search.adult === recentSearchObj.adult &&
            search.child === recentSearchObj.child &&
            search.inf === recentSearchObj.inf &&
            search.tripType === recentSearchObj.tripType &&
            search.class === recentSearchObj.class
          );
        });

        storedRecentFrom.unshift(recentSearchObj);
        console.log('Updated search list:', storedRecentFrom);

        // localStorage.setItem("recentFrom", JSON.stringify(storedRecentFrom));
        return storedRecentFrom;
      },

    

    sended() {
      this.onewayfilterData = [];
      this.rountresult = [];

      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");

      if (this.oneway.from && this.oneway.to && this.oneway.dedate && (this.oneway.redate || !this.oneway.redate) && (this.oneway.from !== this.oneway.to)) {

        this.oneway.tripType = this.activetrip;
        this.oneway.adult = this.adultValue;
        this.oneway.child = this.childValue;
        this.oneway.infant = this.infantValue;
        this.oneway.class = this.classType;

        
        this.searchCase = JSON.stringify(this.oneway);

        let getfrom = ""
        if (this.oneway.from.name) {
          getfrom = this.oneway.from.name.split(" ");
          this.roundfrom = getfrom[getfrom.length - 1].replace("(", "").replace(")", "");
        } 
        else {
          getfrom = this.oneway.from.split(" ");
          this.roundfrom = getfrom[getfrom.length - 1].replace("(", "").replace(")", "");
        } 
  
        let getto = ""
        if (this.oneway.to.name) {
          getto = this.oneway.to.name.split(" ");
          this.roundto = getto[getto.length - 1].replace("(", "").replace(")", "");
        } 
        else {
          getto = this.oneway.to.split(" ");
          this.roundto = getto[getto.length - 1].replace("(", "").replace(")", "");
        }

        this.urldedate = moment(this.oneway.dedate).format("YYYY/MM/DD");
        this.urlredate = moment(this.oneway.redate).format("YYYY/MM/DD");

        let getUrlFormat = "";
        let getTriptype = "";
        if(this.activetrip == "Roundtrip"){
          getUrlFormat = `${this.roundfrom}-${this.roundto}-${this.urldedate}_${this.roundto}-${this.roundfrom}-${this.urlredate}`;
          getTriptype = "RT";
        }
        else{
          getUrlFormat = `${this.roundfrom}-${this.roundto}-${this.urldedate}`;
          getTriptype = "OW"
        }
        
        let [Adt, Chd, Inf] = [this.oneway.adult,this.oneway.child,this.oneway.infant];
        let getPaxtype = `A-${Adt}_C-${Chd}_I-${Inf}`;

        console.log(getPaxtype,'asdsfasdasdaeeqq');
        
        let getLangual = this.$i18n.locale.split('-')[1];

        console.log(getLangual,'dsdassagetLangualgetLangual')
        

        let rountdetail = {
          itinerary: getUrlFormat,
          tripType: getTriptype,
          paxType: getPaxtype,
          cabin: this.classType,
          language: getLangual,
        };

        setTimeout(() => {
          location.reload();
        }, 500);

        console.log(this.roundfrom,'roundfromroundfromroundfrom',this.roundto);
        console.log(rountdetail, "rountdetailrountdetail");

        const resultData = this.handleRecentSearch()

        localStorage.setItem('recentData',JSON.stringify(resultData))

        this.$router.push({ path: "/flight/search", query: rountdetail });
        } else {
          console.log(this.fromErrorMessage,'validatecheckworking.....5');
          // alert("please enter your detail");
          this.validateOnewayForm();
        }
    },

    async sendedRoundData() {
      localStorage.removeItem("onewaytrip");
      localStorage.removeItem("rounttrip");
      this.onewayresulted = [];

      this.oneway.tripType = this.activetrip;
      this.oneway.adult = this.adultValue;
      this.oneway.infant = this.infantValue;
      this.oneway.child = this.childValue;
      // this.oneway.class = this.select;

      let rountdetail = {
        from: null,
        to: null,
        departure: this.oneway.dedate,
        class: this.oneway.class,
        child: this.oneway.child,
        adult: this.oneway.adult,
        infant: this.oneway.infant,
        tripType: this.oneway.tripType,
      };

      if (
        this.oneway.from &&
        this.oneway.to &&
        this.oneway.dedate &&
        this.oneway.redate
      ) {
        let result1 = [];
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("searchedData", result1);
        
        this.loadSearch = true;
        this.disButton = true;
        let res = "";
        if (this.oneway.from.name) {
          rountdetail.from = this.oneway.from.name;
          res = this.oneway.from.name.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.from = this.oneway.from;
          res = this.oneway.from.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let res1 = "";
        if (this.oneway.to.name) {
          rountdetail.to = this.oneway.to.name;
          res1 = this.oneway.to.name.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.to = this.oneway.to;
          res1 = this.oneway.to.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let result =
          this.Rountdata.request.AirShoppingRQ.CoreQuery.OriginDestinations
            .OriginDestination;
        for (let i = 0; i <= result.length - 1; i++) {
          result[0].Departure.AirportCode = this.roundfrom;

          result[0].Departure.Date = moment(
            this.oneway.dedate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[0].Arrival.AirportCode = this.roundto;
          result[1].Departure.AirportCode = this.roundto;
          result[1].Departure.Date = moment(
            this.oneway.redate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[1].Arrival.AirportCode = this.roundfrom;
        }

        let cabin_type = this.oneway.class;
        if (cabin_type == "Economy") {
          cabin_type = "Y";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType = cabin_type;
        } else if (cabin_type == "Business") {
          cabin_type = "C";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType = cabin_type;
        } else if (cabin_type == "First Class") {
        cabin_type = "F";
        this.Rountdata.request.AirShoppingRQ.Preference.CabinType = cabin_type;
        } else if (cabin_type == "Premium Economy") {
          cabin_type = "S";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType = cabin_type;
        } else if (cabin_type == "Premium Business") {
          cabin_type = "J";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType = cabin_type;
        } else if (cabin_type == "Premium First") {
          cabin_type = "P";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType = cabin_type;
        }

        this.Rountdata.country = this.pointofSale;
        this.Rountdata.request.AirShoppingRQ.Preference.TripType = "ROUND";
        this.Rountdata.request.AirShoppingRQ.Preference.Currency = this.preferCurrency;
        this.Rountdata.request.AirShoppingRQ.MetaData.Currency = this.metaCurrency;
        
        console.log(this.oneway,'onewayoconsoleneway...1');
        if(this.oneway.advanceOptions){
          let getadvanceOpt = this.oneway.advanceOptions;
          this.Rountdata.request.AirShoppingRQ.Preference.DirectFlight = getadvanceOpt.directFlight;
          this.Rountdata.request.AirShoppingRQ.Preference.BaggageInclusive = getadvanceOpt.baggageInclusive;
          this.Rountdata.request.AirShoppingRQ.Preference.NearbyAirport = getadvanceOpt.nearbyAirport;
          this.Rountdata.request.AirShoppingRQ.Preference.Stops = getadvanceOpt.stopsSelect ? [getadvanceOpt.stopsSelect] : [];
          this.Rountdata.request.AirShoppingRQ.Preference.LayoverHours = getadvanceOpt.adlayoverTime;
          this.Rountdata.request.AirShoppingRQ.Preference.AvoidCountry = getadvanceOpt.avoidCountry;
          this.Rountdata.request.AirShoppingRQ.Preference.IncludedAirlines = (getadvanceOpt.includeAirline ?? []).map(airline => {
            const match = airline.match(/\(([^)]+)\)/);
            return match ? match[1] : airline;
          });
          this.Rountdata.request.AirShoppingRQ.Preference.ExcludedAirlines = (getadvanceOpt.excludeAirline ?? []).map(airline => {
            const match = airline.match(/\(([^)]+)\)/);
            return match ? match[1] : airline; 
          });
          this.Rountdata.request.AirShoppingRQ.Preference.IncludedViaStops = (getadvanceOpt.includeAirport ?? []).map(airport => {
              const match = airport.match(/\(([^)]+)\)/);
              return match ? match[1] : airport;
          });
          this.Rountdata.request.AirShoppingRQ.Preference.ExcludedViaStops = (getadvanceOpt.excludeAirport ?? []).map(airport => {
              const match = airport.match(/\(([^)]+)\)/);
              return match ? match[1] : airport;
          });
        }

        console.log(this.Rountdata,'preRountdataerCurrencRountdata');

        try {
        const response = await axios.post(this.makeSearchUrl, this.Rountdata, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.responseData.push(response.data);

            console.log(this.responseData,"thisresponseDatathisresponseData");

            let getRounddata = [];
            getRounddata = JSON.stringify(this.responseData);

            localStorage.setItem("rounttrip", getRounddata);

            this.localData.push(JSON.parse(getRounddata));

            if (getRounddata) {
              this.roundedtrip();
            }

            if (response.data.Errors =='Errors') {
              this.searchresult = false;
              this.searchloader = true;
              setTimeout(() => {
                this.pageError = true;
                this.searchresult = false;
              this.searchloader = false;
              }, 7000);
            }
      } 
      catch (error) {
          this.searchloader = true;
              setTimeout(() => {
                this.searchloader = false;
                this.searchresult = true;
                if(error.response && error.response.data.success == false){
                  this.dateError = error.response.data.message;
                }
                this.pageError = true;
              // this.$router.push("/pageError");
  
                }, 7000);

            console.log(error,'eroororororoowooe...round');
      }

        console.log(this.Rountdata,this.makeSearchUrl, "roundtdaaaaaaa");


        // axios
        //   .post(this.makeSearchUrl, this.Rountdata, {
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //   })

        //   .then((response) => {
        //     // console.log(response," this.responseData this.responseData this.responseData");

        //     this.responseData.push(response.data);

        //     console.log(this.responseData,"thisresponseDatathisresponseData");

        //     let getRounddata = [];
        //     getRounddata = JSON.stringify(this.responseData);

        //     localStorage.setItem("rounttrip", getRounddata);

        //     this.localData.push(JSON.parse(getRounddata));

        //     if (getRounddata) {
        //       this.roundedtrip();
        //     }

        //     if (response.data.Errors =='Errors') {
        //       this.searchresult = false;
        //       this.searchloader = true;
        //       setTimeout(() => {
        //         this.pageError = true;
        //         this.searchresult = false;
        //       this.searchloader = false;
        //       }, 7000);
        //     }
        //   })

        //   .catch((error) => {
        //     this.searchloader = true;
        //     setTimeout(() => {
        //       this.searchloader = false;
        //       this.searchresult = true;
        //       this.pageError = true;
        //     // this.$router.push("/pageError");

        //       }, 7000);

        //     console.log(error,'eroororororoowooe...round');

        //     // this.$router.push("/pageError");
        //   });
      } else {
        console.log(this.fromErrorMessage,'validatecheckworking.....6');
        // alert("please enter your detail");
        this.validateOnewayForm();
      }

      // console.log(this.responseData, "0077777777777");
    },

    async sendedone1() {
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");

      let resul = this.onewaydata.request.AirShoppingRQ.DataLists.PassengerList.Passenger;

      if (this.concatenatedArray) {
        this.concatenatedArray.forEach((v) => {
          resul.push(v);
        });
      }

      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        this.loadSearch = true;
        this.disButton = true;

        this.oneway.tripType = this.activetrip;
        this.oneway.adult = this.adultValue;
        this.oneway.infant = this.infantValue;
        this.oneway.child = this.childValue;
        this.oneway.redate = null;
        // this.oneway.class = this.select;
        // console.log(this.oneway, "this.onewaythis.onewaythis.oneway");
        let result1 = [];
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("searchedData", result1);

        let res = "";

        if (this.oneway.from.name) {
          res = this.oneway.from.name.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          res = this.oneway.from.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let res1 = "";
        if (this.oneway.to.name) {
          res1 = this.oneway.to.name.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          res1 = this.oneway.to.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        this.urldedate = moment(this.oneway.dedate, "DD MMM-YYYY").format(
          "YYYY-MM-DD"
        );
        // let rountdetail = {
        //   from: this.roundfrom,
        //   to: this.roundto,
        //   dedate: this.urldedate,
        //   redate: this.urlredate,
        //   class: this.oneway.class,
        //   child: this.oneway.child,
        //   adult: this.oneway.adult,
        //   infant: this.oneway.infant,
        //   city: this.oneway.tripType,
        // };

        let result =
          this.onewaydata.request.AirShoppingRQ.CoreQuery.OriginDestinations
            .OriginDestination;
        for (let i = 0; i <= result.length - 1; i++) {
          result[0].Departure.AirportCode = this.roundfrom;
          result[0].Departure.Date = moment(
            this.oneway.dedate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[0].Arrival.AirportCode = this.roundto;
          // result[1].Departure.AirportCode = this.roundto
          // result[1].Departure.Date = moment(this.oneway.redate).format('YYYY-MM-DD')
          // result[1].Arrival.AirportCode = this.roundfrom
        }

        let cabin_type = this.oneway.class;
        if (cabin_type == "Economy") {
          cabin_type = "Y";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType = cabin_type;
        } else if (cabin_type == "Business") {
          cabin_type = "C";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType = cabin_type;
        } else if (cabin_type == "First Class") {
          cabin_type = "F";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType = cabin_type;
        } else if (cabin_type == "Premium Economy") {
          cabin_type = "S";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType = cabin_type;
        } else if (cabin_type == "Premium Business") {
          cabin_type = "J";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType = cabin_type;
        } else if (cabin_type == "Premium First") {
          cabin_type = "P";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType = cabin_type;
        }

        this.onewaydata.country = this.pointofSale;
        this.onewaydata.request.AirShoppingRQ.Preference.TripType = "ONEWAY";
        this.onewaydata.request.AirShoppingRQ.Preference.Currency = this.preferCurrency;
        this.onewaydata.request.AirShoppingRQ.MetaData.Currency = this.metaCurrency;


        console.log(this.oneway,'onewayoconsoleneway...2');
        if(this.oneway.advanceOptions){
          let getadvanceOpt = this.oneway.advanceOptions;
          this.onewaydata.request.AirShoppingRQ.Preference.DirectFlight = getadvanceOpt.directFlight;
          this.onewaydata.request.AirShoppingRQ.Preference.BaggageInclusive = getadvanceOpt.baggageInclusive;
          this.onewaydata.request.AirShoppingRQ.Preference.NearbyAirport = getadvanceOpt.nearbyAirport;
          this.onewaydata.request.AirShoppingRQ.Preference.Stops = getadvanceOpt.stopsSelect ? [getadvanceOpt.stopsSelect] : [];
          this.onewaydata.request.AirShoppingRQ.Preference.LayoverHours = getadvanceOpt.adlayoverTime;
          this.onewaydata.request.AirShoppingRQ.Preference.AvoidCountry = getadvanceOpt.avoidCountry;
          // this.onewaydata.request.AirShoppingRQ.Preference.IncludedAirlines = getadvanceOpt.includeAirline;
          // this.onewaydata.request.AirShoppingRQ.Preference.ExcludedAirlines = getadvanceOpt.excludeAirline;
          // this.onewaydata.request.AirShoppingRQ.Preference.IncludedViaStops = getadvanceOpt.includeAirport;
          // this.onewaydata.request.AirShoppingRQ.Preference.ExcludedViaStops = getadvanceOpt.excludeAirport;
          this.onewaydata.request.AirShoppingRQ.Preference.IncludedAirlines = (getadvanceOpt.includeAirline ?? []).map(airline => {
            const match = airline.match(/\(([^)]+)\)/);
            return match ? match[1] : airline;
          });
          this.onewaydata.request.AirShoppingRQ.Preference.ExcludedAirlines = (getadvanceOpt.excludeAirline ?? []).map(airline => {
            const match = airline.match(/\(([^)]+)\)/);
            return match ? match[1] : airline; 
          });
          this.onewaydata.request.AirShoppingRQ.Preference.IncludedViaStops = (getadvanceOpt.includeAirport ?? []).map(airport => {
              const match = airport.match(/\(([^)]+)\)/);
              return match ? match[1] : airport;
          });
          this.onewaydata.request.AirShoppingRQ.Preference.ExcludedViaStops = (getadvanceOpt.excludeAirport ?? []).map(airport => {
              const match = airport.match(/\(([^)]+)\)/);
              return match ? match[1] : airport;
          });
        }

        console.log(this.onewaydata, "this.onewaydatathis.onewaydata");


        try {
        const response = await axios.post(this.makeSearchUrl, this.onewaydata, {
          headers: {
            "Content-Type": "application/json",
          },
        });
          console.log(response, "responseresponseresponse");
          this.responseData.push(response.data);

          let getOnewaydata = [];
          getOnewaydata = JSON.stringify(this.responseData);

          localStorage.setItem("onewaytrip", getOnewaydata);

          if (getOnewaydata) {
            this.onewaytrip();
          }

          if (response.data.Errors =='Errors') {
            this.searchresult = false;
            this.searchloader = true;
            setTimeout(() => {
              // this.pageError = true;
              this.searchresult = false;
            this.searchloader = false;
            }, 7000);
          }
        } 
        catch (error) {
          this.searchloader = true;
          setTimeout(() => {
              this.searchloader = false;
              this.searchresult = true;
              if(error.response && error.response.data.success == false){
                this.dateError = error.response.data.message;
              }
              this.pageError = true;
            }, 7000);

          console.log(error,'eroororororoowooe...oneway');
        }







        // axios.post(this.makeSearchUrl, this.onewaydata, {
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //   })
        //   .then((response) => {
        //     console.log(response, "responseresponseresponse");
        //     this.responseData.push(response.data);

        //     let getOnewaydata = [];
        //     getOnewaydata = JSON.stringify(this.responseData);

        //     localStorage.setItem("onewaytrip", getOnewaydata);

        //     if (getOnewaydata) {
        //       this.onewaytrip();
        //     }

        //     if (response.data.Errors =='Errors') {
        //       this.searchresult = false;
        //       this.searchloader = true;
        //       setTimeout(() => {
        //         // this.pageError = true;
        //         this.searchresult = false;
        //       this.searchloader = false;
        //       }, 7000);
        //     }
        //   })
        //   .catch((error) => {
        //     this.searchloader = true;
        //     setTimeout(() => {
        //         this.searchloader = false;
        //         this.searchresult = true;
        //         this.pageError = true;
        //       }, 7000);

        //     console.log(error,'eroororororoowooe...oneway');
        //     // this.$router.push("/pageError");
        //   });
      } else {
        console.log(this.fromErrorMessage,'validatecheckworking.....7');
        // alert("please enter your detail");

        this.validateOnewayForm();
      }
    },
    

    sendedMulti() {
      if (
        this.multi_city.itinery[0].from &&
        this.multi_city.itinery[0].to &&
        this.multi_city.itinery[0].depdate &&
        this.multi_city.itinery[1].from &&
        this.multi_city.itinery[1].to &&
        this.multi_city.itinery[1].depdate &&
        (!this.multi_city.itinery[2] ||
          (this.multi_city.itinery[2].from &&
            this.multi_city.itinery[2].to &&
            this.multi_city.itinery[2].depdate)) &&
        (!this.multi_city.itinery[3] ||
          (this.multi_city.itinery[3].from &&
            this.multi_city.itinery[3].to &&
            this.multi_city.itinery[3].depdate))
      ) {
      this.multi_city.adult = this.adultValue;
      this.multi_city.child = this.childValue;
      this.multi_city.infant = this.infantValue;
      this.multi_city.class = this.classType;
      this.multi_city.tripType = this.activetrip;

      console.log(this.multi_city, "datasdatasdatas");

      this.searchCase = JSON.stringify(this.multi_city);

      let getUrlFormat = [];
        this.multi_city.itinery.forEach((v) => {
          let from = v.from.name ? v.from.name.split(" ").pop().replace("(", "").replace(")", "") : v.from.split(" ").pop().replace("(", "").replace(")", "");
          let to = v.to.name ? v.to.name.split(" ").pop().replace("(", "").replace(")", "") : v.to.split(" ").pop().replace("(", "").replace(")", "");
          let depDate = moment(v.depdate).format("YYYY/MM/DD");

          getUrlFormat.push(`${from}-${to}-${depDate}`);
        });

        let formattedItinerary = getUrlFormat.join('_');

        let [Adt, Chd, Inf] = [this.multi_city.adult, this.multi_city.child, this.multi_city.infant];
        let getPaxtype = `A-${Adt}_C-${Chd}_I-${Inf}`;

        let getLangual = this.$i18n.locale.split('-')[1];

        let multicityData = {
          itinerary: formattedItinerary, 
          tripType: "MC",                
          paxType: getPaxtype,           
          cabin: this.classType,         
          language: getLangual,          
        };

        console.log(multicityData,'dadsdasmulticityData')

        const resultData = this.handleMultiSearch(this.multi_city);
        localStorage.setItem("recentData", JSON.stringify(resultData));

        setTimeout(() => {
          location.reload();
        }, 500);

        this.$router.push({ path: "/flight/search", query: multicityData });

      }
    
    },

    sendedMulticity() {
      for (let i = 0; i < this.multi_city.itinery.length; i++) {
        if (this.multi_city.itinery[i].from) {
          this.multi_city.itinery[i].multiFromError = false;

          // console.log(this.multi_city.itinery[i].from, "mememememe.....");
        } else {
          this.multi_city.itinery[i].multiFromError = true;
        }

        if (this.multi_city.itinery[i].to) {
          this.multi_city.itinery[i].multiToError = false;
        } else {
          this.multi_city.itinery[i].multiToError = true;
        }

        if (this.multi_city.itinery[i].depdate) {
          this.multi_city.itinery[i].multiDateError = false;
        } else {
          this.multi_city.itinery[i].multiDateError = true;
        }
      }
    },

    selected(result) {
      console.log(result, "ppppp");
      if (result.Flight1) {
        let obj = {
          adult: this.multi_city.adult,
          child: this.multi_city.child,
          inf: this.multi_city.infant,
          class: this.multi_city.class,
        };
        console.log(obj, "ppp");
        localStorage.setItem(
          `${"pass"}-${result.selectId}`,
          JSON.stringify(obj)
        );
        localStorage.setItem(
          `${"multi"}-${result.selectId}`,
          JSON.stringify(result)
        );
        this.$router.push({
          path: "/flightinfo",
          query: { pass_id: `${"multi"}-${result.selectId}` },
        });
      } else {
        localStorage.setItem(
          `${result.currency}-${result.selectId}`,
          JSON.stringify(result)
        );

        // const country = this.$route.params.country || 'ca';

        // this.$router.push({
        //   path: `/${country}/flightinfo`,
        //   query: { pass_id: `${result.currency}-${result.selectId}` },
        // });

        console.log('routerWorking...1')
        this.$router.push({
          path: "/flightinfo",
          query: { pass_id: `${result.currency}-${result.selectId}` },
        });
      }
    },

    fromClick($event) {
      console.log(this.fromErrorMessage,'validatecheckworking.....1');
      // console.log($event, "p.....1");
      
      if ($event.from) {
        $event.from = "";
        this.fromErrorMessage = true;

        // console.log(this.fromErrorMessage, "p.....2");
      }
    },

    toClick($event) {
      // console.log($event, "p.....");
      if ($event.to) {
        $event.to = "";
        this.toErrorMessage = true;

        // console.log(this.toErrorMessage, "p.....3");
      }
    },

    multiFromClick(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.from = null;
            v.multiFromError = true;

            // console.log("vvvvv......4444");
            if (!v.from) {
              v.multiFromError = true;

              // console.log("vvvvv......555");
            } else {
              v.multiFromError = false;

              // console.log("vvvvv......666");
            }
          }
        }
      });
    },

    multiToClick(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.to = null;
            v.multiToError = true;

            // console.log("vvvvv......111");
            if (!v.to) {
              // console.log("vvvvv......222");
              v.multiToError = true;
            } else {
              v.multiToError = false;

              // console.log("vvvvv......333");
            }
          }
        }
      });
    },

    onChangeFrom() {
      console.log(this.fromErrorMessage,'validatecheckworking.....2');
      setTimeout(() => {
        if (this.oneway.from) {
          this.fromErrorMessage = false;
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.from) {
          this.fromErrorMessage = true;
        }
      }, 100);
    },

    onChangeTo() {
      setTimeout(() => {
        if (this.oneway.to) {
          this.toErrorMessage = false;
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.to) {
          this.toErrorMessage = true;
        }
      }, 100);
    },

    onChangeMultiFrom(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.multiFromError = false;
          } else {
            v.multiFromError = true;
          }
        }
      });
    },

    onChangeMultiTo(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.multiToError = false;
          } else {
            v.multiToError = true;
          }

            if (this.multi_city.itinery.length - 1 != index){
              this.multi_city.itinery[i + 1].from = this.multi_city.itinery[i].to;
            }
        }
      });

      // for (let i = 1; i < this.multi_city.itinery.length; i++) {
      //   this.multi_city.itinery[i].from = this.multi_city.itinery[i - 1].to;
      },
    // },

    validateOnewayForm() {
      
      if (this.oneway.from) {
        this.fromErrorMessage = false;
        console.log(this.fromErrorMessage,'validatecheckworking.....3');
      } else {
        this.fromErrorMessage = true;
        console.log(this.fromErrorMessage,'validatecheckworking.....4');
      }

      if (this.oneway.to) {
        this.toErrorMessage = false;
      } else {
        this.toErrorMessage = true;
      }

      if (this.oneway.dedate) {
        this.fromDateError = false;
      } else {
        this.fromDateError = true;
      }

      if (this.oneway.redate) {
        this.toDateError = false;
      } else {
        this.toDateError = true;
      }
      // this.fromErrorMessage = !this.oneway.from;
      // this.toErrorMessage = !this.oneway.to;
      // this.fromDateError = !this.oneway.dedate;
      // this.toDateError = !this.oneway.redate;
    },
    datachangeMuti(data, index) {
      console.log(data, index, "data,index1");
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          v.from = `${data.city} (${data.iata})`;
          v.autocomfrom = false;
          v.selectedFrom = true
          if (!v.to) {
              const inputElement =
                this.$refs.multiToAutocomplete[index];
              if (inputElement) {
                inputElement.focus();
              }
          } else {
              const inputElement =
                this.$refs.multiFromAutocomplete[index];
              // console.log(this.$refs.fromAutoComplete);
              if (inputElement) {
                inputElement.blur();
              }
          }
        }
      });
      console.log(this.multi_city, "this.multiDatathis.multiData");
    },
    datachangeMutiTo(data, index) {
      console.log(data, index, "data,index1");

      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          v.to = `${data.city} (${data.iata})`;
          v.autocomTo = false;
          v.selectedTo = true;
          if (this.multi_city.itinery.length - 1 != index){
            this.multi_city.itinery[i + 1].from = this.multi_city.itinery[i].to;
            this.multi_city.itinery[i + 1].selectedFrom = true;
          }

           if (!v.depdate) {
              const inputElement = this.$refs.dpRef4[index];
              if (inputElement) {
                inputElement.openMenu();
              }
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = true;
          } else {
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = false;

              const inputElement =
                this.$refs.multiFromAutocomplete[index];
              // console.log(this.$refs.fromAutoComplete);
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.blur();
              }
          }
        }
        //   if (this.multi_city.itinery.length - 1 != index)
        //     this.multi_city.itinery[i + 1].from = this.multi_city.itinery[i].to;
        // }
      });
    },

    datachange(data) {
      this.oneway.from = `${data.city} (${data.iata})`;
      this.typedrop = false;
      const regex = /[-()]/
      if(regex.test(this.oneway.from)){
        this.selectOnewayFrom = true;
      }

      if(!this.oneway.from){
         this.$refs.firstAutoComplete.focus();
      }

      if (!this.oneway.to) {
        // setTimeout(() => {
          console.log(this.$refs.secondAutoComplete,'secondAutocompletesecondAutocomplete')
          if(this.rount){
            setTimeout(() => {
              this.$refs.secondAutoComplete.focus();
            }, 100);
          }
          else{
            setTimeout(() => {
              this.$refs.onewaysecondAuto.focus();
            }, 100);
          }
    
          // this.$refs.firstAutoComplete.blur();
        // }, 100);
      }
    },
    datachangeTo(data) {
      this.oneway.to = `${data.city} (${data.iata})`;
      this.typedrop1 = false;
      const regex = /[-()]/
      if(regex.test(this.oneway.to)){
        this.selectOnewayTo = true;
      }
      if (!this.oneway.dedate) {
        // console.log('testueeiekek.....1')
     
        if(this.rount){
          setTimeout(() => {
            this.$refs.dpRef1.openMenu();
            }, 100);
          }
          else{
            setTimeout(() => {
              this.$refs.onewaydpRef1.openMenu();
            }, 100);
          }
      } else {
        // console.log('testueeiekek.....2')
        if(this.$refs.onewaysecondAuto){
          setTimeout(() => {
            this.$refs.onewaysecondAuto.blur();
          }, 100);
        }
        else{
          setTimeout(() => {
            this.$refs.secondAutoComplete.blur();
          }, 100);
        }
      }
    },


    fromInputData(){
      this.oneway.from = ""
      // const regex
      if(!this.oneway.from){
        this.selectOnewayFrom = false;
        setTimeout(() => {
          this.$refs.firstAutoComplete.focus();
        }, 100);
      }
    },
    toInputData(){
      this.oneway.to = ""
      if(!this.oneway.to){
        this.selectOnewayTo = false;
        if(this.rount){
          setTimeout(() => {
            this.$refs.secondAutoComplete.focus();
          }, 100);
        }
        else{
          this.$refs.onewaysecondAuto.focus();
        }
      }
    },

    typeCity(event, $data) {
      console.log('Itsworking....')
      if (event.length >= 1) {
        this.formres = false;
        if (event.length >= 1) {
          this.formres1 = false;
        }
      }
      this.city = [];
      this.city1 = [];
      // let $event = [];
      console.log(event, $data, "searchsearchsearchsearch");
      if ($data == 1 && event.length > 0) {
        this.fromloader = true;
      } else {
        this.fromloader = false;
      }

      if ($data == 2 && event.length > 0) {
        this.toloader = true;
      } else {
        this.toloader = false;
      }

      if (event.length >= 3) {
        axios.get(`${this.autocompleteApi}?airportString=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response, response.data, "responseyyyyyyy");

            if ($data == 1) {
              this.fromloader = false;
              this.city = response.data;
              this.typedrop = true;
              return response.data;
            } else if ($data == 2) {
              this.toloader = false;
              this.city1 = response.data;
              this.typedrop1 = true;
              return response.data;
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
      }
    },

    async typeMultiCity(event, index, $num) {
    
      // let $event = [];
      // console.log(event, index, "searchsearchsearchsearch...1");

      if (event.length >= 3) {

        console.log(event, index, $num, "searchsearchsearchsearch...2");

        if ($num == 1 && event.length > 2) {
          this.multi_city.itinery.map((v, i) => {
            v.autocomTo = false;
            v.fromloader = true;
            if (i == index && event.length > 2) {
              v.autocomfrom = true;
            } else {
              v.autocomfrom = false;
              v.fromloader = false;
              this.multidrop = [];
            }
          });
        } 
        else if ($num == 2 && event.length > 0) {
          this.multi_city.itinery.map((v, i) => {
            v.autocomfrom = false;
            if (i == index && event.length > 2) {
              v.autocomTo = true;
              v.toloader = true;
            } else {
              v.autocomTo = false;
              v.toloader = false;
              this.multidrop1 = [];
            }
          });
        }

          // for (let i = 1; i < this.multiData.itinery.length; i++) {
          //   this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
          //   console.log(this.multiData.itinery[i],'this.multiData.itinery[i]')
          // }
        




        try {
        const response = await axios.get(`${this.autocompleteApi}?airportString=${event}`, {
          headers: {
            "Content-Type": this.airlineIcaoType,
          },
        });
        console.log(response.data, "responsedata");

            if ($num == 1) {
              this.multi_city.itinery.map((v) => {
                v.fromloader = false;
              });

              this.multidrop = response.data;
              console.log(this.multidrop, "this.multidrop");
            } else if ($num == 2) {
              this.multi_city.itinery.map((v) => {
                v.toloader = false;
              });

              this.multidrop1 = response.data;
            }
        } 
        catch (error) {
          console.log(error, "erroroor.....");
        }

      
      this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
    }










        // await axios.get(`${this.autocompleteApi}?airportString=${event}`,
        //     {
        //       headers: {
        //         "Content-Type": this.airlineIcaoType,
        //       },
        //     }
        //   )
        //   .then((response) => {
        //     console.log(response.data, "responsedata");

        //     if ($num == 1) {
        //       this.multi_city.itinery.map((v) => {
        //         v.fromloader = false;
        //       });

        //       this.multidrop = response.data;
        //       console.log(this.multidrop, "this.multidrop");
        //     } else if ($num == 2) {
        //       this.multi_city.itinery.map((v) => {
        //         v.toloader = false;
        //       });

        //       this.multidrop1 = response.data;
        //     }
        //   })

        //   .catch((error) => {
        //     console.log(error, "erroroor.....");
        //   });

   
    },

    focus1() {
      setTimeout(() => {
        this.$refs.secondAutoComplete.$el.querySelector("input").focus();
      }, 100);
    },

    focus2() {
      this.$refs.dpRef1.openMenu();
    },

    focus3() {
      setTimeout(() => {
        this.$refs.onewayTo.$el.querySelector("input").focus();
      }, 10);
    },

    focus4() {
      this.$refs.dpRef3.openMenu();
    },

    focus5(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    focus6(index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        // for (let i = 0; i < 1; i++) {
        //   this.multi_city.itinery[1].from = this.multi_city.itinery[0].to;
        // }
      }, 10);
    },

    Multifocus1(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    Multifocus2(data, index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        console.log(data, "bfbfbfbf.....");
        // return data.to.name

        // for (let i = 0; i < 1; i++) {
        //   //console.log(this.multiData[0].to, 'bfbfbfbf.....')
        //   this.input[1].from = this.input[0].to;
        // }

        // for (let i = 0; i < 1; i++) {
        //   this.multi_city.itinery[1].from = this.multi_city.itinery[0].to;
        // }
      }, 10);
    },

    handleResize() {
      if (window.innerWidth < 992) {
        this.isIcon = false;
      } else {
        this.isIcon = true;
      }
    },
    handleCalenderResize() {
      if (window.innerWidth < 400) {
        this.multiCalender = false;
      } else {
        this.multiCalender = true;
      }
    },

    // Form Header section

    getMultifrom(data) {
      // console.log(data, 'datatatetee')
      if (data.itinery[0].from === null) {
        return null;
      } else {
        const $data = data.itinery[0]?.from?.name;
        return $data || data.itinery[0].from;
      }

      // if (data.itinery[0].from.name) {
      //   return data.itinery[0].from.name
      // }
      // else {
      //   return data.itinery[0].from
      // }
    },
    getMultiDedate1(data) {
      if (data.itinery[0].depdate) {
        // return data.itinery[0].depdate
        return moment(data.itinery[0].depdate, "DD-MMM-YYYY").format(
          "DD-MMM-YYYY"
        );
      } else {
        return "";
      }
    },

    getMultiTo(data) {
      // console.log(data, "Testing...1...1...1.");

      if (data.itinery[data.itinery.length - 1].to === null) {
        return null;
      } else {
        const $data = data.itinery[data.itinery.length - 1]?.to?.name;
        return $data || data.itinery[data.itinery.length - 1].to;
      }
    },

    getMultiDedate2(data) {
      if (data.itinery[data.itinery.length - 1].depdate) {
        // return data.itinery[data.itinery.length - 1].depdate
        return moment(
          data.itinery[data.itinery.length - 1].depdate,
          "DD-MMM-YYYY"
        ).format("DD-MMM-YYYY");
      } else {
        return "";
      }
    },

    getMultiClass(data) {
      console.log(data, "mdatatiCadata");
      if (data.class) {
        return data.class;
      } else {
        return "";
      }
    },

    //     getMultifrom(data) {
    //       if (data.length>0) {

    //   console.log(data,'wrthofjsdosjkdjk')

    //    if (data.itinery[0].from) {
    //           return data.itinery[0].from
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },
    //     getMultiDedate1(data) {

    //          if (data.length>0) {
    //    if (data.itinery[0].depdate) {
    //           return data.itinery[0].depdate
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },

    //     getMultiTo(data) {

    //         if (data.length>0) {
    //    if (data.itinery[data.itinery.length - 1].to) {
    //           return data.itinery[data.itinery.length - 1].to
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },
    //     getMultiDedate2(data) {

    //          if (data.length>0) {
    //    if (data.itinery[data.itinery.length - 1].depdate) {
    //           return data.itinery[data.itinery.length - 1].depdate
    //       }
    // }

    //        else {
    //        return data
    //       }
    //     },

    //     getMultiClass(data) {
    //       if (data.classes) {
    //         return data.classes
    //       }
    //       else {
    //         return ''
    //       }

    //     },
    
  filterByTimeRange(flight, range) {
    let [start, end] = range;
    const depTimeParts = flight.Depature[0].Departure.Time.split(":");
    let hours = parseInt(depTimeParts[0])
    let minutes = parseInt(depTimeParts[1])
    let totalHours = Math.floor(hours + minutes / 60);
    let value = totalHours >= start && totalHours < end;
    return value;
},
filterByTimeRange1(flight, range) {
    let [start, end] = range;
    const depTimeParts = flight.Depature[flight.Depature.length-1].Arrival.Time.split(":");
    let hours = parseInt(depTimeParts[0])
    let minutes = parseInt(depTimeParts[1])
    let totalHours = Math.floor(hours + minutes / 60);
    let value = totalHours >= start && totalHours < end;
    console.log(totalHours,value,'disabled...01')
    return value;
},
filterByTimeRangeRet(flight, range){
  let [start, end] = range;
    const retTimeParts = flight.Return[0].Departure.Time.split(":");
    let hours = parseInt(retTimeParts[0])
    let minutes = parseInt(retTimeParts[1])
    let totalHours = Math.floor(hours + minutes / 60);
    let value = totalHours >= start && totalHours < end;
    console.log(hours,value,'valuevalue')
    return value;
},
filterByTimeRangeRet1(flight,range){
    let [start, end] = range;
    const retTimeParts = flight.Return[flight.Return.length-1].Arrival.Time.split(":");
    let hours = parseInt(retTimeParts[0])
    let minutes = parseInt(retTimeParts[1])
    let totalHours = Math.floor(hours + minutes / 60);
    let value = totalHours >= start && totalHours < end;
    console.log(hours,value,'valuevalue')
    return value;
},

//multicity

filterTimeRangeMulti(flight,range){
  let [start, end] = range;
  // const retTimeParts = flight.Flight3[0].Departure.Time.split(":");
  //   let hours = parseInt(retTimeParts[0])
  //   let minutes = parseInt(retTimeParts[1])
  //   let totalHours = Math.round(hours + minutes / 60);
  //   let value = totalHours >= start && totalHours < end;
  //   return value;
  
  let flightTimes = [
    flight.Flight1[0]?.Departure.Time,
    flight.Flight2[0]?.Departure.Time,
    flight.Flight3[0]?.Departure.Time,
    flight.Flight4[0]?.Departure.Time
  ];

  return flightTimes.some(time => {
    if (time) {
      let [hours, minutes] = time.split(":").map(Number);
      let totalHours = hours + minutes / 60;
      return totalHours >= start && totalHours < end;
    }
    return false;
  });
  },
filterTimeMultiArr(flight,range){
  let [start, end] = range;
    // const retTimeParts = flight.Flight1[flight.Flight1.length-1].Arrival.Time.split(":");
    // let hours = parseInt(retTimeParts[0])
    // let minutes = parseInt(retTimeParts[1])
    // let totalHours = Math.round(hours + minutes / 60);
    // let value = totalHours >= start && totalHours < end;
    // return value;
    let flightTimes = [
    flight.Flight1[flight.Flight1.length-1].Arrival.Time,
    flight.Flight2[flight.Flight2.length-1].Arrival.Time,
    flight.Flight3[flight.Flight3.length-1].Arrival.Time,
    flight.Flight4[flight.Flight4.length-1].Arrival.Time
  ];

  return flightTimes.some(time => {
    if (time) {
      let [hours, minutes] = time.split(":").map(Number);
      let totalHours = Math.round(hours + minutes / 60);
      return totalHours >= start && totalHours < end;
    }
    return false;
  });
},

  TimeFilter1() {
      this.timeFilterData1 = !this.timeFilterData1;
      this.applyFilters();
  },

  TimeFilter2() {
    this.timeFilterData2 = !this.timeFilterData2;
      this.applyFilters();
  },

  TimeFilter3() {
    this.timeFilterData3 = !this.timeFilterData3;
      this.applyFilters();
  },
  TimeFilter4() {
    this.timeFilterData4 = !this.timeFilterData4;
      this.applyFilters();
  },

  TimeFilterArr1() {
    this.timeFilterArrData1 = !this.timeFilterArrData1;
    this.applyFilters();
  },

  TimeFilterArr2() {
    this.timeFilterArrData2 = !this.timeFilterArrData2;
    this.applyFilters();
  },

  TimeFilterArr3() {
    this.timeFilterArrData3 = !this.timeFilterArrData3;
    this.applyFilters();
  },
   TimeFilterArr4() {
    this.timeFilterArrData4 = !this.timeFilterArrData4;
    this.applyFilters();
  },
  TimeretFilter1(){
    this.timearrFilterData1 = !this.timearrFilterData1;
    this.applyFilters();
  },
  TimeretFilter2(){
    this.timearrFilterData2 = !this.timearrFilterData2
    this.applyFilters();
  },
  TimeretFilter3(){
    this.timearrFilterData3 = !this.timearrFilterData3
    this.applyFilters();
  },
   TimeretFilter4(){
    this.timearrFilterData4 = !this.timearrFilterData4
    this.applyFilters();
  },
  TimeretFilterarr1(){
    this.timearrFilterArrData1 = !this.timearrFilterArrData1
    this.applyFilters();
  },
  TimeretFilterarr2(){
    this.timearrFilterArrData2 = !this.timearrFilterArrData2
    this.applyFilters();
  },
  TimeretFilterarr3(){
    this.timearrFilterArrData3 = !this.timearrFilterArrData3
    this.applyFilters();
  },
  TimeretFilterarr4(){
    this.timearrFilterArrData4 = !this.timearrFilterArrData4
    this.applyFilters();
  },

  //multicity
  TimeMulti1(){
    this.timeMulti1 = !this.timeMulti1
    this.applyFilters();
  },
  TimeMulti2(){
    this.timeMulti2 = !this.timeMulti2
    this.applyFilters();
  },
  TimeMulti3(){
    this.timeMulti3 = !this.timeMulti3
    this.applyFilters();
  },
   TimeMulti4(){
    this.timeMulti4 = !this.timeMulti4
    this.applyFilters();
  },
  TimeMultiArr1(){
    this.timeMultiArr1 = !this.timeMultiArr1
    this.applyFilters();
  },
  TimeMultiArr2(){
    this.timeMultiArr2 = !this.timeMultiArr2
    this.applyFilters();
  },
  TimeMultiArr3(){
    this.timeMultiArr3 = !this.timeMultiArr3
    this.applyFilters();
  },
  TimeMultiArr4(){
    this.timeMultiArr4 = !this.timeMultiArr4
    this.applyFilters();
  },

applyFilters() {
  if(this.rountresult.length>0){
    this.filteredData = this.filterData;
    // console.log(this.filteredData,'resultttt....3')
  }
  else if(this.onewayresulted.length>0){
    this.filteredData = this.onewayfilterData;
    // console.log(this.filteredData,'resultttt....4')
  }
  else if(this.multicityData.length > 0){
    this.filteredData = this.multiFilterData;
    this.multifil = true
    
    // console.log(this.filteredData,'resultttt...5')
  }
  let result = this.filteredData;
  
  if (this.RefundableData && this.nonRefundableData) {
    result = result.filter(v => v.Refund === "Refundable" || v.Refund === "Non Refundable");
  } else {
    // Refundable filter
    if (this.RefundableData) {
      result = result.filter(v => v.Refund === "Refundable");
    }

    // Non-refundable filter
    if (this.nonRefundableData) {
      result = result.filter(v => v.Refund === "Non Refundable");
    }
  }
  

  // Non-Stop filter OneStopFil
  if(this.rountresult.length > 0){
   if (this.NonStopFil && this.OneStopFil && this.OnePlusFil) {
    result = result.filter(v => v.Depaturejourney.Stops === 0 || v.Returnjourney.Stops === 0 ||
                                 v.Depaturejourney.Stops === 1 || v.Returnjourney.Stops === 1 ||
                                 v.Depaturejourney.Stops > 1 || v.Returnjourney.Stops > 1);
  } else {
    // Non Stop and One Stop filter
    if (this.NonStopFil && this.OneStopFil) {
      result = result.filter(v => (v.Depaturejourney.Stops === 0 || v.Returnjourney.Stops === 0) ||
                                   (v.Depaturejourney.Stops === 1 || v.Returnjourney.Stops === 1));
    } else {
      // Non Stop and One Plus filter
      if (this.NonStopFil && this.OnePlusFil) {
        result = result.filter(v => (v.Depaturejourney.Stops === 0 || v.Returnjourney.Stops === 0) ||
                                     (v.Depaturejourney.Stops > 1 || v.Returnjourney.Stops > 1));
      } else {
        // One Stop and One Plus filter
        if (this.OneStopFil && this.OnePlusFil) {
          result = result.filter(v => (v.Depaturejourney.Stops === 1 || v.Returnjourney.Stops === 1) ||
                                       (v.Depaturejourney.Stops > 1 || v.Returnjourney.Stops > 1));
        } else {
          if (this.NonStopFil) {
            result = result.filter(v => v.Depaturejourney.Stops === 0 || v.Returnjourney.Stops === 0);
          }
          if (this.OneStopFil) {
            result = result.filter(v => v.Depaturejourney.Stops === 1 || v.Returnjourney.Stops === 1);
          }
          if (this.OnePlusFil) {
            result = result.filter(v => v.Depaturejourney.Stops > 1 || v.Returnjourney.Stops > 1);
          }
        }
      }
    }
  }
  }
  else if(this.onewayfilterData.length > 0){
    if (this.NonStopFil && this.OneStopFil && this.OnePlusFil) {
    result = result.filter(v => v.Depaturejourney.Stops === 0 ||
                                 v.Depaturejourney.Stops === 1 ||
                                 v.Depaturejourney.Stops > 1);
  } else {
    // Non Stop and One Stop filter
    if (this.NonStopFil && this.OneStopFil) {
      result = result.filter(v => (v.Depaturejourney.Stops === 0 ) ||
                                   (v.Depaturejourney.Stops === 1));
    } else {
      // Non Stop and One Plus filter
      if (this.NonStopFil && this.OnePlusFil) {
        result = result.filter(v => (v.Depaturejourney.Stops === 0) ||
                                     (v.Depaturejourney.Stops > 1));
      } else {
        // One Stop and One Plus filter
        if (this.OneStopFil && this.OnePlusFil) {
          result = result.filter(v => (v.Depaturejourney.Stops === 1) ||
                                       (v.Depaturejourney.Stops > 1));
        } else {
          if (this.NonStopFil) {
            result = result.filter(v => v.Depaturejourney.Stops === 0);
          }
          if (this.OneStopFil) {
            result = result.filter(v => v.Depaturejourney.Stops === 1);
          }
          if (this.OnePlusFil) {
            result = result.filter(v => v.Depaturejourney.Stops > 1);
          }
        }
      }
    }
  }
  }
  else if(this.multiFilterData.length > 0){
    if (this.NonStopFil && this.OneStopFil && this.OnePlusFil) {
    result = result.filter(v => v.Flight1JourneyTime.Stops === 0 || v.Flight2JourneyTime.Stops === 0 || v.Flight3JourneyTime.Stops === 0 || v.Flight4JourneyTime.Stops === 0 ||
                                 v.Flight1JourneyTime.Stops === 1 || v.Flight2JourneyTime.Stops === 1 || v.Flight3JourneyTime.Stops === 1 || v.Flight4JourneyTime.Stops === 1 ||
                                 v.Flight1JourneyTime.Stops > 1 || v.Flight2JourneyTime.Stops > 1 || v.Flight3JourneyTime.Stops > 1 || v.Flight4JourneyTime.Stops > 1);
  } else {
    // Non Stop and One Stop filter
    if (this.NonStopFil && this.OneStopFil) {
      result = result.filter(v => (v.Flight1JourneyTime.Stops === 0 || v.Flight2JourneyTime.Stops === 0 || v.Flight3JourneyTime.Stops === 0 || v.Flight4JourneyTime.Stops === 0) ||
                                   (v.Flight1JourneyTime.Stops === 1 || v.Flight2JourneyTime.Stops === 1 || v.Flight3JourneyTime.Stops === 1 || v.Flight4JourneyTime.Stops === 1));
    } else {
      // Non Stop and One Plus filter
      if (this.NonStopFil && this.OnePlusFil) {
        result = result.filter(v => (v.Flight1JourneyTime.Stops === 0 || v.Flight2JourneyTime.Stops === 0 || v.Flight3JourneyTime.Stops === 0 || v.Flight4JourneyTime.Stops === 0) ||
                                     (v.Flight1JourneyTime.Stops > 1 || v.Flight2JourneyTime.Stops > 1 || v.Flight3JourneyTime.Stops > 1 || v.Flight4JourneyTime.Stops > 1));
      } else {
        // One Stop and One Plus filter
        if (this.OneStopFil && this.OnePlusFil) {
          result = result.filter(v => (v.Flight1JourneyTime.Stops === 1 || v.Flight2JourneyTime.Stops === 1 || v.Flight3JourneyTime.Stops === 1 || v.Flight4JourneyTime.Stops === 1) ||
                                       (v.Flight1JourneyTime.Stops > 1 || v.Flight2JourneyTime.Stops > 1 || v.Flight3JourneyTime.Stops > 1 || v.Flight4JourneyTime.Stops > 1));
        } else {
          if (this.NonStopFil) {
            result = result.filter(v => {
              if(v.Flight4JourneyTime){
                return v.Flight1JourneyTime.Stops === 0 || v.Flight2JourneyTime.Stops === 0 || v.Flight3JourneyTime.Stops === 0 || v.Flight4JourneyTime.Stops === 0
              }
          else if(v.Flight1JourneyTime && v.Flight2JourneyTime && v.Flight3JourneyTime){
            return v.Flight1JourneyTime.Stops === 0 || v.Flight2JourneyTime.Stops === 0 || v.Flight3JourneyTime.Stops === 0;
          }
          else if(v.Flight1JourneyTime && v.Flight2JourneyTime){
            return v.Flight1JourneyTime.Stops === 0 || v.Flight2JourneyTime.Stops === 0 
          }
           })
          }
          if (this.OneStopFil) {
            result = result.filter(v => {
              if(v.Flight4JourneyTime){
                return v.Flight1JourneyTime.Stops === 1 || v.Flight2JourneyTime.Stops === 1 || v.Flight3JourneyTime.Stops === 1 || v.Flight4JourneyTime.Stops === 1
              }
          else if(v.Flight1JourneyTime && v.Flight2JourneyTime && v.Flight3JourneyTime){
            return v.Flight1JourneyTime.Stops === 1 || v.Flight2JourneyTime.Stops === 1 || v.Flight3JourneyTime.Stops === 1;
          }
          else if(v.Flight1JourneyTime && v.Flight2JourneyTime){
            return v.Flight1JourneyTime.Stops === 1 || v.Flight2JourneyTime.Stops === 1
          }
            });
          }
          if (this.OnePlusFil) {
            result = result.filter(v => {
              if(v.Flight4JourneyTime){
                return v.Flight1JourneyTime.Stops > 1 || v.Flight2JourneyTime.Stops > 1 || v.Flight3JourneyTime.Stops > 1 || v.Flight4JourneyTime.Stops > 1
              }
          else if(v.Flight1JourneyTime && v.Flight2JourneyTime && v.Flight3JourneyTime){
            return v.Flight1JourneyTime.Stops > 1 || v.Flight2JourneyTime.Stops > 1 || v.Flight3JourneyTime.Stops > 1;
          }
          else if(v.Flight1JourneyTime && v.Flight2JourneyTime){
            return v.Flight1JourneyTime.Stops > 1 || v.Flight2JourneyTime.Stops > 1
          }
            });
          }
        }
      }
    }
  }
  }
 

  if (this.changevalue && this.changevalue.length === 2) {
    const [minValue, maxValue] = this.changevalue;
    result = result.filter(item => {
    const price = parseFloat(item.perperson);
    return price >= parseFloat(minValue) && price <= parseFloat(maxValue);
    });
  }
 
  if (this.selectedFromAirports) {
      result = result.filter(flight => this.selectedFromAirports.includes(flight.Depature[0].Departure.AirportName));
      // console.log(result,'resultttt...1')
    }
    if (this.selectedToAirports) {
      result = result.filter(flight => this.selectedToAirports.includes(flight.Depature[flight.Depature.length-1].Arrival.AirportName));
      // console.log(result,'resultttt...2')
    }

    if(this.multicityData.length > 0){
      if (this.multiFromAirports) {
        result = result.filter(flight => 
            this.multiFromAirports.includes(flight.Flight1[0].Departure.AirportName) ||
            this.multiFromAirports.includes(flight.Flight2[0].Departure.AirportName) ||
            this.multiFromAirports.includes(flight.Flight3[0].Departure.AirportName) ||
            this.multiFromAirports.includes(flight.Flight4[0].Departure.AirportName)
        );
      }
      if (this.multiToAirports) {
        result = result.filter(flight => 
            this.multiToAirports.includes(flight.Flight1[flight.Flight1.length-1].Arrival.AirportName) ||
            this.multiToAirports.includes(flight.Flight2[flight.Flight2.length-1].Arrival.AirportName) ||
            this.multiToAirports.includes(flight.Flight3[flight.Flight3.length-1].Arrival.AirportName) ||
            this.multiToAirports.includes(flight.Flight4[flight.Flight4.length-1].Arrival.AirportName)
        );
      }
    }

if(this.rountresult || this.onewayfilterData){
  if (this.defaultOutBoundTime) {
    let compare = parseInt(this.defaultOutBoundTime.split(' ')[0]);
    result = result.filter(v => {
      if (v.Depaturejourney.Time) {
        let timeParts = v.Depaturejourney.Time.split(" ");
        let hours = parseInt(timeParts[0]); 
        let minutes = parseInt(timeParts[2]); 
        let roundedHours = Math.round(hours + minutes / 60);

        // console.log(roundedHours <= compare,roundedHours,'defaultOutBoundDataaa',compare)
        return roundedHours <= compare;
      }
      return false;
    });
  }
}
  if(this.rountresult.length){
  if (this.defaultInBoundTime) {
    let compare = parseInt(this.defaultInBoundTime.split(' ')[0]);
    result = result.filter(v => {
      if (v.Returnjourney.Time) {
        let timeParts = v.Returnjourney.Time.split(" ");
        let hours = parseInt(timeParts[0]); 
        let minutes = parseInt(timeParts[2]); 

        let roundedHours = Math.round(hours + minutes / 60);
        return roundedHours <= compare;
      }
      return false;
    });
  }
  }
  if(this.multiFilterData){
    if (this.defaultOutBoundMulti) {
    let compare = parseInt(this.defaultOutBoundMulti.split(' ')[0]);

      result = result.filter(v => {
        const checkTime = (flight) => {
          if (flight && flight.Time) {
            let timeParts = flight.Time.split(" ");
            let hours = parseInt(timeParts[0]);
            let minutes = parseInt(timeParts[2]);
            let roundedHours = Math.round(hours + minutes / 60);
            return roundedHours <= compare;
          }
          return false;
        };

        return (
          checkTime(v.Flight1JourneyTime) ||
          checkTime(v.Flight2JourneyTime) ||
          checkTime(v.Flight3JourneyTime) ||
          checkTime(v.Flight4JourneyTime)
        );
      });
    }
  }


if(this.rountresult || this.onewayresulted){

  if (this.timeFilterData1 && this.timeFilterData2 && this.timeFilterData3 && this.timeFilterData4) {
  result = result.filter(flight =>
    this.filterByTimeRange(flight, this.morningRange) ||
    this.filterByTimeRange(flight, this.afternoonRange) ||
    this.filterByTimeRange(flight, this.eveningRange) || 
    this.filterByTimeRange(flight, this.beforeMorningRange)
  );
}
  else if (
    (this.timeFilterData1 && this.timeFilterData2) || (this.timeFilterData1 && this.timeFilterData4) ||
    (this.timeFilterData1 && this.timeFilterData3) || (this.timeFilterData2 && this.timeFilterData4) ||
    (this.timeFilterData2 && this.timeFilterData3) || (this.timeFilterData3 && this.timeFilterData4)
  ) {
    result = result.filter(flight => {
      const timeFilters = [];
      if (this.timeFilterData1) timeFilters.push(this.morningRange);
      if (this.timeFilterData2) timeFilters.push(this.afternoonRange);
      if (this.timeFilterData3) timeFilters.push(this.eveningRange);
      if (this.timeFilterData4) timeFilters.push(this.beforeMorningRange);
      return timeFilters.some(range => this.filterByTimeRange(flight, range));
    });
  }
  else {
    
     if (this.timeFilterData1) {
    result = result.filter(flight => this.filterByTimeRange(flight, this.morningRange));
    }

    else if (this.timeFilterData2) {
      result = result.filter(flight => this.filterByTimeRange(flight, this.afternoonRange));
    }

    else if (this.timeFilterData3) {
      result = result.filter(flight => this.filterByTimeRange(flight, this.eveningRange));
    }
    else if(this.timeFilterData4){
       result = result.filter(flight => this.filterByTimeRange(flight, this.beforeMorningRange));
    }

  }
  

    // arrival time filters
    if (this.timeFilterArrData1 && this.timeFilterArrData2 && this.timeFilterArrData3 && this.timeFilterArrData4) {
  result = result.filter(flight =>
    this.filterByTimeRange1(flight, this.morningRange) ||
    this.filterByTimeRange1(flight, this.afternoonRange) ||
    this.filterByTimeRange1(flight, this.eveningRange) ||
    this.filterByTimeRange1(flight, this.beforeMorningRange)
  );
}
  else if (
    (this.timeFilterArrData1 && this.timeFilterArrData2) || (this.timeFilterArrData1 && this.timeFilterArrData4) ||
    (this.timeFilterArrData1 && this.timeFilterArrData3) || (this.timeFilterArrData2 && this.timeFilterArrData4) ||
    (this.timeFilterArrData2 && this.timeFilterArrData3) || (this.timeFilterArrData3 && this.timeFilterArrData4) 
  ) 
  {
    result = result.filter(flight => {
      const timeFilters = [];
      if (this.timeFilterArrData1) timeFilters.push(this.morningRange);
      if (this.timeFilterArrData2) timeFilters.push(this.afternoonRange);
      if (this.timeFilterArrData3) timeFilters.push(this.eveningRange);
      if (this.timeFilterArrData4) timeFilters.push(this.beforeMorningRange);
      
      return timeFilters.some(range => this.filterByTimeRange1(flight, range));
    });
  }
  else {
 
    if (this.timeFilterArrData1) {
      result = result.filter(flight => this.filterByTimeRange1(flight, this.morningRange));
    }
    else if (this.timeFilterArrData2) {
      result = result.filter(flight => this.filterByTimeRange1(flight, this.afternoonRange));
    }
    else if (this.timeFilterArrData3) {
      result = result.filter(flight => this.filterByTimeRange1(flight, this.eveningRange));
    }
    else if (this.timeFilterArrData4) {
      result = result.filter(flight => this.filterByTimeRange1(flight, this.beforeMorningRange));
    }
  }
   
   //Return 
    if (this.timearrFilterData1 && this.timearrFilterData2 && this.timearrFilterData3 && this.timearrFilterData4) {
  result = result.filter(flight =>
    this.filterByTimeRangeRet(flight, this.morningRange) ||
    this.filterByTimeRangeRet(flight, this.afternoonRange) ||
    this.filterByTimeRangeRet(flight, this.eveningRange) ||
    this.filterByTimeRangeRet(flight, this.beforeMorningRange)
  );
}
  else if (
    (this.timearrFilterData1 && this.timearrFilterData2) || (this.timearrFilterData1 && this.timearrFilterData4) ||
    (this.timearrFilterData1 && this.timearrFilterData3) || (this.timearrFilterData2 && this.timearrFilterData4) ||
    (this.timearrFilterData2 && this.timearrFilterData3) || (this.timearrFilterData3 && this.timearrFilterData4) 
  ) {
    result = result.filter(flight => {
      const timeFilters = [];
      if (this.timearrFilterData1) timeFilters.push(this.morningRange);
      if (this.timearrFilterData2) timeFilters.push(this.afternoonRange);
      if (this.timearrFilterData3) timeFilters.push(this.eveningRange);
      if (this.timearrFilterData4) timeFilters.push(this.beforeMorningRange);
      
      return timeFilters.some(range => this.filterByTimeRangeRet(flight, range));
    });
  }
  else {
    if (this.timearrFilterData1) {
      result = result.filter(flight => this.filterByTimeRangeRet(flight, this.morningRange));
    }
    if (this.timearrFilterData2) {
      result = result.filter(flight => this.filterByTimeRangeRet(flight, this.afternoonRange));
    }
    if (this.timearrFilterData3) {
      result = result.filter(flight => this.filterByTimeRangeRet(flight, this.eveningRange));
    }
    if (this.timearrFilterData4) {
      result = result.filter(flight => this.filterByTimeRangeRet(flight, this.beforeMorningRange));
    }
  }

  if (this.timearrFilterArrData1 && this.timearrFilterArrData2 && this.timearrFilterArrData3 && this.timearrFilterArrData4) {
  result = result.filter(flight =>
    this.filterByTimeRangeRet1(flight, this.morningRange) ||
    this.filterByTimeRangeRet1(flight, this.afternoonRange) ||
    this.filterByTimeRangeRet1(flight, this.eveningRange) ||
    this.filterByTimeRangeRet1(flight, this.beforeMorningRange)
  );
}
  else if (
    (this.timearrFilterArrData1 && this.timearrFilterArrData2) || (this.timearrFilterArrData1 && this.timearrFilterArrData4) ||
    (this.timearrFilterArrData1 && this.timearrFilterArrData3) || (this.timearrFilterArrData2 && this.timearrFilterArrData4) ||
    (this.timearrFilterArrData2 && this.timearrFilterArrData3) || (this.timearrFilterArrData3 && this.timearrFilterArrData4)
  ) {
    result = result.filter(flight => {
      const timeFilters = [];
      if (this.timearrFilterArrData1) timeFilters.push(this.morningRange);
      if (this.timearrFilterArrData2) timeFilters.push(this.afternoonRange);
      if (this.timearrFilterArrData3) timeFilters.push(this.eveningRange);
      if (this.timearrFilterArrData4) timeFilters.push(this.beforeMorningRange);
      
      return timeFilters.some(range => this.filterByTimeRangeRet1(flight, range));
    });
  }
  else {
    if (this.timearrFilterArrData1) {
      result = result.filter(flight => this.filterByTimeRangeRet1(flight, this.morningRange));
    }
    if (this.timearrFilterArrData2) {
      result = result.filter(flight => this.filterByTimeRangeRet1(flight, this.afternoonRange));
    }
    if (this.timearrFilterArrData3) {
      result = result.filter(flight => this.filterByTimeRangeRet1(flight, this.eveningRange));
    }
    if (this.timearrFilterArrData4) {
      result = result.filter(flight => this.filterByTimeRangeRet1(flight, this.beforeMorningRange));
    }
  }
}
if(this.multicityData){
  // console.log('dataaaa...1')
  if (this.timeMulti1 && this.timeMulti2 && this.timeMulti3 && this.timeMulti4) {
    result = result.filter(flight =>
      this.filterTimeRangeMulti(flight, this.morningRange) ||
      this.filterTimeRangeMulti(flight, this.afternoonRange) ||
      this.filterTimeRangeMulti(flight, this.eveningRange) ||
      this.filterTimeRangeMulti(flight, this.beforeMorningRange)
    );
  }
  else if (
    (this.timeMulti1 && this.timeMulti2) || 
    (this.timeMulti1 && this.timeMulti4) ||
    (this.timeMulti1 && this.timeMulti3) || 
    (this.timeMulti2 && this.timeMulti4) ||
    (this.timeMulti2 && this.timeMulti3) || 
    (this.timeMulti3 && this.timeMulti4)
  ) {
    // console.log('dataaaa...2')
    result = result.filter(flight => {
      const timeFilters = [];
      if (this.timeMulti1) timeFilters.push(this.morningRange);
      if (this.timeMulti2) timeFilters.push(this.afternoonRange);
      if (this.timeMulti3) timeFilters.push(this.eveningRange);
      if (this.timeMulti4) timeFilters.push(this.beforeMorningRange);
      return timeFilters.some(range => this.filterTimeRangeMulti(flight, range));
    });
  }
  else {

    if (this.timeMulti4) {
      // console.log('dataaaa...3')
      result = result.filter(flight => this.filterTimeRangeMulti(flight, this.beforeMorningRange));
    }
    if (this.timeMulti1) {
      // console.log('dataaaa...4')
      result = result.filter(flight => this.filterTimeRangeMulti(flight, this.morningRange))
    }
    if (this.timeMulti2) {
      // console.log('dataaaa...5')
      result = result.filter(flight => this.filterTimeRangeMulti(flight, this.afternoonRange));
    }
    if (this.timeMulti3) {
      // console.log('dataaaa...6')
      result = result.filter(flight => this.filterTimeRangeMulti(flight, this.eveningRange));
    }
  }
  // console.log(result,'resulttMultii')
  if (this.timeMultiArr1 && this.timeMultiArr2 && this.timeMultiArr3 && this.timeMultiArr4) {
    result = result.filter(flight =>
      this.filterTimeMultiArr(flight, this.morningRange) ||
      this.filterTimeMultiArr(flight, this.afternoonRange) ||
      this.filterTimeMultiArr(flight, this.eveningRange) ||
      this.filterTimeMultiArr(flight, this.beforeMorningRange)
    );
  }
  else if (
    (this.timeMultiArr1 && this.timeMultiArr2) ||
    (this.timeMultiArr1 && this.timeMultiArr3) ||
    (this.timeMultiArr2 && this.timeMultiArr3) ||
    (this.timeMultiArr1 && this.timeMultiArr4) ||
    (this.timeMultiArr2 && this.timeMultiArr4) ||
    (this.timeMultiArr3 && this.timeMultiArr4)
  ) {
    result = result.filter(flight => {
      const timeFilters = [];
      if (this.timeMultiArr1) timeFilters.push(this.morningRange);
      if (this.timeMultiArr2) timeFilters.push(this.afternoonRange);
      if (this.timeMultiArr3) timeFilters.push(this.eveningRange);
       if (this.timeMultiArr3) timeFilters.push(this.beforeMorningRange);
      
      return timeFilters.some(range => this.filterTimeMultiArr(flight, range));
    });
  }
  else {
    if (this.timeMultiArr1) {
      result = result.filter(flight => this.filterTimeMultiArr(flight, this.morningRange));
    }
    if (this.timeMultiArr2) {
      result = result.filter(flight => this.filterTimeMultiArr(flight, this.afternoonRange));
    }
    if (this.timeMultiArr3) {
      result = result.filter(flight => this.filterTimeMultiArr(flight, this.eveningRange));
    }
     if (this.timeMultiArr4) {
      result = result.filter(flight => this.filterTimeMultiArr(flight, this.beforeMorningRange));
    }
  }
 }

if(this.airlineList){
   const activeAirlines = this.airlineList.filter(airline => airline.active).map(airline => airline.airline);

  // result = result.filter(flight => activeAirlines.includes(flight.ownername));
   if (this.rountresult.length > 0) {
      result = result.filter(flight => {
        const ownernameMatch = activeAirlines.includes(flight.ownername);

        const marketingCarrierMatch = flight.Depature.some(dep =>
          activeAirlines.includes(dep.MarketingCarrier.Name)
        );
         const marketingCarrierMatch1 = flight.Return.some(dep =>
          activeAirlines.includes(dep.MarketingCarrier.Name)
        );

        return ownernameMatch || marketingCarrierMatch || marketingCarrierMatch1;
      });
    }
    else if(this.onewayresulted.length > 0){
      result = result.filter(flight => {
        const ownernameMatch = activeAirlines.includes(flight.ownername);

        const marketingCarrierMatch = flight.Depature.some(dep =>
          activeAirlines.includes(dep.MarketingCarrier.Name)
        );

        return ownernameMatch || marketingCarrierMatch;
      });
    }
    else if(this.multicityData.length > 0){
      result = result.filter(flight => {
        const ownernameMatch = activeAirlines.includes(flight.ownername);
        if(flight.Flight1 && flight.Flight2 && flight.Flight3 && flight.Flight4){
          const marketingFlight1 = flight.Flight1.some(dep =>
          activeAirlines.includes(dep.MarketingCarrier.Name)
        );
         const marketingFlight2 = flight.Flight2.some(dep =>
          activeAirlines.includes(dep.MarketingCarrier.Name)
        );
        const marketingFlight3 = flight.Flight3.some(dep =>
          activeAirlines.includes(dep.MarketingCarrier.Name)
        );
        const marketingFlight4 = flight.Flight4.some(dep =>
          activeAirlines.includes(dep.MarketingCarrier.Name)
        );

        return ownernameMatch || marketingFlight1 || marketingFlight2 || marketingFlight3 || marketingFlight4;
        }
        else if(flight.Flight1 && flight.Flight2 && flight.Flight3){
          const marketingFlight1 = flight.Flight1.some(dep =>
          activeAirlines.includes(dep.MarketingCarrier.Name)
        );
         const marketingFlight2 = flight.Flight2.some(dep =>
          activeAirlines.includes(dep.MarketingCarrier.Name)
        );
        const marketingFlight3 = flight.Flight3.some(dep =>
          activeAirlines.includes(dep.MarketingCarrier.Name)
        );

        return ownernameMatch || marketingFlight1 || marketingFlight2 || marketingFlight3;
        }
        else {
        const marketingCarrierMatch = flight.Flight1.some(dep =>
          activeAirlines.includes(dep.MarketingCarrier.Name)
        );
         const marketingCarrierMatch1 = flight.Flight2.some(dep =>
          activeAirlines.includes(dep.MarketingCarrier.Name)
        );

        return ownernameMatch || marketingCarrierMatch || marketingCarrierMatch1;
        }
      });
    }

      //  console.log(result,'resulttttMultti')
}
  if(this.rountresult.length>0){
    this.hasNonStop = result.some(flight => flight.Depaturejourney.Stops === 0 || flight.Returnjourney.Stops === 0);
    this.hasOneStop = result.some(flight => flight.Depaturejourney.Stops === 1 || flight.Returnjourney.Stops === 1);
    this.hasOnePlusStop = result.some(flight => flight.Depaturejourney.Stops > 1 || flight.Returnjourney.Stops > 1);
  }
  else if(this.onewayresulted.length > 0){
     this.hasNonStop = result.some(flight => flight.Depaturejourney.Stops === 0 )
    this.hasOneStop = result.some(flight => flight.Depaturejourney.Stops === 1 )
    this.hasOnePlusStop = result.some(flight => flight.Depaturejourney.Stops > 1)
  }
  else if(this.multiFilterData.length > 0){
    this.multiFilterData.forEach((v) => {
      if(v.Flight4){
         this.hasNonStop = result.some(flight => flight.Flight1JourneyTime.Stops === 0 || flight.Flight2JourneyTime.Stops === 0 || flight.Flight3JourneyTime.Stops === 0 || flight.Flight4JourneyTime.Stops === 0);
         this.hasOneStop = result.some(flight => flight.Flight1JourneyTime.Stops === 1 || flight.Flight2JourneyTime.Stops === 1 || flight.Flight3JourneyTime.Stops === 1 || flight.Flight4JourneyTime.Stops === 1);
         this.hasOnePlusStop = result.some(flight => flight.Flight1JourneyTime.Stops > 1 || flight.Flight2JourneyTime.Stops > 1 || flight.Flight3JourneyTime.Stops > 1 || flight.Flight4JourneyTime.Stops > 1);
      }
      else if(v.Flight1 && v.Flight2 && v.Flight3){
        this.hasNonStop = result.some(flight => flight.Flight1JourneyTime.Stops === 0 || flight.Flight2JourneyTime.Stops === 0 || flight.Flight3JourneyTime.Stops === 0 )
        this.hasOneStop = result.some(flight => flight.Flight1JourneyTime.Stops === 1 || flight.Flight2JourneyTime.Stops === 1 || flight.Flight3JourneyTime.Stops === 1)
        this.hasOnePlusStop = result.some(flight => flight.Flight1JourneyTime.Stops > 1 || flight.Flight2JourneyTime.Stops > 1 || flight.Flight3JourneyTime.Stops > 1)
      }
      else {
         this.hasNonStop = result.some(flight => flight.Flight1JourneyTime.Stops === 0 || flight.Flight2JourneyTime.Stops === 0)
         this.hasOneStop = result.some(flight => flight.Flight1JourneyTime.Stops === 1 || flight.Flight2JourneyTime.Stops === 1)
         this.hasOnePlusStop = result.some(flight => flight.Flight1JourneyTime.Stops > 1 || flight.Flight2JourneyTime.Stops > 1)
      }
    })
  }
    this.hasRefundable = result.some(flight => flight.Refund === "Refundable");
    this.hasNonRefundable = result.some(flight => flight.Refund === "Non Refundable");

  // console.log(result,'resulttttMultti..1')
    this.hasMorningFlights = !this.timeFilterData1 || result.some(flight => this.filterByTimeRange(flight, this.morningRange));
    this.hasAfternoonFlights = !this.timeFilterData2 || result.some(flight => this.filterByTimeRange(flight, this.afternoonRange));
    this.hasEveningFlights = !this.timeFilterData3 || result.some(flight => this.filterByTimeRange(flight, this.eveningRange));

    this.hasMorningFlights1 = !this.timeFilterArrData1 || result.some(flight => this.filterByTimeRange1(flight, this.morningRange));
    this.hasAfternoonFlights1 = !this.timeFilterArrData2 || result.some(flight => this.filterByTimeRange1(flight, this.afternoonRange));
    this.hasEveningFlights1 = !this.timeFilterArrData3 || result.some(flight => this.filterByTimeRange1(flight, this.eveningRange)); 

    this.hasMorningReturn = !this.timearrFilterData1 || result.some(flight => this.filterByTimeRangeRet(flight, this.morningRange));
    this.hasAfternoonReturn = !this.timearrFilterData2 || result.some(flight => this.filterByTimeRangeRet(flight, this.afternoonRange));
    this.hasEveningReturn = !this.timearrFilterData3 || result.some(flight => this.filterByTimeRangeRet(flight, this.eveningRange)); 


    this.hasMorningReturn1 = !this.timearrFilterArrData1 || result.some(flight => this.filterByTimeRangeRet1(flight, this.morningRange));
    this.hasAfternoonReturn1 = !this.timearrFilterArrData2 || result.some(flight => this.filterByTimeRangeRet1(flight, this.afternoonRange));
    this.hasEveningReturn1 = !this.timearrFilterArrData3 || result.some(flight => this.filterByTimeRangeRet1(flight, this.eveningRange)); 

  // this.disableData = !this.hasMorningFlights || !this.hasAfternoonFlights || !this.hasEveningFlights ||!this.hasMorningFlights1 || !this.hasAfternoonFlights1 || !this.hasEveningFlights1 || !this.hasMorningReturn || !this.hasAfternoonReturn || !this.hasEveningReturn || !this.hasMorningReturn1 || !this.hasAfternoonReturn1 || !this.hasEveningReturn1 || result.length == 0



    // console.log(this.hasMorningFlights,'filterFlights1')
    // console.log(this.hasAfternoonFlights,'filterFlights2')
    // console.log(this.hasEveningFlights,'filterFlights3')

if (this.filterData.length > 0) {
  this.rountresult = result;
 
  this.disableData = this.rountresult.length == 0
  if(this.disableData){
    this.hasRefundable = this.filterData.some(flight => flight.Refund === "Refundable");
    this.hasNonRefundable = this.filterData.some(flight => flight.Refund === "Non Refundable");
    this.hasNonStop = this.filterData.some(flight => flight.Depaturejourney.Stops === 0 || flight.Returnjourney.Stops === 0);
    this.hasOneStop = this.filterData.some(flight => flight.Depaturejourney.Stops === 1 || flight.Returnjourney.Stops === 1);
    this.hasOnePlusStop = this.filterData.some(flight => flight.Depaturejourney.Stops > 1 || flight.Returnjourney.Stops > 1);
  }
}
 if (this.onewayfilterData.length > 0) {
  this.onewayresulted = result;
  this.disableData = this.onewayresulted.length == 0
  if((this.hasMorningFlights || this.hasAfternoonFlights || this.hasEveningFlights || this.hasMorningFlights1 || this.hasAfternoonFlights1 || this.hasEveningFlights1) && this.disableData){
    this.hasRefundable = this.onewayfilterData.some(flight => flight.Refund === "Refundable");
    this.hasNonRefundable = this.onewayfilterData.some(flight => flight.Refund === "Non Refundable");
     this.hasNonStop = this.onewayfilterData.some(flight => flight.Depaturejourney.Stops === 0 )
    this.hasOneStop = this.onewayfilterData.some(flight => flight.Depaturejourney.Stops === 1 )
    this.hasOnePlusStop = this.onewayfilterData.some(flight => flight.Depaturejourney.Stops > 1)
  }
}
if(this.multiFilterData.length > 0){
  this.multicityData = result
  // console.log(result,'resulttttMult')
  this.disableData = this.multicityData.length == 0
  if(this.disableData){
    this.hasRefundable = this.multiFilterData.some(flight => flight.Refund === "Refundable");
    this.hasNonRefundable = this.multiFilterData.some(flight => flight.Refund === "Non Refundable");
    this.multiFilterData.forEach((v) => {
      if(v.Flight4){
         this.hasNonStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops === 0 || flight.Flight2JourneyTime.Stops === 0 || flight.Flight3JourneyTime.Stops === 0 || flight.Flight4JourneyTime.Stops === 0);
         this.hasOneStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops === 1 || flight.Flight2JourneyTime.Stops === 1 || flight.Flight3JourneyTime.Stops === 1 || flight.Flight4JourneyTime.Stops === 1);
         this.hasOnePlusStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops > 1 || flight.Flight2JourneyTime.Stops > 1 || flight.Flight3JourneyTime.Stops > 1 || flight.Flight4JourneyTime.Stops > 1);
      }
      else if(v.Flight1 && v.Flight2 && v.Flight3){
        this.hasNonStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops === 0 || flight.Flight2JourneyTime.Stops === 0 || flight.Flight3JourneyTime.Stops === 0 )
        this.hasOneStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops === 1 || flight.Flight2JourneyTime.Stops === 1 || flight.Flight3JourneyTime.Stops === 1)
        this.hasOnePlusStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops > 1 || flight.Flight2JourneyTime.Stops > 1 || flight.Flight3JourneyTime.Stops > 1)
      }
      else {
         this.hasNonStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops === 0 || flight.Flight2JourneyTime.Stops === 0)
         this.hasOneStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops === 1 || flight.Flight2JourneyTime.Stops === 1)
         this.hasOnePlusStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops > 1 || flight.Flight2JourneyTime.Stops > 1)
      }
    })
  }
  // console.log(this.multicityData,'multiiiiii')
}
    // this.rountresult = result;
},

resetAllFilters(){
      this.RefundableData = false
      this.nonRefundableData = false
      this.NonStopFil = false
      this.OneStopFil = false
      this.OnePlusFil = false
      this.selectedFromAirports = false
      this.selectedToAirports = false
      this.timeFilterData1 = false
      this.timeFilterData2 = false
      this.timeFilterData3 = false
      this.timeFilterData4 = false
      this.timeFilterArrData1 = false
      this.timeFilterArrData2 = false
      this.timeFilterArrData3 = false
      this.timeFilterArrData4 = false
      this.timearrFilterData1 = false
      this.timearrFilterData2 = false
      this.timearrFilterData3 = false
      this.timearrFilterData4 = false
      this.timearrFilterArrData1 = false
      this.timearrFilterArrData2 = false
      this.timearrFilterArrData3 = false
      this.timearrFilterArrData4 = false
      this.changevalue = this.initialChangeValue
       this.airlineList.forEach((v) => {
          v.active = true;
        });

        if (this.filterData.length > 0) {
          this.rountresult = this.filterData;
        } else if (this.onewayfilterData.length > 0) {
          this.onewayresulted = this.onewayfilterData;
        } else if (this.multiFilterData.length > 0) {
          this.multicityData = this.multiFilterData;
        }

      if(this.rountresult.length > 0){
        this.defaultOutBoundTime = this.outBound
        this.defaultInBoundTime = this.inBound
        this.rountresult = this.filterData
      }
      else if(this.onewayresulted.length > 0){
        this.defaultOutBoundTime = this.outBound
        this.onewayresulted = this.onewayfilterData
      }
      else if(this.multiFilterData.length > 0){
        this.timeMulti1 = false,
        this.timeMulti2 = false,
        this.timeMulti3 = false,
        this.timeMulti4 = false,
        this.timeMultiArr1 = false,
        this.timeMultiArr2 = false,
        this.timeMultiArr3 = false,
        this.timeMultiArr4 = false,
        this.defaultOutBoundMulti = this.outBoundMulti
        this.multicityData = this.multiFilterData
      }
    },

    getMultiAdult(data) {
      // console.log(data, 'lkllklklklk...22..2.2.2')
      if (data.adult > 0) {
        return data.adult;
      } else {
        return "";
      }
    },
    getMultiChild(data) {
      if (data.child > 0) {
        return data.child;
      } else {
        return "";
      }
    },
    getMultiInfrant(data) {
      if (data.infant > 0) {
        return data.infant;
      } else {
        return "";
      }
    },

    // Form Header section End

    getConfig() {
      // console.log(this.$store.state.data,'homehoemehojoke...')

      // let getConfigData = this.$store.state.data
      // let portalLocal = localStorage.getItem("portalData")

      // let getConfigData1 = JSON.parse(portalLocal)

      const getConfigData1 = getConfigDataFromLocalStorage();

      if (getConfigData1) {
        this.makeSearchUrl =
          getConfigData1.payload.portal_configuration.API_endpoints.search;

        this.airLogoApi =
          getConfigData1.payload.portal_configuration.content_data.airlines_logo;
          this.autocompleteApi = getConfigData1.payload.portal_configuration.API_endpoints.autocomplete;
        if (this.airLogoApi) {
          this.airlineLogo_func();
        }

        this.preferCurrency = getConfigData1.payload.portal_configuration.currency.iso_code;
        this.metaCurrency = getConfigData1.payload.portal_configuration.currency.iso_code;
        this.pointofSale = getConfigData1.payload.portal_configuration.pointofsale;

        this.changedLang = `${this.pointofSale}-${this.$route.query.language}`;
        console.log(this.changedLang,'changedLangsdasdasd');
        localStorage.setItem("defaultlang", this.changedLang);
        this.$emit('localeChanged', this.changedLang);

        console.log(this.preferCurrency,'preferCurrencypreferCurrencyasdasddasd')

        // console.log(this.makeSearchUrl, 'head...sea..1..')

        // this.getConfigFunc();
      }
    },
  },

  // AutoComplete Functions End

  watch: {
    searchloader(newVal) {
      console.log(newVal,'dvsfdasdnewVal')

      if (newVal) {     

        document.getElementById("navbar").style.display = "none";
        document.getElementById("food").style.display = "none";

        // this.loderstatus = newVal;
        // this.$emit('activeLoder',loderstatus);
      } 
      else {

        document.getElementById("navbar").style.display = "block";
        document.getElementById("food").style.display = "block";
      }
    },
    // "$route.path": {
    //   immediate: true,
    //   handler(newValue) {
    //     if (newValue === "/") {
    //       console.log(newValue,'newValuepathnewValuepath');       
    //     }
    //   },
    // },
    "$i18n.locale": {
      handler: function () {
        this.classTypeitems = this.getClassType();
        // this.classType = 'Economy';
         this.translateRefund();
        this.translateClass();
      },
      deep: true,
    },

    'RefundableData'() {
    this.applyFilters();
  },
  'nonRefundableData'() {
    this.applyFilters();
  },
  'NonStopFil'() {
    this.applyFilters();
  },
  'OneStopFil'(){
    this.applyFilters();
  },
  'OnePlusFil'(){
    this.applyFilters();
  },
  'defaultOutBoundTime'(){
    this.applyFilters();
  },
  'defaultInBoundTime'(){
    this.applyFilters();
  },
  'selectedFromAirports'(){
    this.applyFilters();
  },
  'selectedToAirports'(){
    this.applyFilters();
  },
  'changevalue'(){
    this.applyFilters();
  },
  'defaultOutBoundMulti'(){
    this.applyFilters();
  },
    
    // classType(newSelectValue) {
    //   console.log("select property changed to:", newSelectValue);

    //   if (newSelectValue) {
    //     setTimeout(() => {
    //       const focusDrop = this.$refs.countrySelect.focus();
    //       if (focusDrop) {
    //         this.$refs.countrySelect.focus();
    //       }

    //       this.classSelect = false;
    //       console.log(this.classSelect);
    //     }, 100);
    //   }
    // },
   
  },

  computed: {
    filteredCountries() {
      return this.airitems.map((item) => {
        const parts = item.places.split(", ");
        if (parts.length >= 2) {
          const name = `${parts[1]} (${parts[0]})`;
          // const name = `${parts[0]} - ${parts[1]}`;
          const originalName = item.places;
          return {
            name,
            originalName,
          };
        }
      });
    },
  },

  created() {
    // this.getConfig();

    localStorage.removeItem("rounttrip");
    localStorage.removeItem("onewaytrip");

    this.activetrip = this.$route.query.tripType;

    console.log(this.activetrip,'activetripactivetrip')

    // this.autocompletJson();

    if (this.activetrip == "RT" || this.activetrip == "OW") {
      // this.roundData();
      this.mydata();
      this.airlineLogo_func();
      // this.filterShowing();
      this.tripData.class = this.$route.query.class;
      this.tripData.adult = this.$route.query.adult;
      this.tripData.child = this.$route.query.child;
      this.tripData.infant = this.$route.query.infant;
    } else {
      this.airlineLogo_func();
      this.roundModify = false;
      this.onewayModify = false;
      this.multiModify = true;
      this.activetrip = "Multicity";
      console.log(this.activetrip, "reeddd111....");
      
      // this.multiDataConvert();


      // this.paxhide = false
      // localStorage.removeItem("searchedData");

      // this.multi_city = JSON.parse(this.$route.query.muti_city);
      // console.log(this.multi_city.itinery, " this.multi_city this.multi_city");
      // this.tripData.from = this.multi_city.itinery[0].from;
      // this.tripData.to = this.multi_city.itinery[this.multi_city.itinery.length - 1].to;
      // this.tripData.dedate = moment(this.multi_city.itinery[0].departure).format("DD MMM-YYYY");
      // // this.tripData.redate=this.multi_city.itinery[this.multi_city.itinery.length-1].depdate
      // console.log(
      //   this.tripData.dedate,
      //   this.multi_city.itinery[0].depdate,
      //   "this.tripData.dedate"
      // );
      // this.tripData.class = this.multi_city.class;
      // this.tripData.adult = this.multi_city.adult;
      // this.tripData.child = this.multi_city.child;
      // this.tripData.infant = this.multi_city.infant;
      // this.classType = this.multi_city.class;

      // console.log(
      //   this.multi_city,
      //   "this.showMulti_datauerthis.showMulti_datay"
      // );

 
      // this.multicityresult();

      // this.filterShowing();

      // console.log(this.multi_city, "this.$route.querythis.$route.query....1");
      

      if (this.multi_city.itinery.length == 4) {
        console.log(
          this.multi_city,
          this.multi_city.itinery.length,
          "lengthlen.....gthlength"
        );
        this.addmore = false;
        this.clearall = true;
      }
    }

    if (this.activetrip == "RT") {
      console.log('validatecheckworking.....9')
      // this.sendedRoundData();
      this.activetrip = 'Roundtrip';
    }
    if (this.activetrip == "OW") {
      console.log('validatecheckworking.....10')
      // this.sendedone1();
      this.activetrip = 'Oneway';
    }
    this.generateBookingRefId();
    this.activeType(this.activetrip);

    this.clicked1 = true;
    this.clicked2 = true;
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("resize", this.handleCalenderResize);

  },

  mounted() {
     this.getConfig();
    
    this.activetrip = this.$route.query.tripType;

    if (this.activetrip == "RT" || this.activetrip == "OW") {
      this.roundData();
      this.activetrip = this.activetrip == "RT" ? "Roundtrip" : "Oneway" ;
    } else {     
      this.multiDataConvert();
      this.activetrip = "Multicity";
    }

    if(this.searchloader){
      document.getElementById("navbar").style.display = "none";
      document.getElementById("food").style.display = "none";
    }
    else{
      document.getElementById("navbar").style.display = "block";
      document.getElementById("food").style.display = "block";
    }
    // this.autocompletJson();
    // this.fetchItems();
    this.handleResize();
    this.handleCalenderResize();
  },
};
</script>

<style scoped>

:deep(.formshadow){
  box-shadow: none !important;
}

.disable-return{
  pointer-events:none;
  opacity:0.3;
 }

.custom-card {
  padding: 10px 10px;
  overflow: unset;
  transition: 2s;
  color: white;
}

.showSearch {
  width: auto;
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 6px;
  /* margin: 0px 8px; */
  box-shadow: 0px 1px 2px 0px gray;
}

/* Filter By Section*/

>>> .filter_icons .v-selection-control--density-default {
  --v-selection-control-size: 30px !important;
}

>>> .filter_icons .v-selection-control .v-label {
  font-size: 14px;
  font-weight: 500 !important;
}

>>> .filter_icons .v-selection-control__input .v-icon {
  font-size: 20px !important;
}

>>> .filter_icons .price_slider .v-slider-track {
  --v-slider-track-size: 3px !important;
}

>>> .filter_icons .price_slider .v-slider-thumb {
  --v-slider-thumb-size: 16px !important;
}

/* Filter By Section End*/

.bg-image {
  /* height: auto;
  background-image: url("../assets/tourist_vector.jpg");
  background-size: 100% 100%;
  background-position: left; */
  background-color: #2c127a24;
}

.dp__pointer {
  cursor: pointer;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0px;
  width: 100%;
}

>>> .dp__input_icon {
  cursor: pointer;
  position: absolute;
  top: 64%;
  left: 82%;
  color: black !important;
  transform: translateY(-50%);
  color: var(--dp-icon-color);
}

/* .mdi-menu-down::before {
  content: "\F035D222";
  display: none !important;
} */

.v-input__append > .v-icon {
  position: relative;
  right: 43px;
  top: -4px;
}

.dp__input_wrap {
  position: relative;
  width: 100%;
  box-sizing: unset;
  top: -10px;
}

.labeling {
  position: relative;
  bottom: 9px;
}

.input-styles .v-input--density-default {
  --v-input-control-height: 38px;
  position: relative;
  top: -22px;
}

.v-slider.v-input--horizontal {
  position: relative;
  top: 0px;
}

.cart-adult {
  padding: 15px;
  width: 500px;
  cursor: pointer;
  position: relative;
  top: -65px;
}

>>> .dp__clear_icon {
  display: black;
  position: absolute;
  top: 50%;
  /* right: 0; */
  transform: translateY(-50%);
  cursor: pointer;
}

>>> .dp__overlay_col[aria-disabled="true"] {
  display: none;
}

.adult {
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.adult-plus {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #05386d;
}

.adult-title {
  font-size: 12px;
  font-weight: 600;
  color: #05386d;
}

.price-head .price-currency {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  padding-top: 17px;
  padding-right: 6px;
}

.price-head .price-Amount {
  font-size: 36px;
  font-weight: 600;
  color: #000000;
}

.price-head .price-Decimal {
  color: #000000;
}

>>> .selected_btn .v-btn__content {
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase !important;
}

.radio {
  background-color: #3c0ec2 !important;
  color: white !important;
  box-shadow: 0 4px 16px rgba(17, 17, 26, 0.05),
    0 8px 32px rgba(17, 17, 26, 0.05) !important;
}

.comDetailSection .paxpricesection .table tr th,td{
  padding:4px 2px !important
 }

 >>>.comDetailSection .v-radio-group .v-selection-control__wrapper{
  width: 10px !important;
  margin-right: 6px !important;
 }

/* >>>.normal-radio .v-btn__content{
  color:black;
} */

.normal-radio {
  font-size: 12px !important;
  font-weight: 500;
  color: black;
  height: 28px;
  width: auto;
  max-width: 115px;
  padding-right: 10px;
  padding-right: 9px;
  border: 2px solid white;
  /* border: 1px solid #0000003d; */
  box-shadow: none;
  border-radius: 5px;
  text-transform: capitalize;
}

/* .normal-radio span {

 } */

/* .normal-radio {
  font-size: 12px !important;
  font-weight: 600;
  height: 30px;
  width: auto;
  color:black;
} */

.air-title {
  background-color: #ff5400;
  color: white;
  padding: 5px;
}

.cart1 {
  box-shadow: none;
  border: 1px solid lightgray;
  font-size: 15px;
  font-weight: 500;
  max-width: 208px;
  border-radius: 5px;
}

.cart1 p {
  text-align: center;
}

hr {
  margin: 0rem 0 !important;
}


.f-size-18 {
  font-size: 18px;
}

.f-size-16 {
  font-size: 16px;
}

.f-size-15 {
  font-size: 15px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-500 {
  font-weight: 500;
}

.rec-num {
  font-size: 14px;
  font-weight: 600;
  color: navy;
}

.bord {
  border-right: 2px solid gray;
  position: relative;
  top: -2px;
  height: 63px;
}

.depar {
  background-color: lightgray;
  padding: 3px;
}

.res-name {
  /* font-size: 15px; */
  font-weight: 500;
  margin: 0;
  /* margin-top: 5px; */
  /* padding: 0px 0px 0px 8px; */
}

.res-stop {
  font-size: 12px;
  font-weight: 600;
  color: grey;
}

/* .res-stop {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px !important;
  position: relative;
  top: 2px;
  right: 12px;
  color: grey;
} */

.res-time {
  font-size: 11px;
  font-weight: 500;
}

.dot-1 {
  width: 8px;
  height: 8px;
  border: 1px solid black;
  background-color: black;
  border-radius: 50%;
  position: relative;
  top: 42%;
  left: 30%;
  z-index: 1;
}

.dot-2 {
  width: 8px;
  height: 8px;
  border: 1px solid black;
  background-color: black;
  border-radius: 50%;
  position: relative;
  top: 18%;
  left: 50%;
  z-index: 1;
}

.res-icon {
  border: 1px solid #05386d;
  position: relative;
  top: 14px;
  width: 92%;
}

.res-icon1 {
  float: right;
  color: #05386d;
  font-size: 18px;
}

>>> .dp__menu_inner {
  padding: var(--dp-menu-padding);
  box-shadow: 0px 0px 4px 0px black;
  background-color: #f5f5f521;
}

>>> .v-input__details {
  display: none;
}

.v-input--density-default {
  --v-input-control-height: 38px;
}

.stop {
  font-size: 16px;
  font-weight: 500;
  padding: 7px;
}

.v-slider.v-input--horizontal .v-slider-thumb {
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border: 3px solid #3c3cde;
  border-radius: 50px;
}

.v-slider.v-input--horizontal .v-slider-track__fill {
  height: 3px;
  background-color: #7474e0;
}

/*  */
.v-slider-thumb__surface {
  height: 12px !important;
  width: 12px !important;
}

.v-checkbox .v-selection-control {
  min-height: var(--v-input-control-height);
  position: relative;
  top: 12px;
}

>>> .v-timeline-divider__dot--size-large {
  height: 22px;
  width: 56px;
}

>>> .v-timeline-divider__inner-dot {
  border-radius: 15px;
}

>>> .v-timeline-divider__inner-dot span {
  font-size: 11px;
  font-weight: 600;
}

.mdi-airplane-takeoff {
  content: "\F05D5";
  margin-top: 0px;
  margin-right: 10px;
  color: gray;
}

.mdi-airplane-landing {
  content: "\F05D4";
  margin-top: 0px;
  margin-right: 10px;
  color: gray;
}

>>> .dp__input_icon {
  display: none;
  top: 64%;
  left: -5% !important;
}

>>> .p-autocomplete-loader {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -0.5rem;
  left: 13 5px !important;
  display: block !important;
}

>>> .p-icon {
  display: none;
}

.p-error {
  font-size: 12px;
  color: white;
  position: absolute;
}

.dropdown-box {
  padding: 8px 10px;
  color: white;
  background-color: lightslategray;
  border-bottom: 1px solid white;
}

.dropdown-box:hover {
  background-color: black;
  color: white;
  border-radius: 10px;
  /* background-color: lightgray;
  color: black; */
}

>>> .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
}

.p-float-label {
  width: 100%;
  font-size: 16px;
  /* color: #242424; */
  font-weight: 600;
}

.p-float-label label {
  position: relative;
  pointer-events: none;
  top: -46px !important;
  left: -30px;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  /* font-size: 16px;
  color: #242424;
  font-weight:600; */
}

.p-component * {
  box-sizing: border-box;
  /* padding: 2px;
  padding-top: 4px;
  padding-bottom: 4px; */
}

>>> .p-input-filled {
  box-shadow: 1px 1px 6px 0px black !important;
}

.dp__icon {
  display: block;
}

.v-icon {
  --v-icon-size-multiplier: 1;
  align-items: center;
  justify-content: flex-start;
}

>>> .dp__input {
  border: none;
  /* border-bottom: 1px solid lightgrey !important; */
  border-radius: 0px !important;
  padding-left: 2px;
  padding-right: 2px;
  height: 26px;
  font-family: "Poppins", sans-serif !important;
}

.mdi-calendar {
  margin-right: 10px;
  width: auto;
  color: grey;
}

.calendar-container {
  display: flex;
  position: relative;
}

.label-top {
  position: absolute;
  top: -10px !important;
  left: -48px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  color: white;
  /* color: #242424; */
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label {
  position: absolute;
  top: 28px !important;
  left: -8px !important;
  transform: translateY(-110%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.label-top1 {
  position: absolute;
  top: 0 !important;
  left: -8px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label2 {
  position: absolute;
  top: 28px !important;
  left: -8px !important;
  transform: translateY(-110%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  font-weight: 600;
  color: #242424;
  font-size: 16px;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.multiLabel-top {
  position: absolute;
  left: -8px !important;
  bottom: 0px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 12px;
  color: #242424;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.multiDatepicker-label {
  position: absolute !important;
  top: 28px !important;
  left: -9px !important;
  transform: translateY(-100%);
  background-color: transparent !important;
  padding: 0 8px;
  font-size: 1rem;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.delete-icon {
  display: flex;
  justify-content: end;
}

.hoverData1 {
  position: absolute;
  left: 50%;
  top: -33px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverDataStop1 {
  position: absolute;
  left: 38.4%;
  top: -34px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
  height: 50px;
}

.hoverDataStop2 {
  position: absolute;
  left: 62%;
  top: -34px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
  height: 50px;
}

.content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* cursor: pointer; */
}

/* .content:hover{
  width:100%;
  font-size:13px;
} */
.hoverData2 {
  position: absolute;
  left: 63%;
  bottom: 63px;
  border-radius: 5px;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverData1.visible {
  visibility: visible;
  opacity: 1;
}

.hoverDataStop1.visible {
  visibility: visible;
  opacity: 1;
}

.hoverDataStop2.visible {
  visibility: visible;
  opacity: 1;
}

.hoverData2.visible {
  visibility: visible;
  opacity: 1;
}

.hoverData1::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.hoverDataStop1::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.hoverDataStop2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.hoverData2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.refunt {
  color: blue;
}

>>> .v-timeline--vertical.v-timeline {
  grid-row-gap: 1px;
}

>>> .v-timeline--vertical.v-timeline {
  /* grid-row-gap: 24px; */
  height: auto !important;
}

.filter-toggle {
  display: none;
}

.v-card {
  border-radius: 10px !important;
  z-index: revert-layer;
  overflow: unset;
}

.hoveredCard {
  border: 1px solid #ccc;
  border-radius: 1px !important;
}

.hoveredCard:hover {
  cursor: pointer;
  box-shadow: 0px 0px 3px 3px #1700ff29;
  /* box-shadow: 0px 0px 4px 0px black; */
  /* box-shadow: none; */
  /* border: 2px solid lightgray; */
  /* border-radius: 8px !important; */
}

/* .card-in {
  box-shadow: none;
} */

/* .card-in:hover {
  box-shadow: 0px 0px 1px 0px black;
} */

.card-in {
  border-top: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
  border-radius: 0 !important;
  box-shadow: none !important;
  /* box-shadow: 0px 0px 1px 0px black; */
}

.time-section .v-icon {
  font-size: 12px !important;
  color: grey;
}

.time-section span {
  font-size: 9px;
  margin-left: 5px;
  font-weight: 500;
  color: grey;
}

.clear_style {
  border-radius: 5px;
  padding: 4px 6px;
  height: 30px;
  color: white;
  background: red;
}

/* .clear_style:hover {
  background-color: #ffe9e9;
  color:black !important;
} */

.add_style{
    border-radius: 5px;
    padding: 4px;
    height: 30px;
    cursor: pointer;
    color: white;
    background: #9aca0f;
}
.passengers-data .input-styles{
  border-radius:2rem;
}
>>>.passengers-data .v-select__menu-icon{
    color:black;
  }
/* .add_style:hover {
  background-color: #e9ffe9;
} */

@media only screen and (max-width: 991px) {
  .filter-toggle {
    display: block;
  }

  .magnify-btn .v-btn {
    width: 140px !important;
  }
  .magnify-btn1 .v-btn {
    width: 140px !important;
  }
  .Timing-section {
    padding: 0px 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .filter-toggle {
    display: block;
  }

  .airline-logo {
    width: auto;
  }

  .airline-logo .v-img {
    width: 65%;
  }

  .res-name {
    /* font-size: 15px; */
    font-weight: 500;
    margin: 0;
    /* margin-top: 5px; */
    /* padding: 0px 0px 0px 12px; */
  }

  /* .res-stop {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0px !important;
    position: relative;
    top: 2px;
    right: 10px;
    color: grey;
  } */

  .time-section .v-icon {
    font-size: 12px !important;
    color: grey;
  }

  .time-section span {
    font-size: 9px !important;
    font-weight: 500 !important;
    color: grey;
  }

  .airline-logo {
    padding: 5px;
  }

  .airline-logo .v-img {
    width: 20px !important;
  }

  .airline-profile {
    padding: 0;
  }

  .airline-name {
    font-size: 8px;
  }

  .airline-id {
    font-size: 6px;
  }

  .aircode-name {
    font-size: 11px;
  }

  .airline-date {
    font-size: 8px;
  }

  .airline-time {
    font-size: 10px;
  }

  .class-type .v-icon {
    font-size: 11px !important;
  }

  .class-type span {
    font-size: 9px;
  }
}

@media only screen and (min-width: 479px) {
  .aircode-hide {
    display: block;
  }

  .aircode-show {
    display: none;
  }

  .airlineLogo-hide {
    display: flex !important;
  }

  .airlineLogo-show {
    display: none !important;
  }

  .select-section-hide {
    display: flex !important;
  }

  .select-section-hide1 {
    display: flex !important;
  }

  .date-section-hide {
    display: block;
  }

  .date-section-show {
    display: none;
  }

  .fare-detail-mobile {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .hoverDataStop1 {
    left: 38%;
  }

  .hoverDataStop2 {
    left: 59%;
  }

  .airlineLogo-hide {
    display: none !important;
  }

  .airlineLogo-show {
    display: block !important;
  }

  /* .pax-fonts span[data-v-92fd54d6] {
    font-size: 12px;
    color: #242424;
    font-weight: 700;
  } */
  .pax-fonts span{
    font-size:14px !important;
  }
  >>>.modify-data .v-select__selection-text{
    font-size:14px !important;
  }
  >>>.passengers-data .v-select__menu-icon{
    margin-inline-start:0px !important;
  }
  >>>.passengers-data .v-field__input{
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }
  >>>.passengers-data .v-field{
    grid-template-columns: 0% 86%;
  }
  >>>.passengers-data .modify-data .v-field{
    grid-template-columns: 0% 77%;
  }

  .aircode-hide {
    display: none;
  }

  .aircode-show {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .select-section-hide {
    display: none !important;
  }

  .select-section-hide1 {
    display: none !important;
  }
  .price-head .price-currency{
    padding-top:10px;
  }

  .res-name {
    font-size: 10px;
    font-weight: 500;
    margin: 0;
  }

  .airlineLogo-show .mbl-price-section {
    margin-bottom: 0px !important;
  }

  .airlineLogo-show .mbl-price-section .price-currency {
    margin-bottom: 0px !important;
    font-size: 14px !important;
    font-weight: 600;
    color: black;
    padding-top: 10px;
    padding-right: 4px;
  }

  .airlineLogo-show .mbl-price-section .price-Amount {
    margin-bottom: 0px !important;
    font-size: 26px !important;
  }

  .airlineLogo-show .mbl-price-section .content {
    margin-bottom: 0px !important;
  }

  .airlineLogo-show .v-btn {
    height: 30px !important;
  }

  >>> .airlineLogo-show .v-btn .v-btn__content {
    font-size: 15px !important;
    /* padding-left: 7px; */
  }

  .date-section-hide {
    display: none;
  }

  .date-section-show {
    display: block;
  }

  .Timing-section {
    padding: 0px 20px !important;
  }

  .fare-detail-mobile {
    display: block;
  }

  .fare-detail-mobile .v-card-text {
    color: rgb(51, 102, 204);
    font-weight: 500;
    padding-left: 0 !important;
    text-align: left;
    width: auto !important;
  }

  .fare-detail-mobile .v-icon {
    font-size: 24px !important;
    height: 18px !important;
    width: 24px !important;
    position: relative !important;
    right: 60px !important;
    top: 2px !important;
  }

  .itin_Borders {
    box-shadow: inset 0px 0px 3px 0px black;
    padding-top: 16px;
    border: 1px solid black;
    margin-bottom: 8px;
    border-radius: 4px;
  }
}

>>> .v-field__outline {
  display: none;
}

.input-styles {
  box-shadow: 0px 1px 2px 0px gray;
  padding: 5px;
  background: white;
  /* background: #ffffff82; */
  border-radius: 7px;
  height: 38px;
}

.input-styles-1 {
  box-shadow: 0px 1px 2px 0px gray;
  padding: 5px;
  background: #ffffff82;
  border-radius: 7px;
  height: 30px;
  width: 100%;
}

.form-card {
  width: 90%;
  padding: 20px;
  overflow: unset;
  z-index: 1;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: none !important;
}

.back-bg {
  margin: 20px;
  width: 500px;
  height: 500px;
  border-radius: 30px;
}

.fromTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 20px;
  top: 60px;
  color: gray;
}

.toTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 52%;
  top: 60px;
  color: gray;
}

>>> .normal-radio .v-label {
  font-weight: 600 !important;
  opacity: 1 !important;
}

.round-mode,
.oneway-mode {
  padding: 10px 10px;
  overflow: unset;
  box-shadow: none;
  border: 2px solid lightgray;
  border-radius: 5px !important;
}

/* Traveller Pax Dropdown section */

>>> .input-styles .select-1 {
  --v-field-padding-top--plain-underlined: 12px;
}

/* Traveller Pax Dropdown section End */

.p-float-label .p-inputwrapper-focus ~ label {
  /* display:none !important; */
  /* font-size: 14px !important;
  top: -1.2rem !important;
  left: -38px; */
  /* color: white; */
  font-weight: 600;
}

.p-float-label .p-inputwrapper-filled ~ label {
  /* display:none !important; */
  /* font-size: 14px !important; */
  /* top: -1.2rem !important;
  left: -38px; */
  /* color: white; */
  /* color: #242424; */
  font-weight: 600;
}

.p-inputtext {
  font-size: 16px !important;
  font-weight: 600px !important;
}
.pax-fonts span {
  font-size: 16px;
  color: #242424;
  font-weight: 600;
}

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}

>>> .dp__month_year_select {
  pointer-events: none;
}

>>> .dp__today {
  border: 1px solid #162849 !important;
}

>>> .dp__date_hover:hover {
  background: #162849;
  color: white;
}

/* >>> .dp__menu.dp__menu_index.dp__theme_light.dp__menu_transitioned {
  top: -10px !important;
  left: -160px !important;
  transform: none !important;
} */

#navbar {
  display: block;
}

#food {
  display: block;
}

/* >>>.v-overlay__content{
  left:950px !important;
} */

.toggle {
  width: 120px;
  background: white;
  color: tomato;
  border: 0;
  padding: 5px;
  cursor: pointer;
}

.close {
  background: white;
  border: 0;
  cursor: pointer;
}

.right-drawer {
  position: absolute;
  top: 0;
  right: 0;
  width: 0; /* initially */
  overflow: hidden;
  height: 100vh;
  padding-left: 0; /* initially */
  border-left: 1px solid whitesmoke;
  background: white;
  z-index: 200;
  transition: all 0.2s;
}

.drawer-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 0; /* initially */
  height: 100vh;
  background: transparent;
  opacity: 0.3;
  z-index: 199;
  transition: opacity 0.2s;
}
/* >>> .modify-data .select-2 .v-field__input {
  padding-top: 23px !important;
}
>>> .modify-data .select-2 .v-field__append-inner {
  padding-top: 23px !important;
} */
>>> .modify-data .v-select__selection-text {
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  padding-left: 5px;
}

/* modal */
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.modal-content {
  background-color: white;
  padding: 1rem 1.5rem;
  width: 80%;
  margin: auto;
  /* top: 8%; */
  border-radius: 0.5rem;
  /* position: absolute; */
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 2.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  color: gray;
}

.close-button:hover {
  color: darkgray;
}

.trigger {
  /* padding: 0.5rem 1rem; */
  /* background-color: #007bff; */
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}
.modify-btn {
  display: none !important;
}
.modify-btn-web {
  display: flex !important;
}

.magnify-btn2 {
  display: none !important;
}
.magnify-btn1 {
  display: block !important;
}
.passengers-data .input-styles{
  height:28px;
}
>>>.passengers-data .input-styles .v-input--density-default{
  top:-27px;
}
@media screen and (max-width: 480px) {
  /* .modify-form .col-12 {
    width: 95% !important;
  } */
  .modal-content {
    width: 100%;
  }
  .modify-btn {
    display: block !important;
  }

  /* >>>.modify-btn .v-btn .v-btn--elevated .v-theme--light .v-btn--density-default .v-btn--size-default .v-btn--variant-elevated{
    height: 66px !important;
    margin-top: 8px !important;
  } */
  .modify-btn-web {
    display: none !important;
  }
  .f-size-24 {
    font-size: 16px !important;
  }
  .magnify-btn2 {
    display: flex !important;
  }
  .magnify-btn1 {
    display: none !important;
  }
  .magnify-btn {
    display: none !important;
  }
  /* .collapse-row {
    padding-left: 12px;
    padding-right: 15px;
  } */
}
.f-size-24 {
  font-size: 24px;
}
.price-col {
  display: none;
}

@media screen and (min-width: 481px) and (max-width: 992px) {
  .price-col {
    display: block;
  }
  .airportName{
    display:none;
  }
}

>>>.outbound .v-input{
  border:1px solid lightgray;
  border-radius:8px;
}
>>>.inbound .v-input{
  border:1px solid lightgray;
  border-radius:8px;
}
/* >>>.outbound .v-select__selection .v-select__selection-text{
  --v-input-padding-top: 6px !important;
} */

>>>.outbound .v-field{
  height:35px;
}
>>>.outbound .v-field .v-field__field{
  height:35px;
  top:-10px;
}
>>>.outbound .v-field__input .v-input--density-default{
   --v-input-padding-top: 6px !important;
}
>>>.inbound .v-field{
  height:35px;
}
>>>.inbound .v-field .v-field__field{
  height:35px;
  top:-10px;
}
>>>.inbound .v-field__input .v-input--density-default{
   --v-input-padding-top: 6px !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.autocompete-menual-dropdown {
  border: 1px solid lightgray;
  background-color: white;
  color:black;
  cursor: pointer;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  width: 300px;
  /* max-width: 100%; */
  padding: 4px;
  border-radius: 2px;
  margin-top: 18px;
  /* margin-left: -44px; */
  z-index: 99999;
  font-size: 15px;
  font-weight: 500;
}
.inter-input {
  width: 100%;
  background: transparent;
  border: none;
  font-size: 15px;
  font-weight: 600;
  outline: none !important;
}
.inter-input::placeholder {
  color: black !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    font-family:inherit !important;
  }
.vas-autocomplete {
  cursor: pointer;
}
.va-content {
  font-size: 14px;
  font-weight: 500;
  text-align: start;
}
.va-back:hover {
  background-color: lightblue;
}
.labelTop p {
  font-size: 15px;
  margin: 0;
  text-align: left;
  color: white;
  /* color: #3c0ec2; */
  font-weight: 600;
  padding-left: 13px;
}
.dp__pointer {
  cursor: pointer;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0px;
  width: 100% !important;
  border-radius: 50% !important;
  margin: 8px !important;
  outline: none !important;
}

>>>.datepicker input::placeholder{
    color: black !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    font-family:inherit !important;
  /* margin-bottom:4px; */
}
>>>.datepicker input{
  font-weight:600 !important;
  font-family:inherit !important;
  outline: none !important;
}
/* .trigger:hover {
    background-color: #0056b3;
} */
</style>
