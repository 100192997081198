<template>

<div style="background: white;">

<section v-html="privacyContent" class="pb-5"></section>
</div>
</template>



<script>

import axios from "axios";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
    data() {
        return {

           getType: "",
            dataloader: true,
            getcontentUrl:"",
            getLangname:"",
            
            privacyContent:"",
        }
    },

    methods: {

        getConfig() {
            // let getConfigData = this.$store.state.data

            // let portalLocal = localStorage.getItem("portalData")
            // let getConfigData = JSON.parse(portalLocal)
            const getConfigData = getConfigDataFromLocalStorage();
            if (getConfigData) {
                let locale = this.getLangname;
                let getsiteContentData = getConfigData.payload.portal_configuration.site_content_data[locale]
                this.getcontentUrl = getsiteContentData.privacypolicy.url
                this.getType = getsiteContentData.privacypolicy.type

                this.getConfigFunc();
            }
        },

        getConfigFunc() {
            axios.get(this.getcontentUrl, {
                headers: {
                    "Content-Type": this.getType,
                },
            })
                .then((response) => {

                    this.privacyContent = response.data
                    console.log(response.data, "getcontentUrlrgetcontentUrlrgetcontentUrlrab.....")
                    this.dataloader = false;

                })

                .catch((error) => {
                    this.dataloader = true;
                    console.log(error, 'erroroor.....')
                })
        },
        updateContent() {
            this.getLangname = this.$i18n.locale;
            console.log(this.$i18n.locale, 'getcontententUrl...1')
            this.getConfig();
        }
    },

    watch: {
		"$i18n.locale": {
			handler: function () {
				this.updateContent();
				this.getConfig();
				this.getConfigFunc();
			},
			deep: true,
		},
	},

    created() {
        this.updateContent();
        // this.getLangname = this.$i18n.locale
        // this.getConfig();
        this.getConfigFunc();
    }
}

</script>