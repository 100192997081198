<template>
  <div class="pagewrap modify-search">
    <!-- body section -->
    <div class="content-section">
      <!-- flight result page - oneway start -->
      <section class="theme-bg-white py-5">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-2 mb-4 mb-md-0">
              <div class="d-flex flex-column theme-border-radius theme-bg-white theme-box-shadow">
                <div class="d-flex flex-column p-3">
                  <span class="text-center mb-3 profile-pic">
                    <v-icon>mdi-account-circle</v-icon>
                    <a class="edit-btn"><i class="bi bi-pencil p-3"></i></a>
                  </span>
                  <!-- <span class="font-medium">Kislay Chhimwal</span> -->
                  <span class="font-small">{{ $t( $t("myProfile.headings.personalProfile"))}}</span>
                </div>
                <a href="/myprofile" class="border-bottom p-3 pt-0 theme-text-accent-one d-flex align-center">
                    <i class="bi bi-person fs-4 align-middle"></i>
                    <span class="ms-1">{{ $t('myProfile.headings.profile') }}</span></a>
                <a class="border-bottom p-3 theme-text-accent-one">
                  <div class="d-flex">
                    <span>
                      <v-img :src="require('@/assets/myBooking_icon.png')" class="me-1" width="21px" />
                      </span>
                    <span>{{ $t( $t("viewBooking.buttons.myBookings"))}}</span>
                  </div>
                </a>
                <!-- <a
                    href="#travellers"
                    class="border-bottom p-3 theme-text-accent-one"
                    ><i class="bi bi-people fs-4 me-2 align-middle"></i
                    >Travellers</a
                  > -->
              </div>
            </div>

            <div class="col-md-10">
              <div class="row mx-1">
                <!-- <div class="col-md-4" v-for="items in bookingItems" :key="items">
                            <v-card>
                                <div class="d-flex p-3">
                                    <v-icon size="50">{{ items.icons }}</v-icon>
                                    <div class="ms-3">
                                        <p style="font-size:22px;" class="mb-0 fw-600">{{ items.bookHead }}</p>
                                        <p style="font-size:20px;" class="mb-0 fw-600">{{bookingdata.length  }}</p>
                                    </div>
                                </div>
                            </v-card>
                        </div> -->
                <v-card>
                  <v-tabs v-model="tab" align-tabs="center" bg-color="" stacked>
                    <v-tab value="tab-1">
                      <!-- <v-icon icon="mdi-phone"></v-icon> -->

                      {{ $t("viewBooking.header.heading1") }}
                    </v-tab>

                    <v-tab value="tab-2" v-if="completedData.length > 0">
                      <!-- <v-icon icon="mdi-heart"></v-icon> -->

                      {{ $t("viewBooking.header.heading2") }}
                    </v-tab>

                    <v-tab value="tab-3" v-if="cancelledData.length">
                      <!-- <v-icon icon="mdi-account-box"></v-icon> -->

                      {{ $t("viewBooking.header.heading3") }}
                    </v-tab>
                  </v-tabs>
                  <v-card-text class="pt-0 px-lg-2 p-0">
                    <keep-alive>
                      <v-window v-model="tab">
                        <div v-if="tab === 'tab-1'">
                          <div class="row g-0 px-3">
                            <div class="col-12 border-bottom p-2 p-md-5">
                              <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                  <v-img :src="require('../assets/booking-baggage.png')" class="img-fluid"></v-img>
                                </div>
                                <div class="flex-grow-1 ms-5">
                                  <span class="d-flex fs-5 fw-bold">
                                    {{ $t("viewBooking.contents.emptyText1") }}</span>
                                  <span
                                    class="d-flex font-medium theme-text-accent-one my-3"
                                  >
                                    {{ $t("viewBooking.contents.emptyText2") }}
                                  </span>
                                  <v-btn to="/" class="btn btn-effect btn-book text-white px-5" color="#94c20e">
                                    <span class="text-uppercase">{{ $t("viewBooking.buttons.bookNow") }}</span>
                                  </v-btn>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="tab === 'tab-2'">
                          <div v-for="(data, index) of completedData" :key="index"
                            class="row g-0 align-items-center border-bottom p-3 align-self-center">
                            <div class="col-12 col-md-9 mb-3 mb-md-0 d-flex align-center">
                              <div class="me-3">
                                  <v-icon size="26" style="transform: rotate(90deg);">mdi-airplane</v-icon>
                              </div>
                              <div>
                              <!-- <span class="fw-bold"> -->
                                <!-- <span class="text-uppercase">{{
                                  getCountrycode(data.travel_segment)
                                }}</span> -->
                                <!-- <i
                                  v-if="getArrow(data.travel_segment)"
                                  class="bi bi-arrow-right mx-2"
                                ></i>
                                <span class="text-uppercase">{{
                                  getCountrycode1(data.travel_segment)
                                }}</span> -->
                                <!-- <i v-if="getArrow1(data.travel_segment)" class="bi bi-arrow-right mx-2"></i>
                                <span class="text-uppercase">{{ getCountrycode2(data.travel_segment) }}</span>
                                <i v-if="getArrow2(data.travel_segment)" class="bi bi-arrow-right mx-2"></i>
                                <span class="text-uppercase">{{ getCountrycode3(data.travel_segment) }}</span> -->
                              <!-- </span> -->

                              <!-- <span
                                class="font-small theme-text-accent-two"
                                style="color: grey; font-weight: 500"
                              >
                                - Travel Date :
                                {{ gettravelDate(data.travel_date) }}</span
                              > -->

                                <div class="d-flex align-center" v-if="getTripType(data.travel_segment) !== 'multi'">

                                  <span class="fw-bold f-size-14">
                                    <!-- <span class="text-uppercase">{{
                                      data.depair
                                    }}</span> - <span class="text-uppercase">{{
                                        data.arrair
                                      }}</span> -->
                                      {{getCityCode(data.travel_segment)}}
                                      </span>
                                </div>

                                <div class="d-flex align-center" v-if="getTripType(data.travel_segment) == 'multi'">
                                  <span class="fw-bold mb-2 f-size-12">
                                    <!-- <span class="text-uppercase">{{ data.multiair }}</span> -->
                                    <span class="fw-bold f-size-14 text-uppercase">{{getCityCode(data.travel_segment)}}</span>
                                  </span>
                                </div>

                                <ul class="d-flex ps-0 flex-wrap">
                                  <li class="font-small fw-bold">{{ $t("viewBooking.header.heading2") }}</li>
                                  <li class="font-small fw-bold text-capitalize">
                                    <i class="bi bi-circle-fill font-extra-small mx-2"></i>
                                    {{ getTripType(data.travel_segment) }}
                                  </li>
                                  <li class="font-small theme-text-accent-two fw-500" style="color: grey;">
                                    <i class="bi bi-circle-fill font-extra-small ms-sm-2 ms-0 me-2"></i>{{
                                      $t("viewBooking.lebels.bookingId") }} :
                                    {{ data.booking_reference }}
                                  </li>
                                  <li class="font-small theme-text-accent-two fw-500" style="color: grey;">
                                    <i class="bi bi-circle-fill font-extra-small ms-sm-2 ms-0 me-2"></i>
                                     {{$t("viewBooking.lebels.bookingDate") }} :
                                    {{ getbookingDate(data.booking_datetime) }}
                                  </li>
                                  <li class="font-small theme-text-accent-two fw-500" style="color: grey;">
                                    <i class="bi bi-circle-fill font-extra-small ms-sm-2 ms-0 me-2"></i>
                                    {{$t("viewBooking.lebels.travelDate") }} :
                                    {{ gettravelDate(data.travel_date) }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-12 col-md-3 flex-grow-1 text-md-end text-center">
                              <v-btn type="submit" class="btn btn-effect btn-book px-3" style="
                                  background-color: #3c0ec2;
                                  color: white;
                                ">
                                <span class="text-uppercase" @click="bookingview(data)">{{
                                  $t("viewBooking.buttons.viewBooking")
                                }}</span>
                              </v-btn>
                            </div>
                          </div>
                        </div>
                        <div v-if="tab === 'tab-3'">
                          <div v-for="(data, index) of cancelledData" :key="index"
                            class="row g-0 align-items-center border-bottom p-3 align-self-center">
                            <div class="col-12 col-md-9 mb-3 mb-md-0 d-flex align-center">
                              <div class="me-2">
                                  <v-icon size="32" style="transform: rotate(45deg);">mdi-airplane</v-icon>
                              </div>
                              <div>
                              <!-- <span class="fw-bold">
                                <span class="text-uppercase">{{
                                  getCountrycode(data.travel_segment)
                                }}</span>
                                <i
                                  v-if="getArrow(data.travel_segment)"
                                  class="bi bi-arrow-right mx-2"
                                ></i>
                                <span class="text-uppercase">{{
                                  getCountrycode1(data.travel_segment)
                                }}</span>
                              </span> -->

                              <div class="d-flex align-center" v-if="getTripType(data.travel_segment) !== 'multi'">
                                
                                <span class="fw-bold mb-2 f-size-12">
                                  <span class="text-uppercase">{{
                                    data.depair
                                  }}</span> - <span class="text-uppercase">{{
                                      data.arrair
                                    }}</span></span></div>

                              <div class="d-flex align-center" v-if="getTripType(data.travel_segment) == 'multi'">
                                <span class="fw-bold mb-2 f-size-12">
                                  <span class="text-uppercase">{{ data.multiair }}</span>
                                </span>
                              </div>
                              <!-- <span class="font-small theme-text-accent-two" style="color: grey; font-weight: 500">
                                - Travel Date :
                                {{ gettravelDate(data.travel_date) }}</span> -->
                              <ul class="d-flex ps-0 flex-wrap">
                                <li class="font-small fw-bold"> {{ $t("viewBooking.header.heading3") }}</li>
                                <li class="font-small fw-bold">
                                  <i class="bi bi-circle-fill font-extra-small mx-2"></i>
                                  {{ getTripType(data.travel_segment) }}
                                </li>
                                <li class="font-small theme-text-accent-two" style="color: grey; font-weight: 500">
                                  <i class="bi bi-circle-fill font-extra-small ms-sm-2 ms-0 me-2"></i>{{
                                    $t("viewBooking.lebels.bookingId") }} -
                                  {{ data.booking_reference }}
                                </li>
                                <li class="font-small theme-text-accent-two" style="color: grey; font-weight: 500">
                                  <i class="bi bi-circle-fill font-extra-small ms-sm-2 ms-0 me-2"></i>
                                  {{$t("viewBooking.lebels.bookingDate") }} :
                                  {{ getbookingDate(data.booking_datetime) }}
                                </li>
                                <li class="font-small theme-text-accent-two" style="color: grey; font-weight: 500">
                                  <i class="bi bi-circle-fill font-extra-small me-2"></i>
                                  {{$t("viewBooking.lebels.travelDate") }} :
                                  {{ gettravelDate(data.travel_date) }}
                                </li>
                              </ul>
                              </div>
                            </div>
                            <div class="col-12 col-md-3 flex-grow-1 text-md-end text-center">
                              <v-btn type="submit" class="btn btn-effect btn-book px-3" style="
                                  background-color: #3c0ec2;
                                  color: white;
                                ">
                                <span class="text-uppercase" @click="bookingview(data)">
                                  {{$t("viewBooking.buttons.viewBooking") }}
                                  </span>
                              </v-btn>
                            </div>
                          </div>
                        </div>
                      </v-window>
                    </keep-alive>
                  </v-card-text>
                </v-card>
              </div>

              <div class="mt-3 d-none">
                <v-card class="p-3">
                  <div class="col-md-3 my-2">
                    <h3>My Bookings</h3>
                  </div>

                  <v-divider></v-divider>

                  <div>
                    <table class="table table-hover text-center table-responsive">
                      <thead>
                        <tr>
                          <th scope="col">S.no</th>
                          <th scope="col">Booking Id</th>
                          <th scope="col">Booking Date</th>
                          <th scope="col">Travel Segment</th>
                          <th scope="col">Travel Date</th>
                          <th scope="col">Booking Amount</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="data-list" v-for="(details, index) of bookingdata" :key="index">
                          <th scope="row">{{ index + 1 }}</th>
                          <td style="font-size: 13px">
                            {{ details.booking_reference }}
                          </td>
                          <td style="font-size: 13px">
                            {{ details.booking_datetime }}
                          </td>
                          <td style="font-size: 13px">
                            {{ details.travel_segment }}
                          </td>
                          <td style="font-size: 13px">
                            {{ details.travel_date }}
                          </td>
                          <td style="font-size: 13px">
                            {{ details.total_fare }}
                          </td>
                          <td style="font-size: 13px">
                            {{ details.booking_status }}
                          </td>
                          <td style="font-size: 13px">
                            <v-icon size="20" @click="bookingview(details)">mdi-eye</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <!-- <div v-if="overlay || !this.bookingdata.length > 0">
    <dataloader></dataloader>
  </div> -->
</template>

<script>
import moment from "moment";
import axios from "axios";
// import dataloader from "@/components/loaderComponent.vue";
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
export default {
  components: {
    // dataloader,
  },
  data() {
    return {
      dialog: false,
      overlay: false,
      mybooking: false,
      tab: null,
      usermail: "",
      userName: "",
      bookingItems: [
        {
          icons: "mdi-airplane-takeoff",
          bookHead: "Total Bookings",
        },
      ],

      bookingdata: [],
      userBookinglist: {
        request_type: "user_booking_listing",
        pax_email: "qatesting1@vibrace.com",
        user_id: "123789",
        portal_id: "port5678",
        token: "jbsdjhushdj",
      },
      retreivebooking: {
        request_type: "retreive_booking",
        booking_id: "1234qwert",
        pnr: "asddf12",
        last_name: "ldkdf",
        portal_id: "port5678",
      },
      fromDataa: "",
      retriveData: [],
      itineryData: [],
      routtime: [],
      flightsegmet: [],
      flightsegmetkey: [],
      roundOfferId: [],
      airlogodata: [],
      airline_LogoData:[],
      airlogoApi:"",
      Journey1: [],
      Journey: [],
      rountresult: [],
      returnData: [],
      tripId: "",
      bookingDate: "",
      baseprice: "",
      currency: "",
      totalprice: "",
      passengerDetail: [],
      depatureData: [],
      arrivalData: [],
      fromAirports: [],
      datareurn: false,
      triptype: "",
      contactnum: "",
      passEmail: "",
      bookingStatus: "",
      requestedData: [],
      cancelledData: [],
      failedData: [],
      completedData: [],
      responseData: [],
      bookingListingUrl: "",
      portalId: "",
    };
  },
  methods: {
    seleceprint() {
      const printContent = document.getElementById("printMe");
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContent.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
    },
    getCityCode(data){
      // let code = data.split("(");
      // console.log(code[0],'cityCodee')
      // return code[0]
       data = data.replace(/\(.*?\)/g, '').trim();

      const segments = data.split(" ");

      return segments.join('  ➔  ');
    },

    getdob($event) {
      return moment($event).format("YYYY-MMM-DD");
    },
    getdepdate(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getdeptime(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },
    getarrdate(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getarrtime(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },

    getdepcode(data) {
      console.log(data, "pppp");
      return data[0].depart_airport;
    },
    getarrcode(data) {
      console.log(data, "pppp");
      return data[data.length - 1].arrival_airport;
    },

    getstarttime(data) {
      let depdata = data[0].depart_datetime.split("T");
      return moment(depdata[0]).format("ddd,MMM DD,YYYY");
    },

    getdepdate1(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getdeptime1(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },
    getarrdate1(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getarrtime1(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },

    getTripType(data) {
      let type = data.split('(')
      let triptype = type[type.length - 1].split(")")[0]
      // console.log(triptype,'asdasdastripstypesdasdasd')
      return triptype
    },

    getArrow(data) {
      let cityName = data.split(" ");
      if (
        cityName[1] == "(Oneway)" ||
        cityName[1] == "(oneway)" ||
        cityName[1] == "(OneWay)"
      ) {
        return false;
      } else {
        return true;
      }
    },
    getArrow1(data) {
      let cityName = data.split(" ");
      if (
        cityName[3] == "(multi)" ||
        cityName[cityName.length - 1] == "(multi)"
      ) {
        return true;
      } else {
        return false;
      }
    },
    getArrow2(data) {
      let cityName = data.split(" ");
      if (
        cityName[4] == "(multi)" ||
        cityName[cityName.length - 1] == "(multi)"
      ) {
        return true;
      } else {
        return false;
      }
    },





    getcitynameFrom(data) {
      console.log(data, 'getcitynameFromdata....1')
      return data.city
    },
    getcitynameTo(data) {
      console.log(data, 'getcitynameFromdata....2')
      return data.city
    },







    getCountrycode(data) {


      let cityName = data.split(" ");

      // if(cityName.length > 2){

      //   // let getvalue = cityName.splice(-1);
      //   console.log(cityName.splice(-1),'resultttt...1')

      //   return cityName.splice(-1);
      // }
      if (cityName.length == 2 && cityName.length <= 2) {
        console.log(cityName, 'resultttt...2')
        let fromCity = cityName[0];
        return fromCity;
      }
      else {
        console.log(cityName, 'resultttt...3')
        let fromCity = cityName[0];
        return fromCity;
      }

    },
    getCountrycode1(data) {
      let cityName = data.split(" ");
      // console.log(cityName,'cityNameeee')
      if (
        cityName[1] == "(Oneway)" ||
        cityName[1] == "(oneway)" ||
        cityName[1] == "(OneWay)"
      ) {
        return "";
      } else {
        this.fromDataa = cityName[1];
        // console.log(this.fromDataa,'resultttt')
        return this.fromDataa;
      }
    },
    getCountrycode2(data) {
      let cityName = data.split(" ");
      // console.log(cityName, 'cityNameeee')
      if (
        cityName[3] == "(multi)" ||
        cityName[cityName.length - 1] == "(multi)"
      ) {
        return cityName[2];
      } else {
        return "";
      }
    },
    getCountrycode3(data) {
      let cityName = data.split(" ");
      // console.log(cityName, 'cityNameeee')
      if (cityName[4] == "(multi)") {
        return cityName[3];
      } else {
        return "";
      }
    },

    getbookingDate(getDate) {
      let dateSeperate = getDate.split("T")[0];
      let dateFormat = moment(dateSeperate).format("DD-MM-YYYY");
      return dateFormat;
    },

    gettravelDate(getDate) {
      let dateFormat = moment(getDate).format("DD-MM-YYYY");
      return dateFormat;
    },

    separateBooking() {
      this.completedData = [];

      console.log(this.bookingdata, "dataarray");
      let $req = [];
      let $cancel = []
      let currentdate = new Date();
      let preDate = [];

      this.bookingdata.forEach((v) => {
        console.log(currentdate,'=======',new Date(v.travel_date),'BS=',v.booking_status,"tripType= ",v.travel_segment);
        // if (
        //   v.booking_status == "requested" ||
        //   (v.booking_status == "Requested" &&
        //     currentdate > new Date(v.travel_date))
        // ) {
        //   $req.push(v);
        // } else if (
        //   v.booking_status == "requested" ||
        //   (v.booking_status == "Requested" &&
        //     currentdate <= new Date(v.travel_date))
        // ) {
        //   preDate.push(v);
        // }

        // if (v.booking_status == "cancelled") {
        //   $cancel.push(v)
        // }
        const normalizedStatus = v.booking_status.toLowerCase();
        const travelDate = new Date(v.travel_date);

        if (normalizedStatus === "requested") {
          if (travelDate < currentdate) {
            $req.push(v);
          } else {
            preDate.push(v);
          }
        } else if (normalizedStatus === "cancelled") {
          $cancel.push(v);
        }
      });
      this.completedData = $req;
      this.cancelledData = $cancel;
      console.log(this.completedData, "completsedDatacompletedsData");
      console.log(this.cancelledData, "cancelledDatacancelledData");
      console.log(preDate, "preDatsepreDastepreDdatepreDatse");

      //   data.forEach(booking => {
      //   const travelDate = booking.travel_date;
      //   const status = booking.booking_status
      //   let date = moment(new Date()).format("YYYY-MM-DD")
    },
    bookinglist() {

      this.userBookinglist.pax_email = this.usermail
      this.userBookinglist.user_id = this.usermail
      this.userBookinglist.portal_id = this.portalId
      console.log(this.userBookinglist, 'thisbookingListingUrl')
      axios.post(this.bookingListingUrl, this.userBookinglist,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.data.data) {
            this.bookingdata = response.data.data
            this.overlay = false

            if (this.bookingdata.length > 0) {
              this.separateBooking()

              // this.bookingdata.forEach((c, i) => {
              //   let $data = "";
              //   $data = c.travel_segment.split(" ");
              //   let getvalues = $data.slice(0, -1);

              //   let allSegments = [];
              //   // let getairlineLogo = [];

              //   getvalues.forEach(segment => {
              //     let $event = segment.split("-");
              //     let depAir = "";
              //     let arrAir = "";
              //     // let logoAir = [];

              //     c.airport_details.forEach((v, i) => {


              //       console.log(v, i, 'airportairportairportairport')

              //       c.airlines_details.logo.forEach((s, j) => {
              //         console.log(s, j, 'airlines_detailsairlines_details')
              //         if (i == j) {
              //           Object.keys(s).forEach((l) => {
              //             v.logo = s[l];
              //             console.log(s[l], 'airlines_detailsairlines_detailslogo')
              //           });
              //         }
              //       })

              //       Object.keys(v).forEach((icao) => {
              //         if (icao === $event[0]) {
              //           depAir = `${v[icao].city} (${icao})`;
              //           // logoAir.push(v.logo)
              //           // c.airline_logo = logoAir
              //           // console.log(c.airline_logo,'logoAirlogoAirlogoAirlogoAirlogoAir')

              //         }
              //         if (icao === $event[1]) {
              //           arrAir = `${v[icao].city} (${icao})`;
              //         }

                      
              //       });
              //     });







              //     if (depAir && arrAir) {
              //       allSegments.push(`${depAir} - ${arrAir}`);
              //     }
              //   });

              //   console.log(allSegments, 'depAirdepAirdepAirdepAir')

              //   this.bookingdata[i].multiair = allSegments.join(' | ');

              //   if (allSegments.length > 0) {
              //     this.bookingdata[i].depair = allSegments[0].split(' - ')[0];
              //     this.bookingdata[i].arrair = allSegments[0].split(' - ')[1];
              //   }
              // });

              console.log(this.bookingdata, 'bookingdatabookingdata')
            }
          }

          console.log(response.data.data, "bookinglist");
        })
        .catch((error) => {
          console.log(error, 'bookinglistErrorr')
        })
      // this.bookingdata = cityName
      // this.separateBooking()
      // this.overlay=false
      // console.log(this.bookingdata,'responseeeee')
    },
    
    bookingview(details) {
      //  console.log(details.booking_reference, 'detailsdetails')
      console.log(details, "pppahajhadjhdba");
      let $data = details.pax_info.split("/");
      let $name = $data[0];
      console.log($name, "afajha");

      this.$router.push({
        path: "/bookingView",
        query: { booking_id: details.booking_reference, last_name: $name },
      });
    },

    async airlineLogo_func() {
      let airlogoUrl = "";
      let airlogoType = "";

      airlogoUrl = this.airlogoApi.url;
      airlogoType = this.airlogoApi.type;

      await axios
        .get(airlogoUrl, {
          headers: {
            "Content-Type": airlogoType,
          },
        })
        .then((response) => {
          if (response.data) {
            this.airline_LogoData = response.data;

            console.log( this.airlogodata,'this.airlogodatathis.airlogodata')
          }
        })
        .catch((error) => {
          console.log(error, "ressloerrrororooool");
        });

    },

    bookingretrive() {
      this.airlogodata = this.airline_LogoData;
      this.retriveData.forEach((v) => {
        console.log(v, "dtaswww");
        this.baseprice = (
          v.fare_info.itin_total_fare / v.pax_info.length
        ).toFixed(2);
        this.currency = v.fare_info.itin_currency;
        this.totalprice = v.fare_info.itin_total_fare;
        this.passengerDetail = v.pax_info;
        this.itineryData = v.itinerary;
        this.contactnum = v.contact_info.paxphone;
        this.passEmail = v.contact_info.paxemail;
        this.bookingStatus = v.status;
        for (let i = 0; i < this.airlogodata.length; i++) {
          this.itineryData.forEach((a) => {
            if (this.airlogodata[i].id == a.carrier) {
              a.logo = this.airlogodata[i].logo;
              a.flightname = this.airlogodata[i].name;
            }
          });
        }

        console.log(this.datareurn, "this.datareurnthis.datareurn");

        // Object.keys(userData).forEach((icao) => {
        //   const airport = userData[icao];
        //   if (airport.iata !== "") {
        //     this.fromAirports.push({
        //       iata: airport.iata,
        //       icao: airport.icao,
        //       places: `${airport.name}`,
        //     });
        //   }
        // });

        // for (let i = 0; i < this.fromAirports.length; i++) {
        //   this.itineryData.forEach((a) => {
        //     if (this.fromAirports[i].iata == a.depart_airport) {
        //       a.depAirportName = this.fromAirports[i].places;
        //     }
        //     if (this.fromAirports[i].iata == a.arrival_airport) {
        //       a.arrAirportName = this.fromAirports[i].places;
        //     }
        //   });
        // }
        this.itineryData.forEach((j) => {
          if (j.flight_line == 2) {
            this.triptype = "ROUND TRIP";
          } else {
            this.triptype = "ONEWAY TRIP";
          }
        });

        this.itineryData.forEach((s) => {
          if (s.flight_line == 2) {
            this.datareurn = true;
            this.arrivalData.push(s);
          } else if (s.flight_line == 1) {
            this.depatureData.push(s);
          }
        });
        console.log(this.depatureData, "this.depatureData");
        console.log(this.arrivalData, "this.depatureData");
      });
    },

    getConfig() {
      // let getConfigData1 = JSON.parse(localStorage.getItem("portalData"))
      const getConfigData = getConfigDataFromLocalStorage();
      console.log(getConfigData, "getConfigDatagetConfigData");
      if (getConfigData) {
        this.bookingListingUrl =
          getConfigData.payload.portal_configuration.API_endpoints.booking_listing;
        console.log(this.bookingListingUrl, "head...reg..1..");
        this.airlogoApi = getConfigData.payload.portal_configuration.content_data.airlines_logo;
      }
      this.portalId = getConfigData.payload.portal_configuration.portal_id;
    },
  },

  created() {
    this.getConfig();
    this.airlineLogo_func();
    // this.getCity()
    this.overlay = true;
    let $useresult = JSON.parse(localStorage.getItem("loginuser"));
    // console.log($useresult, '$useresult $useresult ')
    this.usermail = $useresult.user.email;
    this.userName = $useresult.user.name;
    // this.usermail = "qatesting1@vibrace.com";
    // this.userName = "qatesting";
    this.bookinglist();

    //    let datas= localStorage.getItem("bookview")
    //    this.retriveData=JSON.parse(datas)

    //    console.log(this.retriveData,'00000000000')
    //    this.bookingretrive()
  },
};
</script>

<style scoped>
/* @import '@/assets/css/main.css'; */

.f-size-16 {
  font-size: 16px;
}

.fw-600 {
  font-weight: 600;
}

.nav-list {
  cursor: pointer;
}

.dropdown-list {
  cursor: pointer;
}

.data-list {
  cursor: pointer;
}

/* .dropdown-list {
  transition: all 0.5s ease-in-out; 
  max-height: 0; 
  overflow: hidden;
  opacity: 0;
} */

.nav-list:hover .dropdown-list {
  max-height: 500px;
  opacity: 1;
  transition-delay: 0.5s;
}

.book-back {
  background-color: #ff5400;
}

.f-size-16 {
  font-size: 16px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.booking-subtitle {
  font-size: 18px;
  font-weight: 600;
}

/* .booking-text p{
  font-size: 17px;
  font-weight: 400;
} */

.booking-text .book-span {
  color: #0768cf;
  cursor: pointer;
}

.booking-contacts {
  background-color: #f6f6f6;
}

.booking-contacts span {
  font-size: 18px;
}

.Booking-id-head {
  font-size: 14px;
  color: gray;
}

.Booking-id {
  font-size: 26px;
  font-weight: 700;
}

.v-card {
  border-radius: 10px !important;
}

.header-btn {
  background-color: transparent;
  text-transform: capitalize;
  box-shadow: none;
  font-size: large;
  margin: 0px 7px;
}

.custom-icon {
  width: 37px;
  height: 37px;
  border: 1px solid orange;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.flight-det {
  font-size: 25px;
  font-weight: 600;
}

/* Departure & Return Section */

.card-content {
  max-height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.flight-btn {
  cursor: pointer;
  background-color: blueviolet;
  color: white;
}

p.small-size {
  font-size: 14px;
}

.font-weight {
  font-weight: 500;
}

.line-height {
  padding-bottom: 15px;
}

.f-size {
  font-size: 16px;
}

.tool-span span {
  font-size: 18px;
  font-weight: 600;
}

.contion {
  display: none;
}

.travel-date {
  font-size: 14px;
  font-weight: 600;
}

.provide-border {
  border-left: 1px solid lightgray;
}

>>>.v-timeline--vertical.v-timeline {
  grid-row-gap: 3px;
  height: auto !important;
}

.f-family {
  font-family: sans-serif;
  font-size: 15px;
}

.Portal1 {
  display: none;
}

/* Departure & Return Section */

@media only screen and (max-width: 992px) {
  .check-icon {
    margin-right: 40px;
  }

  .book-detail {
    text-align: left;
    padding-top: 10px;
  }

  .flight-det {
    font-size: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .provide-border {
    border-left: none;
  }

  .class-box {
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 480px) {
  .print-btn-2 {
    display: none;
  }

  .mobile-table {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .contion {
    display: block;
  }

  .Portal {
    display: none;
  }

  .Portal1 {
    display: block;
  }

  .mobile-table {
    display: block;
  }

  .desktop-table {
    display: none;
  }

  .print-btn-1 {
    display: none;
  }

  .print-btn-2 {
    display: flex;
  }

  .f-family {
    font-size: 12px;
  }

  .airlogo-size {
    width: 25px !important;
  }

  .airline-name {
    font-size: 10px;
  }

  .f-size-16 {
    font-size: 16px;
  }

  .TERMS {
    display: none;
  }

  .f-size-14 {
    font-size: 14px;
  }

  .f-size-13 {
    font-size: 13px;
  }

  .f-size-12 {
    font-size: 12px;
  }

  .f-size-11 {
    font-size: 11px;
  }

  .f-size-10 {
    font-size: 10px;
  }

  .f-size-9 {
    font-size: 10px;
  }

  .fw-500 {
    font-weight: 600;
  }
}

.print-btn-1 {
  box-shadow: none;
  font-size: large;
  margin: 0px 7px;
  color: green;
  outline: 1px solid;
  width: 130px;
  height: 35px;
  border-radius: 4px;
}

.print-btn-2 {
  font-size: 25px;
  height: 35px;
  width: 35px;
  box-shadow: none;
  margin-top: 4px;
  color: green;
  outline: 2px solid;
  border-radius: 4px;
}

li {
  list-style: none !important;
}

.bi-circle-fill::before {
  font-size: xx-small;
}

/* New Design */

>>>.login-form1 .v-field {
  height: 35px !important;
}

>>>.login-form1 .v-field__prepend-inner {
  height: 35px !important;
}

>>>.login-form1 .v-field__field {
  height: 35px !important;
}

>>>.login-form1 .v-field__input {
  padding-top: 0 !important;
}

html,
body,
ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: #008cff;
}

a:hover,
a:active,
a:focus {
  transition: all ease-in-out 0.3s;
}

.form-control:focus {
  color: #3c0ec2;
  background-color: #ffffff;
  border-color: #ffc104;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 4, 0.5);
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 4, 0.5);
}

.btn:hover {
  border-color: inherit;
  background-color: #3c0ec2;
  overflow: hidden;
}

.ui-state-default {
  text-align: center !important;
}

.ui-state-hover {
  background-color: #ffc104 !important;
  border: inherit !important;
}

.accordion-button {
  background-color: transparent;
  border: 0px solid transparent;
  font-size: 0.875rem;
}

.accordion-button:not(.collapsed) {
  color: #3c0ec2;
  background-color: transparent;
  box-shadow: none;
}

html {
  font-size: 1rem;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #efeeee;
}

a,
a:hover {
  text-decoration: none;
}

h1 {
  text-align: center;
  text-transform: lowercase;
}

.theme-bg-primary {
  background-color: #ffc104;
}

.theme-bg-secondary {
  background-color: #3c0ec2;
}

.theme-bg-white {
  background-color: #ffffff;
}

.theme-bg-light {
  background-color: #9f9f9f;
}

.theme-bg-accent-one {
  background-color: #343534;
}

.theme-bg-accent-two {
  background-color: #9f9f9f;
}

.theme-bg-accent-three {
  background-color: #f5faff;
}

.body-color {
  background-color: #efeeee;
}

.theme-text-white {
  color: #ffffff;
}

.theme-text-primary {
  color: #ffc104;
}

.theme-text-secondary {
  color: #3c0ec2;
}

.theme-text-accent-one {
  color: #343534;
  cursor:pointer;
}

.theme-text-accent-two {
  color: #9f9f9f;
}

.theme-text-accent-three {
  color: #f5faff;
}

.theme-box-shadow {
  box-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
}

.theme-text-shadow {
  text-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
}

.theme-border-radius {
  border-radius: 0.5rem;
}

.theme-border-radius-top {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.theme-border-radius-bottom {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.font-extra-small {
  font-size: 0.625rem;
}

.font-small {
  font-size: 0.75rem;
}

.font-medium {
  font-size: 0.875rem;
}

.max-1 {
  max-width: 50rem;
  margin: auto;
}

.btn-select {
  position: relative;
  border: 1px solid #3c0ec2;
  color: #002d5b;
  z-index: 1;
}

.btn-book {
  position: relative;
  /* background-color: #3c0ec2; */
  color: #ffffff;
  z-index: 0;
  overflow: hidden;
}

.min-h58 {
  min-height: 58px;
}

.z-in-2 {
  z-index: 2;
}

.dropdown-container {
  position: relative;
  min-width: 120px;
}

.dropdown-container .dropdown-toggle::after {
  opacity: 0;
}

.dropdown-container .dropdown-toggle {
  cursor: pointer;
  position: relative;
  color: #ffffff;
  transition: all ease-in-out 0.3s;
}

.dropdown-container .dropdown-toggle label {
  cursor: pointer;
  width: 100%;
}

.dropdown-container .dropdown-toggle:hover,
.dropdown-container .dropdown-toggle:active,
.dropdown-container .dropdown-toggle:focus {
  color: #ffc104;
}

.dropdown-container .dropdown-menu {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.5);
  margin-top: 17px;
  position: absolute;
  left: 0;
  top: 100%;
  display: none;
  background-color: #ffffff;
  z-index: 10;
}

.dropdown-container .dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  border-radius: 0.5rem;
  z-index: 10;
}

@media screen and (max-width: 767px) {
  .dropdown-container .dropdown-menu ul {
    border-radius: 0rem;
  }
}

.dropdown-container .dropdown-menu a {
  text-decoration: none;
  display: block;
  padding: 5px 20px;
  font-weight: 600;
  box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.5);
  transition: all ease-in-out 0.3s;
}

.dropdown-container .dropdown-menu a:hover,
.dropdown-container .dropdown-menu a:active,
.dropdown-container .dropdown-menu a:focus {
  color: inherit;
}

.dropdown-menu,
.dropdown-toggle {
  position: relative;
}

.dropdown-menu::before,
.dropdown-toggle::before {
  content: "";
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  border: 5px solid;
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  margin-top: -2.5px;
  background-color: rgba(0, 0, 0, 0);
  transition: all ease-in-out 0.3s;
  z-index: 10;
}

.dropdown-menu {
  z-index: 10;
  position: relative;
}

.dropdown-menu::before {
  z-index: -1;
  transform: rotate(-135deg);
  top: -4px;
  border-color: #ffffff;
  box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.5);
  display: none;
}

.dropdown-open .dropdown-menu.dropdown-active {
  display: block;
}

.dropdown-container.dropdown-open .dropdown-toggle {
  color: #ffffff;
}

.dropdown-container.dropdown-open .dropdown-toggle:before {
  transform: rotate(-135deg);
}

@media screen and (max-width: 767px) {
  #navbarSupportedContent {
    background-color: #ffffff;
    position: absolute;
    right: 0;
    top: 3rem;
    min-width: 12rem;
  }
}

@media screen and (max-width: 767px) {
  #navbarSupportedContent .nav-item {
    padding: 1rem 0.5rem;
  }
}

@media screen and (max-width: 767px) {
  #navbarSupportedContent .dropdown-active {
    position: relative;
    border-radius: 0;
    border: 0;
  }
}

@media screen and (max-width: 767px) {
  #navbarSupportedContent .dropdown-active .nav-item {
    padding: 0rem 0rem;
  }
}

@media screen and (max-width: 767px) {
  #navbarSupportedContent .dropdown-toggle {
    color: #343534;
  }
}

.dropdown-open .dropdown-menu {
  min-width: 5rem;
  padding: 0 0;
  width: auto;
}

.dropdown-open .dropdown-menu a {
  padding: 0.5rem 0.5rem;
}

.dropdown-open .dropdown-menu a:active,
.dropdown-open .dropdown-menu a:hover {
  background-color: #ffc104;
}

#corporate-page .dropdown-container .dropdown-toggle {
  color: #002d5b;
  display: flex;
  flex-direction: column;
}

#corporate-page .dropdown-container .dropdown-toggle::before {
  top: 70%;
}

#corporate-page .dropdown-container .dropdown-toggle::after {
  opacity: 0;
  display: none;
}

#corporate-page .dropdown-container .dropdown-toggle:hover,
#corporate-page .dropdown-container .dropdown-toggle:active,
#corporate-page .dropdown-container .dropdown-toggle:focus {
  color: #ffc104;
}

#corporate-page .dropdown-container .dropdown-menu {
  margin-top: 0;
}

.flag {
  width: 20px;
  height: 20px;
  display: inline-block;
  /* background: url(../images/icons/flag.png); */
  background-repeat: no-repeat;
  text-align: center;
  border-radius: 50px;
  overflow: hidden;
  vertical-align: middle;
  margin-right: 6px;
}

.flag.in {
  background-position: -4px 0px;
}

.flag.us {
  background-position: 0 -24px;
}

.flag.er {
  background-position: 0 -50px;
}

.flag.ru {
  background-position: 0 -100px;
}

.flag.fr {
  background-position: -4px -75px;
}

.preloader {
  position: fixed;
  z-index: 999999;
  background-color: #efeeee;
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0;
  right: 0;
}

.preloader .d-table {
  width: 100%;
  height: 100%;
}

.preloader .d-table .d-table-cell {
  vertical-align: middle;
}

.preloader .load-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.preloader .load-spinner div {
  transform-origin: 40px 40px;
  animation: load-spinner 1.2s linear infinite;
}

.preloader .load-spinner div::after {
  content: " ";
  display: block;
  position: absolute;
  top: 5px;
  left: 35px;
  width: 5px;
  height: 20px;
  border-radius: 20%;
  background: #002d5b;
}

.preloader .load-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.preloader .load-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.preloader .load-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.preloader .load-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.preloader .load-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.preloader .load-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.preloader .load-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.preloader .load-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.preloader .load-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.preloader .load-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.preloader .load-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.preloader .load-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes load-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.header-light {
  background-color: #ffffff;
}

.header-light .dropdown-toggle {
  color: #002d5b;
}

.header-light .dropdown-container.dropdown-open .dropdown-toggle {
  color: #002d5b;
}

.modify-search .head-wrapper {
  min-height: auto;
}

.modify-search .flight-search {
  background-color: #002d5b;
}

.modify-search .flight-search .check-wrap {
  color: #ffffff;
}

.modify-search .flight-search .check-wrap .checkmark {
  border: 1px solid #ffc104;
}

.modify-search .flight-search .nav-tabs .nav-item .nav-link {
  color: #ffffff;
}

.filter-sec {
  background-color: #f5faff;
}

.filter-sec .flt-btn {
  position: relative;
  padding: 0 2rem 0 0;
}

.filter-sec .flt-btn::before {
  content: "\f282";
  font-family: bootstrap-icons !important;
  display: inline-block;
  background-color: transparent;
  width: 20px;
  height: 20px;
  z-index: 1;
  position: absolute;
  right: 0;
  transform: rotate(180deg);
}

.filter-sec .collapsed::before {
  transform: rotate(0deg);
}

.check-wrap {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: #343534;
  font-size: 0.75rem;
}

.check-wrap:hover input~.checkmark {
  background-color: #ffc104;
}

.check-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.check-wrap input:checked~.checkmark {
  background-color: #ffc104;
  border: 0px solid #ffc104;
}

.check-wrap input:checked~.checkmark:after {
  display: block;
}

.check-wrap .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  border: 1px solid #9f9f9f;
}

.check-wrap .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid #002d5b;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.price-range-slider {
  width: 100%;
  float: left;
  padding: 10px 20px;
}

.price-range-slider .range-value {
  margin: 0;
}

.price-range-slider .range-value input {
  width: 100%;
  background: none;
  color: #343534;
  font-size: 16px;
  font-weight: initial;
  box-shadow: none;
  border: none;
  margin: 20px 0 20px 0;
}

.price-range-slider .range-bar {
  border: none;
  background: #343534;
  height: 3px;
  width: 96%;
  margin-left: 8px;
  padding: 0 !important;
  border: 0 !important;
}

.price-range-slider .range-bar .ui-slider-range {
  background: #ffc104;
}

.price-range-slider .range-bar .ui-slider-handle {
  border: none;
  border-radius: 25px;
  background: #ffffff;
  border: 2px solid #002d5b;
  height: 17px;
  width: 17px;
  top: -0.52em;
  cursor: pointer;
}

.price-range-slider .range-bar .ui-slider-handle+span {
  background: #002d5b;
}

.srp .stops {
  display: block;
  width: 60px;
  height: 2px;
  background-color: #9f9f9f;
  position: relative;
}

.srp .stops .one {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  display: flex;
  background: #002d5b;
  position: absolute;
  top: -3px;
  left: 40%;
}

.selected-strip {
  box-shadow: 0px 0 20px rgba(0, 45, 91, 0.5);
}

.reviewStatus {
  width: 100%;
  display: flex;
  height: 2px;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  color: #ffffff;
  font-size: 0.75rem;
}

.reviewStatus li {
  flex: 1 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.reviewStatus:before {
  height: 2px;
  background: #ffc104;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 8px;
}

.reviewStatus.step1:before {
  width: 0%;
}

.reviewStatus.step2:before {
  width: 25%;
  animation-name: step2;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 500ms;
}

.reviewStatus.step3:before {
  width: 50%;
  animation-name: step3;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 500ms;
}

.reviewStatus.step4:before {
  width: 75%;
  animation-name: step4;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 500ms;
}

@keyframes step2 {
  from {
    width: 0%;
  }

  to {
    width: 25%;
  }
}

@keyframes step3 {
  from {
    width: 25%;
  }

  to {
    width: 50%;
  }
}

@keyframes step4 {
  from {
    width: 50%;
  }

  to {
    width: 75%;
  }
}

.reviewStatus .numbering {
  width: 13px;
  min-height: 13px;
  font-size: 10px;
  background: #ffffff;
  border-radius: 50%;
  text-align: center;
  position: relative;
  top: -5px;
  color: #343534;
}

.reviewStatus .numbering.completed {
  background: #ffc104;
  text-indent: -999999px;
  position: relative;
}

.reviewStatus .numbering.completed:before {
  content: "";
  display: block;
  width: 4px;
  height: 7px;
  border: solid #ffffff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  position: absolute;
  left: 4px;
  top: 2.5px;
}

.reviewStatus .numbering.onpage {
  background: #ffc104;
  color: #ffffff;
}

.reviewStatus .reviewText.active {
  font-weight: 700;
  color: #ffffff;
}

.reviewStatus .numbering.completed:before {
  content: "";
  display: block;
  width: 4px;
  height: 7px;
  border: solid #ffffff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  position: absolute;
  left: 5px;
  top: 2px;
}

.custom-tab .nav-link {
  text-align: left;
  background: #f5faff;
  color: #343534 !important;
  font-size: 0.875rem;
  border-radius: 0;
  border-bottom: 1px solid #efeeee;
  padding: 1.02rem 1rem;
}

.custom-tab .nav-link:last-child {
  border-bottom: 0px solid transparent;
}

.custom-tab .first-tab {
  border-top-left-radius: 0.5rem !important;
}

.custom-tab .last-tab {
  border-bottom-left-radius: 0.5rem !important;
}

.custom-tab .nav-link.active,
.custom-tab .show>.nav-link {
  text-align: left;
  background: #ffffff;
  color: #343534;
  font-size: 0.875rem;
  border-radius: 0;
}

.custom-tab .payment-icon {
  width: 24px;
  height: 16px;
  display: inline-block;
  /* background: url(../images/icons/icons-sprite-payment.png) no-repeat; */
  vertical-align: middle;
  margin-right: 5px;
}

.custom-tab .credit {
  background-position: 0 0;
}

.custom-tab .debit {
  background-position: -25px 0;
}

.custom-tab .net {
  background-position: -50px 0;
}

.custom-tab .upi {
  background-position: -75px 0;
}

.custom-tab .phone {
  background-position: -100px 0;
}

.custom-tab .wallet {
  background-position: -125px 0;
}

.custom-tab .paypal {
  background-position: -150px 0;
}

.custom-tab .emi {
  background-position: -175px 0;
}

.form-label {
  font-size: 0.875rem;
}

.inner-banner {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 430px;
}

.privacy-page-bg {
  /* background-image: url(../images/section/privacy-page-bg.jpg); */
}

.deals-page-bg {
  /* background-image: url(../images/section/deals-offer-banner.jpg); */
}

.faq-page-bg {
  /* background-image: url(../images/section/faq-banner.jpg); */
}

.privacy-list li {
  font-size: 0.875rem;
  color: #9f9f9f;
  line-height: 2;
  list-style: disc;
  margin-left: 1.125rem;
}

.company-qotes {
  font-size: 2.5rem;
  line-height: 1.5;
  color: #9f9f9f;
  font-weight: 300;
  position: relative;
  padding: 0 0 0 3rem;
  margin-bottom: 2rem;
}

.company-qotes::after,
.company-qotes:before {
  content: "";
  /* background: url(../images/icons/qotes-icon.png) no-repeat; */
  width: 36px;
  height: 30px;
  display: block;
}

.company-qotes::after {
  position: absolute;
  top: 0;
  left: 0;
}

.company-qotes::before {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}

.about-pic {
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  box-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
  border: 5px solid #ffffff;
}

.text-color {
  color: rgba(0, 45, 91, 0.2);
}

iframe {
  width: 100%;
}

.deals-tab {
  box-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
  border-radius: 0.5rem;
  flex-wrap: nowrap;
}

.deals-tab li .nav-link {
  color: #343534;
  padding: 1rem;
}

.deals-tab li .nav-link.active {
  background-color: transparent;
  border-bottom: 2px solid #002d5b;
  border-radius: 0;
  color: #002d5b;
}

.offers-content .wrap-img {
  position: relative;
  min-height: 150px;
  height: auto;
  max-height: 200px;
  overflow: hidden;
}

.offers-content .wrap-content {
  display: flex;
  flex-direction: column;
}

.addons-tab li.nav-item {
  background-color: #ffffff;
}

.addons-tab li.nav-item div {
  cursor: pointer;
}

.addons-tab li.nav-item div:hover {
  background-color: #f5faff;
}

.addons-tab li.nav-item .active {
  background-color: #f5faff;
}

.add-btn-wrap {
  box-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
  border-radius: 0.5rem;
  width: 50%;
}

.add-btn-wrap .input-group .input-group-btn .btn:disabled {
  border-color: transparent;
}

.active-addon {
  border-bottom: 2px solid #002d5b;
  background-color: #f5faff;
}

.profile-pic {
  background-color: #efeeee;
  width: 100%;
  height: 100%;
  min-height: 130px;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.edit-btn {
  position: absolute;
  bottom: -5px;
  border: 2px solid #ffffff;
  background-color: #002d5b;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-btn i {
  color: #ffffff;
  font-size: 0.75rem;
}

.edit-btn:hover {
  background-color: #ffffff;
}

.edit-btn:hover i {
  color: #002d5b;
}

.profile {
  width: 100%;
  height: 5px;
  background-color: #9f9f9f;
}

.complete-100 {
  width: 100%;
  background-color: green;
}

.traveller-pic {
  background-color: #f5faff;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
}

.img-icon {
  width: 25px;
  height: 23px;
  display: inline-block;
  /* background: url(../images/icons/my-booking-sprite.svg) no-repeat; */
}

.icon-upcoming {
  background-size: 540%;
  background-position: 0px 0px;
}

.icon-cancel {
  background-size: 540%;
  background-position: -85px 0px;
}

.icon-complete {
  background-size: 540%;
  background-position: -53px 0px;
}

.icon-fail {
  background-size: 540%;
  background-position: -27px 0px;
}

.refund-step {
  margin-top: 1rem;
}

.refund-step li {
  font-size: 0.75rem;
  display: inline-flex;
  flex-direction: column;
  padding-right: 5rem;
  position: relative;
}

.refund-step li::after {
  content: "";
  background-color: #ffc104;
  width: 90%;
  height: 4px;
  display: block;
  position: absolute;
  top: 15px;
  left: 24px;
  z-index: 1;
}

.refund-step li:last-child::after {
  display: none;
}

.wallet-head {
  background-color: #f5faff;
  border-radius: 0.5rem 0.5rem 0 0;
}

.cash-point {
  width: 2rem;
  height: 2rem;
  background-color: #ffc104;
  border: 2px solid #002d5b;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  line-height: 2rem;
  font-size: 0.75rem;
  margin-right: 1rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cash-info {
  background-color: rgba(255, 193, 4, 0.2);
  border-radius: 0.5rem;
}

.hex {
  width: 30px;
  height: 18px;
  background-color: #002d5b;
  border-color: #002d5b;
  position: relative;
  display: inline-block;
  text-align: center;
  line-height: 0;
}

.hex i {
  color: #ffffff;
}

.hex:before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 8px solid;
  border-color: inherit;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  position: absolute;
  top: -8px;
  left: 0;
}

.hex:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: -8px;
  border-top: 8px solid;
  border-color: inherit;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  left: 0;
}

.pagination li a.page-link {
  border: 2px solid #9f9f9f;
  border-radius: 0.5rem;
  color: #ffc104;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  width: 3rem;
  height: 3rem;
  display: inline-block;
  margin-right: 10px;
}

.pagination li a.page-link:hover {
  color: #ffffff;
  background: #ffc104;
  border-color: #9f9f9f;
}

.pagination li a.page-link:first-child {
  border-radius: 0;
  border-radius: 0.5rem;
}

.pagination li a.page-link:last-child {
  border-radius: 0;
  border-radius: 0.5rem;
}

.post-wrap {
  padding-bottom: 3rem;
  margin-bottom: 3rem;
}

.post-wrap p i {
  line-height: 2rem;
  font-size: 0.875rem;
  border-left: 2px solid #ffc104;
  height: 100%;
  display: block;
  padding: 1rem;
  background: rgba(255, 193, 4, 0.04);
  text-align: justify;
}

.post-wrap .post-head {
  font-size: 1.5rem;
  margin: 2rem 0;
  font-weight: bold;
}

.post-wrap .post-desc {
  font-size: 0.875rem;
  line-height: 2rem;
  text-align: justify;
}

.blog-post-social {
  border-top: 2px solid #efeeee;
  border-bottom: 2px solid #efeeee;
  padding: 25px 0px;
  margin-bottom: 55px;
}

.post-tag-list {
  display: flex;
  justify-content: start;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.post-tag-list li {
  font-weight: bold;
  color: #002d5b;
  margin-right: 8px;
}

.post-tag-list li:first-child {
  margin-right: 15px;
}

.post-tag-list li a {
  font-size: 0.875rem;
  color: #9f9f9f;
  font-weight: normal;
}

.post-tag-list li a:hover {
  color: #002d5b;
}

.blog-icon-list {
  display: flex;
  justify-content: start;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.blog-icon-list li {
  margin-left: 1rem;
}

.blog-icon-list li:first-child {
  font-weight: bold;
  color: #002d5b;
}

.blog-icon-list li a {
  display: block;
  transition: 0.4s ease;
}

.blog-icon-list li a i {
  color: #002d5b;
}

.blog-icon-list li a:hover {
  transform: scale(1.2);
}

.comment-box {
  border-radius: 0.5rem;
  box-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
}

.comment-box .comment-head {
  padding: 2rem;
  font-weight: bold;
  font-size: 1.5rem;
}

.comment-box .user-comment-card {
  margin-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.comment-box .user-comment-card li {
  position: inherit;
}

.comment-box .user-comment-card .userPic {
  position: relative;
}

.comment-box .user-comment-card .userName {
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 1rem;
  font-size: 0.875rem;
}

.comment-box .comment-title {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: space-between;
}

.comment-box .comment-title .comment-meta span {
  color: #343534;
  font-size: 0.875rem;
}

.comment-box .comment-body p {
  font-size: 0.875rem;
  line-height: 1.5;
  color: #9f9f9f;
}

.comment-box h4 {
  margin-bottom: 2rem;
}

.blog-list-head {
  position: relative;
  font-size: 20px;
  text-transform: uppercase;
  margin: 0 0 30px 0;
  color: #002d5b;
  font-weight: bold;
}

.blog-list-head :after {
  content: "";
  width: 20px;
  height: 4px;
  position: absolute;
  bottom: -10px;
  left: 0;
  background: #ffc104;
}

.widget-categories li :first-child {
  margin-top: 0;
}

.widget-categories li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffc104;
  padding: 10px 15px;
  padding-left: 15px;
  position: relative;
  transition: all 0.2s;
}

.widget-categories li a:hover {
  background: rgba(255, 193, 4, 0.04);
  color: #002d5b;
}

.widget-categories li a:hover::before {
  opacity: 1;
  transform: translateX(0);
}

.widget-categories li a::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 100%;
  background: #002d5b;
  border-radius: 2px 0px 0px 2px;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0.2s;
  transform: translateX(-5px);
}

.tags {
  list-style-type: none;
  border-radius: 0.5rem;
  box-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
  padding: 1rem;
}

.tags li {
  padding: 1rem 0.5rem;
  margin: 0 0;
  display: inline-block;
}

.tags li a {
  color: #002d5b;
  font-weight: 600;
  border-radius: 0.5rem;
  background: #f5faff;
  padding: 0.5rem;
}

.tags li a:hover {
  background: #002d5b;
  color: #ffffff;
}

#default-page .head-wrapper {
  min-height: 110vh;
}

.promo-ban .container-fluid .owl-carousel .owl-nav button.owl-prev {
  left: 0;
}

.promo-ban .container-fluid .owl-carousel .owl-nav button.owl-next {
  right: 0%;
}

#corporate-page {
  position: relative;
  /*-- coupon deals css --*/
}

#corporate-page .head-wrapper {
  background: #002d5b;
  background: linear-gradient(0deg, #002d5b 0%, #002d5b 100%);
}

#corporate-page .head-wrapper::after {
  background: rgba(52, 53, 52, 0.2);
  opacity: 0.1;
}

#corporate-page .head-wrapper .flight-search {
  background-color: transparent;
}

#corporate-page .head-wrapper .flight-search .search-pan {
  padding: 0rem;
}

#corporate-page .head-wrapper .flight-search .search-pan .form-control {
  background-color: #efeeee;
}

#corporate-page .head-wrapper .flight-search .icon-20 {
  border: 1px solid #002d5b;
}

#corporate-page .coupon-deal {
  position: relative;
}

#corporate-page .coupon-deal #carouseldeals img {
  width: auto;
}

#corporate-page .coupon-deal .owl-carousel .owl-nav button.owl-next {
  top: 0;
  right: 0;
}

#corporate-page .coupon-deal .owl-carousel .owl-nav button.owl-prev {
  top: 0;
  left: 0;
}

#corporate-page .coupon-deal .owl-carousel .owl-nav button.owl-next,
#corporate-page .coupon-deal .owl-carousel .owl-nav button.owl-prev {
  position: absolute;
}

#corporate-page .owl-dots {
  position: absolute;
  bottom: -20px;
  left: 39%;
}

@media screen and (max-width: 575px) {
  #corporate-page .owl-dots {
    left: 31%;
  }
}

#corporate-page .footer {
  background-color: #efeeee;
}

#corporate-page .footer h1,
#corporate-page .footer h2,
#corporate-page .footer h3,
#corporate-page .footer p {
  color: #343534;
}

#corporate-page .footer .social a.smo {
  border-radius: 50%;
  padding: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5;
  border: 1px solid #343534;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-left: 0.5rem;
}

#corporate-page .footer .social a,
#corporate-page .footer .social .botom-link {
  color: #343534;
}

#corporate-page .footer .social a:hover,
#corporate-page .footer .social .botom-link:hover {
  color: #ffc104;
}

#video-page .head-wrapper {
  min-height: 95vh;
}

#video-page .flight-search {
  background: rgba(255, 255, 255, 0);
  z-index: 2;
}

#video-page .content {
  margin-top: 8rem;
}

@media screen and (max-width: 992px) {
  #video-page .content {
    margin-top: 4rem;
  }
}

#video-page .video-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#video-page .video-image .background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

#video-page .video-image:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(52, 53, 52, 0.6);
  z-index: 2;
}

/*-- header wraper css --*/
.head-wrapper {
  /* background: url(../images/hero/banner-bg.jpg) no-repeat; */
  background-position: center center;
  display: block;
  min-height: 80vh;
  background-size: cover;
  position: relative;
  z-index: 2;
}

.head-wrapper::after {
  content: "";
  position: absolute;
  display: block;
  background: #343534;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}

/*-- header css --*/
.header .sideMenu {
  position: relative;
}

.header .sideMenu .custom-btn-close {
  background-image: none;
  background: #002d5b;
  position: absolute;
  right: -48px;
  top: 0;
  border-radius: 0;
  padding: 1rem;
  opacity: 1;
  line-height: 0;
}

.header .sideMenu .custom-btn-close:hover {
  background-color: #ffc104;
  color: #343534;
}

.header .sideMenu .menuList li {
  line-height: 3rem;
}

.header .sideMenu .menuList li a {
  color: #ffffff;
  display: flex;
}

.header .sideMenu .menuList li a:hover {
  color: #343534;
}

.header .sideMenu .social a {
  color: #ffffff;
}

.header .sideMenu .social a:hover {
  color: #ffc104;
}

@media screen and (max-width: 575px) {
  .header .offcanvas-start {
    width: 260px;
  }
}

.header .btn-left {
  width: auto;
  height: auto;
  min-height: 50px;
  background-color: #002d5b;
  color: #ffffff;
  background-position: 0 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.header .btn-left:hover {
  background-color: #ffc104;
  color: #343534;
}

/*-- custom theme css --*/
.custom-btn-head {
  color: #ffffff;
  padding: 0.6rem 1.6rem;
  background-color: #002d5b;
}

.custom-shadow:hover {
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.5);
}

/*-- Search engine css*/
.flight-search {
  position: relative;
  z-index: 0;
  background: rgba(255, 255, 255, 0.5);
  padding: 1.5rem 0;
  /*checkbox css*/
}

.flight-search .icon-20 {
  width: 20px;
  height: 20px;
}

.flight-search .search-pan {
  background: #ffffff;
  padding: 0.8rem;
  min-height: auto;
  /*Date calender*/
}

.flight-search .search-pan .dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 50%;
}

.flight-search .search-pan .dropdown .dropdown-menu {
  width: 100%;
  padding: 0px 0.5rem;
  border-radius: 0.5rem;
  /*-- Droup down Traveller --*/
}

.flight-search .search-pan .dropdown .dropdown-menu .drop-rest li {
  border-bottom: 1px solid #f5faff;
  display: flex;
  padding: 5px 0 5px 0;
}

.flight-search .search-pan .dropdown .dropdown-menu .drop-rest li span {
  font-size: 14px;
  font-weight: 500;
}

.flight-search .search-pan .dropdown .dropdown-menu .drop-rest li .br {
  display: block;
  padding-top: 0px;
}

.flight-search .search-pan .dropdown .dropdown-menu .drop-rest li .btn {
  background-color: #002d5b;
  color: #ffffff;
  border: 0;
}

.flight-search .search-pan .dropdown .dropdown-menu .drop-rest li .btn:hover {
  background-color: #ffc104;
  color: #343534;
}

.flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input {
  align-items: center;
  width: 45%;
}

@media screen and (max-width: 992px) {
  .flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input {
    width: 30%;
  }
}

@media screen and (max-width: 575px) {
  .flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input {
    width: 50%;
  }
}

.flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input .input-group-button button {
  background-color: #002d5b;
  border: none;
  color: #ffffff;
}

.flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input .input-group-button button:hover {
  background-color: #ffc104;
  color: #343534;
}

.flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input .input-group-field {
  text-align: center;
  width: 50%;
  background: #f5faff;
  border: 0;
}

.flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input .input-group-field::-webkit-inner-spin-button,
.flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input .input-group-field::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.flight-search .search-pan .form-group {
  position: relative;
}

.flight-search .search-pan .icon-pos {
  top: 0.6rem;
  left: 0.5rem;
  color: #9f9f9f;
}

@media screen and (max-width: 992px) {
  .flight-search .search-pan .icon-pos {
    top: 1rem;
  }
}

.flight-search .search-pan .input-group {
  color: inherit;
}

.flight-search .search-pan .form-control {
  font-size: 14px;
  background-color: #f5faff;
  min-height: 56px;
  border: 0px;
  text-decoration: none;
  border-radius: 0.5rem;
  text-align: left;
}

.flight-search .search-pan .cal-input {
  background: none;
  border: 0px;
  width: 6rem;
  padding: 0.4rem 0 0 0.5rem;
}

.flight-search .search-pan .dep-date-input {
  position: relative;
  width: auto;
  height: 2rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  background: #ffffff;
  border-style: none;
  padding: 0 0;
  margin: 0.3rem 0 0 2rem;
}

.flight-search .search-pan .dep-date-input:after {
  content: "";
  position: absolute;
  right: -12px;
  bottom: 0px;
  width: 0;
  height: 0;
  border-left: 0.8rem solid #ffffff;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
}

.flight-search .search-pan .arv-date-input {
  position: relative;
  width: 45%;
  height: 2rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background: #ffffff;
  border-style: none;
  padding: 0 0;
  margin: 0.3rem 0 0 2rem;
}

.flight-search .search-pan .arv-date-input:before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 0.5rem solid #f5faff;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
}

.flight-search .search-pan .dropdown-toggle {
  padding: 0 1rem 0 3rem;
}

.flight-search .search-pan .dropdown-toggle::before {
  color: #9f9f9f;
  right: 0.8rem;
}

.flight-search .nav-tabs .nav-item .nav-link {
  font-weight: 700;
  color: #002d5b;
  background-color: transparent;
  border: none;
  padding: 0.5rem 1rem 1.5rem 0rem;
}

.flight-search .nav-tabs .nav-item .nav-link:hover {
  border-color: transparent;
  color: #ffc104;
}

.flight-search .nav-tabs .nav-item .active span {
  background: #ffffff !important;
  border: 1px solid #ffc104;
  position: relative;
}

.flight-search .nav-tabs .nav-item .active span:after {
  content: "";
  display: block;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid #ffc104;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  position: absolute;
}

.flight-search .btn-search {
  background-color: #002d5b;
  min-height: 56px;
  color: #ffffff;
  min-width: 75px;
  width: 100%;
  border-radius: 0.5rem;
  border: none;
  transition: all 0.2s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.flight-search .btn-search:before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: -100%;
  background: #ffc104;
  transition: all 0.2s;
}

.flight-search .btn-search:hover {
  color: #343534;
}

.flight-search .btn-search:hover:before {
  top: 0;
  left: 0;
}

.flight-search .pos-swap {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #002d5b;
  position: absolute;
  top: 0.9rem;
  right: -1.2rem;
  z-index: 1;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
  border: 0;
  cursor: pointer;
}

.flight-search .pos-swap:hover {
  background-color: #ffc104;
  color: #343534;
}

@media screen and (max-width: 992px) {
  .flight-search .pos-swap {
    right: 0;
    top: 2.5rem;
  }
}

.flight-search .check-wrap {
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: #343534;
}

.flight-search .check-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.flight-search .check-wrap .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  border-radius: 0.2rem;
  border: 1px solid #343534;
}

.flight-search .check-wrap .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.flight-search .check-wrap .checkmark:after {
  left: 0.2rem;
  top: 0rem;
  width: 0.5rem;
  height: 0.8rem;
  border: solid #343534;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.flight-search .check-wrap:hover input~.checkmark {
  background-color: #f5faff;
  background: #ffc104;
}

.flight-search .check-wrap input:checked~.checkmark {
  border: 1px solid #343534;
}

.flight-search .check-wrap input:checked~.checkmark:after {
  display: block;
}

/*-- calender header modify css --*/
.ui-widget-header {
  background-color: #ffc104 !important;
  color: #343534 !important;
}

/*-- Promotional banner css --*/
.promo-ban {
  position: relative;
}

.promo-ban .owl-dots {
  display: none;
}

.promo-ban .owl-nav {
  margin: 0;
}

.promo-ban .owl-carousel .owl-item .promo {
  height: 100%;
  min-height: auto;
}

.promo-ban .owl-carousel .owl-item .promo img {
  height: auto;
}

.promo-ban .owl-carousel .owl-nav {
  margin: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.promo-ban .owl-carousel .owl-nav button.owl-prev,
.promo-ban .owl-carousel .owl-nav button.owl-next {
  color: #343534;
  font-size: 3rem;
  position: absolute;
  top: 40%;
  background: #ffffff;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  transition: all 0.3s ease-in-out;
}

.promo-ban .owl-carousel .owl-nav button.owl-prev span,
.promo-ban .owl-carousel .owl-nav button.owl-next span {
  line-height: 3rem;
  position: absolute;
  top: -10px;
  left: 8px;
  color: #002d5b;
}

.promo-ban .owl-carousel .owl-nav button.owl-prev:hover,
.promo-ban .owl-carousel .owl-nav button.owl-next:hover {
  background-color: #ffc104;
}

.promo-ban .owl-carousel .owl-nav button.owl-prev {
  left: -2%;
}

@media screen and (max-width: 575px) {
  .promo-ban .owl-carousel .owl-nav button.owl-prev {
    left: 0px;
  }
}

.promo-ban .owl-carousel .owl-nav button.owl-next {
  right: -2%;
}

@media screen and (max-width: 575px) {
  .promo-ban .owl-carousel .owl-nav button.owl-next {
    right: 0px;
  }
}

.promo-ban .owl-carousel:hover .owl-nav {
  opacity: 1;
}

.promo-ban .promo-hover {
  bottom: -130px;
  left: 0;
  height: auto;
  color: #ffffff;
  padding: 10px 15px;
  margin: 0 0;
  width: 100%;
  position: absolute;
  transition: all 0.3s ease 0s;
  min-height: 175px;
  background: #002d5b;
  opacity: 0.8;
}

.promo-ban .item {
  overflow: hidden;
  width: 100%;
  position: relative;
  border-radius: 0.5rem;
}

.promo-ban .item:hover .promo-hover {
  background: #002d5b;
  opacity: 0.9;
  bottom: 0px;
  color: #ffc104;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
}

.promo-ban .item span {
  z-index: 10;
}

/*-- Travel deals css --*/
.travel-deal {
  position: relative;
}

/*-- card effect --*/
.cardeffect {
  transition: all 500ms ease-in-out;
}

.cardeffect:hover {
  transform: translateY(-10px);
  cursor: pointer;
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}

/*-- Flight Featured deals css --*/
.featured-deal {
  position: relative;
}

.featured-deal .picHeight {
  position: relative;
  max-height: 240px;
  height: auto;
}

@media screen and (max-width: 767px) {
  .featured-deal .picHeight {
    max-height: 100%;
  }
}

.featured-deal .airline-tags {
  top: 1rem;
  left: 1rem;
  z-index: 1;
}

.featured-deal .airline-tags .airlineName {
  opacity: 0;
}

.featured-deal .airline-icon {
  display: block;
  background: #ffffff;
  border-radius: 40px;
  cursor: pointer;
}

.featured-deal .airline-icon a {
  text-decoration: none;
  color: #002d5b;
}

.featured-deal .airline-icon a:hover {
  border-radius: 50px;
}

.featured-deal .airline-icon a:hover a {
  color: #ffffff;
}

.featured-deal .airline-icon a:hover .airlineName {
  opacity: 100;
}

/*-- flood effect --*/
.flood-effect {
  transition: 0.25s ease-in-out;
  overflow: hidden;
  display: inline-block;
  border-radius: 40px;
  vertical-align: middle;
}

.flood-effect:hover {
  box-shadow: inset 10rem 0 0 0 #002d5b;
}

.flood-effect:hover:hover a {
  color: #ffffff;
}

/*-- Shine effect --*/
.hoverShine figure {
  position: relative;
}

.hoverShine figure::before {
  position: absolute;
  top: 0;
  left: -90%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%);
  transform: skewX(-25deg);
}

.hoverShine figure:hover::before {
  animation: shine 0.75s;
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

/*-- card hover effect --*/
.card-effect {
  overflow: hidden;
  z-index: 2;
}

.card-effect:hover .card-box {
  left: 0;
}

.card-effect .card-box {
  width: 100%;
  height: 100%;
  left: -50rem;
  top: 0;
  background: #ffc104;
  position: absolute;
  transition: all 0.5s ease;
  z-index: -1;
}

/*-- image hover effect --*/
.img-effect img {
  transition: all 0.4s ease-in-out;
}

.img-effect:hover img {
  transform: scale(1.5);
}

/*-- process card hover effect --*/
.process-card {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.process-card:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -0.2rem;
  left: -0.2rem;
  background: #ffc104;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.process-card:hover:before {
  transform: scale(50);
}

.process-card:hover p {
  transition: all 0.3s ease-out;
  color: #343534;
}

.step-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  top: 0;
  left: 0;
  border-radius: 0 4px 0 32px;
}

.step-corner .step-arrow {
  color: #343534;
}

.process-card {
  cursor: pointer;
}

.process-card svg {
  width: 70px;
  height: 70px;
  transition: 0.5s ease-in-out;
}

.process-card:hover svg {
  transform: translateY(-20px);
}

.process-icon {
  margin-top: 3rem;
}

#work-process-01 path,
#work-process-02 path,
#work-process-03 path {
  fill: #002d5b;
}

/*-- flight top destination css --*/
.flight-destination {
  position: relative;
}

.flight-destination img {
  width: 100%;
  max-height: 185px;
}

@media screen and (max-width: 767px) {
  .flight-destination img {
    max-height: 100%;
  }
}

.text-link,
.text-link>span {
  position: relative;
}

.text-link:before,
.text-link:after,
.text-link>span:before,
.text-link>span:after {
  content: "";
  position: absolute;
  transition: transform 0.5s ease;
}

.text-link-effect {
  display: inline-block;
  overflow: hidden;
}

.text-link-effect:before,
.text-link-effect:after {
  right: 0;
  bottom: 0;
  background: #ffc104;
}

.text-link-effect:before {
  width: 100%;
  height: 2px;
  transform: translateX(-100%);
}

.text-link-effect:after {
  width: 2px;
  height: 100%;
  transform: translateY(100%);
}

.text-link-effect>span {
  display: block;
  color: #002d5b;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.text-link-effect>span:before,
.text-link-effect>span:after {
  left: 0;
  top: 0;
  background: #ffc104;
}

.text-link-effect>span:before {
  width: 100%;
  height: 2px;
  transform: translateX(100%);
}

.text-link-effect>span:after {
  width: 2px;
  height: 100%;
  transform: translateY(-100%);
}

.text-link-effect:hover:before,
.text-link-effect:hover:after,
.text-link-effect:hover>span:before,
.text-link-effect:hover>span:after {
  transform: translate(0, 0);
}

/*-- unique experience css --*/
.recom-sec {
  position: relative;
}

.recom-sec .con {
  position: absolute;
  left: 5%;
  top: 5%;
}

.item-effect {
  background: #343534;
  overflow: hidden;
  max-height: 250px;
}

@media screen and (max-width: 575px) {
  .item-effect {
    max-height: 100%;
  }
}

.item-effect img {
  min-height: 220px;
}

.item-effect a {
  position: absolute;
  bottom: 0;
  left: 10%;
  border-radius: 50%;
  font-size: 2rem;
  color: #ffffff;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  background: #002d5b;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 300ms 0ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.item-effect:hover a {
  transform: translate(-50%, -50%) scale(1);
  transition: transform 300ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: 0.3s ease-in-out;
}

.item-effect:hover a:hover {
  background-color: #ffc104;
  color: #343534;
}

.item-effect:hover img {
  opacity: 0.3;
  filter: grayscale(10%);
}

/*-- ribbon css --*/
.ribbon {
  --ribbon-size: 20px;
  --ribbon-padding: 10px;
  padding: var(--ribbon-padding);
  padding-inline-start: calc(var(--ribbon-size) + var(--ribbon-padding));
  border-radius: 4px;
  background-color: #002d5b;
  color: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-clip-path: polygon(0 0,
      var(--ribbon-size) 50%,
      0 100%,
      100% 100%,
      100% 0);
  clip-path: polygon(0 0, var(--ribbon-size) 50%, 0 100%, 100% 100%, 100% 0);
}

/*-- button effect --*/
.btn::before,
.btn::after {
  background: #ffc104;
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.btn-effect::after {
  height: 100%;
  left: 0;
  top: 0;
  width: 0;
}

.btn-effect:hover:after {
  width: 100%;
}

/*-- footer css --*/
.footer {
  background-color: #343534;
}

.footer h1,
.footer h2,
.footer h3,
.footer p {
  color: #ffffff;
}

.footer .custom-btn-subscribe {
  color: #ffffff;
  background-color: #002d5b;
}

.footer .custom-btn-subscribe:hover {
  color: #343534;
  background-color: #002d5b;
}

.footer .fl-menu li {
  list-style: none;
  line-height: 34px;
}

.footer .fl-menu li a {
  color: #9f9f9f;
  position: relative;
}

.footer .fl-menu li a::after {
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 10px;
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
  transform: scale(0, 1);
}

.footer .fl-menu li a:hover {
  color: #ffc104;
}

.footer .fl-menu li a:hover:after {
  opacity: 1;
  transform: scale(1);
}

.footer .social a,
.footer .social .botom-link {
  color: #ffffff;
}

.footer .social a:hover,
.footer .social .botom-link:hover {
  color: #ffc104;
}

/* Back to top button css */
.back-to-top {
  position: fixed;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  z-index: 50;
  border-radius: 50%;
  right: 2rem;
  bottom: 2rem;
  text-align: center;
  padding: 0px;
  margin: 0px;
}

.back-to-top .back-to-top a {
  text-decoration: none;
}

.back-to-top i {
  color: #ffffff;
  font-size: 1.8rem;
  transition: all 0.3s ease-in-out;
}

.back-to-top i:hover {
  color: #ffc104;
}
.img-fluid{
  width:100px;
}
@media screen and (max-width:430px) {
  .img-fluid{
    width:65px;
  }
  .flex-grow-1 .btn{
    width:60%;
    font-size:14px;
  }
}

/*# sourceMappingURL=main.css.map */
</style>
