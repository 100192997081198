<template>
  <v-app v-if="loading">
    <v-main>
      <div v-if="domaintrue" class="text-white fs-14 d-flex justify-content-between align-center px-5 py-2" style="background-color: #005672;">
        <div>
          <span>{{ $t("geoipContent.text1") }} {{ countryname }}
            {{ $t("geoipContent.text2") }}
          </span>
          <span class="text-capitalize ms-1">{{ searchDomaintitle }}</span>.
          <span>{{ $t("geoipContent.text3") }}</span>
          <span @click="routerDomain" class="border-bottom border-1 fw-500 ms-1" style="cursor: pointer">
            {{ dataDomain }}
          </span>
        </div>
        <div>
          <v-icon color="red" @click="domaintrue = false">
            mdi-close-circle-outline
          </v-icon>
        </div>
      </div>
      <div v-if="datashow">
        <div id="navbar">
          <headerCom @localeChanged="updateLocale" />
        </div>
        <div class="bg-pages">
          <router-view @localeChanged="updateLocale"></router-view>
        </div>

        <div v-if="dataloader">
          <dataloader></dataloader>
        </div>

        <div id="food">
          <footerCom/>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import headerCom from "@/components/headerCom1.vue";
// import country1 from "@/coundtrycode.json";
import CryptoJS from "crypto-js";
import moment from "moment";
import dataloader from "@/components/dataLoder.vue";
// import getConfigResUS from "@/getConfigResUS.json";
// import getConfigResCA from "@/getConfigResCA.json";

import footerCom from "@/components/footerCom.vue";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
import { updateLocaleMessages } from '@/plugins/i18n.js';

export default {
  name: "App",
  components: {
    headerCom,
    footerCom,
    dataloader
  },
  emits :{
    localeChanged: null,
  },
  
  data: () => ({
    dataloader:false,
    navbar: true,
    food: true,
    datashow: false,
    countryname: "",
    loading: false,
    visitcountryname: "",

    i18nlocaleLang:"",

    dataDomain: "",
    dataDomainData: "",
    SearchDomain: "",
    searchDomaintitle: "",
    domaintrue: false,

    countryOptions: [],
    siteContentData: "",
    languageData: "",
    getLanguages: "",
    countryIsocode: "",

    configReq: {
      service_type: "GetPortalConfigRQ",
      api_ver: "1",
      payload: {
        domain_name: "",
        env: "CERT",
        language: "EN",
        token: "jsdjOJDKJ7675",
      },
    },

    configUser_Id: "",

    getEndpoint:"",
    loaderReq:{
      "payload": {
        "domain": "traveloplexcomuat.vercel.app",
        "etoken": "d9781f79c8e577d068587e0aca400e28"
      }
    }
  }),

  methods: {

    routerDomain(){
      window.location.href = `https://${this.dataDomain}`
    },

    updateLocale(newLocale) {
      // Update the app's locale based on the event received from headerCom1.vue
      this.i18nlocaleLang = newLocale;
      console.log('App locale updated to:', this.i18nlocaleLang);
    },
    
    async getloaderPortal(){
      let hostname = window.location.hostname;
      if (hostname == "localhost") {
        console.log(hostname, "localhost....");
      } else {
        this.loaderReq.payload.domain = hostname;
      }

      // if(window.location.href == "http://localhost:8080/"){
      //   this.loaderReq.payload.domain = "traveloplex.com";
      // }
      // else{
      //   this.loaderReq.payload.domain = "traveloplex.ca";
      // }

      let Key = "8dsxBc9vDl4I9alHbJU7Dg4EBbr7AS0w";
      let domain_name = this.loaderReq.payload.domain;
      let current_date = moment(new Date()).format("YYYYMMDD");
      let fixed_value = 3;

      // console.log(current_date,'dasafsdfdasdasd');

      let compineskey = Key + domain_name + current_date + fixed_value.toString();
      const encryptedkey = CryptoJS.MD5(compineskey).toString();

      this.loaderReq.payload.etoken = encryptedkey

      // console.log(this.loaderReq,'loderred...2');

      await axios.post("https://otaget.nexus2.wistirna.com",this.loaderReq, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if(response.data.response_type === "success"){
            this.getEndpoint = response.data.endpoint;

            if(this.getEndpoint){
              this.envPortal();
            }
          }
        })
        .catch((error) => {
          console.log("Error fetching application:", error);
        });
    },

    
    indexHeader() {
      const getConfigData = getConfigDataFromLocalStorage();

      if (getConfigData) {
        let portalTitle = getConfigData.payload.portal_configuration.site_title;
        let portalFavicon = getConfigData.payload.portal_configuration.favicon;

        document.getElementById("siteTitle").innerText = portalTitle;
        document.getElementById("faviconPortal").href = portalFavicon;
      }
    },

    envPortal() {
      this.configReq.payload.domain_name = this.loaderReq.payload.domain;
      console.log(this.configReq.payload.domain_name, "hshshshs....");

      console.log(this.configReq, "configReqconfigReqconfigReq");

      axios.get(`${this.getEndpoint}getportalconfig`, {
          params: this.configReq,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response) {
            this.loading = true;
            console.log(response, "configResponseconfigResponse");
            let configResponse = response.data;
            // let configResponse = this.loaderReq.payload.domain == ""  ?  getConfigResUS : getConfigResCA;
            // this.dataDomainData = configResponse.payload.portal_configuration.geoip_location.country_domain.replace("https://","");
            // this.dataDomain = this.dataDomainData.charAt(0).toUpperCase() + this.dataDomainData.slice(1);
            this.SearchDomain = this.configReq.payload.domain_name;
            this.searchDomaintitle =
              configResponse.payload.portal_configuration.site_title;
            this.countryname =
              configResponse.payload.portal_configuration.country.name;

            this.getLanguages = configResponse.payload.portal_configuration.languages;

            this.countryIsocode = configResponse.payload.portal_configuration.country.iso_code;
            
            this.countryOptions = configResponse.payload.portal_configuration.country_options;

            this.siteContentData = configResponse.payload.portal_configuration.site_content_data;
            this.languageData = configResponse.payload.portal_configuration.language_data;
            // let portalurl = configResponse.payload.portal_configuration.geoip_location.portal_url;
            if (this.languageData) {
              this.getlangaugeUrl();
            }
            else{
              this.$i18n.locale = 'CA-en';
            }

            let geoIsocode = configResponse.payload.portal_configuration.geoip_location.country_iso_code;
            let geoCountrydomain = configResponse.payload.portal_configuration.geoip_location.country_domain;
            
            let domainName = window.location.hostname;
            let currentDomain = "";

            if (domainName == "localhost") {
              console.log(domainName, "localhost....");
              currentDomain = `https://${this.loaderReq.payload.domain}`;
            }
            else{
              currentDomain = window.location.href;
            }

            let findIsocode = this.countryOptions.find((v) => v.country_code == geoIsocode);
            if(findIsocode){
              console.log(geoIsocode,'====',findIsocode.country_code,'-----',currentDomain,'=====',geoCountrydomain);
              if(findIsocode.country_code == geoIsocode && currentDomain !== geoCountrydomain){
                this.domaintrue = true;
                // this.visitcountryname = findIsocode ? findIsocode.country_name : "Global";
                const findCountry = this.countryOptions.find((c) => c.portal_url == geoCountrydomain);
  
                let getDomainUrl = findCountry ? findCountry.portal_url : geoCountrydomain;
                let getDomain = getDomainUrl.replace("https://","");
                this.dataDomain = getDomain.charAt(0).toUpperCase() + getDomain.slice(1);
                console.log(this.domaintrue,'getDomsinUsrlasads...1');
              }
            }
            else{
              this.domaintrue = false;
              console.log(this.domaintrue,'getDomsinUsrlasads...2');
            }

         
            // console.log(this.dataDomain,this.SearchDomain, 'response.data')


            //  let hrefData = "";
            // console.log(this.$route.query, "this.$route.query.id;");
            // if (this.$route.query.refer == "web") {
            //   console.log(this.$route.query.id, "this.$route.query.id;");
            // } else if (this.$route.query.ref == "web") {
            //   console.log(this.$route.query.ref, "this.$route.query.id;");
            // }
            // else if (window.location.href == portalurl) {
            //   this.countryOptions.forEach((c) => {

            //     console.log(c,'sdasdasddomaojddd');
                
            //     if (c.default_domain) {
            //       hrefData = c.portal_url;
            //       // hrefData = "http://localhost:8080";
            //     }

            //     if (c.country_code !== geoIsocode && c.default_domain) {
            //       this.domaintrue = false;
            //         console.log(c, "country");
            //       if (c.portal_url !== portalurl) {
            //         // window.location.href =hrefData;
            //         window.location.href = `${hrefData}?ref=web&redirect=false`;
            //       }
            //     }

            //     if (c.country_code == geoIsocode) {
            //       if (c.portal_url !== portalurl) {
            //         this.domaintrue = true;
            //         window.location.href = `${hrefData}?refer=web&redirect=false`;
            //       }
            //     }
            //   });

            //   country1.forEach((v) => {
            //     if (geoIsocode == "US" || geoIsocode == "CA") {
            //       if (v.iso_code == geoIsocode) {
            //         this.visitcountryname = v.name;
            //       console.log(this.visitcountryname,'visitcountryname')
            //       }
            //     } else {
            //       this.visitcountryname = "Global";
            //     }
            //   });
            // } else {
            //   console.log("asdasdasdasdasdaspp");
            // }

            // console.log(this.dataDomainData,this.SearchDomain,'this.dataDomain')


            // if (this.dataDomainData.includes(this.SearchDomain)) {
            //   console.log("pppppppaduhdh");
            //   this.domaintrue = false;
            // } else {
            //   this.domaintrue = true;
            // }

            // Encrypt data using CryptoJS
            const secretKey = "portal_config_datas";
            const encryptedData = CryptoJS.AES.encrypt(
              JSON.stringify(configResponse),
              secretKey
            ).toString();

            localStorage.setItem("portalData", encryptedData);

            // localStorage.setItem("portalData", JSON.stringify(configResponse))

            if (configResponse) {
              this.datashow = true;
            }
            this.indexHeader();

            if (this.getLanguages && this.countryIsocode) {
              this.setLanguage();
              console.log('working...1')
              // this.$i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`
              // console.log(this.$i18n.locale, "langresponseresponse...1");
            }
            else{
              this.$i18n.locale = 'CA-en';
              console.log('working...2')
              // this.$i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`
            }

          }
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
          // alert("Something went difficulties...");
          this.loading = false;
        });
    },

    setLanguage() {
      let getLang = this.getLanguages[0].code;
      let savedLanguage = `${this.countryIsocode}-${getLang}`;
      localStorage.setItem("setlang", savedLanguage);
      // let setlocalLang = localStorage.setItem("setlang", savedLanguage);
      // if(setlocalLang){
      //   this.localeLanguage();
      // }
    },
    localeLanguage() {
      const storedLocale = localStorage.getItem("defaultlang");
      const storedSetLang = localStorage.getItem("setlang");

      let getlocalIso = "";
      let getlocalLang = "";
      let getsetIso = "";
      let getsetlang = "";
      // let savedLanguage = "";


      if (storedLocale) {
        getlocalIso = storedLocale.split('-')[0];
        getlocalLang = storedLocale.split('-')[1];
      }
      if (storedSetLang) {
        getsetIso = storedSetLang.split('-')[0];
        getsetlang = storedSetLang.split('-')[1];
      }


      if (storedSetLang && storedLocale) {
        console.log('Check...1');
        if (storedLocale == storedSetLang) {
          console.log('Check...2');

          return storedSetLang;
          // this.i18n.locale = storedSetLang;
        }
        else if (storedLocale !== storedSetLang) {
          console.log(getlocalIso, getsetIso, 'Check...3', getlocalLang, getsetlang);
          if (getlocalIso !== getsetIso && getlocalLang !== getsetlang || getlocalIso !== getsetIso && getlocalLang == getsetlang) {

            localStorage.removeItem("defaultlang");
            console.log('Check...4');
            return storedSetLang;
            // this.i18n.locale = storedSetLang;

          }
          else if (getlocalIso == getsetIso && getlocalLang !== getsetlang) {
            console.log(storedLocale, 'Check...5');

            return storedLocale;
            // this.i18n.locale = storedLocale;
          }
        }
      }
      else if (storedSetLang) {
        console.log('Check...6');

        return storedSetLang;
        // this.i18n.locale = storedSetLang;
      }
      else if (storedLocale) {
        console.log('Check...7');

        return storedLocale;
        // this.i18n.locale = storedLocale;
      }
      else {
        console.log('Check...8');

        return 'CA-en';
        // return `${this.countryIsocode}-${this.getLanguages[0].code}`;

        // this.i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`;
      }
    },


    getlangaugeUrl() {
      // this.dataloader = true;
      let langData = this.languageData
      // let getlocale = this.localeLanguage();
      // this.$i18n.locale = this.localeLanguage();
      var localLang = this.localeLanguage(); //CA.es

      console.log(localLang, "getegeteegtegete...1");

      // this.$i18n.locale = localLang;
      // console.log(this.$i18n.locale, "getegeteegtegete...2");
      let langdataUrl = "";
      // console.log(localLang,'localLanglocalLang')
      
      Object.keys(langData).forEach(v => {
        console.log(v,this.countryIsocode, 'vcountryIsocodevcountryIsocode...3');
        if (v == this.countryIsocode) {
          const lang = langData[v];

          Object.keys(lang).forEach(local => {
            console.log(local, 'dasasasasdas')
            if (local == localLang) {

              langdataUrl = lang[local]
              console.log(local, localLang, langdataUrl, 'dasasasasdas...2');

              axios.get(langdataUrl, {
                headers: {
                  "Content-Type": "text/html; charset=utf-8",
                },
              })
                .then((response) => {
                  if (response) {

                    console.log(response, "getegeteegtegete...3");
                    this.dataloader = false;
                    const cleanedData = this.parseLanguageData(response.data);
                    this.setLanguageMessages(localLang, cleanedData);                 
                  }
                })
                .catch((error) => {
                  console.log(error, 'errorrLangaugeAPI.....')
                  if(error){
                    setInterval(() => {
                      this.dataloader = false;
                    }, 3000);
                  }

                  this.$i18n.locale = 'CA-en'
                })
            }
          })
        }
      });
    },


    parseLanguageData(data) {
      try {
        const cleanedData = data
          .replace(/export\s+default\s+/, '')
          .replace(/;\s*$/, ''); // Remove trailing semicolon if present
        return eval(`(${cleanedData})`);
      } catch (error) {
        console.error('Error processing language content:', error);
        return {};
      }
    },

    // updateLocaleMessages(locale, messages) {
    //   // Update i18n with new locale messages
    //   updateLocaleMessages(locale, messages);
    //   console.log(this.$i18n.locale,'getegeteegtegete....4')
    // },

    setLanguageMessages(locale, messages) {
      // this.$i18n.setLocaleMessage(locale, messages);
      updateLocaleMessages(locale, messages);
      this.$i18n.locale = locale;

      console.log(this.$i18n.locale,'getegeteegtegete....4')
    }
  },

  watch: {
    "i18nlocaleLang": {
      immediate: true,
      handler: function (newValue) {
        // this.localeLanguage();
        console.log(newValue,'sdasdasdasnewValue');
        this.dataloader = true;
        console.log(this.dataloader,'getegeteegtegete....0')
        this.getlangaugeUrl();
        console.log(this.$i18n.locale,'getegeteegtegete....5')
      },
      deep: true,
    },

    "$route.path": {
      immediate: true,
      handler(newValue) {
        this.getloaderPortal();

        if (newValue === "/loader") {
          this.navbar = false;
          this.food = false;
        } else {
          this.navbar = true;
          this.food = true;
        }
        // console.log(newValue, '4545');
      },
    },
  },

  // created() {
  //   console.log(this.$i18n.locale, "getegeteegtegete....0");
  // },

  mounted(){
    this.getloaderPortal();
  }
  
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300&family=Poppins:wght@300;400;500&display=swap");

/* .bg-pages {
background: rgb(230, 217, 26);
background: linear-gradient(to right, rgb(45 129 253 / 65%), rgb(193 193 193));
} */

/* * {
  font-family: 'Poppins', sans-serif;
} */

* {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

#navbar {
  display: block;
}

#food {
  display: block;
}

.fs-14{
  font-size: 14px;
}
.fw-500{
  font-weight: 500;
}

.bg-pages {
  background: #f1f1f1;
  /* background-image:url('./assets/bmbg2.svg'); */
}

.v-main{
  background: #f1f1f1;
}

/* .bg-pages{
    background: linear-gradient(90deg, rgba(148, 187, 233, 0.7512254901960784) 0%, rgba(238, 174, 202, 1) 50%, rgba(148, 187, 233, 0.7456232492997199) 100%);
  } */
/* .bg-pages { */

/* background-image: url(https://andit.co/projects/html/and-tour/demo/assets/img/banner/bg.png); */
/* background: rgb(230, 217, 26); */
/* background: linear-gradient(277deg, rgba(230,217,26,1) 0%, rgba(51,175,167,1) 58%, rgba(253,45,164,1) 100%); */
/* background: linear-gradient(to right, rgb(45 129 253 / 65%), rgb(193 193 193)); */

/* margin-top:70px; */
/* background-image: url(https://andit.co/projects/html/and-tour/demo/assets/img/banner/bg.png); */
/* } */
</style>
