<template>
    <div class="m-md-0 m-3 form-container">
        <div>
            <div class="row gx-0">
                <div class="col-lg-12 ps-0 pe-0 rounded-border formshadow">
                  <div class="headcard" align="center">
                    <v-card class="form-card rounded-border px-sm-4 px-3" rounded="4"
                      style="padding: 10px 16px; overflow: revert;min-height: auto;">
                        <div class="hisOptions" @click="travelHistory = !travelHistory" v-if="this.RecentSearchData.length > 0">
                            <v-icon color="white" size="24">{{ !travelHistory ?  'mdi-history' : 'mdi-undo' }}</v-icon>
                        </div>
                        <div class="row gx-0" v-if="!travelHistory">
                            <div class="col-md-12">
                              <div class="row gx-2 pt-3">
                                <div class="col-md-12 col-lg-4 col-12 d-flex">
                                  <v-btn
                                    size="small"
                                    value="1"
                                    class="normal-radio"
                                    style="border-radius: 25px 0px 0px 25px"
                                    :class="{ radio: activetrip == 'Roundtrip' }"
                                    @click="activeType('Roundtrip')"
                                  >
                                    <span style="letter-spacing: 0.5px">{{
                                      $t("formsContents.options.roundTrip")
                                    }}</span></v-btn
                                  >
                                  <v-btn
                                    size="small"
                                    value="2"
                                    class="normal-radio"
                                    style="border-radius: 0 !important"
                                    :class="{ radio: activetrip == 'Oneway' }"
                                    @click="activeType('Oneway')"
                                  >
                                    <span style="letter-spacing: 0.5px">{{
                                      $t("formsContents.options.oneWay")
                                    }}</span></v-btn
                                  >
                                  <v-btn
                                    rounded="xl"
                                    size="small"
                                    value="3"
                                    class="normal-radio"
                                    style="
                                      border-radius: 0px 25px 25px 0px !important; width:95px;padding-left:4px;
                                    "
                                    :class="{ radio: activetrip == 'Multicity'}"
                                    @click="activeType('Multicity')"
                                  >
                                    <span style="letter-spacing: 0.5px">{{
                                      $t("formsContents.options.multiCity")
                                    }}</span></v-btn
                                  >
                                </div>
                            
                                <div class="col-md-6 col-lg-2 col-sm-6 col-5 my-lg-0 my-3">
                                  <div class="d-flex align-center input-styles-2 select-option rounded-border">
                                    <v-select
                                      v-model="classType"
                                      :items="classTypeitems"
                                      ref="mySelect"
                                      class="select-2"
                                      variant="underlined"
                                      item-title="text"
                                      style="color: black"
                                    >
                                      <template
                                        v-slot:item="{ props, item, index }"
                                      >
                                        <v-list-item
                                          v-bind="props"
                                          :subtitle="item.department"
                                          @click="selectClasstype(item, index)"
                                        >
                                        </v-list-item>
                                      </template>
                                    </v-select>
                                  </div>
                                </div>
                            
                                <div class="col-lg-3 col-sm-6 col-7 g-2 my-lg-0 my-3 passenger-card">
                                  <div class="row justify-center">
                                    <div class="col-12 justify-center">
                                      <!-- <div class="labelTop">
                                          <p>Pax Selection</p>
                                        </div> -->
                                      <div
                                        class="d-flex input-styles rounded-border">
                                        <v-select
                                          v-model="selectedPax"
                                          :items="countries"
                                          ref="countrySelect"
                                          variant="underlined"
                                          style="height:30px"
                                          class="select-1"
                                        >
                                          <!-- <template #item="{ item }"> -->
                                          <template #item="{}">
                                            <div
                                              class="dropdown_section"
                                              style="cursor: pointer"
                                            >
                                              <div
                                                class="row d-flex align-center my-2 mx-1"
                                                style="height: 37px"
                                                @click.stop
                                              >
                                                <div
                                                  class="col-3"
                                                  style="width: 38%"
                                                >
                                                  <span
                                                    style="
                                                      font-size: 14px;
                                                      font-weight: 500;
                                                      color: #4e4d4d;
                                                    "
                                                    >{{
                                                      $t(
                                                        "formsContents.options.adult"
                                                      )
                                                    }}</span
                                                  >
                                                </div>
                                                <div
                                                  class="col-3 px-0"
                                                  style="width: 9%"
                                                >
                                                  <v-icon
                                                    style="
                                                      width: auto;
                                                      color: grey;
                                                      font-size: 18px;
                                                    "
                                                  >
                                                    mdi-human-male-female
                                                  </v-icon>
                                                </div>
                                                <div
                                                  class="col-6 adult"
                                                  style="width: 50%; height: auto"
                                                >
                                                  <div
                                                    class="row"
                                                    style="padding: 0px"
                                                  >
                                                    <div
                                                      class="col-4 formbuttons"
                                                      @click="deg1()"
                                                    >
                                                      <div class="adult-plus">
                                                        -
                                                      </div>
                                                    </div>
                                                    <div
                                                      class="col-4"
                                                      style="
                                                        background-color: white;
                                                      "
                                                      align="center"
                                                    >
                                                      <div>{{ adultValue }}</div>
                                                    </div>
                                                    <div
                                                      class="col-4 formbuttons"
                                                      @click="add1()"
                                                    >
                                                      <div class="adult-plus">
                                                        +
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <!-- <div>{{ item.name }}</div> -->
                                              </div>
                                          
                                              <div
                                                class="row d-flex align-center my-2 mx-1"
                                                @click.stop
                                              >
                                                <div
                                                  class="col-4 d-flex flex-column pe-0"
                                                  style="width: 38%"
                                                >
                                                  <span
                                                    style="
                                                      font-size: 14px;
                                                      font-weight: 500;
                                                      color: #4e4d4d;
                                                    "
                                                    >{{
                                                      $t(
                                                        "formsContents.options.children"
                                                      )
                                                    }}</span
                                                  >
                                                  <span
                                                    style="
                                                      font-size: 11px;
                                                      color: #4e4d4d;
                                                    "
                                                    >2-11({{
                                                      $t(
                                                        "formsContents.options.years"
                                                      )
                                                    }})</span
                                                  >
                                                </div>
                                                <div
                                                  class="col-2 px-0"
                                                  style="width: 9%"
                                                >
                                                  <v-icon
                                                    style="
                                                      width: auto;
                                                      color: grey;
                                                      font-size: 18px;
                                                    "
                                                  >
                                                    mdi-human-child
                                                  </v-icon>
                                                </div>
                                                <div
                                                  class="col-6 adult"
                                                  style="width: 50%"
                                                >
                                                  <div
                                                    class="row"
                                                    style="padding: 0px"
                                                  >
                                                    <div
                                                      class="col-4 formbuttons"
                                                      @click="deg2()"
                                                    >
                                                      <div class="adult-plus">
                                                        -
                                                      </div>
                                                    </div>
                                                    <div
                                                      class="col-4"
                                                      style="
                                                        background-color: white;
                                                      "
                                                      align="center"
                                                    >
                                                      <div>{{ childValue }}</div>
                                                    </div>
                                                    <div
                                                      class="col-4 formbuttons"
                                                      @click="add2()"
                                                    >
                                                      <div class="adult-plus">
                                                        +
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <!-- <div>{{ item.name }}</div> -->
                                              </div>
                                          
                                              <div
                                                class="row d-flex align-center my-2 mx-1"
                                                @click.stop
                                              >
                                                <div
                                                  class="col-5 d-flex flex-column pe-0"
                                                  style="width: 38%"
                                                >
                                                  <span
                                                    style="
                                                      font-size: 14px;
                                                      font-weight: 500;
                                                      color: #4e4d4d;
                                                    "
                                                    >{{
                                                      $t(
                                                        "formsContents.options.infant"
                                                      )
                                                    }}</span
                                                  >
                                                  <span
                                                    style="
                                                      font-size: 11px;
                                                      color: #4e4d4d;
                                                    "
                                                    >2({{
                                                      $t(
                                                        "formsContents.options.years"
                                                      )
                                                    }})</span
                                                  >
                                                </div>
                                            
                                                <div
                                                  class="col-1 ps-0"
                                                  style="width: 9%"
                                                >
                                                  <v-icon
                                                    style="
                                                      width: auto;
                                                      color: grey;
                                                      font-size: 18px;
                                                    "
                                                  >
                                                    mdi-baby-buggy
                                                  </v-icon>
                                                </div>
                                                <div
                                                  class="col-6 adult"
                                                  style="width: 50%"
                                                >
                                                  <div
                                                    class="row"
                                                    style="padding: 0px"
                                                  >
                                                    <div
                                                      class="col-4 formbuttons"
                                                      @click="deg3()"
                                                    >
                                                      <div class="adult-plus">
                                                        -
                                                      </div>
                                                    </div>
                                                    <div
                                                      class="col-4"
                                                      style="
                                                        background-color: white;
                                                      "
                                                      align="center"
                                                    >
                                                      <div>{{ infantValue }}</div>
                                                    </div>
                                                    <div
                                                      class="col-4 formbuttons"
                                                      @click="add3()"
                                                    >
                                                      <div class="adult-plus">
                                                        +
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                          
                                              <div
                                                class="mt-4 pe-3 d-flex justify-end"
                                                @click="
                                                  (travels = false),
                                                    localdone(),
                                                    closeDropdown()
                                                "
                                              >
                                                <v-btn
                                                  rounded="3"
                                                  color="#94C20E"
                                                  width="100px"
                                                  height="30px"
                                                  style="color: white"
                                                >
                                                  {{
                                                    $t("formsContents.options.done")
                                                  }}
                                                </v-btn>
                                              </div>
                                            </div>
                                          </template>
                                      
                                          <template #selection="{}">
                                            <div class="pax-fonts d-flex">
                                              <span
                                                class="align-center me-lg-2 me-0"
                                              >
                                                <v-icon color="#3c0ec2"
                                                  >mdi-account-outline</v-icon
                                                >
                                              </span>
                                              <span
                                                style="
                                                  color: grey;
                                                  font-size: 14px;
                                                  font-weight: 500;
                                                "
                                              >
                                                {{ totalPassenger }}
                                                {{
                                                  $t(
                                                    "formsContents.options.passengers"
                                                  )
                                                }}</span
                                              >
                                              <!-- <span>, {{ childValue }} Ch</span>
                                                <span>, {{ infantValue }} In</span> -->
                                            </div>
                                          </template>
                                        </v-select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                        <div v-if="roundTrip || Oneway">
                          <v-form>
                            <div class="row gx-0 mt-3 mb-2">
                              <div class="col-lg-11">
                                <div class="row gx-0">
                                  <div class="col-lg-3 col-sm-6 col-12 my-2 g-2">
                                    <div class="labelTop">
                                      <p>{{$t("formsContents.labels.fromlabel")}}</p>
                                    </div>
                                    <div class="d-flex input-styles align-center rounded-border">
                                      <span class="d-flex align-center ps-2 me-2">
                                        <v-icon color="#3c0ec2">mdi-airplane-takeoff</v-icon>
                                      </span>
                                      <div class="w-100">
                                        <div class="vas-autocomplete">
                                          <div class="d-flex row align-center">
                                            <div class="col-10 pe-0 d-flex justify-start">
                                              <input
                                                type="text"
                                                :placeholder="this.$t('formsContents.labels.origin')"
                                                style="cursor: pointer"
                                                v-model="inputCity"
                                                @keyup="typeCity(inputCity, 1)"
                                                class="inter-input"
                                                @click="
                                                  (inputCity = ''),
                                                    (formres = true),
                                                    (formres1 = false),
                                                    (this.city1 = [])
                                                "
                                                ref="firstAutocomplete"
                                              />
                                          </div>
                                           <div v-if="oneway.from" class="col-2 ps-0">
                                            <v-icon @click="fromInputData()" size="16" color="grey">mdi-close</v-icon>
                                          </div>
                                          </div>
                                          <div v-if="typedrop" v-click-outside="clickoutDropdown">
                                            <div v-if="this.city.length > 0 ? true : false"
                                              class="autocompete-menual-dropdown">
                                              <div v-for="(data, index) in city" :key="index">
                                                <div @click="datachange(data)" :style="index == city.length - 1 ? '' : 'border-bottom:1px solid lightgray'"
                                                  class="d-flex p-2 va-back" >
                                                  <div style="display: flex;align-items: center;">
                                                    <v-icon style="transform: rotate(90deg);" color="gray" size="20">
                                                      {{ data.location === "airport" ? "mdi-airplane" : "mdi-radiobox-marked" }}
                                                    </v-icon>
                                                  </div>

                                                  <div class="m-1 va-content">
                                                    {{ data.iata }}, {{ data.city }},{{ data.name }}
                                                  </div>
                                                </div>

                                                <div v-if="data.group_airports.length > 0">
                                                  <div v-for="group in data.group_airports" :key="group">
                                                    <div v-if="data.group_airport_city != group.iata">
                                                      <div class="d-flex va-back" @click="datachange(group)">
                                                        <div style="display: flex;align-items: center;" class="ms-4">
                                                          <v-icon style="transform: rotate(90deg);" color="gray" size="15"
                                                            >mdi-airplane</v-icon>
                                                        </div>
                                                        <div class="m-1 va-content">
                                                          {{ group.iata }}, {{ group.city }},{{ group.name }}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                         
                                        </div>
                                      </div>
                                      <div v-if="fromloader">
                                        <div class="spinner-border spinner-border-sm" 
                                        style="display:flex;align-items:center;color:rgb(47,47,47);" role="status"></div>
                                      </div>
                                    </div>
                                    <div class="text-start">
                                      <span
                                        class="p-error"
                                        v-if="fromErrorMessage"
                                        >{{$t("formsContents.errorMsg.departReq")}}</span>
                                      <br
                                        v-if="fromErrorMessage && maximumletter"
                                      />
                                      <span
                                        class="p-error"
                                        v-if="maximumletter"
                                        >{{$t("formsContents.errorMsg.enterMinimum")}}</span>
                                    </div>
                                    <div v-if="formres">
                                      <div class="recent_style">
                                        <v-card
                                          style="
                                            max-height: 300px;
                                            border-radius: 20px;
                                          "
                                        >
                                          <h5
                                            style="
                                              text-align: left;
                                              margin: 12px;
                                              font-weight: 500;
                                              font-size: 18px;
                                            "
                                          >
                                            {{$t("formsContents.labels.recentSearch")}}
                                          </h5>
                                          <div
                                            class="row resu-form mt-1"
                                            v-for="data of resultFrom"
                                            :key="data"
                                          >
                                            <div class="col-2">
                                              <v-icon>
                                                mdi-clock-outline
                                              </v-icon>
                                            </div>

                                            <div
                                              class="col-10"
                                              @click="getrecent(data)"
                                            >
                                              <div
                                                style="
                                                  font-size: 16px;
                                                  text-align: left;
                                                "
                                              >
                                                <!-- {{
                                                  getrecentData(
                                                    data.from.originalName
                                                  )
                                                }} -->

                                                {{data.from.name || data.from}}
                                              </div>
                                            </div>
                                          </div>
                                        </v-card>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-3 col-sm-6 col-12 my-2 g-2">
                                    <div class="labelTop">
                                      <p>
                                        {{ $t("formsContents.labels.tolabel") }}
                                      </p>
                                    </div>
                                    <div
                                      class="d-flex input-styles align-center rounded-border"
                                      @click="formres1 = true"
                                    >
                                      <span
                                        class="d-flex align-center ps-2 me-2"
                                      >
                                        <v-icon color="#3c0ec2"
                                          >mdi-airplane-landing</v-icon
                                        >
                                      </span>

                                      <div class="w-100">

                                        <div class="vas-autocomplete">
                                          <div class="d-flex row align-center">
                                            <div class="col-10 pe-0  d-flex justify-start">
                                              <input
                                                type="text"
                                                style="cursor: pointer"
                                                :placeholder="this.$t('formsContents.labels.destination')"
                                                v-model="inputCityto"
                                                @keyup="typeCity(inputCityto, 2)"
                                                class="inter-input"
                                                @click="
                                                  (inputCityto = ''),
                                                    (formres = false),
                                                    (formres1 = true),
                                                    (this.city = [])
                                                "
                                                ref="secondAutoComplete"
                                              />
                                          </div>
                                           <div v-if="oneway.to" class="col-2 ps-0">
                                            <v-icon @click="toInputData()" size="16" color="grey">mdi-close</v-icon>
                                          </div>
                                          </div>
                                          <div v-if="typedrop1" v-click-outside="clickoutDropdown">
                                            <div v-if="this.city1.length > 0 ? true : false" class="autocompete-menual-dropdown">
                                              <div v-for="(data, index) in city1" :key="index">
                                                <div @click="datachangeTo(data)" :style="index == city.length - 1 ? '' : 'border-bottom:1px solid lightgray'"
                                                  class="d-flex p-2 va-back">
                                                  <div style="display: flex;align-items: center;">
                                                    <v-icon style="transform: rotate(90deg);" color="gray" size="20">
                                                      {{data.location === "airport" ? "mdi-airplane" : "mdi-radiobox-marked"}}
                                                    </v-icon>
                                                  </div>

                                                  <div class="m-1 va-content">
                                                    {{ data.iata }}, {{ data.city }},{{ data.name }}
                                                  </div>
                                                </div>

                                                <div v-if="data.group_airports.length > 0">
                                                  <div v-for="group in data.group_airports" :key="group">
                                                    <div v-if="data.group_airport_city != group.iata">
                                                      <div class="d-flex va-back" @click="datachangeTo(group)">
                                                        <div style="display: flex;align-items: center;" class="ms-4">
                                                          <v-icon
                                                            style="transform: rotate(90deg );"
                                                            color="gray"
                                                            size="15"
                                                            >mdi-airplane</v-icon>
                                                        </div>

                                                        <div class="m-1 va-content">
                                                          {{ group.iata }},
                                                          {{ group.city }},
                                                          {{ group.name }}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <!-- 
                                        <label for="ac">{{
                                          $t("formsContents.labels.goingTo")
                                        }}</label> -->
                                      </div>
                                      <div v-if="toloader">
                                        <div class="spinner-border spinner-border-sm" 
                                        style="display:flex;align-items:center;color:rgb(47,47,47);" role="status"></div>
                                      </div>
                                    </div>
                                    <div class="text-start">
                                      <span
                                        class="p-error"
                                        v-if="toErrorMessage"
                                        >{{$t("formsContents.errorMsg.arrivalReq")}}</span>
                                      <br
                                        v-if="toErrorMessage && maximumletter1"
                                      />
                                      <span
                                        class="p-error"
                                        v-if="maximumletter1"
                                        >{{ $t("formsContents.errorMsg.enterMinimum")}}</span>
                                    </div>
                                    <div class="recent_style" v-if="formres1">
                                      <v-card
                                        style="
                                          max-height: 300px;
                                          border-radius: 20px;
                                        "
                                      >
                                        <h5
                                          style="
                                            text-align: left;
                                            margin: 12px;
                                            font-weight: 500;
                                            font-size: 20px;
                                          "
                                        >
                                          {{$t("formsContents.labels.recentSearch")}}
                                        </h5>
                                        <div
                                          class="row resu-form mt-1"
                                          v-for="data of resultTo"
                                          :key="data"
                                        >
                                          <div class="col-2">
                                            <v-icon> mdi-clock-outline </v-icon>
                                          </div>

                                          <div
                                            class="col-10"
                                            @click="getrecent1(data)"
                                          >
                                            <div
                                              style="
                                                font-size: 16px;
                                                text-align: left;
                                              "
                                            >
                                              <!-- {{
                                                getrecentData(
                                                  data.to.originalName
                                                )
                                              }} -->{{
                                                data.to.name || data.to
                                              }}
                                            </div>
                                          </div>
                                        </div>
                                      </v-card>
                                    </div>
                                  </div>

                                  <div class="col-lg-3 col-sm-6 col-12 my-2 g-2">
                                    <div class="labelTop">
                                      <p>
                                        {{$t("formsContents.labels.departDate")}}
                                      </p>
                                    </div>

                                    <div
                                      class="d-flex align-items-center input-styles rounded-border"
                                    >
                                      <span
                                        class="d-flex align-center ps-1 me-2"
                                      >
                                        <v-icon color="#3c0ec2"
                                          >mdi-calendar</v-icon
                                        >
                                      </span>

                                      <span class="p-float-label w-100">
                                        <!-- <span :class="clicked1 ? 'label-top' : 'datepicker-label'">
                                      Departure
                                    </span> -->

                                        <VueDatePicker
                                          ref="dpRef1"
                                          v-model="oneway.dedate"
                                          :min-date="new Date()"
                                          :start-date="oneway.dedate ? oneway.dedate : new Date()"
                                          :max-date="new Date(new Date().getFullYear() + 1, new Date().getMonth(),new Date().getDate())"
                                          :six-weeks="sixWeekMode"
                                          :placeholder="this.$t('formsContents.labels.addDate')"
                                          :format="dateFormat"
                                          :hide-navigation="['time']"
                                          auto-apply prevent-min-max-navigation
                                          :multi-calendars="multiCalender"
                                          @update:modelValue="fromDateChanged"
                                          :on-click-outside="ClickOutDepat"
                                          class="px-0 datepicker"
                                        />

                                        <!-- <label style="top: -12px">{{
                                          !oneway.dedate
                                            ? $t("formsContents.labels.addDate")
                                            : ""
                                        }}</label> -->

                                        <!-- <div class="col-12"> -->
                                        <!-- <span class="p-error" v-if="fromDateError">Dep. Date is required</span> -->
                                        <!-- </div> -->
                                      </span>
                                    </div>
                                    <div
                                      class="text-start"
                                      v-if="fromDateError"
                                    >
                                      <span class="p-error">{{
                                        $t("formsContents.errorMsg.depdateReq")
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-lg-3 col-sm-6 col-12 my-2 g-2" :class="{ 'disable-return': Oneway }">
                                    <div class="labelTop">
                                      <p>{{ $t("formsContents.labels.returnDate") }}</p>
                                    </div>
                                    <div
                                      class="d-flex input-styles rounded-border"
                                    >
                                      <span class="d-flex align-center ps-1 me-2">
                                        <v-icon color="#3c0ec2">mdi-calendar</v-icon>
                                      </span>
                                      <span class="p-float-label w-100">
                                        <!-- <span :class="clicked2 ? 'label-top' : 'datepicker-label'">
                                          Return
                                        </span> -->
                                        <VueDatePicker
                                          ref="dpRef2"
                                          :placeholder="this.$t('formsContents.labels.addDate')"
                                          v-model="oneway.redate"
                                          :format="dateFormat"
                                          no-today
                                          :six-weeks="sixWeekMode"
                                          :multi-calendars="multiCalender"
                                          :disabled="activetrip === 'Oneway'"
                                          :start-date="oneway.dedate"
                                          :min-date="oneway.dedate ? oneway.dedate: new Date()"
                                          :max-date="new Date(new Date().getFullYear() + 1,new Date().getMonth(),new Date().getDate())"
                                          :hide-navigation="['time']"
                                          auto-apply prevent-min-max-navigation
                                          :on-click-outside="ClickOutReturn"
                                          class="datepicker"
                                        />

                                        <!-- <label style="top: -12px">{{
                                          !oneway.redate
                                            ? $t("formsContents.labels.addDate")
                                            : ""
                                        }}</label> -->

                                        <!-- <span class="p-error" v-if="toDateError">Arr. Date is required</span> -->
                                      </span>
                                    </div>
                                    <div class="text-start">
                                      <span
                                        class="p-error"
                                        v-if="roundTrip && toDateError"
                                        >{{
                                          $t(
                                            "formsContents.errorMsg.retdateReq"
                                          )
                                        }}</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-1 col-md-3 col-6 my-2 d-flex align-end search">
                                <v-btn
                                  block
                                  class="search-btn ms-sm-2 ms-0"
                                  v-if="roundTrip"
                                  :disabled="disButton"
                                  rounded="2"
                                  @click="sended()"
                                  ><span class="f-size-16 me-1 text-capitalize"
                                    >{{ $t("formsContents.options.go") }}
                                  </span>
                                  <v-icon
                                    size="24"
                                    style="transform: rotate(90deg)"
                                    >mdi-airplane</v-icon
                                  ></v-btn
                                >
                                <v-btn
                                  block
                                  class="search-btn ms-sm-2 ms-0"
                                  v-if="Oneway"
                                  :disabled="disButton"
                                  rounded="2"
                                  @click="sended()"
                                  ><span class="f-size-16 me-1 text-capitalize"
                                    >{{ $t("formsContents.options.go") }}
                                  </span>
                                  <v-icon
                                    size="24"
                                    style="transform: rotate(90deg)"
                                    >mdi-airplane</v-icon
                                  ></v-btn
                                >
                              </div>
                            </div>
                          </v-form>
                        </div>

                        <div class="my-4" v-if="multicity">
                          <v-form>
                            <div class="row">
                              <div class="col-md-12">
                                <div
                                  class="row mb-2 gx-0"
                                  v-for="(data, index) of multiData.itinery"
                                  :key="index"
                                >
                                  <div class="col-lg-3 col-md-4 col-12 pe-0 position-relative">
                                    <div class="labelTop mb-1 mb-lg-0">
                                      <p>
                                        {{ $t("formsContents.labels.flight") }}
                                        {{ index + 1 }}
                                        {{
                                          $t("formsContents.labels.fromlabel")
                                        }}
                                      </p>
                                    </div>
                                    <div class="d-flex mb-3 mb-lg-0">
                                      <div
                                        class="input-styles d-flex align-center styles_input1"
                                        :class="{
                                          'input-styles-focus':
                                            data.focusMultiEvent1,
                                        }"
                                      >
                                        <span class="d-flex px-2">
                                          <v-icon color="#3c0ec2"
                                            >mdi-airplane-takeoff</v-icon
                                          >
                                        </span>

                                        <div>
                                          <span class="">
                                            <div class="vas-autocomplete">
                                              <div class="d-flex row align-center">
                                                <div class="col-10 pe-0">
                                                  <input type="text" style="cursor: pointer"
                                                    v-model="data.from" :placeholder="this.$t('formsContents.labels.origin')"
                                                    @click="multiData.itinery[index].from = '',data.selectedFrom = false"
                                                    @keyup="typeMultiCity(data.from,index,1)"
                                                    class="inter-input" ref="multiFromAutocomplete"
                                                  />
                                                </div>
                                                <div v-if="data.selectedFrom" class="col-2 ps-0">
                                                  <!-- <v-btn icon="$close" variant="text" @click="multiData.itinery[index].from = ''"></v-btn> -->
                                                  <v-icon @click="multiFrom(index)" size="16" color="grey">mdi-close</v-icon>
                                                </div>
                                              </div>
                                              <!-- {{console.log(data,'data.autocomfrom')}} -->
                                               
                                              <div v-if="multiData.itinery[index].autocomfrom ? true : false" 
                                                v-click-outside="clickoutmultiDropdown">
                                                <div class="autocompete-menual-dropdown"
                                                  v-if="this.multidrop.length > 0 ? true : false">
                                                  <div v-for="(data1, index1) in multidrop" :key="index1">
                                                    
                                                    <div @click="datachangeMuti(data1,index)"
                                                      :style="index1 == multidrop.length - 1 ? '' : 'border-bottom:1px solid lightgray'"
                                                      class="d-flex p-2 va-back">
                                                      <div style="display: flex;align-items: center;">
                                                        <v-icon style="transform: rotate( 90deg); " color="gray" size="20">mdi-airplane</v-icon>
                                                      </div>

                                                      <div class="ms-1 va-content">
                                                        {{ data1.iata }}, {{ data1.city }},{{ data1.name }}
                                                      </div>
                                                    </div>

                                                    <div v-if="data1.group_airports.length > 0">
                                                      <div v-for="group in data1.group_airports" :key="group">
                                                        <div v-if="data1.group_airport_city != group.iata">
                                                          <div
                                                            class="d-flex va-back"
                                                            @click="datachangeMuti(group,index)">
                                                            <div style="display: flex;align-items: center;" class="ms-2">
                                                              <v-icon style="transform: rotate(90deg);"
                                                                color="gray"
                                                                size="20"
                                                                >mdi-airplane</v-icon>
                                                            </div>

                                                            <div
                                                              class="m-1 va-content"
                                                            >
                                                              {{ group.iata }},
                                                              {{ group.city }},
                                                              {{ group.name }}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </span>
                                        </div>
                                        <div v-if="data.autocomfrom">
                                          <div class="spinner-border spinner-border-sm" 
                                          style="display:flex;align-items:center;color:rgb(47,47,47);" role="status"></div>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="text-start" v-if="data.multiFromError" style="position: relative;left: 20px;">
                                      <span class="p-error-from">Departure is required</span>
                                      <!-- <br v-if="fromErrorMessage && maximumletter" /> -->
                                      <span
                                        class="p-error-from"
                                        v-if="data.maximumletter"
                                      >
                                        Enter minimum 3 letters
                                      </span>
                                    </div>
                                  </div>

                                  <div class="col-lg-3 col-md-4 col-12 ps-0 pe-0 position-relative">
                                    <div class="labelTop mb-1 mb-lg-0">
                                      <p>
                                        {{ $t("formsContents.labels.flight") }}
                                        {{ index + 1 }}
                                        {{ $t("formsContents.labels.tolabel") }}
                                      </p>
                                    </div>
                                    <div class="d-flex mb-3 mb-lg-0">
                                      <div
                                        class="input-styles d-flex align-center"
                                        :class="{
                                          'input-styles-focus':
                                            data.focusMultiEvent2,
                                        }"
                                      >
                                        <span class="d-flex px-2">
                                          <v-icon color="#3c0ec2"
                                            >mdi-airplane-takeoff</v-icon
                                          >
                                        </span>

                                        <div class="d-flex align-center">
                                          <span class="">

                                            <div class="vas-autocomplete">
                                              <div class="d-flex row align-center">
                                                <div class="col-10 pe-0">
                                                  <input v-model="data.to" style="cursor: pointer"
                                                    type="text" :placeholder="this.$t('formsContents.labels.destination')"
                                                    @click="multiData.itinery[index].to = '', data.selectedTo = false"
                                                    @keyup="typeMultiCity(data.to,index,2)"
                                                    class="inter-input" ref="multiToAutocomplete"
                                                  />
                                              </div>
                                                <div v-if="data.selectedTo" class="col-2 ps-0">
                                                  <!-- <v-btn icon="$close" variant="text" @click="multiData.itinery[index].to = ''"></v-btn> -->
                                                  <v-icon @click="multiTo(index)" size="16" color="grey">mdi-close</v-icon>
                                                </div>
                                              </div>
                                              <div v-if="data.autocomTo" v-click-outside="clickoutmultiDropdown">
                                                <div
                                                  class="autocompete-menual-dropdown"
                                                  v-if=" multidrop1.length > 0 ? true : false">
                                                  <div v-for="(data, index1) in multidrop1" :key="index1">
                                                    <div @click="datachangeMutiTo(data,index)"
                                                      :style="index1 ==multidrop1.length - 1 ? '' : 'border-bottom:1px solid lightgray'" class="d-flex p-2 va-back">
                                                      <div style="display: flex;align-items: center;">
                                                        <v-icon
                                                          style="transform: rotate(90deg);"
                                                          color="gray" size="20">
                                                          {{ data.location === "airport" ? "mdi-airplane" : "mdi-radiobox-marked"}}
                                                        </v-icon>
                                                      </div>

                                                      <div class="ms-1 va-content">
                                                        {{ data.iata }}, {{ data.city }},{{ data.name }}
                                                      </div>
                                                    </div>

                                                    <div v-if="data.group_airports.length > 0">
                                                      <div v-for="group in data.group_airports" :key="group">
                                                        <div v-if="data.group_airport_city != group.iata">
                                                          <div
                                                            class="d-flex va-back"
                                                            @click="datachangeMutiTo(group,index)">
                                                            <div style="display: flex;align-items: center;" class="ms-2">
                                                              <v-icon style="transform: rotate(90deg);"
                                                                color="gray" size="20">mdi-airplane</v-icon>
                                                            </div>

                                                            <div class="m-1 va-content">
                                                              {{ group.iata }},
                                                              {{ group.city }},
                                                              {{ group.name }}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </span>
                                          <div v-if="data.autocomTo">
                                            <div class="spinner-border spinner-border-sm" 
                                            style="display:flex;align-items:center;color:rgb(47,47,47);" role="status"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-start" v-if="data.multiToError" style="position: relative;left: 20px;">
                                      <span class="p-error-from">Arrival is required</span>
                                      <span
                                        class="p-error-to"
                                        v-if="data.maximumletter1"
                                        >Enter minimum 3 letters</span
                                      >
                                    </div>
                                  </div>

                                  <div class="col-lg-3 col-md-4 col-12 px-0 position-relative">
                                    <div class="labelTop mb-1 mb-lg-0">
                                      <p>
                                        {{ $t("formsContents.labels.flight") }}
                                        {{ index + 1 }}
                                        {{
                                          $t("formsContents.labels.datelabel")
                                        }}
                                      </p>
                                    </div>

                                    <div
                                      class="d-flex align-center input-styles styles_input2 mb-3 mb-lg-0"
                                      tabindex="0"
                                    >
                                      <span class="d-flex pt-1 ps-2 me-2">
                                        <v-icon color="#3c0ec2"
                                          >mdi-calendar</v-icon
                                        >
                                      </span>
                                      <!-- <div
                                        class="d-flex input-styles-focus justify-content-between align-center w-100"
                                      > -->
                                        <!-- <div
                                          class="input-styles-focus"
                                        > -->
                                          <span
                                            class="p-float-label"
                                            style="position: relative"
                                          >
                                            <VueDatePicker
                                              ref="dpRef4"
                                              :placeholder="this.$t('formsContents.labels.addDate')"
                                              v-model="data.depdate"
                                              :six-weeks="sixWeekMode"
                                              :min-date="
                                                getdate1(
                                                  multiData.itinery,
                                                  index
                                                )
                                              "
                                              :start-date="
                                                getdate1(
                                                  multiData.itinery,
                                                  index
                                                )
                                              "
                                              :max-date="
                                                new Date(
                                                  new Date().getFullYear() + 1,
                                                  new Date().getMonth(),
                                                  new Date().getDate()
                                                )
                                              "
                                              :format="dateFormat"
                                              :hide-navigation="['time']"
                                              auto-apply
                                              :multi-calendars="multiCalender"
                                              class="px-0 datepicker"
                                              @focus="
                                                (data.focusMultiEvent3 = true),
                                                  (data.focusMultiEvent1 = false),
                                                  (data.focusMultiEvent2 = false)
                                              "
                                              @update:modelValue="
                                                (newValue) =>
                                                  multiDateChanged(
                                                    newValue,
                                                    index
                                                  )
                                              "
                                              :month-change-on-scroll="false"
                                            >
                                            </VueDatePicker>
                                          </span>
                                        <div class="addmore-btn d-lg-none">
                                          <div
                                            class="d-flex"
                                            v-if="index !== 0 ? true : false"
                                          >
                                            <div
                                              class="crossicon"
                                              style="
                                                display: flex;
                                                align-items: center;
                                              "
                                            >
                                              <span
                                                style="margin: auto"
                                                v-if="index > 1 ? true : false"
                                                @click="deleteItin(index)"
                                              >
                                                <v-icon color="red">
                                                  mdi-close-circle-outline
                                                </v-icon>
                                              </span>
                                            </div>

                                            <div
                                              v-if="
                                                multiData.itinery.length == 4
                                                  ? false
                                                  : true
                                              "
                                            >
                                              <v-btn
                                                v-if="
                                                  index ==
                                                  multiData.itinery.length - 1
                                                    ? true
                                                    : false
                                                "
                                                class="d-flex align-center formbottom_addBtn"
                                                @click="addTrip(index)"
                                                prepend-icon="mdi-plus-circle"
                                              >
                                                <span class="addmorebtn">{{$t("formsContents.options.addMore")}}</span></v-btn>
                                            </div>

                                            <div
                                              v-if="index == 3 ? true : false"
                                            >
                                              <v-btn
                                                class="d-flex align-center formbottom_ClearBtn"
                                                @click="clearall1()"
                                                prepend-icon="mdi-close-box-multiple"
                                              >
                                                <span class="clearAll">{{
                                                  $t(
                                                    "formsContents.options.clearAll"
                                                  )
                                                }}</span></v-btn
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      <!-- </div> -->
                                    </div>
                                    <div class="text-start" style="position: relative;left: 20px;">
                                      <span class="p-error-from" v-if="data.multiDateError">Dep. Date is required</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-2 col-sm-6 col-12 searchBtns px-0 mt-3 pt-1"
                                    style="margin: 0 auto"
                                  >
                                    <div class="btn-multi">
                                      <v-btn
                                        v-if="index == 0 ? true : false"
                                        block
                                        class="search-btn m-0"
                                        :disabled="disButton"
                                        color="#94C20E"
                                        @click="sendedMulticity()"
                                        ><span
                                          class="f-size-20 me-1 text-capitalize"
                                          >{{ $t("formsContents.options.go") }}
                                        </span>
                                        <v-icon
                                          size="24"
                                          style="transform: rotate(90deg)"
                                          >mdi-airplane</v-icon
                                        >
                                      </v-btn>

                                      <div
                                        class="d-flex"
                                        v-if="index !== 0 ? true : false"
                                      >
                                        <div
                                          style="
                                            display: flex;
                                            align-items: center;
                                            margin-right: 10px;
                                          "
                                        >
                                          <span
                                            style="margin: auto"
                                            v-if="index > 1 ? true : false"
                                            @click="deleteItin(index)"
                                          >
                                            <v-icon color="red">
                                              mdi-close-circle-outline
                                            </v-icon>
                                          </span>
                                        </div>

                                        <div
                                          v-if="
                                            multiData.itinery.length == 4
                                              ? false
                                              : true
                                          "
                                        >
                                          <v-btn
                                            v-if="
                                              multiData.itinery.length - 1 ==
                                                3 ||
                                              index ==
                                                multiData.itinery.length - 1
                                                ? true
                                                : false
                                            "
                                            class="d-flex align-center formbottom_addBtn"
                                            @click="addTrip(index)"
                                            prepend-icon="mdi-plus-circle"
                                            >Add More</v-btn
                                          >
                                        </div>

                                        <div v-if="index == 3 ? true : false">
                                          <v-btn
                                            class="d-flex align-center formbottom_ClearBtn"
                                            @click="clearall1()"
                                            prepend-icon="mdi-close-box-multiple"
                                            >Clear All</v-btn
                                          >
                                        </div>
                                      </div>
                                    </div>

                                    <div class="btn-multi1">
                                      <v-btn
                                        v-if="
                                          index == multiData.itinery.length - 1
                                            ? true
                                            : false
                                        "
                                        block
                                        class="search-btn m-0"
                                        :disabled="disButton"
                                        color="#94C20E"
                                        @click="sendedMulticity()"
                                        ><span
                                          class="f-size-20 me-1 text-capitalize"
                                          >{{ $t("formsContents.options.go") }}
                                        </span>
                                        <v-icon
                                          size="24"
                                          style="transform: rotate(90deg)"
                                          >mdi-airplane</v-icon
                                        >
                                      </v-btn>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-form>
                        </div>

                        <div class="advanceOpt">
                          <div class="d-flex flex-wrap">
                            <div class="d-flex flex-wrap">
                              <v-switch v-model="directFlight" label="Direct Flight" color="#94c20e" class="me-4"></v-switch>
                              <v-switch v-model="baggageInclusive" label="Baggage Inclusive Only" color="#94c20e" class="me-4"></v-switch>
                              <v-switch v-model="nearbyAirport" label="Nearby Airport" color="#94c20e"></v-switch>
                              <div class="d-md-none d-flex align-items-center justify-content-end custom-font ms-auto">
                              <span class="not-collapsed collapsed f-size-14" data-bs-toggle="collapse"
                                href="#collapse-Example" aria-expanded="false"
                                aria-controls="collapse-Example"
                                style="padding-right: 5px;cursor: pointer;letter-spacing: 1px;" @click="toggleIcon">
                                <span class="text-white text-decoration-underline">Advanced options</span
                                >
                              </span>
                            
                              <v-icon v-model="isAdvanceOpt" size="18" class="not-collapsed collapsed me-3 text-white" data-bs-toggle="collapse" 
                                href="#collapse-Example" aria-expanded="false" aria-controls="collapse-Example"                                
                                style="cursor: pointer; overflow-anchor: none">
                                {{ isAdvanceOpt ? "mdi-chevron-up-box-outline" : "mdi-chevron-down-box-outline"}}</v-icon>
                            </div>
                            </div>
                            <div class="d-md-flex d-none align-items-center justify-content-end custom-font ms-auto">
                              <span class="not-collapsed collapsed f-size-14" data-bs-toggle="collapse"
                                href="#collapse-Example" aria-expanded="false"
                                aria-controls="collapse-Example"
                                style="padding-right: 5px;cursor: pointer;letter-spacing: 1px;" @click="toggleIcon">
                                <span class="text-white text-decoration-underline">Advanced options</span
                                >
                              </span>
                            
                              <v-icon v-model="isAdvanceOpt" size="18" class="not-collapsed collapsed me-3 text-white" data-bs-toggle="collapse" 
                                href="#collapse-Example" aria-expanded="false" aria-controls="collapse-Example"                                
                                style="cursor: pointer; overflow-anchor: none">
                                {{ isAdvanceOpt ? "mdi-chevron-up-box-outline" : "mdi-chevron-down-box-outline"}}</v-icon>
                            </div>
                          </div>

                          <div class="displayedAdvanceOptions border-1 border-top px-2 pt-2" v-if="getallAdvanceOptions.length > 0">
                            <div class="d-flex flex-wrap">
                              <div v-for="(data, index) of getallAdvanceOptions" :key="index" class="me-1 d-flex">
                                <span v-if="data.selectedAirline" class="d-flex align-center">
                                  <i class="mdi mdi-checkbox-marked-circle f-size-14" style="color:#94c20e;"></i>
                                  <span class="f-size-12 fw-400 text-white ms-1">
                                     {{ airlineSelect == 'includeAirline' ? 'Include' : 'Exclude' }} Airline: {{ data.selectedAirline.join(', ') }}
                                  </span> 
                                </span>
                                <span v-if="data.stopsSelect && data.stopsSelect !== 'anyStop'" class="d-flex align-center">
                                  <i class="mdi mdi-checkbox-marked-circle f-size-14" style="color:#94c20e;"></i>
                                  <span class="f-size-12 fw-400 text-white ms-1">Stops: {{ data.stopsSelect }}</span> 
                                </span>
                                <span v-if="data.adlayoverTime && data.adlayoverTime !=='noPreference'" class="d-flex align-center">
                                  <i class="mdi mdi-checkbox-marked-circle f-size-14" style="color:#94c20e;"></i>
                                  <span class="f-size-12 fw-400 text-white ms-1">Layover Time: Min: {{ data.adlayoverTime.min }}, Max: {{ data.adlayoverTime.max }}</span> 
                                </span>
                                <span v-if="data.selectedAirport" class="d-flex align-center">
                                  <i class="mdi mdi-checkbox-marked-circle f-size-14" style="color:#94c20e;"></i>
                                  <span class="f-size-12 fw-400 text-white ms-1">
                                    {{ adviaAirport == 'includeAirport' ? 'Include' : 'Exclude' }} Airport: {{ data.selectedAirport.join(', ') }}
                                  </span> 
                                </span>
                                <span v-if="data.avoidCountry" class="d-flex align-center">
                                  <i class="mdi mdi-checkbox-marked-circle f-size-14" style="color:#94c20e;"></i>
                                  <span class="f-size-12 fw-400 text-white ms-1">Avoid Country: {{ data.avoidCountry.join(', ') }}</span> 
                                </span>
                                <span v-if="index < getallAdvanceOptions.length - 1" class="text-white ms-1"> | </span>
                              </div>
                              <v-icon @click="resetOptions" v-if="getallAdvanceOptions.length > 0" size="16" class="ms-2 m-auto rounded-3 bg-white" color="red" style="cursor: pointer;">
                                mdi-close-circle
                              </v-icon>
                            </div>
                          </div>

                          <div class="collapse border-1 border-top p-1 pt-2 mt-2" :class="{ show: isAdvanceOpt }" id="collapse-Example">
                            <div class="row g-2 dropdownSection">
                              <div class="col-lg-2 col-12 d-flex justify-space-around">
                                <v-menu v-model="adAirlineMenu" :close-on-content-click="false">
                                  <template v-slot:activator="{ props }">
                                      <div @click="openAdvanceMenu('adAirline')" class="input-styles rounded-border d-flex justify-content-between" v-bind="props">
                                          <span class="f-size-14 fw-500 text-capitalize me-2" style="color:#808080">Airline</span>
                                          <v-icon size="25" :class="{ 'rotate-icon': adAirlineMenu }" right dark >mdi-menu-down</v-icon>
                                      </div>
                                  </template>
                                  <v-card class="pa-3">
                                    <div>
                                      <v-radio-group v-model="airlineSelect" inline class="advanceRadio f-size-12">
                                          <v-radio value="includeAirline" label="Include" class="me-2" @click="changeSelected(airlineSelect)"></v-radio>
                                          <v-radio value="excludeAirline" label="Exclude" @click="changeSelected(airlineSelect)"></v-radio>
                                      </v-radio-group>
                                    </div>
                                    <div class="mt-2 mb-3">
                                      <div>
                                        <v-text-field v-model="airlineVal" placeholder="Airline code/Name" @input="searchAirline(airlineVal)" 
                                          class="airlineCode" dense solo hide-details rounded-lg>
                                          <template v-slot:prepend-inner>
                                              <v-icon icon="mdi-magnify" size="20"></v-icon>
                                          </template>
                                          <template v-slot:append-inner>
                                            <div v-if="airlineVal && airlineList ? airlineVal.length >= 2 && !airlineList.length > 0 : false">
                                              <div class="spinner-border spinner-border-sm" role="status"></div>
                                            </div>
                                            <!-- <v-progress-circular v-if="!airlineVal.length >= 3 && !airlineList.length > 0" color="primary" indeterminate></v-progress-circular> -->
                                          </template>
                                        </v-text-field>
                                        <v-card v-if="airlineList.length > 0 && airlineVal.length > 1" max-height="200px" class="pa-3 pt-0 overflow-auto airlineCard" > 
                                          <div v-for="(item) of airlineList" :key="item">
                                            <v-checkbox v-model="selectedAirline" :value="`${item.name} - (${item.iata})`" :label="`${item.name} - (${item.iata})`" class="adcheckBox"></v-checkbox>
                                          </div>
                                        </v-card>
                                      </div>
                                    </div>
                                    <v-card-actions class="p-0" style="min-height:fit-content;">
                                      <v-btn color="danger" height="24px" class="f-size-12" variant="text" @click="resetAirlines()" v-if="selectedAirline.length > 0">
                                        <span>Reset</span>
                                        <span><i class="fa-solid fa-rotate-right"></i></span></v-btn>
                                      <v-spacer></v-spacer>
                                      <v-btn color="primary" height="24px" class="f-size-12" variant="text" @click="adAirlineMenu = false">Done</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-menu>
                              </div>

                              <div class="col-lg-2 col-12 d-flex justify-space-around advanceStops">
                                <v-menu v-model="adStopMenu" :close-on-content-click="false">
                                  <template v-slot:activator="{ props }">
                                      <div @click="openAdvanceMenu('adStops')" class="input-styles rounded-border d-flex justify-content-between" v-bind="props">
                                          <span class="f-size-14 fw-500 text-capitalize me-2" style="color:#808080">Stops</span>
                                          <v-icon size="25" :class="{ 'rotate-icon': adStopMenu }" right dark >mdi-menu-down</v-icon>
                                      </div>
                                  </template>
                                  <v-card class="pa-3">
                                    <div>
                                      <v-radio-group v-model="stopsSelect" class="advanceRadio f-size-12">
                                          <v-radio value="anyStop" label="Any Stop" :disabled="stopsSelect == 'anyStop'"></v-radio>
                                          <v-radio value="0" label="Non-Stop"></v-radio>
                                          <v-radio value="1" label="Upto 1 Stop"></v-radio>
                                          <v-radio value="2" label="Upto 2 Stops"></v-radio>
                                          <v-radio value="3" label="Upto 3 Stops"></v-radio>
                                          <v-radio value="4" label="4+ Stops"></v-radio>
                                      </v-radio-group>
                                    </div>
                                    <v-card-actions class="p-0" style="min-height:fit-content;">
                                      <v-spacer></v-spacer>
                                      <v-btn color="primary" height="24px" class="f-size-12" variant="text" @click="adStopMenu = false">Done</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-menu>
                              </div>

                              <div class="col-lg-2 col-12 d-flex justify-space-around layoverStops">
                                <v-menu v-model="adlayoverMenu" :close-on-content-click="false">
                                  <template v-slot:activator="{ props }">
                                      <div @click="openAdvanceMenu('adlayover')" class="input-styles rounded-border d-flex justify-content-between" v-bind="props">
                                          <span class="f-size-14 fw-500 text-capitalize me-2" style="color:#808080">Layover Duration</span>
                                          <v-icon size="25" :class="{ 'rotate-icon': adlayoverMenu }" right dark >mdi-menu-down</v-icon>
                                      </div>
                                  </template>
                                  <v-card class="pa-3">
                                    <div>
                                      <v-radio-group v-model="adlayoverTime" class="advanceRadio f-size-12">
                                          <v-radio value="noPreference" label="No Preference" :disabled="adlayoverTime == 'noPreference'"></v-radio>
                                          <v-radio :value="{ min: 1, max: 3 }" label="1-3 hours"></v-radio>
                                          <v-radio :value="{ min: 3, max: 6 }" label="3-6 hours"></v-radio>
                                          <v-radio :value="{ min: 6, max: 12 }" label="6-12 hours"></v-radio>
                                          <v-radio :value="{ min: 12, max: 24 }" label="12+ hours"></v-radio>
                                      </v-radio-group>
                                    </div>
                                    <v-card-actions class="p-0" style="min-height:fit-content;">
                                      <v-spacer></v-spacer>
                                      <v-btn color="primary" height="24px" class="f-size-12" variant="text" @click="adlayoverMenu = false">Done</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-menu>
                              </div>

                              <div class="col-lg-2 col-12 d-flex justify-space-around">
                                <v-menu v-model="adviaAirportMenu" :close-on-content-click="false">
                                  <template v-slot:activator="{ props }">
                                      <div @click="openAdvanceMenu('adviaAirport')" class="input-styles rounded-border d-flex justify-content-between" v-bind="props">
                                          <span class="f-size-14 fw-500 text-capitalize me-2" style="color:#808080">Via-Airport</span>
                                          <v-icon size="25" :class="{ 'rotate-icon': adviaAirportMenu }" right dark >mdi-menu-down</v-icon>
                                      </div>
                                  </template>
                                  <v-card class="pa-3">
                                    <div>
                                      <v-radio-group v-model="adviaAirport" inline class="advanceRadio f-size-12">
                                          <v-radio value="includeAirport" label="Include" class="me-2" @click="changeSelected(adviaAirport)"></v-radio>
                                          <v-radio value="excludeAirport" label="Exclude" @click="changeSelected(adviaAirport)"></v-radio>
                                      </v-radio-group>
                                    </div>

                                    <div class="mt-2 mb-3">
                                      <div>
                                        <v-text-field v-model="airportVal" placeholder="Airline code/Name" @input="searchAirport(airportVal)" 
                                          class="airlineCode" dense solo hide-details rounded-lg>
                                          <template v-slot:prepend-inner>
                                              <v-icon icon="mdi-magnify" size="20"></v-icon>
                                          </template>
                                          <template v-slot:append-inner>
                                            <div v-if="airportVal && airportList ? airportVal.length >= 3 && !airportList.length > 0 : false">
                                                <div class="spinner-border spinner-border-sm" role="status"></div>
                                              </div>
                                          </template>
                                        </v-text-field>
                                        <v-card v-if="airportList.length > 0 && airportVal.length > 1" max-height="200px" class="pa-3 pt-0 overflow-auto airlineCard" > 
                                          <div v-for="(item) of airportList" :key="item">
                                            <v-checkbox v-model="selectedAirport" :value="`${item.city} - (${item.iata})`" :label="`${item.name} - (${item.iata})`" class="adcheckBox"></v-checkbox>
                                          </div>
                                        </v-card>
                                      </div>
                                    </div>
                                    <v-card-actions class="p-0" style="min-height:fit-content;">
                                      <v-btn color="danger" height="24px" class="f-size-12" variant="text" @click="resetAirport()" v-if="selectedAirport.length > 0">
                                        <span>Reset</span>
                                        <span><i class="fa-solid fa-rotate-right"></i></span></v-btn>
                                      <v-spacer></v-spacer>
                                      <v-btn color="primary" height="24px" class="f-size-12" variant="text" @click="adviaAirportMenu = false">Done</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-menu>
                              </div>

                              <div class="col-lg-2 col-12 d-flex justify-space-around advanceStops">
                                <v-menu v-model="adavoidCountryMenu" :close-on-content-click="false">
                                  <template v-slot:activator="{ props }">
                                      <div @click="openAdvanceMenu('adavoidCountry')" class="input-styles rounded-border d-flex justify-content-between" v-bind="props">
                                          <span class="f-size-14 fw-500 text-capitalize me-2" style="color:#808080">Avoid Country</span>
                                          <v-icon size="25" :class="{ 'rotate-icon': adavoidCountryMenu }" right dark >mdi-menu-down</v-icon>
                                      </div>
                                  </template>
                                  <v-card class="pa-3">
                                    <div>
                                      <v-checkbox v-model="avoidCountry" label="US" value="US" class="adcheckBox"></v-checkbox>
                                      <v-checkbox v-model="avoidCountry" label="CA" value="CA" class="adcheckBox"></v-checkbox>
                                      <v-checkbox v-model="avoidCountry" label="UK" value="UK" class="adcheckBox"></v-checkbox>
                                    </div>
                                    <v-card-actions class="p-0" style="min-height:fit-content;">
                                      <v-spacer></v-spacer>
                                      <v-btn color="primary" height="24px" class="f-size-12" variant="text" @click="adavoidCountryMenu = false">Done</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-menu>
                              </div>
                            </div>
                          </div>
                        </div>                   
                          
                      </div>

                      <div v-if="travelHistory">
                        <!-- <div v-if="travelHistory" style="max-height: 132px;overflow-y: scroll;overflow-x: hidden;"> -->
                        <p class="f-size-18 fw-500 m-0 text-white">{{ $t("formsContents.labels.recentSearch") }}</p>
                            <div v-if="this.RecentSearchData.length > 0 ? true : false" class="searchrecent">
                                <div class="row">
                                
                                  <div class="col-md-4 mt-2" v-for="data of this.RecentSearchData" :key="data">
                                    <div v-if="!data.itinery">
                                      <v-card @click="cardassigndata(data)" class="pa-2" style="border-radius:10px;">
                                        <div class="row">
                                          <div class="col-5 text-start">
                                            <p class="f-size-14 fw-500 m-0">
                                              {{ data.from }}
                                            </p>
                                            <p class="f-size-14 fw-500 m-0">
                                              {{ getdated(data.fromDate) }}
                                            </p>
                                          </div>
                                          <div class="col-2">
                                            <div v-if="data.tripType == 'Roundtrip'">
                                              <v-icon>mdi-swap-horizontal</v-icon>
                                            </div>
                                            <div v-if="data.tripType == 'Oneway'">
                                              <v-icon>mdi-arrow-right</v-icon>
                                            </div>
                                            <!-- <div v-if="data.tripType == 'multithree'">
                                              <v-icon>mdi-swap-horizontal</v-icon>
                                              <v-icon>mdi-swap-horizontal</v-icon>
                                              <v-icon>mdi-swap-horizontal</v-icon>
                                            </div> -->
                                          </div>
                                          <div class="col-5 text-start">
                                            <p class="f-size-14 fw-500 m-0">
                                              {{ data.to }}
                                            </p>
                                            <p class="f-size-14 fw-500 m-0" v-if="data.toDate">
                                              {{ getdated(data.toDate) }}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-7 text-start">
                                            <p class="f-size-14 fw-500 m-0">
                                             {{ $t("formsContents.options.passengers") }}:
                                              <span v-if="data.adult > 0"
                                                >{{ data.adult }}Adt</span
                                              >
                                              <span class="ms-1" v-if="data.child > 0"
                                                >{{ data.child }}Ch</span
                                              >
                                              <span class="ms-1" v-if="data.inf > 0"
                                                >{{ data.inf }}Inf</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-5 text-start" v-if="data.class">
                                            <span class="f-size-14 fw-500">{{ $t("bookingviewContent.headings.class") }}: {{ data.class }}</span>
                                          </div>
                                        </div>
                                      </v-card>
                                    </div>
                                
                                    <div v-if="data.itinery">
                                      <v-card @click="cardassigndata(data)" class="pa-2">
                                        <div class="row" v-for="(item,index) of data.itinery" :key="index">
                                          <div class="col-5 text-start">
                                            <p class="f-size-14 fw-500 m-0">
                                              {{ item.from }}
                                            </p>
                                            <p class="f-size-14 fw-500 m-0">
                                              {{ getdated(item.depdate) }}
                                            </p>
                                          </div>
                                          <div class="col-2">
                                            <div>
                                              <v-icon>mdi-arrow-right</v-icon>
                                            </div>
                                        
                                          </div>
                                          <div class="col-5 text-start">
                                            <p class="f-size-14 fw-500 m-0">
                                              {{ item.to }}
                                            </p>
                                            <!-- <p class="f-size-14 fw-500 m-0" v-if="data.toDate">
                                              {{ getdated(data.toDate) }}
                                            </p> -->
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-7 text-start">
                                            <p class="f-size-14 fw-500 m-0">
                                              {{ $t("formsContents.options.passengers") }}:
                                              <span v-if="data.adult > 0"
                                                >{{ data.adult }}Adt</span
                                              >
                                              <span class="ms-1" v-if="data.child > 0"
                                                >{{ data.child }}Ch</span
                                              >
                                              <span class="ms-1" v-if="data.infant > 0"
                                                >{{ data.infant }}Inf</span
                                              >
                                            </p>
                                          </div>
                                          <div class="col-5 text-start" v-if="data.class">
                                            <span class="f-size-14 fw-500">{{ $t("bookingviewContent.headings.class") }}: {{ data.class }}</span>
                                          </div>
                                        </div>
                                      </v-card>
                                    </div>
                                  </div>
                              
                              
                                </div>
                            </div>
                        </div>
                        </v-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
// import AutoComplete from "primevue/autocomplete";
// import dataloader from "@/components/dataLoder.vue";
import servicedata from '@/service.json';
import trendingplacesdata from '@/trendingplaces.json';
import topdestinationsdata from '@/topdestinations.json';


import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
// import { indexOf } from "lodash";

export default {
  components: {
    VueDatePicker,
    // dataloader,
  },

  props: {
    searchCase: String
  },

  data() {
    return {
      localSearchCase:null,
      selectedCity: "Modern",
      fromloader:false,
      toloader:false,
      fromlabel: false,
      tolabel: false,
      subloader: false,
      usermailbtn: true,
      confirmcaptcha: false,
      subscribeMenu:false,
      dialog1: false,
      dialog2: false,
      travelHistory:false,
      RecentSearchData: [],
      dateFormat: "MMM dd",
      sixWeekMode: true,
      classSelect: false,
      subEmail: "",
      subPhonenumber: "",
      multiCalender: true,
      validData: false,
      emailPortal:"",
      selectedPax: "",
      countries: [{ name: "Australia", code: "AU" }],

      maximumletter: false,
      maximumletter1: false,
      fromDate: "",
      name1: "John Doe",
      source: "vasanth",
      trav: true,
      loginbox: false,
      registerbox: false,
      terms: false,
      visible: false,
      isAdvanceOpt:false,

      adAirlineMenu:false,
      adStopMenu:false,
      adlayoverMenu:false,
      adviaAirportMenu:false,
      adavoidCountryMenu:false,

      directFlight:false,
      baggageInclusive:false,
      nearbyAirport:false,
      airlineSelect:"includeAirline",
      stopsSelect:'anyStop', 
      adlayoverTime:'noPreference',
      adviaAirport:"includeAirport",
      selectedAirline:[],
      selectedAirport:[],
      avoidCountry:[],

      airlineVal:"",
      airportVal:"",
      airlineList:[],
      airportList:[],

      getallAdvanceOptions : [],
      selectAirports: "",
      activetrip: 'Roundtrip',
      roundTrip: true,
      Oneway: false,
      multicity: false,
      // select: "Economy",
      travels: false,
      adultValue: 1,
      childValue: 0,
      infantValue: 0,
      totalPassenger: 1,
      classType: "Economy",
      addition: 0,
      customer: [],
      buton: true,
      avath: false,
      error: false,
      viewbox: false,
      isVisible: true,
      // selectAirports: "",
      token: "Tc7RQaX79Hrx5CFK",

      classTypeitems: [
        {
          text: this.$t("formsContents.options.economy"),
          value: "Economy",
          department: "Economy Department",
        },
        {
          text: this.$t("formsContents.options.business"),
          value: "Business",
          department: "Business Department",
        },
        {
          text: this.$t("formsContents.options.firstClass"),
          value: "First Class",
          department: "First Class Department",
        },
        { 
          text: this.$t('formsContents.options.premiumEconomy'), 
          value: 'Premium Economy', 
          department: 'Premium Economy Department' 
        },
        { 
          text: "Premium Business", 
          value: 'Premium Business', 
          department: 'Premium Business Department' 
        },
        { 
          text: "Premium First", 
          value: 'Premium First', 
          department: 'Premium First Department' 
        },
      ],

      // Autocomplete
      loading: false,
      fromAirports: [],
      airitems: [],
      // Autocomplete End

      items: [],

      clicked1: false,
      clicked2: false,
      clicked3: false,
      datePickerIsOpen: false,

      fromErrorMessage: false,
      toErrorMessage: false,
      fromDateError: false,
      toDateError: false,

      disButton: false,
      loadSearch: false,
      valid: false,
      Errormsg: "",
      agreeMsg: "",
      subscribe: {
        email: "",
        phone: "",
        checkAgree: false,
      },

      roundedShadow: false,
      emailBox: false,
      phoneNumber: true,

      subEmailvalid: [
        (v) => !!v || "Invalid Email ID",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address",
      ],
      phoneValidate: [
        (v) => /^\+?[0-9]{1,16}$/.test(v) || "Please enter a phone number ",
        // (v) => /^(\+?[0-9]{1,12})?$/.test(v) || "Please enter a valid phone number (up to 12 digits, starting with +)",
      ],

      addmore: true,
      clearall: false,

      oneway: {
        from: null,
        to: null,
        travelDate: null,
        dedate: null,
        redate: null,
        class: "Economy",
        child: "",
        adult: "",
        infant: "",
        tripType: "",
      },
      multidrop: [],
      multidrop1: [],
      multiData: {
        itinery: [
          {
            from: null,
            to: null,
            depdate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocomfrom:false,
            autocomTo:false,
            selectedFrom:false,
            selectedTo:false,
          },
          {
            from: null,
            to: null,
            depdate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocomfrom:false,
            autocomTo:false,
            selectedFrom:false,
            selectedTo:false
          },
        ],
        class: "Economy",
        child: "",
        adult: "",
        infant: "",
        tripType: "",
      },

      Adult: [],
      Child: [],
      recendFrom: [],
      recendTo: [],
      resultFrom: [],
      resultTo: [],
      forData: false,
      formres: false,
      formres1: false,
      Infrant: [],
      urldedate: "",
      urlredate: "",
      roundfrom: "",
      roundto: "",
      concatenatedArray: [],
      loadingData: true,
      dataloaded: false,
      randomAlphabets: "",
      recaptchaData: "",
      fromRecent: "",
      toRecent: "",
      multi: {
        from: null,
        to: null,
      },
      deatail: {
        name: "",
        email: "",
        password: "",
        repassword: "",
      },
      loginuser: {
        email: "",
        password: "",
      },
      userName: [(v) => !!v || "Please enter your name"],
      email: [(v) => !!v || "Please enter your Email"],
      password: [(v) => !!v || "Please enter your Password"],
      repassword: [
        (v) => !!v || "Please enter your Re-Password",
        (v) => v === this.deatail.password || "Passwords do not match",
      ],
      pnr: [(v) => !!v || "Please enter your PNR/Booking Ref no"],
      emaillast: [(v) => !!v || "Please enter your Email/Lastname"],

      chooseUs: [
        {
          logo: "fa-solid fa-shield-halved",
          sideHead: "Cheapest Deals",
          chooseLine:
            "Lowest Guaranteed booking price for popular destinations all over the globe.",
        },
        {
          logo: "fa-solid fa-mobile-screen",
          sideHead: "Easy To Book",
          chooseLine:
            "Search for your favorite city, choose the best deal and get ready to travel It is that easy!",
        },
        {
          logo: "fa-solid fa-certificate",
          sideHead: "Trust",
          chooseLine: "Providing 100% Safe and Secure bookings to customers.",
        },
      ],

      Destination: [
        {
          name: "Andaman",
          rate: "$480",
          url: require("@/assets/andaman.jpg"),
        },
        {
          name: "SriLanka",
          rate: "$890",
          url: require("@/assets/SriLanka.jpg"),
        },
        {
          name: "Dubai",
          rate: "$2310",
          url: require("@/assets/DUBAI.jpg"),
        },
        {
          name: "Thailand",
          rate: "$760",
          url: require("@/assets/Thailand.jpg"),
        },
      ],

      service: this.getService(),

      profile: [
        {
          url: require("@/assets/double-quotes.png"),
          url2: require("@/assets/profile.png"),
          name: "Alex Feder",
        },
        {
          url: require("@/assets/double-quotes.png"),
          url2: require("@/assets/profile.png"),
          name: "Elly Forb",
        },
        {
          url: require("@/assets/double-quotes.png"),
          url2: require("@/assets/profile.png"),
          name: "Mia Nest",
        },
        {
          url: require("@/assets/double-quotes.png"),
          url2: require("@/assets/profile.png"),
          name: "Dan Dorno",
        },
      ],

      bookingus: [
        {
          url: require("@/assets/maldives.avif"),
          name: "MALDIVES ISLAND - MALDIVES",
          letter: "Discover Maldives Island",
          rate: "$960",
        },
        {
          url: require("@/assets/taj-mahal.avif"),
          name: "TAJ MAHAL - AGRA",
          letter: "Historical Architecture",
          rate: "$1200",
        },
        {
          url: require("@/assets/paris.jpeg"),
          name: "EIFFEL TOWER - PARIS",
          letter: "Most Recognizable Monuments",
          rate: "$2120",
        },
        {
          url: require("@/assets/Jaipur.avif"),
          name: "PINK PALACE - JAIPUR",
          letter: "Palace of a Pyramidal shape",
          rate: "$2145",
        },
        {
          url: require("@/assets/munnar.avif"),
          name: "MUNNAR",
          letter: "Popular Resort Town in Kerala",
          rate: "$850",
        },
        // {
        //   url: require('@/assets/china.jpg'),
        //   name : "SIKKIM",
        //   letter : "The land of Gorgeous Nature",
        //   rate: "$1550"
        // }
      ],
      inputCity: "",
      inputCityto: "",
      typedrop: false,
      typedrop1: false,
      city: [],
      city1: [],
      cardData: [
        {
          url: require("@/assets/canada.avif"),
          name: "CANADA",
          letter:
            "Snow-capped Mountains, Exotic Beaches, Lush Green Forests and Pristine Lakes.",
          rate: "$960",
        },
        {
          url: require("@/assets/baliimg.avif"),
          name: "BALI",
          letter: "Green Mountains, Rice Field Terraces, and Cool Villages",
          rate: "$1200",
        },
        {
          url: require("@/assets/dubaiimg.avif"),
          name: "DUBAI",
          letter:
            "Stunning Architecture, Majestic Skyscrapers, Glittering Skylines",
          rate: "$2120",
        },
        {
          url: require("@/assets/san.avif"),
          name: "SAN FRANCISCO",
          letter: " Famous for Grand-dame Victorians, a Soaring Golden Bridge",
          rate: "$2145",
        },
        {
          url: require("@/assets/parisimg.avif"),
          name: "PARIS",
          letter: "Paris is known for its Gorgeous, Imposing Monuments.",
          rate: "$850",
        },
        {
          url: require("@/assets/cardimg3.avif"),
          name: "CHITKUL - HIMACHAL PRADESH",
          letter:
            "Hub of Beautiful Lakes hold Cultural and Religious Significance.",
          rate: "$920",
        },
      ],

      airlineIcao: {},

      // uniqueId:[],

      airlineIcaoUrl: "",
      airlineIcaoType: "",

      focusedElement: null,
      changeTextFrom: "",
      changeTextTo: "",
      focusEvent1: false,
      focusEvent2: false,
      focusEvent3: false,
      dateUnfocus: false,
      closeDateMenu: false,

      focusFalse: true,

      pluginWhatspp: false,
      whatsappApi: "",
      subscribeApi:"",
      autocompleteApi:"",
      getserviceUrl:"",
      getServicedata:[],
      getTopdestinationdata:[],
      getTrendingplacesdata:[]
      
    };
  },

  beforeUnmount() {
    document.body.removeEventListener("click", this.handleOutsideClick);
  },

  methods: {

    openAdvanceMenu(menu){
      this.adAirlineMenu = menu === 'adAirline';
      this.adStopMenu = menu === 'adStops';
      this.adlayoverMenu = menu === 'adlayover';
      this.adviaAirportMenu = menu === 'adviaAirport';
      this.adavoidCountryMenu = menu === 'adavoidCountry';
    },  
    
    resetOptions() {
      this.getallAdvanceOptions = [];
      this.directFlight = false;
      this.baggageInclusive = false;
      this.nearbyAirport = false;
      this.airlineSelect = "includeAirline";
      this.stopsSelect = 'anyStop'; 
      this.adlayoverTime = 'noPreference';
      this.adviaAirport = "includeAirport";
      this.selectedAirline = [];
      this.selectedAirport = [];
      this.avoidCountry = [];

      this.airlineVal = "";
      this.airportVal = "";
      this.airlineList = [];
      this.airportList = [];
    },

    resetAirlines(){
      this.airlineSelect = "includeAirline";
      this.selectedAirline = [];
      this.airlineVal = "";
      this.airlineList = [];
    },
    resetAirport(){
      this.adviaAirport = "includeAirport";
      this.selectedAirport = [];
      this.airportVal = "";
      this.airportList = [];
    },
    
    changeSelected(data){
      if(data == 'includeAirline' || data == 'excludeAirline'){
        this.airlineVal = null;
        this.airlineList = [];
        this.selectedAirline = [];
      }
      else{
        this.airportVal = null
        this.airportList = [];
        this.selectedAirport = [];
      }
    },

    clickoutDropdown(){
      this.typedrop = false;
      this.typedrop1 = false;
      this.inputCity = null;
      this.inputCityto = null;
    },

    clickoutmultiDropdown(){
      this.multiData.itinery.map((v) => {
          if (v.autocomfrom) {
            v.from = null;
            v.autocomfrom = false;
          }

         if(v.autocomTo){
            v.to = null;
            v.autocomTo = false;
          }
      });
    },

    preventMenuClose(event) {
      console.log(event,'eventsadasdasdas');
    if (event.key === 'Tab') {
      event.stopPropagation();
      event.preventDefault();
    }
  },

  filter: function (evt) {
    evt = evt ? evt : window.event;
    let currentInput = evt.target.value;
    let newChar = evt.key;
    let expect = currentInput + newChar;
    if (expect.length > 6) {
      evt.preventDefault();
      return;
    }
    if (!/^[a-zA-Z]*$/.test(expect)) {
      evt.preventDefault();
    }
  },

    getrecent(data) {
      // console.log(data, 'pppp')
      this.inputCity = data.from.name || data.from;
      this.oneway.from = this.inputCity;
      this.fromRecent = this.inputCity;
      // this.oneway.from= $data[$data.length - 1].replace(")", '')
    },

    getrecent1(data) {
      this.inputCityTo = data.to.name || data.to;
      this.oneway.to = this.inputCityTo;
      this.toRecent = this.inputCityTo;
      //
    },

    getrecentData(data) {
      console.log(data, "ahahakkaaj");
      let res = data.split(",");
      let result = "";
      res.forEach((v, i) => {
        if (i !== res.length - 1) result += v + ",";
      });
      return result.slice(0, -1);
    },


    deleteItin(index) {
      this.multiData.itinery.splice(index, 1);
    },

    // subData($data) {
    //   console.log($data, "tytttt");
    //   if ($data.includes("com")) {
    //     document.getElementById("visible").style.visibility = "visible";
    //     this.dataloaded = true;
    //   } else {
    //     this.dataloaded = false;
    //   }
    // },

    selectClasstype(item) {
      this.classType = item.value;
    },

    getClassType() {
      return [
        {
          text: this.$t("formsContents.options.economy"),
          value: "Economy",
          department: "Economy Department",
        },
        {
          text: this.$t("formsContents.options.business"),
          value: "Business",
          department: "Business Department",
        },
        {
          text: this.$t("formsContents.options.firstClass"),
          value: "First Class",
          department: "First Class Department",
        },
        { 
          text: this.$t('formsContents.options.premiumEconomy'), 
          value: 'Premium Economy', 
          department: 'Premium Economy Department' 
        },
        { 
          text: "Premium Business", 
          value: 'Premium Business', 
          department: 'Premium Business Department' 
        },
        { 
          text: "Premium First", 
          value: 'Premium First', 
          department: 'Premium First Department' 
        },
      ];
    },
    getService() {
      return [
        {
          url: require("@/assets/cardimg.jpg"),
          name: this.$t("homePageContent.bestService.heading1"),
          letter: this.$t("homePageContent.bestService.content1"),
        },
        {
          url: require("@/assets/cardimg1.jpg"),
          name: this.$t("homePageContent.bestService.heading2"),
          letter: this.$t("homePageContent.bestService.content2"),
        },
        {
          url: require("@/assets/cardimg2.svg"),
          name: this.$t("homePageContent.bestService.heading3"),
          letter: this.$t("homePageContent.bestService.content3"),
        },
      ];
    },

    focusAutocomplete(element) {
      this.focusedElement = element;

      if (this.focusedElement === "from") {
        this.formres = true;
        this.formres1 = false;

        this.focusEvent1 = true;
        this.focusEvent2 = false;
        this.focusEvent3 = false;

        this.$refs.fromAutoComplete.$el.querySelector("input").focus();
      } else if (this.focusedElement === "to") {
        this.formres1 = true;
        this.formres = false;
        this.focusEvent3 = false;
        this.focusEvent1 = false;
        this.focusEvent2 = false;

        this.$nextTick(() => {
          this.$refs.secondAutoComplete.$el.querySelector("input").focus();
        });
      }
    },

    focusMulti_AutoComplete(element, index) {
      this.multiData.itinery.map((v, i) => {
        if (index == i) {
          if (!this.focusFalse) {
            this.focusedElement = null;
            v.focusMultiEvent3 = false;
          } else {
            this.focusedElement = element;
          }
        }
      });

      this.multiData.itinery.map((v, i) => {
        if (index == i) {
          v.focusMultiEvent3 = false;
          console.log(v.focusMultiEvent3, "checkcheckcheck");
        }
      });

      if (this.focusedElement === "from") {
        this.multiData.itinery.map((v, i) => {
          // console.log(v,i,index,'mimimimi...1.')
          if (i == index) {
            v.formres = true;
            v.formres1 = false;

            v.focusMultiEvent1 = true;
            v.focusMultiEvent2 = false;
            v.focusMultiEvent3 = false;
            const inputElement =
              this.$refs.fromAutoComplete[index].$el.querySelector("input");
            if (inputElement) {
              inputElement.focus();
            }
          } else {
            v.focusMultiEvent1 = false;
            v.focusMultiEvent2 = false;
            v.focusMultiEvent3 = false;
          }
        });
      } else if (this.focusedElement === "to") {
        this.multiData.itinery.map((v, i) => {
          if (i == index) {
            v.formres = false;
            v.formres1 = true;

            v.focusMultiEvent1 = false;
            v.focusMultiEvent2 = true;
            v.focusMultiEvent3 = false;

            const inputElement =
              this.$refs.secondAutoComplete[index].$el.querySelector("input");
            if (inputElement) {
              inputElement.focus();
            }
          } else {
            v.focusMultiEvent1 = false;
            v.focusMultiEvent2 = false;
            v.focusMultiEvent3 = false;
          }
        });
      } else if (this.focusedElement == "calendar1") {
        this.multiData.itinery.map((v, i) => {
          if (i == index) {
            v.formres = false;
            v.formres1 = true;

            v.focusMultiEvent1 = false;
            v.focusMultiEvent2 = false;
            v.focusMultiEvent3 = true;

            const inputElement = this.$refs.dpRef4[index];
            if (inputElement) {
              inputElement.openMenu();
            }
          } else {
            v.focusMultiEvent1 = false;
            v.focusMultiEvent2 = false;
            v.focusMultiEvent3 = false;
          }
        });
      }
    },

    focusCalendar(element) {
      if (!this.focusFalse) {
        this.focusedElement = null;
        this.focusEvent3 = false;
        this.focusEvent2 = false;
        this.focusEvent1 = false;
        this.$refs.dpRef1.closeMenu();
      } else {
        this.focusedElement = element;
      }

      if (this.focusedElement == "calendar1") {
        this.$nextTick(() => {
          this.$refs.dpRef1.openMenu();
          this.focusEvent3 = true;
          this.focusEvent2 = false;
          this.focusEvent1 = false;
          this.formres = false;
          this.formres1 = false;
        });
      } else {
        this.focusEvent3 = false;
        this.focusEvent2 = false;
        this.focusEvent1 = false;
      }
    },
    fromInputData(){
      this.inputCity = ""
      this.oneway.from = ""
      // if(!this.oneway.from || !this.inputCity){
      //    this.$refs.firstAutoComplete.focus();
      // }
    },
    toInputData(){
      this.inputCityto = ""
      this.oneway.to = ""
      // if(!this.oneway.to || !this.inputCityto){
      //    this.$refs.secondAutoComplete.focus();
      // }
      // this.$refs.secondAutoComplete.focus();
    },

    handleOutsideClick(event) {
      if (!event.target.closest(".input-styles")) {
        this.formres = false;
        this.formres1 = false;
        this.focusedElement = null;
        this.focusEvent1 = false;
        this.focusEvent2 = false;
        this.focusEvent3 = false;

        this.multiData.itinery.map((v) => {
          // console.log(v, i, "focusMultiEvent3focusMultiEvent3")
          v.focusMultiEvent1 = false;
          v.focusMultiEvent2 = false;
          v.focusMultiEvent3 = false;
        });
      }

      if (!event.target.closest(".subscribe-inputFields")) {
        this.focusedElement = null;
        this.roundedShadow = false;
      }
    },
    // subRadio() {

    //   this.phoneNumber = !this.phoneNumber
    // },
    adulted() {
      this.Adult.push({
        PassengerID: "T",
        PTC: "ADT",
      });
      //console.log(this.Adult, 'add')
    },
    adulted1() {
      this.Adult.pop();
      //console.log(this.Adult, 'remove')
    },

    Childed() {
      this.Child.push({
        PassengerID: "T",
        PTC: "CHD",
      });
    },
    Childed1() {
      this.Child.pop();
    },
    Infranted() {
      this.Infrant.push({
        PassengerID: "T",
        PTC: "INF",
      });
      this.passengerData();
    },
    Infranted1() {
      this.Infrant.pop();
    },

    activeType(data) {
      this.activetrip = data;
      console.log(this.activetrip, 'asadsdasdpppppppsdfsfsfaA');
      this.oneway.tripType = this.activetrip;
      if (this.activetrip == "Oneway") {
        this.Oneway = true;
        (this.roundTrip = false), (this.multicity = false);
        this.oneway.redate = null;
      }
      if (data == "Roundtrip") {
        this.Oneway = false;
        this.roundTrip = true;
        this.multicity = false;
      }
      if (data == "Multicity") {
        this.Oneway = false;
        this.roundTrip = false;
        this.multicity = true;
      }
    },
    roundData() {
      let result = "";
      result = localStorage.getItem("searchedData");
      console.log(this.result, "lavs");
      let result1 = [];
      result1 = JSON.parse(result);
      this.oneway.class = result1.class;
      if (result1.from.name) {
        this.oneway.from = result1.from.name;
      } else {
        this.oneway.from = result1.from;
      }
      if (result1.to.name) {
        this.oneway.to = result1.to.name;
      } else {
        this.oneway.to = result1.to;
      }
      this.oneway.dedate = moment(result1.dedate).format("DD MMM-YYYY");

      this.oneway.redate = moment(result1.redate).format("DD MMM-YYYY");

      this.oneway.class = result1.class;

      this.adultValue = result1.adult;

      this.infantValue = result1.infant;

      this.childValue = result1.child;

      this.select = result1.class;
    },

    passengerData() {
      this.totalPassenger = parseInt(this.adultValue) + parseInt(this.childValue) + parseInt(this.infantValue);
    },
    addTrip() {
      if (this.multiData.itinery.length < 4) {
        this.multiData.itinery.push({
          from: null,
          to: null,
          depdate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          multiSearchBtn: true,
          focusMultiEvent1: false,
          focusMultiEvent2: false,
          focusMultiEvent3: false,
        });

        if (this.multiData.itinery.length == 4) {
          this.addmore = false;
        }
      }

      // if(this.multiData.itinery.length-1){
      //   this.multiSearchBtn = true
      // }

      if (this.multiData.itinery.length > 3) {
        this.clearall = true;
      }

      this.multiData.itinery.map((v, i) => {
        if (this.multiData.itinery.length - 2 == i) {
          this.multiData.itinery[i + 1].from = this.multiData.itinery[i].to;
        }
      });

      if (this.multiData.itinery.length > 2) {
        this.multiData.itinery.map((v) => {
          if(v.from){
            v.selectedFrom = true;
          }
          else{
            v.selectedFrom = false;
          }
        });
      }
    },

    clearall1() {
      this.multiData.itinery.splice(2, 5);
      (this.multiData.itinery = [
        {
          from: null,
          to: null,
          depdate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter: false,
          maximumletter1: false,
          clicked3: false,
        },
        {
          from: null,
          to: null,
          depdate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter1: false,
          clicked3: false,
        },
      ]),
        (this.clearall = false);
      this.addmore = true;
    },
    delet(index) {
      this.multiData.itinery.splice(index, 1);
    },
   
    deg1() {
      this.adulted1();
      if (this.adultValue == this.infantValue && this.infantValue > 1) {
        this.deg3();
      }
      if (this.adultValue > 1) {
        this.adultValue = this.adultValue - 1;

        this.addedPassenger();
      }
      // //console.log( this.Adult,'concatenatedArray')
    },
    add1() {
      if (this.adultValue < 9 && this.addition < 9) {
        this.adultValue += 1;
        //console.log(this.adultValue, '1111')

        this.adulted();
        this.addedPassenger();
      }

      // //console.log( this.Adult,'concatenatedArray')
    },

    deg2() {
      if (this.childValue > 0) {
        this.childValue = this.childValue - 1;

        this.Childed1();
        this.addedPassenger();
      }
      // //console.log( this.Child,'concatenatedArray')
    },
    add2() {
      if (this.childValue < 8 && this.addition < 9) {
        this.childValue = this.childValue + 1;
        //console.log(this.childValue, '2222')

        this.Childed();
        this.addedPassenger();
      }
      // //console.log( this.Child,'concatenatedArray')
    },
    deg3() {
      if (this.infantValue > 0) {
        this.infantValue = this.infantValue - 1;

        this.Infranted1();
        this.addedPassenger();
      }
    },
    add3() {
      if (this.addition < 9 && this.adultValue > this.infantValue) {
        this.infantValue = this.infantValue + 1;
        //console.log(this.infantValue, '3333')

        this.Infranted();
        this.addedPassenger();
      }
    },

    addedPassenger() {
      this.addition = this.adultValue + this.childValue + this.infantValue;
      this.concatenatedArray = this.Adult.concat(this.Child).concat(this.Infrant);
      this.passengerData();
      //console.log(this.concatenatedArray, 'concatenatedArray')
    },

    localdone() {
      localStorage.setItem("value1", "this.adultValue");
    },

    // selectFunction() {
    //   this.classSelect = !this.classSelect
    //   this.$refs.mySelect.focus();
    //   if (!this.classSelect) {
    //     this.$refs.countrySelect.focus();
    //   }
    // },

    closeDropdown() {
      this.$refs.countrySelect.blur();
      // this.$refs.mySelect.blur();
    },

    // fromDateChanged(newValue) {
    //   this.oneway.dedate = newValue;
    //   // if(!this.oneway.dedate){
    //   //   this.$refs.dpRef2.openMenu();
    //   //   this.focusedElement = "calendar2";
    //   // }

    //   if (this.oneway.dedate) {
    //     this.oneway.redate = this.oneway.dedate > this.oneway.redate ? null : this.oneway.redate;
    //     this.clicked1 = true;
    //     this.fromDateError = false;
    //     this.$refs.dpRef2.openMenu();
    //     this.focusedElement = "calendar2";
    //   } else {
    //     this.clicked1 = false;
    //     this.fromDateError = true;
    //     this.$refs.dpRef1.openMenu();

    //     // setTimeout(() => {
    //     //   this.$refs.dpRef2.closeMenu();
    //     // }, 10)
    //   }
    // },
    fromDateChanged(newValue) {
      this.oneway.dedate = newValue;

      this.oneway.dedate = new Date(this.oneway.dedate)
      this.oneway.redate = new Date(this.oneway.redate)

      if (this.oneway.dedate) {
        if (this.oneway.redate && this.oneway.redate < this.oneway.dedate) {
          this.oneway.redate = null;
        }

        this.clicked1 = true;
        this.fromDateError = false;

        this.$refs.dpRef2.openMenu();
        // this.focusedElement = "calendar2";

      } else {
        this.clicked1 = false;
        this.fromDateError = true;

        this.$refs.dpRef1.openMenu();
      }
    },

    clearDate() {
      this.oneway.dedate = null;
      this.oneway.travelDate = null;
      this.focusEvent3 = false;
    },

    // ClickOutDepat() {
    //   this.$refs.dpRef1.closeMenu();
    //   // console.log(v.clicked3,"checking....")

    //   if (this.oneway.dedate) {
    //     this.clicked1 = true;
    //     // this.fromlabel=true
    //   } else {
    //     this.clicked1 = false;
    //     // this.fromlabel=false;
    //   }
    // },

    // ClickOutReturn() {
    //   this.$refs.dpRef2.closeMenu();

    //   // this.$refs.mySelect.focus();

    //   if (this.oneway.redate) {
    //     this.clicked2 = true;
    //   } else {
    //     this.clicked2 = false;
    //   }
    // },
    ClickOutDepat() {
    this.$refs.dpRef1.closeMenu();

      if (this.oneway.dedate) {
          this.clicked1 = true;
          if (this.oneway.redate && this.oneway.dedate > this.oneway.redate) {
              this.oneway.redate = null;
              this.clicked2 = false;
          } 
          // else {
          //     this.dateError = "";
          // }
      } else {
          this.clicked1 = false;
      }
    },

    ClickOutReturn() {
        this.$refs.dpRef2.closeMenu();

        if (this.oneway.redate) {
            this.clicked2 = true;

            if (this.oneway.dedate && this.oneway.redate < this.oneway.dedate) {
                this.oneway.redate = null;
                this.clicked2 = false;
                // this.dateError = "Return date cannot be earlier than the departure date.";
            } 
            // else {
            //     this.dateError = "";
            // }
        } else {
            this.clicked2 = false;
        }
    },

    // RoundTrip datePicker End

    // Oneway datePicker
    onewayDateChanged(newValue) {
      this.oneway.dedate = newValue;
      //console.log(this.oneway.dedate, "kkkkkkkkk");

      // this.$refs.select1.focus();

      if (this.oneway.dedate) {
        this.clicked1 = true;
        this.fromDateError = false;
      } else {
        this.clicked1 = false;
        this.fromDateError = true;
      }
    },

    onewayClickOutside() {
      this.$refs.dpRef3.closeMenu();

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },
    // Oneway datePicker End

    // Multicity datepicker

    multiDateChanged(newValue, index) {
      this.multiData.itinery.map((v, i) => {
        if (index == i) {
          if (v.depdate) {
            setTimeout(() => {
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = false;
              v.multiDateError = false;

              const inputElement =
                this.$refs.multiToAutocomplete[index+1];
              // console.log(this.$refs.fromAutoComplete);
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.focus();
              }
            }, 10);
          //    else{
          //   setTimeout(() => {
          //     const inputElement = this.$refs.dpRef4[index];
          //     if (inputElement) {
          //       inputElement.openMenu();
          //     }
          //     v.focusMultiEvent2 = false;
          //     v.focusMultiEvent3 = true;
          //   }, 10);
          // } else {
            
          // }
          //   v.focusMultiEvent3 = false;
          } else {
            setTimeout(() => {
              const inputElement = this.$refs.dpRef4[index];
              if (inputElement) {
                inputElement.openMenu();
              }
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = true;
              v.multiDateError = true;
            }, 10);
            v.focusMultiEvent3 = true;
          }
        }
      });

      this.multiData.itinery.forEach((v, i) => {
        if (index < i) {
          if (newValue > new Date(v.depdate)) {
            v.depdate = null;
          }
        }
      });
    },

    getdate1($data, index) {
      if (index > 0) {
        return $data[index - 1].depdate;
      } else {
        return `${new Date()}`;
      }
    },

    multiClickOutside() {
      this.datePickerIsOpen = false;
    },

       cardassigndata($event) {
      console.log($event, "$event4444");
      this.resentSearch = false;
      this.airbooking = true;
      this.inputCity = $event.from;
      this.oneway.from = $event.from;
      this.inputCityto = $event.to;
      this.oneway.to = $event.to;
      this.adultValue = $event.adult;
      this.childValue = $event.child;
      this.infantValue = $event.inf;
      this.classType = $event.class;
      this.oneway.dedate = $event.fromDate;
      this.oneway.redate = $event.toDate;
      this.oneway.tripType = $event.tripType;

      this.activeType($event.tripType);
      // this.updateRecentSearch($event)

      if ($event.itinery) {
        console.log($event, '$event1111111');
        this.adultValue = $event.adult
        this.childValue = $event.child
        this.infantValue = $event.infant
        
        this.multiData = {
          adult: $event.adult,
          child: $event.child,
          infant: $event.infant,
          class: $event.class,
          tripType: $event.tripType,
          itinery: $event.itinery.map(itin => ({
            ...itin,
            depdate: itin.depdate,
            selectedFrom: true,
            selectedTo: true,
          }))
        };

        this.handleMultiSearch(this.multiData);

        console.log(this.multiData, 'this.multiData..11');
      }
      else{
        this.handleRecentSearch($event);
      }
      this.passengerData();
      this.travelHistory = false;
    },
    // recentFromData() {
    //   let result1 = JSON.parse(localStorage.getItem("recentData")) || [];
    //   const today = new Date();

    //   const filteredResults = result1.filter(item => new Date(item.fromDate) >= today);

    //   localStorage.setItem("recentData", JSON.stringify(filteredResults));
    //   this.RecentSearchData = filteredResults.slice(0,5)
    // },

    recentFromData() {
        let result1 = JSON.parse(localStorage.getItem("recentData")) || [];
        let today = new Date();

        const filteredResults = result1.filter(item => {
          if (item.itinery && item.itinery.length > 0) {
            for (let itineraryItem of item.itinery) {
              let depDate = new Date(itineraryItem.depdate);
              if (depDate < today) {
                return false;
              }
            }
          } else {
            let fromDate = new Date(item.fromDate);
            if (fromDate < today) {
              return false;
            }
          }
          return true;
        });

        localStorage.setItem("recentData", JSON.stringify(filteredResults));

        this.RecentSearchData = filteredResults.slice(0, 5);
      },
    
    
    handleMultiSearch(newMultiData) {
      let storedRecentFrom = JSON.parse(localStorage.getItem("recentData")) || [];

      storedRecentFrom = storedRecentFrom.filter(existingItem => {
          const existingItinery = existingItem.itinery || [];

          const isDuplicate = newMultiData.itinery.every(newItin => 
              existingItinery.some(existingItin =>
                  newItin.from === existingItin.from &&
                  newItin.to === existingItin.to &&
                  newItin.depdate === existingItin.depdate
              )
          );

          const isAdditionalPropsMatch = (
              existingItem.adult === newMultiData.adult &&
              existingItem.child === newMultiData.child &&
              existingItem.infant === newMultiData.infant &&
              existingItem.class === newMultiData.class &&
              existingItem.tripType === newMultiData.tripType
          );

          return !(isDuplicate && isAdditionalPropsMatch);
      });

      storedRecentFrom.unshift(newMultiData);

      // localStorage.setItem("recentFrom", JSON.stringify(storedRecentFrom));
      return storedRecentFrom;
    },


     handleRecentSearch(data) {
        let recentSearchObj;

        if (data) {
          recentSearchObj = {
            from: data.from || '',
            to: data.to || '',
            fromDate: data.fromDate || '',
            toDate: data.toDate || null,
            adult: data.adult || 0,
            child: data.child || 0,
            inf: data.inf || 0,
            tripType: data.tripType || '',
            class: data.class || ''
          };
          console.log('Using provided data:', recentSearchObj);
        } else {
          recentSearchObj = {
            from: this.oneway.from || '',
            to: this.oneway.to || '',
            fromDate: this.oneway.dedate || '',
            toDate: this.oneway.redate || null,
            adult: this.adultValue || 0,
            child: this.childValue || 0,
            inf: this.infantValue || 0,
            tripType: this.activetrip || '',
            class: this.classType || ''
          };
          console.log('Using current state values:', recentSearchObj);
        }

        let storedRecentFrom = JSON.parse(localStorage.getItem("recentData")) || [];
        console.log('Stored recent searches:', storedRecentFrom);

        storedRecentFrom = storedRecentFrom.filter((search) => {
          const searchFromDate = new Date(search.fromDate).toDateString();
          const searchToDate = new Date(search.toDate).toDateString();
          const recentFromDate = new Date(recentSearchObj.fromDate).toDateString();
          const recentToDate = new Date(recentSearchObj.toDate).toDateString();

          return !(
            search.from === recentSearchObj.from &&
            search.to === recentSearchObj.to &&
            searchFromDate === recentFromDate &&
            searchToDate === recentToDate &&
            search.adult === recentSearchObj.adult &&
            search.child === recentSearchObj.child &&
            search.inf === recentSearchObj.inf &&
            search.tripType === recentSearchObj.tripType &&
            search.class === recentSearchObj.class
          );
        });

        storedRecentFrom.unshift(recentSearchObj);
        console.log('Updated search list:', storedRecentFrom);

        // localStorage.setItem("recentFrom", JSON.stringify(storedRecentFrom));
        return storedRecentFrom;
      },

    dataAssign(){
      let onewayObj = {}
      // if(this.directFlight) onewayObj.directFlight = this.directFlight;
      if(this.baggageInclusive) onewayObj.baggageInclusive = this.baggageInclusive;
      if(this.nearbyAirport) onewayObj.nearbyAirport = this.nearbyAirport;
      if(this.avoidCountry && this.avoidCountry.length > 0) onewayObj.avoidCountry = this.avoidCountry;
      if(this.adlayoverTime && this.adlayoverTime !== 'noPreference') onewayObj.adlayoverTime = this.adlayoverTime;
      this.airlineSelect == 'includeAirline' && this.selectedAirline.length > 0 ? onewayObj.includeAirline = this.selectedAirline : [];
      this.airlineSelect == 'excludeAirline' && this.selectedAirline.length > 0 ? onewayObj.excludeAirline = this.selectedAirline : [];
      this.adviaAirport == 'includeAirport' && this.selectedAirport.length > 0 ? onewayObj.includeAirport = this.selectedAirport : [];
      this.adviaAirport == 'excludeAirport' && this.selectedAirport.length > 0 ? onewayObj.excludeAirport = this.selectedAirport : [];

      console.log(this.stopsSelect,'sdstopsSsadsdelectasd...1');
      if(this.stopsSelect.length > 0 && (this.stopsSelect !== 'anyStop' || !this.stopsSelect.includes('anyStop'))) {
        console.log(this.stopsSelect,'sdstopsSsadsdelectasd...2');
        if(this.directFlight || this.stopsSelect == 0 || this.stopsSelect == '0'){
          this.stopsSelect = '0';
          onewayObj.stopsSelect = this.stopsSelect;
        }
        else{
          onewayObj.stopsSelect = this.stopsSelect;
        }
      }

      this.roundTrip || this.Oneway ? this.oneway.advanceOptions = onewayObj : this.multiData.advanceOptions = onewayObj;
      console.log(this.oneway,'onewayonrounttripewayoneway...2');
      console.log(this.multiData,'multiDataonrounttripewaymultiData...2');
    },

    sended() {
      this.focusFalse = false;
      this.$nextTick(() => {
        this.focusEvent3 = false;
        this.$refs.dpRef1.closeMenu();
      });

      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");

      console.log(this.activeAdvanceSearch,'sdasdactiveAdvanceSearch...1')
      if(this.activeAdvanceSearch) this.dataAssign();

      let returnDate = this.Oneway ? true : this.oneway.redate;
      console.log(this.oneway,'onewayonrounttripewayoneway...1');

      if (this.oneway.from && this.oneway.to && this.oneway.dedate && returnDate && (this.oneway.from !== this.oneway.to)) {
        setTimeout(() => {
          // this.$refs.dpRef1.closeMenu();
          this.focusEvent3 = false;
          // console.log(this.$refs.dpRef1, 'fdfdfdfdfdf...1')
        }, 5);


        this.oneway.tripType = this.activetrip;
        this.oneway.adult = this.adultValue;
        this.oneway.child = this.childValue;
        this.oneway.infant = this.infantValue;
        this.oneway.class = this.classType;

        console.log(this.oneway,'sdasdasdasdadasd');


        const tabId = sessionStorage.getItem("tabId") || Date.now().toString();
        sessionStorage.setItem("tabId", tabId);

        const result1 = JSON.stringify(this.oneway);
        console.log(result1,'sdasdaresult1')

        if(this.oneway.redate){
            localStorage.setItem(`searchedData-round-${tabId}`, result1);
        }
        else{
            localStorage.setItem(`searchedData-oneway-${tabId}`, result1);
        }
  
        this.localSearchCase = this.oneway;
        console.log(this.localSearchCase,'ssdasdseasaaSrchCase...5')


        let getfrom = ""
        if (this.oneway.from.name) {
          getfrom = this.oneway.from.name.split(" ");
          this.roundfrom = getfrom[getfrom.length - 1].replace("(", "").replace(")", "");
        } 
        else {
          getfrom = this.oneway.from.split(" ");
          this.roundfrom = getfrom[getfrom.length - 1].replace("(", "").replace(")", "");
        } 
  
        let getto = ""
        if (this.oneway.to.name) {
          getto = this.oneway.to.name.split(" ");
          this.roundto = getto[getto.length - 1].replace("(", "").replace(")", "");
        } 
        else {
          getto = this.oneway.to.split(" ");
          this.roundto = getto[getto.length - 1].replace("(", "").replace(")", "");
        }

        this.urldedate = moment(this.oneway.dedate).format("YYYY/MM/DD");
        this.urlredate = moment(this.oneway.redate).format("YYYY/MM/DD");

        let getUrlFormat = "";
        let getTriptype = "";
        if(this.activetrip == "Roundtrip"){
          getUrlFormat = `${this.roundfrom}-${this.roundto}-${this.urldedate}_${this.roundto}-${this.roundfrom}-${this.urlredate}`;
          getTriptype = "RT";
        }
        else{
          getUrlFormat = `${this.roundfrom}-${this.roundto}-${this.urldedate}`;
          getTriptype = "OW"
        }
        
        let [Adt, Chd, Inf] = [this.oneway.adult,this.oneway.child,this.oneway.infant];
        let getPaxtype = `A-${Adt}_C-${Chd}_I-${Inf}`;

        console.log(getPaxtype,'asdsfasdasdaeeqq');
        
        let getLangual = this.$i18n.locale.split('-')[1];

        console.log(getLangual,'dsdassagetLangualgetLangual')

        console.log(this.classType,'classTypeclassType');
        
        let getClassType;

        if(this.classType == "Economy"){
            getClassType = "Economy";
        }
        if(this.classType == "Business"){
            getClassType = "Business";
        }
        if(this.classType == "First Class"){
            getClassType = "FirstClass";
        }
        if(this.classType == "Premium Economy"){
            getClassType = "PremiumEconomy";
        }
        if(this.classType == "Premium Business"){
            getClassType = "PremiumBusiness";
        }
        if(this.classType == "Premium First"){
            getClassType = "PremiumFirstClass";
        }
        

        let rountdetail = {
          itinerary: getUrlFormat,
          tripType: getTriptype,
          paxType: getPaxtype,
          cabin: getClassType,
          language: getLangual,
        };

        console.log(rountdetail, "rountdetailrountdetail");
        console.log(this.roundfrom,'roundfromroundfromroundfrom',this.roundto);

        const resultData = this.handleRecentSearch()

        localStorage.setItem('recentData',JSON.stringify(resultData))

        
        if(this.$route.path == "/flight/search"){
            setTimeout(() => {
                location.reload();
            }, 500);
        }

        this.$router.push({ path: "/flight/search", query: rountdetail });
        } 
        else {
          // alert("please enter your detail");
          this.validateOnewayForm();
        }
    },


    sendedMulticity() {
      this.focusFalse = false;

      console.log(this.activeAdvanceSearch,'sdasdactiveAdvanceSearch...2')
      if(this.activeAdvanceSearch) this.dataAssign();

      console.log(this.multiData,'multiDataonrounttripewaymultiData...1');
      if (
        this.multiData.itinery[0].from &&
        this.multiData.itinery[0].to &&
        this.multiData.itinery[0].depdate &&
        this.multiData.itinery[1].from &&
        this.multiData.itinery[1].to &&
        this.multiData.itinery[1].depdate &&
        (!this.multiData.itinery[2] ||
          (this.multiData.itinery[2].from &&
            this.multiData.itinery[2].to &&
            this.multiData.itinery[2].depdate)) &&
        (!this.multiData.itinery[3] ||
          (this.multiData.itinery[3].from &&
            this.multiData.itinery[3].to &&
            this.multiData.itinery[3].depdate))
      ) {
        this.multiData.adult = this.adultValue;
        this.multiData.child = this.childValue;
        this.multiData.infant = this.infantValue;
        this.multiData.class = this.classType;
        this.multiData.tripType = this.activetrip;


        const tabId = sessionStorage.getItem("tabId") || Date.now().toString();
        sessionStorage.setItem("tabId", tabId);
        
        const result1 = JSON.stringify(this.multiData);
        localStorage.setItem(`searchedData-multicity-${tabId}`, result1);

        console.log(result1,'ssdasdseasaaSrchCase...7')


        let getUrlFormat = [];
        this.multiData.itinery.forEach((v) => {
          let from = v.from.name ? v.from.name.split(" ").pop().replace("(", "").replace(")", "") : v.from.split(" ").pop().replace("(", "").replace(")", "");
          let to = v.to.name ? v.to.name.split(" ").pop().replace("(", "").replace(")", "") : v.to.split(" ").pop().replace("(", "").replace(")", "");
          let depDate = moment(v.depdate).format("YYYY/MM/DD");

          getUrlFormat.push(`${from}-${to}-${depDate}`);
        });

        let formattedItinerary = getUrlFormat.join('_');

        let [Adt, Chd, Inf] = [this.multiData.adult, this.multiData.child, this.multiData.infant];
        let getPaxtype = `A-${Adt}_C-${Chd}_I-${Inf}`;

        let getLangual = this.$i18n.locale.split('-')[1];

        let getClassType;

        if(this.classType == "Economy"){
            getClassType = "Economy";
        }
        if(this.classType == "Business"){
            getClassType = "Business";
        }
        if(this.classType == "First Class"){
            getClassType = "FirstClass";
        }
        if(this.classType == "Premium Economy"){
            getClassType = "PremiumEconomy";
        }
        if(this.classType == "Premium Business"){
            getClassType = "PremiumBusiness";
        }
        if(this.classType == "Premium First"){
            getClassType = "PremiumFirstClass";
        }

        let multicityData = {
          itinerary: formattedItinerary, 
          tripType: "MC",                
          paxType: getPaxtype,           
          cabin: getClassType,         
          language: getLangual,          
        };

        console.log(multicityData,'dadsdasmulticityData')

        const resultData = this.handleMultiSearch(this.multiData);
        localStorage.setItem("recentData", JSON.stringify(resultData));

        if(this.$route.path == "/flight/search"){
          setTimeout(() => {
            location.reload();
          }, 500);
        }

        this.$router.push({ path: "/flight/search", query: multicityData });

      } else {
        this.validateMultiForm()
      }
    },


    validateMultiForm(){
      console.log('validateMultiformError')
      this.multiData.itinery.forEach((data, index) => {
        if (!data.depdate) {
          this.multiData.itinery[index].multiDateError = true;
        } else {
          this.multiData.itinery[index].multiDateError = false;
        }
        if(!data.from){
           this.multiData.itinery[index].multiFromError = true;
        }
        else{
           this.multiData.itinery[index].multiFromError = false;
        }
        if(!data.to){
          this.multiData.itinery[index].multiToError = true;
        }
        else{
          this.multiData.itinery[index].multiToError = false;
        }
      });
    },


    onewaySwap() {
      this.swapData = !this.swapData;
      let $dataFrom = this.inputCity;
      let $dataTo = this.inputCityto;
      this.inputCity = $dataTo;
      this.inputCityto = $dataFrom;
    },


    login() {
      let user1 = this.customer.findIndex(
        (v) => v.email === this.loginuser.email
      );
      //console.log(user1, '23442234')
      let user2 = this.customer.findIndex(
        (v) => v.password === this.loginuser.password
      );
      //console.log(user2, '23442234')
      if (user1 === 0 && user2 === 0) {
        this.avath = true;
        this.buton = false;
      } else {
        this.avath = false;
        this.buton = true;
        this.error = true;
      }
    },

    onChangeMultiFrom(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.multiFromError = false;
            v.maximumletter = false;
          } else {
            v.multiFromError = true;
          }
        }
      });
    },

    onChangeMultiTo(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.multiToError = false;
            v.maximumletter1 = false;
          } else {
            v.multiToError = true;
          }
        }
      });
    },

    multiFromClick(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.from = null;
            v.multiFromError = true;
            if (!v.from) {
              v.multiFromError = true;
            } else {
              v.multiFromError = false;
            }
          }
        }
      });
    },

    multiToClick(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.to = null;
            v.multiToError = true;
            if (!v.to) {
              v.multiToError = true;
            } else {
              v.multiToError = false;
            }
          }
        }
      });
    },
    datachange(data) {
      this.oneway.from = `${data.city} (${data.iata})`;
      this.inputCity = this.oneway.from;
      this.typedrop = false;
      if(!this.oneway.from || !this.inputCity){
         this.$refs.firstAutoComplete.focus();
      }
      if (!this.oneway.to || !this.inputCityto) {
        setTimeout(() => {
          console.log(this.$refs.secondAutoComplete,'secondAutocomplete')
          this.$refs.secondAutoComplete.focus();
          this.focusEvent2 = true;
          // this.$refs.firstAutoComplete.blur();
        }, 100);
      } else {
        this.focusEvent2 = false;
        // console.log('testueeiekek.....2')
        setTimeout(() => {
          
        }, 100);
      }
    },
    datachangeTo(data) {
      this.oneway.to = `${data.city} (${data.iata})`;
      this.inputCityto = this.oneway.to;
      this.typedrop1 = false;
      this.selectedFrom = true;

      if (!this.oneway.dedate) {
        // console.log('testueeiekek.....1')
        setTimeout(() => {
          this.$refs.dpRef1.openMenu();
          this.focusEvent2 = true;
        }, 100);
      } else {
        this.focusEvent2 = false;
        // console.log('testueeiekek.....2')
        setTimeout(() => {
          this.$refs.secondAutoComplete.blur();
        }, 100);
      }

      // if(this.oneway.from == this.oneway.to){
      //   alert('From and To airports cannot be same')
      // }
    },
    multiFrom(index){
      this.multiData.itinery.map((v, i) => {
        if(i==index){
          v.from = null
          v.selectedFrom = false
        }
      })
      // const regex = /[-()]/;
      //  this.multiData.itinery.map((v, i) => {
      //   if(i==index){
      //       v.from = ""
      //       v.selectedFrom = false
      //     }
      //     else if(regex.test(v.from)){
      //       v.selectedFrom = true
      //   }
      //  })
    },
    multiTo(index){
      this.multiData.itinery.map((v, i) => {
        if(i==index){
          v.to = null
          v.selectedTo = false
        }
      })
      // const regex = /[-()]/;
      //  this.multiData.itinery.map((v, i) => {
      //   if(i==index){
      //       v.to = ""
      //       v.selectedTo = false
      //     }
      //     else if(regex.test(v.from)){
      //       v.selectedTo = true
      //   }
      //  })
    },
     datachangeMuti(data, index) {
      console.log(data, index, "data,index1");
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          v.from = `${data.city} (${data.iata})`;
          v.autocomfrom = false;
          v.selectedFrom = true
          v.multiFromError = false
          if (!v.to) {
            setTimeout(() => {
              const inputElement =
                this.$refs.multiToAutocomplete[index];
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = true;
              if (inputElement) {
                inputElement.focus();
              }
            }, 10);
          } else {
            setTimeout(() => {
              const inputElement =
                this.$refs.multiFromAutocomplete[index];
              // console.log(this.$refs.fromAutoComplete);
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.blur();
              }
            }, 10);
          }
        }
      });
      console.log(this.multiData, "this.multiDatathis.multiData");
    },
    datachangeMutiTo(data, index) {
      console.log(data, index, "data,index1");

      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          v.to = `${data.city} (${data.iata})`;
          v.autocomTo = false;
          v.selectedTo = true;
          v.multiToError = false;
          if (this.multiData.itinery.length - 1 != index){
            this.multiData.itinery[i + 1].from = this.multiData.itinery[i].to;
            this.multiData.itinery[i + 1].selectedFrom = true;
            this.multiData.itinery[i + 1].multiFromError = false;
          }

           if (!v.depdate) {
            setTimeout(() => {
              const inputElement = this.$refs.dpRef4[index];
              if (inputElement) {
                inputElement.openMenu();
              }
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = true;
            }, 10);
          } else {
            setTimeout(() => {
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = false;

              const inputElement =
                this.$refs.multiFromAutocomplete[index];
              // console.log(this.$refs.fromAutoComplete);
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.blur();
              }
            }, 10);
          }
        }
      });

      // for (let i = index + 1; i < this.multiData.itinery.length; i++) {
      //   this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
      // }

      console.log(this.multiData, "Updated multiData with index check");
    },
    multifocus1(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (!v.to) {
            setTimeout(() => {
              const inputElement =
                this.$refs.secondAutoComplete[index].$el.querySelector("input");
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = true;
              if (inputElement) {
                inputElement.focus();
              }
            }, 10);
          } else {
            setTimeout(() => {
              const inputElement =
                this.$refs.fromAutoComplete[index].$el.querySelector("input");
              console.log(this.$refs.fromAutoComplete);
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.blur();
              }
            }, 10);
          }
        }
      });
    },

    multifocus2(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (!v.depdate) {
            setTimeout(() => {
              const inputElement = this.$refs.dpRef4[index];
              if (inputElement) {
                inputElement.openMenu();
              }
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = true;
            }, 10);
          } else {
            setTimeout(() => {
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = false;

              const inputElement =
                this.$refs.secondAutoComplete[index].$el.querySelector("input");
              console.log(this.$refs.fromAutoComplete);
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.blur();
              }
            }, 10);
          }

          // for (let i = 0; i < 1; i++) {
          //   console.log(this.multiData[0].to, 'bfbfbfbf.....')
          //   this.multiData.itinery[1].from = this.multiData.itinery[0].to;
          // }
        }
      });

      // setTimeout(() => {
      //   if (this.$refs.dpRef4.length > 0) {
      //     const inputElement = this.$refs.dpRef4[index];
      //     if (inputElement) {
      //       inputElement.openMenu();
      //     }
      //   }

      // for (let i = 0; i < 1; i++) {
      //   //console.log(this.multiData[0].to, 'bfbfbfbf.....')
      //   this.multiData.itinery[1].from = this.multiData.itinery[0].to;
      // }
    },
    // Subcribe Section

    async searchAirline(event) {

      if (event.length > 1) {
        // console.log(event,'asdasdasdasdeventada');
        // await axios.get(`${this.autocompleteApi}?airportString=${event}`,
        await axios.get(`https://otmapi.manninternationaltravel.com/api/airlinesautocomplete?airlineString=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response,"responseairline");
            this.airlineList = response.data;
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });
      }
    },

    async searchAirport(event) {

      if (event.length >= 3) {
        // console.log(event,'asdasdasdasdevddddentada');
        // await axios.get(`${this.autocompleteApi}?airportString=${event}`,
        await axios.get(`https://otmapi.manninternationaltravel.com/api/airportdata?airportString=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response,"responseeairportdata");
            this.airportList = response.data;
          })

          .catch((error) => {
            console.log(error, "erroroorrrr.....");
          });
      }
    },



    async typeCity(event, $data) {
      if (event.length >= 1) {
        this.formres = false;
        if (event.length >= 1) {
          this.formres1 = false;
        }
      }
      this.city = [];
      this.city1 = [];
      // let $event = [];
      console.log(event, $data, "searchsearchsearchsearch");
      if ($data == 1 && event.length > 0) {
        this.fromloader = true;
      } else {
        this.fromloader = false;
      }

      if ($data == 2 && event.length > 0) {
        this.toloader = true;
      } else {
        this.toloader = false;
      }

      if (event.length >= 1) {
        await axios.get(`${this.autocompleteApi}?airportString=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response, response.data, "responseyyyyyyy");

            if ($data == 1) {
              this.fromloader = false;
              this.city = response.data;
              this.typedrop = true;
            } else if ($data == 2) {
              this.toloader = false;
              this.city1 = response.data;
              this.typedrop1 = true;
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
      }
    },

    async typeMultiCity(event, index, $num) {
      console.log(event, index, $num, "searchsearchsearchsearch");
      // let $event = [];
      console.log(event, index, "searchsearchsearchsearch");

      if (event.length >= 3) {
        if ($num == 1 && event.length > 2) {
          this.multiData.itinery.map((v, i) => {
            v.autocomTo = false;
            v.fromloader = true;
            if (i == index && event.length > 2) {
              v.autocomfrom = true;
            } else {
              v.autocomfrom = false;
              v.fromloader = false;
              this.multidrop = [];
            }
          });
        } else if ($num == 2 && event.length > 0) {
          this.multiData.itinery.map((v, i) => {
            v.autocomfrom = false;
            if (i == index && event.length > 2) {
              v.autocomTo = true;
              v.toloader = true;
            } else {
              v.autocomTo = false;
              v.toloader = false;
              this.multidrop1 = [];
            }
          });

          // for (let i = 1; i < this.multiData.itinery.length; i++) {
          //   this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
          //   console.log(this.multiData.itinery[i],'this.multiData.itinery[i]')
          // }
        }

        await axios
          .get(
           `${this.autocompleteApi}?airportString=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response.data, "responsedata");

            if ($num == 1) {
              this.multiData.itinery.map((v) => {
                v.fromloader = false;
              });

              this.multidrop = response.data;
              console.log(this.multidrop, "this.multidrop");
            } else if ($num == 2) {
              this.multiData.itinery.map((v) => {
                v.toloader = false;
              });

              this.multidrop1 = response.data;
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
      }
    },

    generateRandomAlphabets() {
      const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let result = '';

      for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * alphabets.length);
        result += alphabets[randomIndex];
      }

      this.randomAlphabets = result;
      setTimeout(() => {
        this.updateCanvas();
      }, 100);
    },
    updateCanvas() {
      const canvas = this.$refs.myCanvas;
      if(canvas){
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.font = '20px Arial';
        ctx.fillStyle = "white";
        ctx.fillText(this.randomAlphabets, 10, 40);
      }
    },
    generateCaptcha() {
      // console.log(this.validData,'subscriSbeDatasubscribDeData...1',this.confirmcaptcha,this.subloader);

      this.generateRandomAlphabets();
      // console.log(this.recaptchaData,'subscriSbeDatasubscribDeData...11',this.randomAlphabets);

      this.confirmcaptcha = this.recaptchaData !== this.randomAlphabets ? false : true;

      if(this.validData && !this.confirmcaptcha){
        // console.log(this.validData,'subscriSbeDatasubscribDeData...3')
        this.recaptchaData = null;
        this.confirmcaptcha = false;

        if(!this.confirmcaptcha && !this.recaptchaData){
          // console.log(this.validData,'subscriSbeDatasubscribDeData...4')
          this.subloader = true;
        }
      }

    },
    keyData($data) {
      if($data !== null || $data !== '' || $data || $data.length > 0){
        const getdataUpper = $data.toUpperCase();
        if (this.randomAlphabets === getdataUpper && $data === getdataUpper) {
          this.subloader = false;
          this.confirmcaptcha = true;
          this.usermailbtn = false;
        } else {
          this.subloader = true;
          this.confirmcaptcha = false;
          this.usermailbtn = true;
        }
      }
    },

    updatemail(data){

      if(data !== null || data !== ''){
        if(this.dialog2){
          this.dialog2 = false;
          this.dialog1 = false;
          this.subloader = false;
          this.recaptchaData = null;
          this.validData = null;
          this.confirmcaptcha = false;
          this.generateCaptcha();
          this.$refs.subscribeform.reset();
        }
        else{
          this.confirmcaptcha = false;
          this.subloader = false;
        }
      }

      if(data && this.validData && this.dialog2){
          this.dialog2 = false;
          this.dialog1 = false;
          this.subloader = false;
          this.recaptchaData = null;
          this.validData = null;
          this.confirmcaptcha = false;
          this.generateCaptcha();
          this.$refs.subscribeform.reset();
      }
    },

    subscribeData() {
      let subscribeData = {
        request_type: "subscriber_form",
        email: this.subEmail,
        whatsapp: false,
        phone_no: this.subPhonenumber,
        token: "djfkdjkf",
      };

      // console.log(this.validData,'subscriSbeDatasubscribDeData...5',this.confirmcaptcha);

      if (this.validData && this.confirmcaptcha) {
        this.subloader = true;
        axios.post(this.subscribeApi, subscribeData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(response, "subscriSbeDatasubscribDeData...6");
            if(response){
              setTimeout(() => {
                this.subloader = false;
                this.dialog2 = true;
                this.recaptchaData = null;
                this.validData = null;
                this.confirmcaptcha = false;
                this.$refs.subscribeform.reset();
              }, 2000);

              setTimeout(() => {
                this.dialog2 = false;
              }, 10000);
            }
          })
          .catch((error) => {
            console.log(error, "subscriSbeDatasubscribDeData...7");
            if(error){
              setTimeout(() => {
                this.dialog1 = true;
                this.subloader = false;
                this.recaptchaData = null;
                this.validData = null;
                this.confirmcaptcha = false;
                this.generateCaptcha();
                this.$refs.subscribeform.reset();
              }, 2000);
              setTimeout(() => {
                this.dialog1 = false;
              }, 10000);
            }
          });
      }
      else if((!this.validData && !this.confirmcaptcha) || (this.validData && !this.confirmcaptcha)){
        this.confirmcaptcha = false;
        this.generateCaptcha();
        // console.log(this.validData, "subscriSbeDatasubscribDeData...8",this.confirmcaptcha);
        return this.confirmcaptcha;
      }
      else{
        this.validData = false;
        this.generateCaptcha();
        // console.log(this.validData, "subscriSbeDatasubscribDeData...9",this.confirmcaptcha);
        return this.validData;
      }
    },

    tryAgain(){
      setTimeout(()=>{
        location.reload();
      },500);
    },

    clearEmailError() {
      if (this.Errormsg && this.subscribe.email) {
        this.Errormsg = "";
      }
    },
    clearAgreeError() {
      if (this.agreeMsg && this.subscribe.checkAgree) {
        this.agreeMsg = "";
      }
    },
    // Subcribe Section End

    // fromOutsideAuto($event){
    //   console.log($event.from, 'p.....')
    //   if ($event.from) {
    //     this.fromlabel=true
    //   }
    //   else{
    //   this.fromlabel=false
    //   }
    // },

    // toOutsideAuto($event){
    //   console.log($event.to, 'p.....')
    //   if ($event.to) {
    //     this.tolabel=true
    //   }
    //   else{
    //   this.tolabel=false
    //   }
    // },

    // AutoComplete Functions

    fromClick($event) {
      this.formres = true;
      this.fromlabel = true;
      //console.log($event, 'p.....')
      if ($event.from) {
        $event.from = null;
        this.fromErrorMessage = true;
        this.changeTextFrom = false;
      }
    },

    toClick($event) {
      this.tolabel = true;
      //console.log($event, 'p.....')
      if ($event.to) {
        $event.to = null;
        this.toErrorMessage = true;
        this.changeTextTo = false;
      }
    },

    onChangeFrom() {
      setTimeout(() => {
        if (this.oneway.from) {
          this.fromErrorMessage = false;
          this.changeTextFrom = true;
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.from) {
          this.fromErrorMessage = true;
        }
      }, 100);
    },
    // if (this.oneway.from) {
    //   //console.log(this.oneway.from, 'okokok....1...')
    //   this.fromErrorMessage = false;
    // }
    // else {
    //   //console.log(this.oneway.from, 'okokok....2...')
    //   this.fromErrorMessage = true;
    // }

    onChangeTo() {
      setTimeout(() => {
        if (this.oneway.to) {
          this.toErrorMessage = false;
          this.changeTextTo = true;
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.to) {
          this.toErrorMessage = true;
        }
      }, 100);
    },

    validateOnewayForm() {
      if (this.oneway.from) {
        this.fromErrorMessage = false;
      } else {
        this.fromErrorMessage = true;
      }

      if (this.oneway.to) {
        this.toErrorMessage = false;
      } else {
        this.toErrorMessage = true;
      }

      if (this.oneway.dedate) {
        this.fromDateError = false;
      } else {
        this.fromDateError = true;
      }

      if (this.oneway.redate) {
        this.toDateError = false;
      } else {
        this.toDateError = true;
      }
      // this.fromErrorMessage = !this.oneway.from;
      // this.toErrorMessage = !this.oneway.to;
      // this.fromDateError = !this.oneway.dedate;
      // this.toDateError = !this.oneway.redate;
    },

    // autocompletJson() {
    //   const getConfigData = getConfigDataFromLocalStorage();

    //   this.airlineIcaoUrl = getConfigData.payload.portal_configuration.content_data.airports_icao.url
    //   this.airlineIcaoType = getConfigData.payload.portal_configuration.content_data.airports_icao.type

    //   axios.get(this.airlineIcaoUrl, {
    //     headers: {
    //       "Content-Type": this.airlineIcaoType,
    //     },
    //   })
    //     .then((response) => {

    //       console.log(response, 'respomsedrespomsedr.......')

    //       let airlineIcao = response.data

    //       if (airlineIcao) {

    //         Object.keys(airlineIcao).forEach((icao) => {
    //           const airport = airlineIcao[icao];

    //           if (airport.iata !== '') {
    //             this.fromAirports.push({
    //               iata: airport.iata,
    //               icao: airport.icao,
    //               places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
    //             });
    //           }
    //         });

    //       }

    //     })

    //     .catch((error) => {
    //       console.log(error, 'erroroor.....')
    //     })

    // },

    // fetchData() {

    //   console.log(userData, 'respomsedrespomsedr.......')
    //   Object.keys(userData).forEach((icao) => {
    //     const airport = userData[icao];

    //     if (airport.iata !== '') {
    //       this.fromAirports.push({
    //         iata: airport.iata,
    //         icao: airport.icao,
    //         places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
    //       });
    //     }
    //   });
    // },

    // autocompletJson() {
    //   axios.get("https://metadls.example.com/cdn/resources/get.php?doc=airports_icao.json", {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //     .then((response) => {

    //       let airlineIcao = response.data
    //       // console.log(airlineIcao, 'respomsedrespomsedr.......')

    //       if(airlineIcao){
    //         // console.log(airlineIcao, 'respomsedrespomsedr.......')
    //         Object.keys(airlineIcao).forEach((icao) => {
    //         const airport = airlineIcao[icao];

    //         if (airport.iata !== '') {
    //           this.fromAirports.push({
    //             iata: airport.iata,
    //             icao: airport.icao,
    //             places:` ${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
    //           });
    //         }
    //       });
    //       }

    //     })

    //     .catch((error) => {
    //       console.log(error, 'erroroor.....')
    //     })

    // },

    async search(event) {
      console.log(event, "searchsearchsearchsearch");

      if (event.query.length >= 1) {
        this.formres = false;
        if (event.query.length >= 1) {
          this.formres1 = false;
        }
      }

      this.fromAirports = [];

      if (event.query.length >= 3) {
        await axios.get(`${this.autocompleteApi}?airportString=${event.query}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response, "response");

            let airlineIcao = response.data;
            if (airlineIcao) {
              Object.keys(airlineIcao).forEach((icao) => {
                const airport = airlineIcao[icao];

                if (airport.iata !== "") {
                  this.fromAirports.push({
                    iata: airport.iata,
                    icao: airport.icao,
                    places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
                  });
                }
              });
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
        const searchString = event.query.toUpperCase();
        // console.log(searchString,'searchStringsearchString')
        setTimeout(() => {
          const iataMatch = [];
          const otherMatches = [];
          this.fromAirports.forEach((e) => {
            const iata = e.iata ? e.iata.toUpperCase() : "";
            const placesLowerCase = e.places.toLowerCase();
            if (iata === searchString) {
              iataMatch.push(e);
              // console.log(iataMatch,'iataMatchiataMatch')
            } else if (placesLowerCase.includes(searchString.toLowerCase())) {
              otherMatches.push(e);
              //  console.log(otherMatches,'otherMatches')
            }
          });

          this.airitems = iataMatch.concat(otherMatches);
          this.loading = false;
        }, 1000);
      } else {
        if (this.oneway.from) {
          this.maximumletter = true;
          // this.maximumletter1 = false;
        }
        if (this.oneway.to) {
          this.maximumletter1 = true;
          this.maximumletter = false;
        }
      }
    },
     getdated($event) {
      console.log($event,'eventeventevent');
      return moment($event).format("MMM DD, YYYY");
    },
    getdated1($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated2($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated3($event) {
      return moment($event).format("MMM DD, YYYY");
    },

    focus1() {
      if (!this.oneway.to) {
        // console.log('testueeiekek.....1')
        setTimeout(() => {
          this.$refs.secondAutoComplete.$el.querySelector("input").focus();
          this.focusEvent2 = true;
        }, 100);
      } else {
        this.focusEvent2 = false;
        // console.log('testueeiekek.....2')
        setTimeout(() => {
          this.$refs.fromAutoComplete.$el.querySelector("input").blur();
        }, 100);
      }
    },

    focus2() {
      if (!this.oneway.dedate || !this.oneway.travelDate) {
        setTimeout(() => {
          this.focusEvent3 = true;
          if (this.$refs.dpRef1) {
            this.$refs.dpRef1.openMenu();
          }
        }, 100);
      } else {
        this.focusEvent3 = false;

        setTimeout(() => {
          this.$refs.secondAutoComplete.$el.querySelector("input").blur();
        }, 100);
      }
    },

    focus3() {
      setTimeout(() => {
        this.$refs.onewayTo.$el.querySelector("input").focus();
      }, 10);
    },

    focus4() {
      this.$refs.dpRef3.openMenu();
    },

    focus5(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index];
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    focus6(index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }
      }, 10);
    },

    handleResize() {
      if (window.innerWidth <= 480) {
        // console.log(this.multiCalender,'multmiikmhuihuu...1111')
        this.multiCalender = false;
      } else {
        // console.log(this.multiCalender,'multmiikmhuihuu...2222')

        this.multiCalender = true;
      }
    },


    getservicecontent(){
      console.log(this.getServicedata, 'getServicedatagetServicedata')
      axios.get("https://booking.traveloplex.com/api/top_destinations?domain=traveloplex.ca&language=es&content=bestservice123", {
        headers: {
          "Content-Type": "text/html; charset=utf-8",
        },
      })
      .then((response) =>{
        if(!response.data.error){
          this.getServicedata = response.data;
          console.log(this.getServicedata, 'getServicedatagetServicedata...1')
        }
        else{
          this.getServicedata = servicedata; 
          console.log(this.getServicedata, 'getServicedatagetServicedata...2')
        }
      
      })
      .catch((error) =>{
        this.getServicedata = servicedata;
        console.log(error, 'erroroor.....')
      })
    },


    gettopdestinationcontent(){
     console.log(this.getTopdestinationdata, 'getTopdestinationdatagetTopdestinationdata')
     axios.get("https://booking.traveloplex.com/api/top_destinations?domain=traveloplex.ca&language=es&content=topdestinations123", {
               headers: {
                   "Content-Type": "text/html; charset=utf-8",
               },
           })
               .then((response) => {
                if(!response.data.error){
            this.getTopdestinationdata = response.data;
            console.log(this.getTopdestinationdata, 'getTopdestinationdatagetTopdestinationdata..0')
           }
           else{
            this.getTopdestinationdata = topdestinationsdata;
            console.log(this.getTopdestinationdata, 'getTopdestinationdatagetTopdestinationdata..1')
           }
               })

               .catch((error) => {
                 this.getTopdestinationdata = topdestinationsdata;
                   console.log(error, 'erroroor.....')
               })
   },

   gettrendingplacescontent(){
    
     console.log(this.getTrendingplacesdata, 'getTrendingplacesdatagetTrendingplacesdata')
      axios.get("https://booking.traveloplex.com/api/top_destinations?domain=traveloplex.ca&language=es&content=trendingplaces123", {
                headers: {
                    "Content-Type": "text/html; charset=utf-8",
               },
            })
                .then((response) => {
                  if(!response.data.error){
                    this.getTrendingplacesdata = response.data;
                    console.log(this.getTrendingplacesdata, 'getTrendingplacesdatagetTrendingplacesdata..0')
                  }
                  else{
                    this.getTrendingplacesdata = trendingplacesdata;
                    console.log(this.getTrendingplacesdata, 'getTrendingplacesdatagetTrendingplacesdata..1')
                  }
                })
               .catch((error) => {
                this.getTrendingplacesdata = trendingplacesdata;
                   console.log(error, 'erroroor.....')
               })
   },


   loadSearchData(){
      const tabId = sessionStorage.getItem("tabId");
      const tripType = this.$route.query.tripType;
      // console.log(this.searchCase,'storedDatasdasdasdas...1')

      let key = '';

      if (tripType === 'RT' || tripType === 'OW' ) {
        key = tripType === 'RT'? `searchedData-round-${tabId}` : `searchedData-oneway-${tabId}`;
        this.Oneway = tripType === 'OW';
        this.roundTrip = tripType === 'RT';

        this.activetrip = tripType === 'RT' ? 'Roundtrip' : 'Oneway';
        console.log(key,'storedDatasdasdasdas...2');
      }
      else if (tripType === 'MC') {
        key = `searchedData-multicity-${tabId}`;
        this.multicity = true;
        this.activetrip = 'Multicity';
        console.log(key,'storedDatasdasdasdas...3');
      }

      console.log(this.activetrip,'sdasdactivetripasdad');
      this.activeType(this.activetrip);

      const storedData = localStorage.getItem(key);
      console.log(storedData,'storedDatasdasdasdas...4')


      if (storedData) {
        if (this.multicity) {
          this.multiData = JSON.parse(storedData) || this.searchCase;
          console.log(storedData,'storedDatasdasdasdas...44');
          console.log(this.searchCase,'storedDatasdasdasdas...55');
          console.log(this.multiData,'storedDatasdasdasdas...5');
        } else {
          this.searchData = JSON.parse(storedData) || this.searchCase;
          console.log(this.searchData,'storedDatasdasdasdas...6');
          
          this.oneway = this.searchData;
          this.inputCity = this.searchData.from;
          this.inputCityto = this.searchData.to;
        console.log(this.searchData,'storedDatasdasdasdas...7');
    }
    
        this.classType = this.searchData ? this.searchData.class : this.multiData.class;
        console.log(this.classType,'asdasdclagetClasTypessTypeasdasda');

        // if(getClassType == "Economy"){
        //     this.classType = "Economy";
        // }
        // if(getClassType == "Business"){
        //     this.classType = "Business";
        // }
        // if(getClassType == "First Class"){
        //     this.classType = "FirstClass";
        // }
        // if(getClassType == "Premium Economy"){
        //     this.classType = "PremiumEconomy";
        // }
        // if(getClassType == "Premium Business"){
        //     this.classType = "Premium Business";
        // }
        // if(getClassType == "Premium First"){
        //     this.classType = "Premium First";
        // }

        // console.log(this.classType,'asdasdclassTypeasdasda');

        this.adultValue = this.searchData ? parseInt(this.searchData.adult) : parseInt(this.multiData.adult);
        this.childValue = this.searchData ? parseInt(this.searchData.child) : parseInt(this.multiData.child);
        this.infantValue = this.searchData ? parseInt(this.searchData.infant) : parseInt(this.multiData.infant);
        this.passengerData();
        this.addedPassenger();

      } else {
        console.log('No search data found for this tab.');
      }

    },
   


    
    getConfig() {
      const getConfigData = getConfigDataFromLocalStorage();

      console.log(getConfigData, 'maldivesmaldivesmaldives');


      if (getConfigData) {
        let pluginWhats = "";
        pluginWhats =
          getConfigData.payload.portal_configuration.menus.enabled.plugins;
          this.subscribeApi = getConfigData.payload.portal_configuration.API_endpoints.subscriber_form;
          this.autocompleteApi = getConfigData.payload.portal_configuration.API_endpoints.autocomplete;
          this.emailPortal = getConfigData.payload.portal_configuration.email;

          this.getservicecontent();
          this.gettopdestinationcontent();
          this.gettrendingplacescontent();


        // tagLineCaption
        // console.log(pluginWhats, 'dasdasdasda..1..1..1.1')
        if (pluginWhats) {
          this.pluginWhatspp = true;
          this.whatsappApi =
            getConfigData.payload.portal_configuration.API_endpoints.whatsapp;
        } else {
          this.pluginWhatspp = false;
        }
      }
    },

    autoDate() {
      this.oneway.travelDate = [];
      let value1 = new Date();
      let value2 = new Date(new Date().setDate(value1.getDate() + 7));

      this.oneway.travelDate.push(value1, value2);

      if (this.oneway.travelDate == 2) {
        this.oneway.dedate = this.oneway.travelDate[0];
        this.oneway.redate = this.oneway.travelDate[1];
      }
      // console.log(this.oneway.travelDate, 'creaaadddddddd....')
    },

    // appendIcon(data) {
    //   return data ? "mdi-check-underline"
    //     : "mdi-credit-card-outline";
    // },
    // iconColor(index) {
    //   if (this.billingDetails.cardDetails[index].cardnumber == "") return "";
    //   return this.result[index].isValid ? "green" : "red";
    // },

    getadvanceOption(){
      // console.log(this.searchCase,'sadsdasdasdsearchCasea...1');
      this.localSearchCase = JSON.parse(this.searchCase);

      if (this.localSearchCase && this.localSearchCase.advanceOptions) {
        let getSearchedData = this.localSearchCase.advanceOptions;

        this.baggageInclusive = getSearchedData.baggageInclusive ? true : false;
        this.nearbyAirport = getSearchedData.nearbyAirport ? true : false;
        this.stopsSelect = getSearchedData.stopsSelect ? getSearchedData.stopsSelect : 'anyStop';
        this.directFlight = getSearchedData.stopsSelect ? getSearchedData.stopsSelect == '0' : false;
        this.adlayoverTime = getSearchedData.adlayoverTime ? getSearchedData.adlayoverTime : 'noPreference';
        this.avoidCountry = getSearchedData.avoidCountry ? [getSearchedData.avoidCountry] : [];
        this.airlineSelect = (getSearchedData.includeAirline) ? 'includeAirline' : (getSearchedData.excludeAirline) ? 'excludeAirline' : 'includeAirline';
        this.adviaAirport = getSearchedData.includeAirport ? 'includeAirport' : (getSearchedData.excludeAirport) ? 'excludeAirport' : 'includeAirport';
        this.selectedAirline = (getSearchedData.includeAirline) ? getSearchedData.includeAirline : (getSearchedData.excludeAirline) ? getSearchedData.excludeAirline : [];
        this.selectedAirport = (getSearchedData.includeAirport) ? getSearchedData.includeAirport : (getSearchedData.excludeAirport) ? getSearchedData.excludeAirport : [];
        }
      },


      handleOptionChange(optionKey, newValue) {
          const removableValues = ["includeAirline", "excludeAirline","includeAirport", "excludeAirport","anyStop",'0', "noPreference"];

        const isRemovable = !newValue || (Array.isArray(newValue) && newValue.length === 0);
        const isSpecialOption = removableValues.includes(newValue);

        if (isRemovable || isSpecialOption) {
          this.getallAdvanceOptions = this.getallAdvanceOptions.filter(option => !Object.prototype.hasOwnProperty.call(option, optionKey));
          return;
        }

        const existingIndex = this.getallAdvanceOptions.findIndex(option => 
          Object.prototype.hasOwnProperty.call(option, optionKey)
        );

        if (newValue && newValue.length !== 0) {
          if (existingIndex !== -1) {
            this.getallAdvanceOptions[existingIndex][optionKey] = newValue;
          } else {
            this.getallAdvanceOptions.push({ [optionKey]: newValue });
          }
        } else {
          if (existingIndex !== -1) {
            this.getallAdvanceOptions.splice(existingIndex, 1);
          }
        }
        console.log(this.getallAdvanceOptions,'getallAsadvancsadaeOptionssdasdasda');
      }

  },

  // AutoComplete Functions End
  watch: {
    directFlight(newValue) {
      if(newValue) {
        this.stopsSelect = '0';
      }
      else if(!newValue && this.stopsSelect == '0'){
        this.stopsSelect = 'anyStop';
      }
      else if(!newValue && (this.stopsSelect !== 'anyStop' || this.stopsSelect !== '0')){
        return this.stopsSelect;
      }
      else{
        this.stopsSelect = 'anyStop';
      }
    },

    stopsSelect(newValue) {
      this.directFlight = newValue == '0' ? true : false;
          
      if(!this.directFlight && (newValue !== 'anyStop' || newValue !== '0')){
        this.stopsSelect = newValue;
      }
      if(newValue !== 'anyStop' || newValue !== '0') this.handleOptionChange('stopsSelect', newValue);
    },
    adlayoverTime(newValue) {
      this.handleOptionChange('adlayoverTime', newValue);
    },
    avoidCountry(newValue) {
      this.handleOptionChange('avoidCountry', newValue);
    },


    airlineSelect(newValue) {
      this.handleOptionChange('airlineSelect', newValue);
    },
    adviaAirport(newValue) {
      this.handleOptionChange('adviaAirport', newValue);
    },
    selectedAirline(newValue) {
      this.handleOptionChange('selectedAirline', newValue);
    },
    selectedAirport(newValue) {
      this.handleOptionChange('selectedAirport', newValue);
    },
  

    "$i18n.locale": {
      handler: function () {
        this.service = this.getService();
        // this.flightDeals = this.getflightDeals();

        this.classTypeitems = this.getClassType();

        // console.log(this.$i18n.locale, "getegeteegtegete...9");
      },
      deep: true,
    },

    //     'oneway.from'(newVal) {
    //    this.oneway.from="yyz"
    //       console.log(newVal, 'ppppvvsd')

    // },

    "oneway.class"(newSelectValue) {
      // console.log('select property changed to:', newSelectValue);

      if (newSelectValue) {
        // setTimeout(() => {
        this.$refs.countrySelect.focus();
        this.classSelect = false;
        // console.log(this.classSelect)
        //  }, 100);
      }
    },

    "oneway.travelDate"(value) {
      if (value && value.length <= 2) {
        this.oneway.dedate = value[0];
        this.oneway.redate = value[1];

        this.focusEvent3 = false;
      } else {
        this.oneway.travelDate = null;
      }
    },

    // "oneway.dedate"(value) {
    //   if (value) {
    //     this.focusEvent3 = false;
    //   } else {
    //     this.oneway.dedate = null;
    //   }
    // },
     'oneway.dedate'(newDepDate) {
    if (this.oneway.redate && this.oneway.redate < newDepDate) {
      this.oneway.redate = null;
      this.toDateError = true;
    }
  },
  'oneway.redate'(newRetDate) {
    if (newRetDate && newRetDate < this.oneway.dedate) {
      this.toDateError = true;
    } else {
      this.toDateError = false;
    }
  }
  },

  // AutoComplete Functions
  computed: {
    filteredCountries() {
      return this.airitems.map((item) => {
        const parts = item.places.split(", ");
        if (parts.length >= 2) {
          const name = `${parts[1]} (${parts[0]})`;
          // const name = `${parts[0]} - ${parts[1]}`;
          const originalName = item.places;
          return {
            name,
            originalName,
          };
        }
      });
    },
    // AutoComplete Functions End

    activeAdvanceSearch() {
        const hasAvoidCountry = Array.isArray(this.avoidCountry) && this.avoidCountry.length > 0;
        const hasSelectedAirline = Array.isArray(this.selectedAirline) && this.selectedAirline.length > 0;
        const hasSelectedAirport = Array.isArray(this.selectedAirport) && this.selectedAirport.length > 0;

        // Ensure stopsSelect is treated correctly based on its type
        const isStopValid = 
          (Array.isArray(this.stopsSelect) && this.stopsSelect.length > 0 && 
          (this.stopsSelect[0] !== 'anyStop')) || 
          (this.stopsSelect !== 'anyStop' && this.stopsSelect);

        const hasAdlayoverTime = this.adlayoverTime && this.adlayoverTime !== 'noPreference';

        if (
          this.directFlight || 
          this.baggageInclusive || 
          this.nearbyAirport || 
          hasAvoidCountry || 
          isStopValid || 
          hasAdlayoverTime || 
          hasSelectedAirline || 
          hasSelectedAirport
        ) {
          console.log(this.directFlight,this.baggageInclusive,this.nearbyAirport,hasAvoidCountry,isStopValid,hasAdlayoverTime,
            hasSelectedAirline,hasSelectedAirport,'sadsdasdasdsearchCasea...2');
          // console.log(this.directFlight,this.baggageInclusive,this.nearbyAirport,this.airlineSelect,this.stopsSelect,this.adlayoverTime,
          //   this.adviaAirport,this.selectedAirline,this.selectedAirport,this.avoidCountry,'sadsdasdasdsearchCasea...2');
          return true;
        } else {
          console.log(this.directFlight,this.baggageInclusive,this.nearbyAirport,hasAvoidCountry,isStopValid,hasAdlayoverTime,
            hasSelectedAirline,hasSelectedAirport,'sadsdasdasdsearchCasea...3');
          // console.log(this.directFlight,this.baggageInclusive,this.nearbyAirport,this.airlineSelect,this.stopsSelect,this.adlayoverTime,
          //   this.adviaAirport,this.selectedAirline,this.selectedAirport,this.avoidCountry,'sadsdasdasdsearchCasea...3');
          delete this.oneway.advanceOptions; // Make sure `this.oneway` is defined in your data
          delete this.multiData.advanceOptions; // Make sure `this.oneway` is defined in your data
          return false;
        }
      // if (
      //   this.directFlight || this.baggageInclusive || this.nearbyAirport || this.avoidCountry.length > 0 ||
      //   (this.stopsSelect || this.stopsSelect.length > 0 && (this.stopsSelect !== 'anyStop' || !this.stopsSelect.includes('anyStop'))) ||
      //   this.adlayoverTime && this.adlayoverTime !== 'noPreference' ||
      //   this.selectedAirline.length > 0 || this.selectedAirport.length > 0
      // ) {
      //   console.log(this.directFlight,this.baggageInclusive,this.nearbyAirport,this.airlineSelect,
      //     this.stopsSelect,this.adlayoverTime,this.adviaAirport,this.selectedAirline,this.selectedAirport,
      //     this.avoidCountry,'sadsdasdasdsearchCasea...2');
      //   return true;
      // } else {
      //   delete this.oneway.advanceOptions;
      //   return false;
      // }
    },
  },

  created() {
    localStorage.removeItem("delete_Id");
    this.getConfig();
    // this.autoDate();
    // this.autocompletJson();
    window.addEventListener("resize", this.handleResize);

    console.log(this.$i18n.locale, "getegeteegtegete...8");

  },

  mounted() {
    // this.generateRandomAlphabets();
    // this.fetchData();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    document.body.addEventListener("click", this.handleOutsideClick);
    this.recentFromData();
    this.loadSearchData();
    
    console.log(this.$i18n.locale, "getegeteegtegete...7");

    if(this.searchCase){
      this.getadvanceOption();
    }

    this.subscribeMenu = true;
    this.generateCaptcha();
    this.generateRandomAlphabets();


    //
    // loginService.getAllUser().then(res => {
    //   this.customer = res.data
    //   //console.log(this.customer, '44444')
    // })
    //console.log(this.oneway, 'llllllllllllllll')
  },
};
</script>

<style scoped>
.v-select .v-field .v-text-field__prefix,
.v-select .v-field .v-text-field__suffix,
.v-select .v-field .v-field__input,
.v-select .v-field.v-field {
  cursor: pointer;
  height: 41px;
  box-shadow: none !important;
}

body {
  zoom: 0.9;
  -ms-zoom: 0.9;
  -webkit-zoom: 0.9;
  -moz-transform: scale(0.9, 0.9);
  -moz-transform-origin: top;
}

/* >>>.v-overlay .v-overlay__content {
    border-radius: 20px !important; 
} */

.f-size-24 {
  font-size: 24px;
}
.f-size-20 {
  font-size: 20px;
}

.f-size-16 {
  font-size: 16px;
}
.f-size-15 {
  font-size: 15px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

>>> .captcha_section .v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  position: relative !important;
  padding: 0px 12px !important;
  /* top: -6px !important; */
}

>>> .captcha_section
  .v-input--density-default
  .v-field--variant-solo-filled
  .v-label.v-field-label--floating {
  top: 8px;
  margin: 0px 7px;
  font-size: 10px;
}

>>> .captcha_section .v-field--center-affix .v-label.v-field-label {
  font-size: 11px;
  top: 50%;
  transform: translateY(-50%);
}

>>> .captcha_section .v-field__input {
  padding: 0px 6px;
}

#visible {
  visibility: hidden;
}

>>> .p-autocomplete-input::placeholder {
  color: #9ca3af70 !important;
  /* Set the desired color for the placeholder text */
}

.container-styles {
  border-radius: 20px;
}

:deep(.v-select .v-field){
  cursor: pointer;
  box-shadow: none;
  align-items: center;
}

>>> .v-overlay__content {
  outline: none;
  position: absolute;
  pointer-events: auto;
  contain: layout;
}

.recent_style {
  position: absolute;
  background: white;
  width: 260px;
  margin: 12px 0px;
  border-radius: 20px;
  z-index: 5;
  border: 1px solid gray;
  display: none !important;
}

/* >>>.v-selection-control__input {
  bottom: 6px;
} */

>>> .subscribe-inputFields .v-input__details {
  display: block !important;
  position: relative;
  top: 5px;
}

>>> .subscribe-inputFields .v-input__details .v-messages__message {
  font-size: 10px !important;
}

>>> .input-styles-2 .v-select .v-select__selection-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  color: grey !important;
}

>>> .input-styles-3 .v-select .v-select__selection-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  color: grey !important;
}

>>>.v-select .v-select__selection{
  margin-top:0px;
}

.v-selection-control__wrapper {
  width: 30px;
  max-height: 23px;
}

.login-card {
  position: absolute;
  z-index: 2;
}

/* Datepicker Style Section */

.dp__icon {
  display: block;
}

/* .v-icon {
   width: 100%;
 } */

>>> .dp__input {
  border: none;
  /* border-bottom: 1px solid lightgrey !important; */
  border-radius: 0px !important;
  padding: 0px;
  height: auto;
  /* font-size: 16px;
  font-weight: 600; */
}

/* .mdi-calendar {
   margin-right: 10px;
   width: auto;
   color: grey;
 } */

.calendar-container {
  display: flex;
  align-items: center;
  position: relative;
}

.label-top {
  display: none;
}

.datepicker-label {
  position: absolute;
  top: 22px;
  left: -8px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  color: gray;
  pointer-events: none;
  z-index: 1;
  font-size: 14px;
  font-weight: 500;
  color: #242424;
}

.multiLabel-top {
  position: absolute;
  right: 87px;
  transform: translateY(-100%);
  background-color: white;
  padding: 0 8px;
  font-size: 15px;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.multiDatepicker-label {
  position: absolute;
  right: 69px;
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
  font-weight: 600;
}

.delete-icon {
  display: flex;
  justify-content: end;
  position: absolute;
  left: 12px;
  z-index: -1;
}

/* >>>.dp__clear_icon {
   top: 95% !important;
 } */

/* Datepicker Style Section End */

.dp__pointer {
  cursor: pointer;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0px;
  width: 100% !important;
  border-radius: 50% !important;
  margin: 8px !important;
}

>>>.datepicker input::placeholder{
    color: rgba(0, 0, 0, 0.8) !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    font-family:inherit !important;
  /* margin-bottom:4px; */
}
>>>.datepicker input{
  font-weight:700 !important;
}
/* >>>.datepicker .dp__icon{
  display:block;
} */
>>> .dp__input_wrap {
  position: relative;
  width: 100% !important;
  box-sizing: unset;
  top: 0px;
  left: 0px !important;
}

/* >>> .dp__input {
  background-color: #fff0;
} */

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.form-btn {
  padding-left: 40px;
  color: white;
}

.btn-box {
  background-color: transparent;
  margin: 5px;
  padding: 2px;
  box-shadow: none;
  font-size: 15px !important;
}

.btn-active {
  background-color: #ff5400;
  color: white;
}

.v-checkbox .v-input__details {
  display: none;
}

/* Login Page End */

/* Login Remember Btn */

/* .v-selection-control {
  align-items: start;
} */

.v-label {
  align-items: start;
}

.labeling1 {
  position: relative;
  /* bottom: -6px; */
  top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: black;
  left: 30px;
  z-index: 2;
}

.labeling {
  position: relative;
  /* bottom: -6px; */
  top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(51, 6, 148);
  left: 30px;
  z-index: 2;
}

.labeling:focus {
  color: red !important;
}

/* .back {
   background-image: url('../assets/bg.png');
   background-repeat: no-repeat;
   background-size: 800px;
   background-position: top right 24%;
   z-index: 1;
   border-radius: 20px;
 } */

body {
  font-family: var(--normal-font);
}

/* navbar */
.logo {
  width: 180px;
}

.nav-link {
  font-weight: var(--font-600);
  font-size: var(--font-14);
}

.nav-link.active {
  color: var(--primary-color) !important;
}

.btn-white {
  background-color: white;
  padding: 10px 25px;
  border-radius: 15px;
}

.btn-text {
  color: var(--primary-color);
  font-weight: var(--font-700);
}

>>> .search-btn .v-btn__content {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1;
  color: white;
}

/* .v-btn__content {
   font-size: 17px;
   font-weight: 600;
   text-transform: capitalize;
 } */

.book-ticket {
  /* color: #FF5400; */
  background-color: #f0f0f0;
}

.whatts-logo {
  display: block;
  position: fixed;
  cursor: pointer;
  right: 2px;
  bottom: 6px;
  z-index: 9999;
  width: 60px;
  height: 60px;
}

/* .whatts-logo {
   display: block;
   position: fixed;
   cursor: pointer;
   right: 30px;
   bottom: 40px;
   z-index: 9999;
 } */

/* navbar end */

/*Flight banner */
.banner-left-small-title {
  color: var(--primary-color) !important;
}

/* .bannar-bg {
   background-image: url('../assets/bg-map.png');
   background-size: 55% 83%;
   background-repeat: no-repeat;
   background-position: center;
 } */

.flight-right {
  width: 500px !important;
  height: 500px;
}

.banner-left-small-title h6 {
  font-weight: var(--font-600);
}

.banner-small-text {
  color: var(--primary-color) !important;
}

.banner-left-title h4 {
  font-family: var(--title-font);
  font-weight: var(--font-600);
  font-size: 60px;
}

.location {
  color: #ff5400;
  width: 22px;
}

.form-control {
  border: none;
  border-bottom: 1px solid lightgrey;
  border-radius: 0;
  background-color: unset !important;
  /* width: 150px; */
}

.form-control:focus {
  color: unset !important;
  border-color: unset !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-select {
  color: unset !important;
  /* border-color:unset !important; */
  outline: none !important;
  box-shadow: none !important;
  border: none;
  border-bottom: 1px solid lightgrey;
  border-radius: 0;
  /* width: 150px; */
}

.form-select:focus {
  border-color: unset !important;
}

.form-control::placeholder {
  font-size: 14px;
  font-weight: var(--font-500);
}

.input-group-text {
  text-align: center;
  white-space: nowrap;
  border: none;
}

.form-location {
  width: 18px;
  margin-top: -15px;
}

.btn {
  background-color: #ff5400;
  color: #fff;
}

.btn:hover {
  border: 1px solid #ff5400;
  color: #ff5400;
}

/* banner end */

/* Explore Top Destination */

.top-destination {
  color: #ff5400;
  font-size: 14px;
}

.heading {
  /* font-family: var(--title-font);
   font-weight: var(--font-600); */
  font-weight: 400;
  color: #3c0ec2;
}

.card.top-card {
  height: 330px;
  border-radius: 25px;
}

.card.top-card .card-tour {
  height: 220px;
  width: auto;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

/* .icon-container {
    margin-right: 10px;
  } */

.calendar-wrapper {
  display: inline-block;
  border-bottom: 1px solid lightgray;
}

.calendar-label {
  display: block;
  margin-bottom: 5px;
}

.card-body .star {
  color: #ff5400;
  margin-top: 20px;
}

.card-body .rate {
  color: #ff5400;
}

.how-we-work {
  color: #ff5400;
  font-weight: 600;
  font-size: 14px;
}

.how-we-section {
  margin-top: 100px;
}

.choose {
  width: 40px;
}

.choose-item {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  width: 75px;
  height: 75px;
  border-radius: 10px;
}

.choose-para {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.car-right-wrapper {
  width: 700px;
  height: 700px;
}

.lorem {
  font-size: 11px;
}

.session-bg {
  background-image: url("../assets/bg1.png");
  background-repeat: no-repeat;
  background-position: top 100px right -290%;
}

/* .travel-type {
   background-color: #fff7ed;
 } */

.trvel-para {
  color: #ff5400;
  font-weight: 600;
}

.owl-theme .owl-nav [class*="owl-"] {
  border: 2px solid darkturquoise;
  padding: 15px !important;
  border-radius: 50% !important;
  background-color: rgb(243, 216, 207) !important;
  width: 50px;
  margin-right: 20px;
  color: darkturquoise;
}

.owl-images img {
  height: 350px;
  border-radius: 30px;
}

.owl-theme .item {
  width: 330px;
}

.owl-images {
  position: relative;
}

.badge {
  position: absolute;
  top: 40px;
  left: 15px;
  background-color: #162849;
  color: #fff;
  border-radius: 30px;
}

.testi-para {
  color: #ff5400;
  font-weight: 600;
}

.testi-div {
  margin-top: 100px;
}

.card img {
  width: 100%;
  height: 100%;
}

.test-name {
  font-size: 18px;
  font-weight: 400;
}

.card-text {
  font-size: 14px;
}

.card {
  height: 250px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  border: none;
  border-radius: 25px;
}

.card-relative {
  position: relative;
}

.quotes {
  position: absolute;
  top: -35px;
}

/* Subscribe Section */

.subscribe {
  /* background-color: navy !important; */
  background-color: #019df2 !important;
  color: white;
  border-radius: 14px;
  line-height: 28px;
}

input {
  outline: none;
}

:deep(.v-switch__track) {
  height: 12px;
  width: 24px;
}

:deep(.v-switch__thumb) {
  width: 16px;
  height: 16px;
}

.subscribe-input {
  border-radius: 17px;
  border: none;
  padding: 15px 20px;
  background-color: #fff;
}

/* >>> .dp__input {
  font-family: "Poppins", sans-serif !important;
} */

.rounded-shadow {
  box-shadow: 0px 0px 4px 0px #162849;
}

>>> .subscribe-inputFields .v-input__control {
  display: flex;
  grid-area: control;
  height: 20px !important;
}

>>> .subscribe-inputFields .v-field__input {
  padding-top: 0;
}

>>> .subscribe-inputFields .v-field__field {
  top: -3px;
}

/* >>>.v-field__field {
   height: 40px;
 } */

/* >>> .v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  opacity: 1;
  position: relative;
  top: -5px;
} */

>>> .subscribe-inputFields .v-input--density-default .v-field--variant-solo,
.v-input--density-default .v-field--variant-solo-inverted,
.v-input--density-default .v-field--variant-solo-filled,
.v-input--density-default .v-field--variant-filled {
  --v-input-control-height: 50px;
  --v-field-padding-bottom: -7px;
}

>>> .subscribe-inputFields .v-field--variant-solo {
  box-shadow: none;
}

>>> .subscribe-inputFields
  .v-input--density-default
  .v-field--variant-solo
  .v-label.v-field-label--floating,
.v-input--density-default
  .v-field--variant-solo-inverted
  .v-label.v-field-label--floating,
.v-input--density-default
  .v-field--variant-filled
  .v-label.v-field-label--floating,
.v-input--density-default
  .v-field--variant-solo-filled
  .v-label.v-field-label--floating {
  top: -1px;
}

/* .subscribe-btn {
   border-radius: 5px;
   border: none;
   width: 200px;
   height: 35px;
   background-color: #162849 !important;
   color: white !important;
 } */

.subscribe-button {
  min-width: 0px !important;
  width: 40px;
  height: 40px;
  font-size: 16px;
  display: inline-block;
  border-radius: 50%;
  padding: 0px;
  background-color: #162849 !important;
  color: white !important;
}

.subscribe-inputFields {
  border: 1px solid lightgray;
  border-radius: 32px;
}

.subscribe-inputFields .v-field--rounded {
  border-radius: 9999px 0px 0px 9999px;
  box-shadow: none;
}

.subscribe-inputFields .v-field--variant-solo {
  box-shadow: none;
}

>>> .v-input__details {
  display: none;
}

>>> .dp__overlay_col[aria-disabled="true"] {
  display: none;
}

.email-error {
  position: absolute;
  margin-top: 20px !important;
  margin-bottom: -25px;
  margin-right: 65px;
}

.agree-error {
  position: absolute;
  margin-top: 40px;
  margin-left: 40px;
  font-size: 14px;
}

/* Subscribe Section End */

.form-input {
  border: none;
}

.btn-badge {
  background-color: aqua;
  border: none;
  border-radius: 40px;
}

.btn-badge:hover {
  background-color: aqua;
  border: none;
  color: black;
}

.py-8 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.social-icons {
  color: var(--primary-color) !important;
}

.fs-14 {
  font-size: 14px;
  list-style-type: none;
}

.fs-14 li {
  padding: 6px;
}

section {
  margin: auto !important;
}

.slide-card {
  border-radius: 4%;
  width: 270px;
  height: 315px;
}
.top-card {
  border-radius: 4%;
  width: auto;
  height: 400px;
}
>>> .bg-grey-lighten-1 {
  background-color: white !important;
}
.card-test {
  position: relative;
  padding: 10px 15px;
  border-radius: 8%;
  max-height: 500px;
}

.card-img {
  position: absolute;
  width: 100px;
  height: 100px;
  top: -50%;
  right: 0;
  transform: translateY(-50%);
}

.radio {
  border-radius: 5px;
  /* background-color: #162849; */
  background-color: #3c0ec2;
  color: white;
  /* font-size: 12px !important;
   font-weight: 500; */
  box-shadow: 0 4px 16px rgba(17, 17, 26, 0.05),
    0 8px 32px rgba(17, 17, 26, 0.05) !important;
}

.v-input--density-default {
  --v-input-control-height: 38px;
  /* position: relative;
  top: 0px; */
}

.normal-radio {
  font-size: 10px !important;
  font-weight: 400px;
  height: 28px;
  width: auto;
  /* max-width: 100px; */
  padding-right: 10px;
  padding-right: 9px;
  border: 2px solid white;
  /* border: 1px solid #0000003d; */
  box-shadow: none;
}

.normal-radio span {
  font-size: 12px !important;
  font-weight: 500;
  /* color: black; */
}

.cart-adult {
  padding: 2px 15px 15px 15px;
  width: 214px;
  cursor: pointer;
  position: absolute;
  top: 131px;
  left: 252px;
}

.adult {
  height: 100%;
  border: 1px solid lightgrey;
  border-radius: 5px;
  /* background-color: #F7F7F7; */
}

.booknow {
  padding-right: 20px;
}

.adult-plus {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: white;
  /* color: #05386d; */
}

.adult-title {
  font-size: 12px;
  font-weight: 600;
  color: #05386d;
}

>>> .p-datepicker-group-container {
  background-color: #ffffff !important;
  width: 200px;
  padding: 5px 3px;
  border: 1px solid lightgray;
  position: relative;
  right: 14px;
}

.calendar-wrapper {
  border-bottom: 1px solid #a9a1a1;
  max-width: 500px;
}

.calendar-wrapper {
  border-bottom: 1px solid #a9a1a1;
  max-width: 100% !important;
}

>>> .p-datepicker {
  width: auto;
  background: white !important;
}

/* Flight Search Box */

.search-btn {
  width: 90px;
  display: inline-block;
  border-radius: 2rem !important;
  padding: 0px;
  background-color: #94c20e;
  box-shadow: none;
}

/* .search-btn span {
   font-size: 20px;
   font-weight: 600;
   text-transform: capitalize;
   margin: 10px;
 } */
.btn-multi {
  display: block;
}
.btn-multi1 {
  display: none;
}
.addmore-btn {
  display: none;
}

.formbottom_ClearBtn {
  /* border: 1px solid lightgray; */
  border-radius: 2rem;
  padding: 8px 12px;
  background-color: red;
  color: white;
}

.formbottom_addBtn {
  /* border: 1px solid lightgray; */
  border-radius: 2rem;
  padding: 8px 12px;
  background-color: #3e2294;
  color: white;
}

/* .search-btn {
   background-color: #162849;
   color: white !important;
   text-transform: capitalize;
 } */

/* .mdi-airplane-takeoff::before {
   content: "\F05D5";
   margin-top: 0px;
   margin-right: 10px;
   color: gray;
 }

 .mdi-airplane-landing::before {
   content: "\F05D4";
   margin-top: 0px;
   margin-right: 10px;
   color: gray;
 } */

>>> .dp__input_icon {
  display: none;
  top: 64%;
  left: -5% !important;
}

.p-component * {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
  /* padding: 2px;
  padding-top: 8px;
  padding-bottom: 8px; */
}
>>> .p-autocomplete {
  display: flex;
}

>>> .p-autocomplete-loader {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -0.5rem;
  left: 13 5px !important;
  display: block !important;
}

>>> .p-icon {
  display: none;
}

/* >>>.p-autocomplete-panel {
   color: white;
   border-radius: 10px;
 } */

.p-error {
  font-size: 12px;
  color: white;
  position: absolute;
  /* left: 0px;
  top: 24px; */
}

.p-error-from {
  font-size: 12px;
  color: white;
  /* position: absolute;
  bottom: -16px; */
}

.p-error-to {
  font-size: 12px;
  color: white;
  position: absolute;
  bottom: 1px;
}

.dropdown-box {
  padding: 8px 10px;
  /* border-radius: 10px; */
  color: white;
  background-color: lightslategray;
  border-bottom: 1px solid white;
}

.dropdown-box:hover {
  background-color: black;
  color: white;
  border-radius: 10px;
  /* background-color: lightgray;
  color: black; */
}

>>> .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
}

/* .p-float-label {
   width: 100%;
   font-size: 16px;
   color: #242424;
   font-weight: 600;
 } */

.p-float-label label {
  position: relative;
  pointer-events: none;
  top: -8px;
  left: -50px;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  color: #757575;
  font-size: 14px;
  font-weight: 600;
}

>>> .p-input-filled {
  box-shadow: 1px 1px 6px 0px black !important;
}

/* End Flight Search Box */

/* .flight-back{
  position: relative;
  top: -70px;
  background: white;
 } */

.background_section {
  background-image: url("https://dl.traveloplex.com/img/portalhomebg-min.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 80vh;
  width: 100%;
  /* background-image: url("../assets/trbg1.jpg"); */
  /* background-image:url('../assets/flight2.jpg'); */
  /* background-size: cover; */
  /* background-size: 100% 111%;
  background-position: center;
  background-position-y: bottom; */
}

/* .flight-back {
   background-image: url('../assets/travel-pattern-bg4.svg');
     background-repeat: no-repeat;
     background-attachment: fixed;
     background-size: cover;
     background-position-y: 70px;
   background-color:#e0e9f191;
   background: linear-gradient(90deg, rgba(148, 187, 233, 0.7512254901960784) 0%, rgba(238, 174, 202, 1) 50%, rgba(148, 187, 233, 0.7456232492997199) 100%);

 } */

/* .background_section{
  position: relative;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.background_video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
} */

.form-card {
  width: 100%;
  padding: 20px;
  overflow: unset;
  z-index: 1;
  cursor: pointer;
  background-color: #5a33ca;
  /* background-color: #5a33ca; */
  box-shadow: none !important;
  border-radius: 0px 20px 20px 20px !important;
}

.rounded-border {
  border-radius: 2rem !important;
}

.formshadow{
    box-shadow: 3px 3px 20px 0px black
}

.disable-return {
  pointer-events: none;
  opacity: 0.3;
}

/* .form-card {
   width: 100%;
   padding: 20px 0px 20px 20px;
   overflow: unset;
   z-index: 1;
   cursor: pointer;
   background-color: #ffffffe8;
   box-shadow: none !important;
   border-radius: 0px 0px 0px 20px !important;
 } */

.form-container {
  position: relative;
  /* top: 250px; */
  z-index: 99;
}

.banner-lg-text {
  font-size: 45px;
  font-weight: 700;
  -webkit-text-stroke: 1px white;
}

.banner-sm-text {
  font-size: 28px;
  font-weight: 600;
  -webkit-text-stroke: 1px white;
}

.paxSection_Mbl {
  display: none;
}
@media only screen and (max-wisth:768px){
  
}

@media only screen and (max-width: 480px) {
  .formbottom_addBtn {
    height: 24px;
    box-shadow: none;
    border-radius: 5px;
    box-shadow: none;
    min-width: 25px;
    padding: 0px;
  }
  >>> .formbottom_addBtn .v-btn__prepend {
    margin-inline-end: 0px;
    margin-inline-start: 0px;
  }
  >>> .formbottom_addBtn .v-btn__prepend .mdi-plus-circle::before {
    font-size: 17px;
  }
  .formbottom_ClearBtn {
    box-shadow: none;
    min-width: 25px;
    height: 24px;
    border-radius: 5px;
    padding: 0px;
  }
  >>> .formbottom_ClearBtn .v-btn__prepend {
    margin-inline-end: 0px !important;
    margin-inline-start: 0px !important;
  }

  .form-container {
    position: relative;
    top: 0px;
  }

  .rounded-border {
    border-radius: 1rem !important;
  }

  .banner-lg-text {
    font-size: 45px;
    font-weight: 500;
  }

  .banner-sm-text {
    font-size: 14px;
  }

  /* .mobile {
     display: none
   } */

  .flight1 {
    display: none !important;
  }

  /* .form-card {
     padding: 20px;
     border-radius: 0px 20px 20px 20px !important;
   } */

  .pax-fonts span {
    font-size: 12px !important;
    color: #242424;
    font-weight: 500;
  }

  .paxSection_Mbl {
    display: block;
  }

  .paxSection_Mbl .input-styles {
    height: 35px !important;
  }

  .paxSection_desktop {
    display: none;
  }

  /* .flight-back {
     background-image: url('../assets/flight.png');
     background-repeat: no-repeat;
     background-attachment: fixed;
     background-size: cover;
     background-position-y: 70px;

   } */

  /* .subcribe-card {
     margin-top: 10% !important
   } */
}

@media only screen and (max-width: 992px) {
  .car-right {
    width: 100%;
    height: auto;
    margin-top: 0;
  }

  .session-bg {
    background-image: none;
  }

  .back {
    background-image: none;
  }

  .flight-right {
    width: 80% !important;
  }

  .form-btn {
    padding: 10px 0px 0px 0px !important;
  }

  .btn-box,
  .pipe {
    color: blueviolet !important;
  }

  .btn-active {
    background-color: blueviolet;
    color: white !important;
  }

  .sub-text {
    font-size: 26px;
  }

  /* .subscribe-btn {
    width: 40%;
    height: 100%;
    font-size: 18px;
  } */

  .cart-adult {
    top: 113px !important;
    left: 275px !important;
  }

  .email-error {
    width: 100%;
  }

  .agree-error {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .owl-theme .owl-nav [class*="owl-"] {
    margin-right: 0 !important;
  }

  .owl-theme .item {
    width: auto;
    padding-right: 15px;
  }

  .cart-adult {
    top: 134px;
    left: 188px;
  }

  .email-error {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .addmore-btn {
    display: block;
    display: flex;
    align-items: center;
  }
  .btn-multi {
    display: none;
  }
  .btn-multi1 {
    display: block;
  }

  .rounded-border {
    border-radius: 1rem !important;
  }

  .owl-theme .owl-nav [class*="owl-"] {
    margin-right: 0 !important;
  }

  .owl-theme .item {
    width: auto;
  }

  .dropdown_section {
    width: auto !important;
  }

  /* .email-error {
    margin-right: 465px;
  } */
}

@media only screen and (max-width: 576px) {
  .cart-adult {
    top: 280px !important;
    left: 181px !important;
  }

  /* .email-error {
    margin-right: 240px;
  } */
}

.p-float-label::-webkit-scrollbar {
  width: 5px !important;
  background-color: navy !important;
}

.p-float-label::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}

.p-float-label::-webkit-scrollbar-thumb {
  background: lightgrey !important;
  border-radius: 10px !important;
}

/* Scroll Bar Work */

>>> .pv_id_2_list {
  overflow: auto !important;
  max-height: 100px !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar {
  width: 5px !important;
  background-color: navy !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar-thumb {
  background: lightgrey !important;
  border-radius: 10px !important;
}

>>> .v-field__outline {
  display: none;
}

.input-styles {
  background: white;
  height: 30px;
  border: 1px solid #0000003d;
  border-radius: 2rem !important;
  padding-right: 12px;
  width: 100%;
  margin-right: 4px;
  padding: 0 8px;
  align-items: center;
  /* background: #cecece2e; */
  /* padding: 6px 0px 6px 8px; */
}

.input-styles-1 {
  box-shadow: 0px 1px 2px 0px gray;
  padding: 5px;
  background: #ffffff82;
  border-radius: 7px;
  height: 30px;
}

.input-styles-1 {
  height: 32px;
  width: 180px;
  padding: 4px 7px;
  border-radius: 15px;
  border: 1px solid #0000003d;
}

/* .input-styles-focus {
   box-shadow: 0 4px 16px rgba(17, 17, 26, .05), 0 8px 32px rgba(17, 17, 26, .05);
   padding: 1rem 1.5rem;
   border-radius: 60px !important;
   height: 92px;
   -webkit-clip-path: none !important;
   clip-path: none !important;
 } */

/* .input-styles {
   box-shadow: 0px 1px 2px 0px gray;
   padding: 5px;
   background: #ffffff;
   border-radius: 7px;
   height: 44px;
 } */

.input-styles-2 {
  box-shadow: none;
  padding: 0 10px;
  /* padding: 3px 5px 8px 12px; */
  border: 1px solid #0000003d;
  height: 30px !important;
  color: black;
  background: white;
  /* width: 90px; */
  /* height: auto; */
}

/* >>>.select-option .v-select__selection-text {
   color: black !important;
   font-size: 12px !important;
   font-weight: 500;
   position: relative;
   top: -3px;
 } */

/* >>>.input-styles-2 .v-select__selection-text {
   color: black !important;
   font-size: 12px !important;
   font-weight: 500;
   position:relative;
   top:-14px;
 } */

>>> .v-menu > .v-overlay__content {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  left: 505px !important;
  width: 290px !important;
  border-radius: 20px !important;
  position: relative !important;
}

>>> .v-overlay-container .v-overlay .v-select__content {
  left: 2.8px !important;
  width: 300px !important;
  border-radius: 20px !important;
  position: relative !important;
}

/* .form-card {
   width: 100%;
   padding: 20px 0px 20px 20px;
   overflow: unset;
   z-index: 1;
   cursor: pointer;
   background-color: #ffffffe8;
   box-shadow: none !important;
   border-radius: 0px 0px 0px 20px;
 } */

:deep(.select-1 .v-field__append-inner) {
  padding: 0px !important;
}

:deep(.select-1 .v-field__input) {
    min-height: 30px;
  padding: 0;
  color: grey !important;
}

/* >>>.select-1 .v-input--density-default .v-field--variant-plain,
 .v-input--density-default .v-field--variant-underlined {
   --input-control-height: 52px !important;
   --v-field-padding-bottom: 0px;
 } */

:deep(.select-2 .v-field__append-inner) {
  padding: 0px !important;
}

>>> .select-2 .v-field__input {
  padding: 0;
  min-height: 30px;
}

>>> .select-2 .v-input--density-default .v-field--variant-plain,
.v-input--density-default .v-field--variant-underlined {
  --v-input-control-height: 48px;
  --v-field-padding-bottom: 10px !important;
}

/* >>> .select-option .v-input {
  --v-input-chips-margin-bottom: 0 !important;
  --v-input-chips-margin-top: -3px !important;
} */

>>> .select-1 .v-list.v-theme--light.v-list--density-default.v-list--one-line {
  background: #f9f8f8 !important;
}

/* >>>.input-styles .dp__today {
   border: 1px solid #162849 !important;
 } */

>>> .input-styles .dp__menu {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px !important;
  border-radius: 20px !important;
  border: 1px solid #d3d3d361 !important;
  top: 40px !important;
}

>>> .input-styles .dp__cell_inner {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
}

>>> .input-styles .dp__range_between {
  border-radius: 0px !important;
}

>>> .input-styles .dp__date_hover_end:hover {
  background: #1976d2 !important;
  color: white !important;
  border-radius: 50% !important;
}

>>> .input-styles .dp__calendar_item:hover {
  background: #f3f3f3 !important;
  /* color:black !important; */
  border-radius: 50% !important;
}

/* >>>.input-styles .dp__range_end, .dp__range_start, .dp__active_date {
    border-radius: 50% !important;
    margin: 8px !important;
}
 >>>.input-styles .dp__cell_inner:hover{
    border-radius: 50% !important;
    margin: 8px !important;
} */

>>> .dp__date_hover:hover {
  background: #162849;
  color: white;
}

.back-bg {
  width: 100%;
  /* height: 500px; */
  border-radius: 20px;
  padding: 20px;
}

.fromTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 20px;
  top: 60px;
  color: gray;
}

.formbuttons {
  background-color: #94c20e;
}

.labelTop p {
  font-size: 14px;
  margin: 0;
  text-align: left;
  color: white;
  /* color: #3c0ec2; */
  font-weight: 600;
  padding-left: 13px;
}

/* .p-float-label .p-inputwrapper-focus ~ label {
  display: none !important;
}

.p-float-label .p-inputwrapper-filled ~ label {
  display: none !important;
} */

>>> .normal-radio .v-label {
  font-weight: 600 !important;
  opacity: 1 !important;
}

.pax-fonts span {
  font-size: 14px !important;
  color: #000;
  font-weight: 400;
}

.contact-box {
  box-shadow: none;
  background: none;
  /* border: 1px solid lightgray; */
}

>>> .contact-box .v-icon {
  transform: rotate(45deg);
}

.resu-form:hover {
  background-color: lightgray;
  /* padding:5px; */
}

.resu-form {
  padding: 10px;
}

.resu-card {
  border-radius: 10px;
  width: 350px;
  height: 200px;
  text-align: start;
}

.about-us {
  background: url("../assets/about-five-bg.png");
}

>>> .v-responsive {
  left: 115px;
}
>>> .slide-card .v-responsive {
  left: 0px !important;
}
>>> .top-card .v-responsive {
  left: 0px !important;
}
>>> .about-card .v-responsive {
  left: 0px !important;
}
.about-img .v-responsive {
  left: 0px !important;
}

>>> .v-slide-group__prev {
  height: 50px !important;
  width: 50px !important;
  /* text-align: center; */
  margin: auto 0 !important;
  background-color: #162849 !important;
  border-radius: 50px !important;
  z-index: 1 !important;
  position: relative !important;
  left: 40px !important;
}
>>> .v-slide-group__next {
  height: 50px !important;
  width: 50px !important;
  /* text-align: center; */
  margin: auto 0 !important;
  background-color: #162849 !important;
  border-radius: 50px !important;
  z-index: 1 !important;
  position: relative !important;
  left: -37px !important;
}
>>> .mdi-chevron-left::before {
  color: white !important;
}
>>> .mdi-chevron-right::before {
  color: white !important;
}

.top1-card .top-tour-img {
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
}
.top-card:hover .top1-card .top-tour-img {
  transform: scale(1.1);
}


.book-btn {
  font-size: 13px;
  color: white;
  background-color: #94c20e;
  padding: 8px 15px;
  border-radius: 10px;
  font-weight: 600;
}

.subscribeBtn{
  height: 55px; 
  width: 126px;
  font-size: 13px;
  color: white;
  background-color: #94c20e;
  padding: 13px 35px;
  border-radius: 10px;
  font-weight: 600;
}
.offer-card:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
.offer-card {
  padding: 5px;
  border-radius: 10%;
  max-width: 300px;
  height: 200px;
  transition: box-shadow 0.3s;
}
.subscribe-section {
  padding: 150px 0;
  background: url("../assets/bg-img1.jpg") no-repeat center;
  background-size: 100% 500px;
}
.subscribe-section p {
  font-size: 30px;
  font-weight: 700;
  color: white;
}
.subscribe-section input[type="text"] {
  width: 100%;
  padding-left: 10px;
  height: 55px;
  border-radius: 10px;
  border: 1px solid grey;
  margin-right: 10px;
  background-color: white;
  color: grey;
}
.subscribe-section .col-lg-2 {
  width: 11.6%;
}
>>>.subscribe-section .mailtextfield .v-input__details{
  display:block !important;
}

>>>.subscribe-section .mailtextfield .v-input__details .v-messages__message{
  color:white !important;
}

>>> .v-img__img--contain {
  object-fit: cover;
}
>>> .offer-card .v-img__img--contain {
  object-fit: contain;
}
>>> .top-card .v-card {
  border-radius: 0px !important;
}
.input-styles-3 {
  display: none;
}
@media screen and (max-width: 480px) {
  .addmorebtn {
    display: none;
  }
  .clearAll {
    display: none;
  }
  >>> .passenger-card .v-input__control .v-field {
    grid-template-columns: 0% 88%;
  }
  .subscribe-section p {
    font-size: 16px;
  }
  .subscribe-section input[type="text"] {
    width: 100%;
  }
  .subscribe-section .col-xs-4 {
    width: 35%;
  }
  .slide-card {
    width: 224px;
  }
  >>> .v-slide-group__next {
    left: -25px !important;
  }

  .input-styles-3 {
    display: block;
  }
  .mobile-select {
    box-shadow: none;
    padding: 6px 5px 6px 12px;
    border: 1px solid #0000003d;
    height: 30px !important;
    color: black;
    background: #cecece2e;
    height: 35px !important;
  }
  >>> .v-slide-group__prev {
    left: 25px !important;
  }
  .search {
    margin-left: 6px;
  }
  /* >>>.ma-4{
      margin:12px !important;
    } */
}
@media screen and (max-width: 390px) {
  >>> .ma-4 {
    margin: 0px !important;
  }
}
@media screen and (min-width: 400px) and (max-width: 430px) {
  >>> .ma-4 {
    margin: 33px !important;
  }
}
>>> .p-autocomplete-panel .p-component .p-ripple-disabled ul {
  list-style: none !important;
}
.p-float-label label {
  left: -70px;
}
.p-float-label .fromLabel {
  left: -48px;
}

/* .resu-card:hover{
padding:0px;
} */
/* >>>.dp__menu.dp__menu_index.dp__theme_light.dp__menu_transitioned {
   top: -10px !important;
   left: -50px !important;
   transform: none !important;
 } */

/* >>>.dp__menu.dp__menu_index.dp__theme_light.dp__menu_transitioned {

   top: -10px !important;
   transform: none !important;
   left: -50px !important;
 } */
.inter-input {
  /* height: 30px; */
  width: auto;
  font-weight: 600;
}
.flight-search .pos-swap {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #002d5b;
  position: absolute;
  top: 0.9rem;
  right: -1.2rem;
  z-index: 1;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
  border: 0;
  cursor: pointer;
}

.flight-search .pos-swap:hover {
  background-color: #ffc104;
  color: #343534;
}

@media screen and (max-width: 992px) {
  .flight-search .pos-swap {
    right: 0;
    top: 2.5rem;
  }
}
.autocompete-menual-dropdown {
  border: 1px solid lightgray;
  background-color: white;
  cursor: pointer;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  width: 300px;
  /* max-width: 100%; */
  padding: 4px;
  border-radius: 2px;
  margin-top: 18px;
  /* margin-left: -44px; */
  z-index: 99999;
  font-size: 15px;
  font-weight: 500;
}
.vas-autocomplete {
  cursor: pointer;
}
.va-content {
  font-size: 14px;
  font-weight: 500;
  text-align: start;
}
.va-back:hover {
  background-color: lightblue;
}
.hisOptions{
  position: absolute;
  top:0;
  right:0;
  background-color:#94c20e;
  border-bottom-left-radius: 14px;
  cursor: pointer;
  padding: 4px 6px;
  border-top-right-radius: 15px;
}
.searchrecent{
  min-height: auto;
  max-height: 366px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px 0;
}

>>>.v-btn--icon.v-btn--size-default{
    --v-btn-size: 14px !important;
    width: 5px;
    height: 5px;
}
>>>.v-btn--icon .v-icon{
  --v-icon-size-multiplier: 0.87;
    color: #959595;
}

>>>.captchasection .v-field__input{
  min-height: 36px;
  padding: 0px 8px;
  font-size: 14px;
}

.disableInput {
  pointer-events: none;
  opacity: 0.8;
}

.rotate-icon {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

:deep(.v-input__append) {
  margin: 0; 
}

:deep(.advanceOpt .v-label){
  font-size: 12px;
  color:white;
  padding: 0;
  opacity: 1;
}

:deep(.advanceOpt .v-switch__thumb) {
  width: 12px;
  height: 12px;
}

:deep(.advanceOpt .v-switch__track) {
  height: 12px;
  width: 20px;
  opacity: 1;
}

:deep(.advanceOpt .dropdownSection .v-select .v-field__input) {
  padding: 0 !important;
  min-height: 30px;
}

:deep(.dropdownSection .v-field__append-inner) {
  padding: 0px !important;
}

:deep(.dropdownSection .v-selection-control__wrapper) {
  height: 20px;
}

:deep(.advanceOpt .dropdownSection .v-select input::placeholder) {
  font-size: 14px;
  font-weight: 500;
  color: #767676;
  opacity: 1;
  height: 20px;
}

/* InlucudeEclude Radio button */

:deep(.advanceRadio.v-radio-group .v-selection-control--density-default) {
  --v-selection-control-size: 24px !important;
}

:deep(.advanceRadio.v-radio-group .v-label) {
  font-size: 14px;
  font-weight: 500;
}

.dropdownSection .input-styles .v-btn{
  height:30px;
}

.v-text-field.airlineCode{
  width:auto;
  /* width:180px; */
}

:deep(.airlineCode .v-field) {
  padding: 0 6px;
}
:deep(.airlineCode .v-field__input) {
  min-height: 30px;
  padding: 0 6px;
  font-size: 14px;
  font-weight: 500;
}

/* :deep(.dropdownSection .airlineCode input.v-text-field::placeholder) {
  font-size: 14px;
  font-weight: 500;
} */

/* Advanced Stops */
:deep(.adcheckBox .v-selection-control--density-default){
  --v-selection-control-size: 30px !important;
}

:deep(.adcheckBox .v-input__control){
  height: 30px;
}

:deep(.adcheckBox .v-selection-control__input > .v-icon){
  font-size: 18px !important;
}

:deep(.adcheckBox .v-input__control .v-label){
  font-size: 14px;
  font-weight: 500;
}


/* Scroll Bar */

.airlineCard::-webkit-scrollbar {
  width: 5px !important;
  background-color: white !important;
}

.airlineCard::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #5a33ca !important;
  border-radius: 10px !important;
}

.airlineCard::-webkit-scrollbar-thumb {
  background: #5a33ca !important;
  border-radius: 10px !important;
}

/* Firefox */
/* .airlineCard {
  scrollbar-width: thin;
  scrollbar-color: #5a33ca white;
}

.airlineCard::-moz-scrollbar {
  width: 4px;
  background-color: white; 
}
.airlineCard::-moz-scrollbar-track {
  box-shadow: inset 0 0 5px #5a33ca !important;
  border-radius: 10px !important;
}

.airlineCard::-moz-scrollbar-thumb {
  background-color: #5a33ca; 
  border-radius: 10px;
} */

/* Internet Explorer 10+ */
/* .airlineCard {
  -ms-overflow-style: -ms-autohiding-scrollbar;
} */



</style>